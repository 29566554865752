import { useAuth } from '@ui/state/auth';

export function CurrentSiteGuard({ children }: { children: React.ReactNode }) {
  const { currentSite } = useAuth();

  if (!currentSite) {
    return null;
  }

  return <>{children}</>;
}
