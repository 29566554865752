import { craftQuoteUrl } from '@lib';
import { Link, StyleSheet } from '@react-pdf/renderer';
import { EnvConfig } from '@ui/config/env';

const quoteLinkStyles = StyleSheet.create({
  link: {
    fontSize: '8px',
    marginTop: 8,
  },
});

/**
 * Generates and renders a link to ecom website to for customers to order
 * @param quoteId READABLE quote.quoteId
 */
export function QuoteLink({ quoteId }: { quoteId: string }) {
  return (
    <Link
      href={craftQuoteUrl(EnvConfig.getKey('MW_COMPONENTS_URI'), quoteId)}
      style={quoteLinkStyles.link}
    >
      Click to Purchase These Items Online
    </Link>
  );
}
