import type { AdminMetricsResponse, AdminMetricsStat } from '@lib/responses';
import { createColumnHelper } from '@tanstack/react-table';
import DataTable from '@ui/components/DataTable';
import { QueryError, QueryLoading, QueryNoResults } from '@ui/components/query';
import { useAdminMetricsByDate } from '@ui/data/admin/metrics';
import { useMemo } from 'react';

type AdminMetricsTableProps = {
  startDate: Date;
  endDate: Date;
};

export const AdminMetricsTable = ({
  startDate,
  endDate,
}: AdminMetricsTableProps) => {
  const metricsQuery = useAdminMetricsByDate(startDate, endDate);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<AdminMetricsResponse>();

    return [
      columnHelper.accessor('siteId', {
        header: 'Site',
        cell: (props) => props.getValue(),
      }),
      columnHelper.accessor('quotes', {
        header: 'SNAP Quotes',
        cell: (props) => {
          const { total, percentage } = props.getValue<AdminMetricsStat>();
          return `${total} (${percentage}%)`;
        },
      }),
      columnHelper.accessor('unitPrice', {
        header: 'Accepted Unit Price',
        cell: (props) => {
          const { total, percentage } = props.getValue<AdminMetricsStat>();
          return `${total} (${percentage}%)`;
        },
      }),
      columnHelper.accessor('leadTime', {
        header: 'Accepted Lead Time',
        cell: (props) => {
          const { total, percentage } = props.getValue<AdminMetricsStat>();
          return `${total} (${percentage}%)`;
        },
      }),
      columnHelper.accessor('both', {
        header: 'Accepted Both',
        cell: (props) => {
          const { total, percentage } = props.getValue<AdminMetricsStat>();
          return `${total} (${percentage}%)`;
        },
      }),
    ];
  }, []);

  if (metricsQuery.status === 'pending') {
    return <QueryLoading />;
  }

  if (metricsQuery.status === 'error') {
    return (
      <QueryError
        title="Failed to load metrics"
        error={metricsQuery.error}
        onRetry={metricsQuery.refetch}
      />
    );
  }

  if (metricsQuery.data.length === 0) {
    return (
      <QueryNoResults description="Ensure that you have access to one or more sites" />
    );
  }

  return <DataTable columns={columns} data={metricsQuery.data} />;
};
