import { useQuery } from '@tanstack/react-query';
import { adminMetricsService } from '@ui/services';

export const adminMetricsByDateQuery = (startDate: Date, endDate: Date) => {
  return {
    queryKey: ['admin', 'metrics', startDate, endDate],
    queryFn: () => adminMetricsService.getByDate(startDate, endDate),
  };
};

export function useAdminMetricsByDate(startDate: Date, endDate: Date) {
  return useQuery({
    ...adminMetricsByDateQuery(startDate, endDate),
  });
}
