import { Flex, Text } from '@chakra-ui/react';
import { totalLineItem } from '@lib/calculations';
import type { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import type { QuoteBuilderForm } from '../QuoteBuilder';
import type { LinePathType } from '../types';

interface LineItemTotalProps {
  linePath: LinePathType;
}

export const LineItemTotal: FC<LineItemTotalProps> = ({ linePath }) => {
  const { control } = useFormContext<QuoteBuilderForm>();

  const unitPrice = useWatch({
    control,
    name: `${linePath}.unitPrice`,
  });

  const quantity = useWatch({
    control,
    name: `${linePath}.quantity`,
  });

  return (
    <Flex justifyContent="end" width="full">
      <Text fontWeight="semibold" fontSize={14}>
        Line Total: {totalLineItem(+unitPrice, +quantity)}
      </Text>
    </Flex>
  );
};
