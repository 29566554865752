import { z } from 'zod';
import { costBucketsSchema } from '..';

export const reqCostsInputSchema = z.object({
  /** essentially the "original" qty used to group & derive other values used in actual calculations */
  basisQty: z.number(),
  fixedQty: z.number(),
  matUnitCost: z.number(),
  matMinCost: z.number(),
  matFixedCost: z.number(),
  labUnitCost: z.number(),
  burUnitCost: z.number(),
  srvUnitCost: z.number(),
  burPct: z.number(),
  burPerUnit: z.number(),
});

export const calcReqCostsInputSchema = reqCostsInputSchema
  .omit({ basisQty: true })
  .merge(
    z.object({
      qty: z.number(),
      subOpsCosts: z.array(costBucketsSchema),
    }),
  );

export type CalcReqCostsInput = z.infer<typeof calcReqCostsInputSchema>;
