import { useQuery } from '@tanstack/react-query';
import { customerService } from '@ui/services';

export const customerTiersQuery = () => {
  return {
    queryKey: ['customerTiers'],
    queryFn: () => customerService.getCustomerTiers(),
  };
};

export const useGetCustomerTiers = () => {
  return {
    ...useQuery({
      ...customerTiersQuery(),
    }),
  };
};
