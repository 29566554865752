import { Icon as ChakraIcon, Flex } from '@chakra-ui/react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type GroupBase, components } from 'react-select';
import ReactSelect, { type Props } from 'react-select';

interface SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends Props<Option, IsMulti, Group> {
  leftElement?: React.ReactElement;
}

export function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ leftElement, ...props }: SelectProps<Option, IsMulti, Group>) {
  return (
    <ReactSelect
      {...props}
      styles={{
        control: (styles) => ({
          ...styles,
          backgroundColor: 'white',
          borderRadius: '0',
          borderColor: 'var(--chakra-colors-chakra-border-color)',
        }),
        placeholder: (styles) => ({
          ...styles,
          color: '#b7beca',
        }),
        valueContainer: (styles) => ({
          ...styles,
          padding: leftElement ? 0 : '0 1rem',
        }),
      }}
      components={{
        Control: ({ children, ...props }) => (
          <components.Control {...props}>
            {leftElement}
            {children}
          </components.Control>
        ),
        IndicatorSeparator: () => null,
        DropdownIndicator: () => {
          return (
            <Flex w={10} justifyContent="center" alignItems="center">
              <ChakraIcon as={FontAwesomeIcon} icon={faChevronDown} w={3} />
            </Flex>
          );
        },
      }}
    />
  );
}
