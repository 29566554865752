import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
} from '@chakra-ui/react';

export type QueryNoResultsProps = {
  title?: string;
  description?: string;
};

export function QueryNoResults({ title, description }: QueryNoResultsProps) {
  return (
    <Box padding={6} width="100%">
      <Alert status="info">
        <AlertIcon />
        <AlertTitle>{title || 'No results found'}</AlertTitle>
        {description && <AlertDescription>{description}</AlertDescription>}
      </Alert>
    </Box>
  );
}
