import type { PartOptionMetadata } from '@lib/validation/part-option';
import type { UnknownRecord } from 'type-fest';
import { calcQuoteLine } from '..';
import { maybeParseNum, round } from '../util';
import type { AbstractPricingStrategy } from './strategies/abstract';
import type { PriceParts, RecalcLineData } from './types';

type ThisPricingStrategy = Pick<AbstractPricingStrategy, 'throwWithContext'>;

export function calculatePartOptions(
  this: ThisPricingStrategy,
  lineData: RecalcLineData,
): PriceParts {
  let errorMeta: UnknownRecord = {};
  const throwError = (msg: string): never => {
    return this.throwWithContext(msg, errorMeta);
  };

  const { partOptions } = lineData;

  const {
    quantity,
    pricing: { unitPrice, baseUnitPrice },
  } = calcQuoteLine(lineData);

  errorMeta = { ...errorMeta, quantity, unitPrice, baseUnitPrice, partOptions };

  if (!baseUnitPrice) {
    return throwError('Missing baseUnitPrice');
  }

  if (!partOptions || !quantity || quantity <= 0) {
    return {
      unitPrice: unitPrice || baseUnitPrice || 0, // reduce possibility of suggesting 0 price while still respecting prev user input
      baseUnitPrice,
    };
  }

  let unitPriceAddition = 0;

  for (const { metadata, weight } of partOptions) {
    errorMeta = { ...errorMeta, unitPriceAddition, weight, metadata };
    const parsedWeight = maybeParseNum(weight);

    switch (metadata.calculationMode) {
      /**
       * default - add price to line price
       * unitPriceAddition = price / quantity
       * If price is null, add nothing
       */
      case 'default':
        // Some part options with default pricing don't have a price;
        // in this case, we should skip them
        if (!metadata.price) {
          break;
        }

        unitPriceAddition = unitPriceAddition + metadata.price / quantity;
        break;

      /**
       * batch-quantity
       * unitPriceAddition = price / unitsPerBatch
       */
      case 'batch-quantity': {
        if (!metadata.price) {
          return throwError(`Missing price for part option ${metadata.name}`);
        }

        if (!metadata.unitsPerBatch) {
          return throwError(
            `Missing unitsPerBatch for part option ${metadata.name}`,
          );
        }

        const batches = Math.ceil(quantity / metadata.unitsPerBatch);
        const totalBatchesPrice = batches * metadata.price;
        const pricePerUnit = totalBatchesPrice / quantity;

        unitPriceAddition = unitPriceAddition + pricePerUnit;
        break;
      }

      /**
       * batch-weight
       * unitPriceAddition = (price / unitsPerBatch) * weight
       */
      case 'batch-weight': {
        if (!metadata.price) {
          return throwError(`Missing price for part option ${metadata.name}`);
        }

        if (!metadata.unitsPerBatch || metadata.unitsPerBatch <= 0) {
          return throwError(
            `Missing unitsPerBatch for part option ${metadata.name}`,
          );
        }

        if (!parsedWeight) {
          return throwError(`Missing weight for part option ${metadata.name}`);
        }

        const batches = Math.ceil(
          (quantity * parsedWeight) / metadata.unitsPerBatch,
        );
        const totalBatchesPrice = batches * metadata.price;
        const pricePerUnit = totalBatchesPrice / quantity;

        unitPriceAddition = unitPriceAddition + pricePerUnit;
        break;
      }

      /**
       * lot
       * unitPriceAddition = lot.unitPrice where lot.qty >= quantity
       * Find the first lot price that is greater than or equal to the quantity
       * If no lot price is found, add the minimum lot charge divided by the quantity
       */
      case 'lot': {
        if (!metadata.minimumLotCharge) {
          return throwError(
            `Missing minimumLotCharge for part option ${metadata.name}`,
          );
        }

        const sortedLotPrices = metadata.lotPrices.sort(
          (a, b) => a.qty - b.qty,
        );

        const unmetThresholdIndex = sortedLotPrices.findIndex(
          (lot) => lot.qty >= quantity,
        );

        // If unmetThresholdIndex is 0, then the quantity is less than the first lot quantity,
        // so we use the minimumLotCharge
        if (unmetThresholdIndex === 0) {
          unitPriceAddition =
            unitPriceAddition + metadata.minimumLotCharge / quantity;
          break;
        }

        let lotPrice = null;

        // If unmetThresholdIndex is -1, then the quantity is greater than or equal to the
        // last lot quantity, so we use the last lot price
        if (unmetThresholdIndex === -1) {
          lotPrice = sortedLotPrices[sortedLotPrices.length - 1];
        } else {
          lotPrice = sortedLotPrices[unmetThresholdIndex - 1];
        }

        if (!lotPrice?.unitPrice) {
          return throwError(
            `No lot price found for part option ${metadata.name}`,
          );
        }

        unitPriceAddition = unitPriceAddition + lotPrice.unitPrice;

        break;
      }

      default:
        return throwError(
          `Unknown calculation mode ${metadata.calculationMode} for part option ${metadata.name}`,
        );
    }
  }

  const roundedBasePrice = round(baseUnitPrice, {
    min: 2,
    small: 2,
    max: 2,
  });

  const newPrice = round(unitPriceAddition, {
    min: 2,
    small: 2,
    max: 2,
  });

  const roundedPrice = round(roundedBasePrice + newPrice, {
    min: 2,
    small: 2,
    max: 2,
  });

  return {
    unitPrice: roundedPrice,
    baseUnitPrice,
  };
}

export const hasPartOptionPrice = ({
  calculationMode,
  price,
}: PartOptionMetadata): boolean => {
  if (calculationMode === 'lot') {
    return true;
  }

  if (!price) {
    return false;
  }

  return true;
};
