/**
 * Helpers for working with URLs for compatibility with Craft/Yii.
 *
 * More context: https://teams.microsoft.com/l/message/19:_iLMhGFf5tQXYfxklF-oxhdlv5rjDEq25eE6j6iYXQA1@thread.tacv2/1709318346629?tenantId=c70cfdb0-d54a-48c7-94b5-3ca6beb09191&groupId=83a903ca-8728-4377-91cc-1151b6a699ba&parentMessageId=1709300258268&teamName=Software%20Engineering&channelName=General&createdTime=1709318346629
 */

const oddEncodeURIComponent = (input: string) => {
  return input.replace('+', '-').replaceAll('/', '_');
};

const oddDecodeURIComponent = (input: string) => {
  return input.replaceAll('-', '+').replaceAll('_', '/');
};

export const craftBase64encodeURIComponent = (input: string) => {
  return oddEncodeURIComponent(btoa(input));
};

export const craftBase64DecodeURIComponent = (input: string) => {
  return oddDecodeURIComponent(atob(input));
};

export const craftQuoteUrl = (baseUrl: string, quoteId: string) => {
  return `${baseUrl}/account/open-quotes/${craftBase64encodeURIComponent(
    quoteId,
  )}`;
};
