import { Flex, Text } from '@chakra-ui/react';
import { type QuoteLineResponse, calcQuoteLine, roundToString } from '@lib';
import { calculateAndFormatLeadTimeWeeks } from '@lib/calculations/lead-time';
import {
  QuoteLineActions,
  QuoteLinePartDetails,
} from '@ui/components/QuoteLine';
import type { FC } from 'react';
import { FormSectionTitle } from '../../components/Text';
import { DocumentsModal } from './components/actions';

interface QuoteLineItemProps {
  line: QuoteLineResponse;
  index: number;
}

export const ViewQuoteLineItem: FC<QuoteLineItemProps> = ({ line, index }) => {
  const { material, partId, type, description } = line.part;

  const automatedUnitPrice = Number(line.automatedUnitPrice);

  const hasAutomation =
    !Number.isNaN(automatedUnitPrice) && automatedUnitPrice > 0;

  const { operationCosts, quantityBreaks, ...restLine } = line;
  const mainLineCalcs = calcQuoteLine({
    ...restLine,
    detailed: { operationCosts },
  });
  const { margins, unitCosts } = mainLineCalcs ?? {};

  return (
    <Flex
      backgroundColor={'#EDF2F7'}
      paddingY={3}
      paddingX={5}
      borderRadius={5}
      display="flex"
      direction="column"
      marginBottom={5}
      gap={3}
    >
      <Flex
        justifyContent="space-between"
        alignItems={{ base: 'start', md: 'center' }}
        width="100%"
      >
        <QuoteLinePartDetails
          partId={partId}
          description={description}
          type={type}
          material={material}
        />

        <QuoteLineActions>
          <DocumentsModal partId={partId} documents={line.documents} />
        </QuoteLineActions>
      </Flex>

      <table>
        <thead>
          <tr>
            <th align="left">
              <FormSectionTitle fontSize={11} mr={6}>
                Quantity
              </FormSectionTitle>
            </th>
            <th align="left">
              <FormSectionTitle fontSize={11} mr={6}>
                Unit Price
              </FormSectionTitle>
            </th>
            {hasAutomation && (
              <th align="left">
                <FormSectionTitle fontSize={11} mr={6}>
                  Suggested Unit Price
                </FormSectionTitle>
              </th>
            )}
            <th align="left">
              <FormSectionTitle fontSize={11} mr={6}>
                Estimated Total Unit Cost
              </FormSectionTitle>
            </th>
            <th align="left">
              <FormSectionTitle fontSize={11} mr={6}>
                Gross Margin
              </FormSectionTitle>
            </th>
            <th align="left">
              <FormSectionTitle fontSize={11} mr={6}>
                Lead Time
              </FormSectionTitle>
            </th>
          </tr>
        </thead>

        <tbody>
          <ViewQuoteLineItemTableRow
            quantity={line.quantity}
            unitPrice={line.unitPrice}
            hasAutomation={hasAutomation}
            automatedUnitPrice={automatedUnitPrice}
            grossCost={unitCosts?.grossCost}
            grossMarginPercent={margins?.grossMarginPercent}
            leadTime={line.leadTime}
          />

          {line.quantityBreaks.map((lineBreak, i) => {
            const quantityBreakCalcs = calcQuoteLine({
              ...lineBreak,
              detailed: { operationCosts },
            });
            const { margins, unitCosts } =
              quantityBreakCalcs?.detailed ?? quantityBreakCalcs ?? {};

            const lineBreakAutomatedUnitPrice = Number(
              lineBreak.automatedUnitPrice,
            );

            return (
              <ViewQuoteLineItemTableRow
                key={lineBreak.id}
                quantity={lineBreak.quantity}
                unitPrice={lineBreak.unitPrice}
                hasAutomation={hasAutomation}
                automatedUnitPrice={lineBreakAutomatedUnitPrice}
                grossCost={unitCosts?.grossCost}
                grossMarginPercent={margins?.grossMarginPercent}
                leadTime={null}
              />
            );
          })}
        </tbody>
      </table>
    </Flex>
  );
};

function ViewQuoteLineItemTableRow({
  quantity,
  unitPrice,
  hasAutomation,
  automatedUnitPrice,
  grossCost,
  grossMarginPercent,
  leadTime,
}: {
  quantity: string;
  unitPrice: string;
  hasAutomation: boolean;
  automatedUnitPrice: number;
  grossCost: number | undefined;
  grossMarginPercent: number | undefined;
  leadTime: number | null;
}) {
  const AutomatedPriceText =
    !Number.isNaN(automatedUnitPrice) && automatedUnitPrice > 0 ? (
      <Text>
        ${roundToString(Number(automatedUnitPrice), { min: 2, small: 4 })}
      </Text>
    ) : (
      <Text>N/A</Text>
    );

  return (
    <tr>
      <td>
        <Text>{quantity.toLocaleString()}</Text>
      </td>
      <td>
        <Text>${roundToString(Number(unitPrice), { min: 2, small: 4 })}</Text>
      </td>
      {hasAutomation && <td>{AutomatedPriceText}</td>}
      <td>
        {grossCost && `$${roundToString(grossCost, { min: 2, small: 4 })}`}
      </td>
      <td>{grossMarginPercent && `${grossMarginPercent}%`}</td>
      <td>{leadTime ? calculateAndFormatLeadTimeWeeks(leadTime) : 'N/A'}</td>
    </tr>
  );
}
