import {
  Box,
  Flex,
  HStack,
  Link,
  Spacer,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  faCircleMinus,
  faCirclePlus,
  faDollarSign,
  faHashtag,
  faPercent,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  MinQuantityCalculator,
  MinimumOrderLineQuantityError,
  calcQuoteLine,
  isTargetContributionStrategy,
} from '@lib';
import {
  QuoteLineActions,
  QuoteLinePartDetails,
} from '@ui/components/QuoteLine';
import { useGetPartOptions } from '@ui/data/part-options';
import { useActiveSiteConfig } from '@ui/hooks';
import { useAuth } from '@ui/state/auth';
import { getErrorPropsBase } from '@ui/validation/ui-manager';
import { AxiosError } from 'axios';
import { daysToWeeks, weeksToDays } from 'date-fns';
import { get } from 'lodash-es';
import {
  type FC,
  type MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import React from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { P, match } from 'ts-pattern';
import { ComparisonInput } from '../../components/form/ComparisonField';
import { quoteService } from '../../services';
import { useQuoteLineContext } from '../../state/QuoteLineContextProvider';
import { PartOptions } from './PartOptions';
import type { QuoteBuilderForm, QuoteFormLine } from './QuoteBuilder';
import { QuoteLineDetails } from './QuoteLineDetails';
import { LineItemFooter } from './components/LineItemFooter';
import {
  DeleteAction,
  DetailsToggle,
  DocumentsModal,
  NoteModal,
} from './components/actions';
import { SyncAction } from './components/actions/SyncAction';
import { makeLastWinProps } from './history-helpers';
import { useFinishedPartIdBuilder } from './hooks/useFinishedPartIdBuilder';
import { useValidationToast } from './hooks/useToast';
import type { MarginTypes } from './types';

const QuoteLineItem: FC = React.memo(() => {
  const {
    part,
    partHistory,
    partHistoryCalcs,
    mainLinePath,
    index,
    onDelete,
    setIsSaving,
    lastWinCalcs,
    mostRecentQuoteLine,
    syncEngineering,
    isSyncing,
    lineManager,
    pricingCalculator,
  } = useQuoteLineContext();
  const { currentSite } = useAuth();

  const { register, control, setValue, getValues, trigger } =
    useFormContext<QuoteBuilderForm>(); // TODO: pick out only what we need from form type
  /** READABLE quote.quoteId */
  const quoteId = useWatch({ name: 'quoteId', control });
  const mainLineQuantity = useWatch({
    name: `${mainLinePath}.quantity`,
    control,
  });
  // TODO(bb): trigger is expensive to call in this context so we want to
  //  isolate the min-qty validation (and validation in general) to a custom hook
  const marketCode = useWatch({ name: 'customerHeader.marketCode', control });
  const drawingId = partHistory?.engineering.masters[0]?.drawing;
  const drawingRevision = partHistory?.engineering.masters[0]?.drawingRevision;

  useEffect(() => {
    trigger(`${mainLinePath}.quantity`);
  }, [trigger, mainLinePath, marketCode]);

  const { pricingConfig: sitePricingConfig } = useActiveSiteConfig();
  const pricingConfigConsolidated =
    partHistoryCalcs?.suggestions?.pricingConfigConsolidated ??
    sitePricingConfig;

  useEffect(() => {
    const { masterEng } = partHistoryCalcs?.masterPrime ?? {};
    if (masterEng) {
      masterEng.drawing &&
        setValue(`${mainLinePath}.drawing`, masterEng.drawing);
      masterEng.drawingRevision &&
        setValue(`${mainLinePath}.drawingRevision`, masterEng.drawingRevision);
    }
  }, [partHistoryCalcs?.masterPrime, mainLinePath, setValue]);

  const {
    fields: quantityBreaks,
    remove: removeQuantityBreak,
    append: appendQuantityBreak,
  } = useFieldArray({
    name: `${mainLinePath}.quantityBreaks`,
    control,
  });

  const curLineItem = getValues(mainLinePath);
  const { errors } = useFormState({ name: mainLinePath, control });

  useFinishedPartIdBuilder(mainLinePath);

  const [detailsToggled, setDetailsToggled] = useState<boolean>(false);

  const noteDraftRef = useRef<string | undefined>();
  const {
    isOpen: isNoteOpen,
    onClose: onNoteClose,
    onOpen: onNoteOpen,
  } = useDisclosure({
    id: `note-modal.${index}`,
  });
  const noteInputRef: MutableRefObject<HTMLTextAreaElement | null> =
    useRef<HTMLTextAreaElement>(null);
  // focus end of input on note textarea mount
  const noteInputRefFocusEnd = useCallback(
    (node: HTMLTextAreaElement | null) => {
      if (node) {
        node.focus();
        node.setSelectionRange(node.value.length, node.value.length);
      }

      noteInputRef.current = node;
    },
    [],
  );

  const { genericErrorToast } = useValidationToast();

  const { material, partId, type, description } = part;
  const { lastWin } = partHistory ?? {};

  const {
    lastWinContrMarginPercent,
    lastWinGrossMarginPercent,
    unitPrice: lastWinUnitPrice,
    lastWonAtFormatted,
    quantity,
  } = makeLastWinProps({ lastWinCalcs, lastWin, mostRecentQuoteLine });

  const partOptionsQuery = useGetPartOptions(partId);

  const borderProps = match(lineManager)
    .with({ mostSevere: P.select(P.not(P.nullish)) }, (err) => ({
      borderColor: err.color,
      borderStyle: 'solid',
      borderWidth: '3px',
    }))
    .otherwise(() => ({}));

  const handleDetailsToggle = () => {
    setDetailsToggled(!detailsToggled);
  };

  const handleAddQuantityBreak = useCallback(() => {
    const quoteLine = getValues(mainLinePath);
    // get calcs here
    // TODO: add automatedUnitPrice
    //       add automatedMargin
    //       add unitCosts
    const QTY_BRK_QTY_SCALE = 1;
    // For now just use the last qty break (or main line if no brks) qty
    const basisLine = quoteLine.quantityBreaks.at(-1) ?? quoteLine;
    const { quantity: basisQty, ...restBasisLine } = basisLine;
    const initQty = (+basisQty || 1) * QTY_BRK_QTY_SCALE;
    // Pass the main line's values (i.e. costs & margins) for the pricing calculator to use as
    // targets if relevent
    const { unitPrice, baseUnitPrice } =
      pricingCalculator.recalcPriceOnQtyChange({
        ...quoteLine,
        quantity: initQty,
        unitPrice: null,
      });

    const { margins } = calcQuoteLine({
      ...quoteLine,
      quantity: initQty,
      unitPrice,
      baseUnitPrice,
    });

    const latestBreakNumber = quoteLine.quantityBreaks.at(-1)?.breakNumber;

    const newQtyBreak: QuoteFormLine['quantityBreaks'][number] = {
      breakNumber: latestBreakNumber ? latestBreakNumber + 1 : 1,
      quantity: initQty,
      unitPrice: unitPrice || '',
      baseUnitPrice: baseUnitPrice ?? '',
      contributionMarginPercent: margins?.contributionMarginPercent,
      grossMarginPercent: margins?.grossMarginPercent,
    };

    appendQuantityBreak(newQtyBreak);
  }, [mainLinePath, pricingCalculator, appendQuantityBreak, getValues]);

  const handleRemoveQuantityBreak = async (index: number) => {
    // need new route method to delete qty break
    const quoteLine = getValues(mainLinePath);
    try {
      setIsSaving(true);
      const qtyBreak = quoteLine.quantityBreaks[index];
      // only try deleting from db if it's been persisted (has id)
      if (quoteLine.id && qtyBreak.id) {
        await quoteService.deleteQuoteLineItemQuantityBreak(
          quoteId,
          quoteLine.id,
          qtyBreak.id,
        );
      }
      removeQuantityBreak(index);
      setIsSaving(false);
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        genericErrorToast(
          `Unexpected error removing quantity break: ${err.message}`,
        );
      }
      setIsSaving(false);
    }
  };

  const handleRemoveMainLine = useCallback(async () => {
    // Abort if no quantity breaks
    if (quantityBreaks.length === 0) {
      return;
    }

    // Fetch and delete first quantity break
    const qb = getValues(`${mainLinePath}.quantityBreaks.0`);

    await handleRemoveQuantityBreak(0);

    // Set main line values to first quantity break
    setValue(`${mainLinePath}.quantity`, qb.quantity);
    setValue(`${mainLinePath}.unitPrice`, qb.unitPrice);
    setValue(
      `${mainLinePath}.contributionMarginPercent`,
      qb.contributionMarginPercent,
    );
    setValue(`${mainLinePath}.grossMarginPercent`, qb.grossMarginPercent);
  }, [
    mainLinePath,
    quantityBreaks,
    getValues,
    setValue,
    handleRemoveQuantityBreak,
  ]);

  // TODO (nt): QuoteBuilder.tsx handles loading and validating
  // the other line-specific data which currently doesn't use
  // React Query. We should refactor those to use React Query, potentially
  // leveraging the API to handle validation. +1 (bb)
  if (partOptionsQuery.status === 'pending' && partOptionsQuery.isEnabled) {
    return null;
  }

  // TODO (bb): I hate that we use these adhoc flaky existence checks &
  //  dictating preferences all over the place in lieu of a simple view config
  //  but maintaining legacy behavior for now.
  let marginViewType: MarginTypes = 'grossMarginPercent';
  if (isTargetContributionStrategy(pricingConfigConsolidated.pricingStrategy)) {
    marginViewType = 'contributionMarginPercent';
  }
  // TODO(bb): See above comment for related effect + `form.trigger` but this should be
  //  in the custom min qty validator hook as well, and set via `form.setError`
  const marketSiteRequirements = MinQuantityCalculator.calculateRequirement(
    currentSite!,
    partHistoryCalcs?.marketPricing.codeMap ?? null,
    marketCode,
  );

  const minimumQuantityError = new MinimumOrderLineQuantityError(
    marketSiteRequirements,
  );

  const hasInvalidQuantity =
    Number(mainLineQuantity) < marketSiteRequirements.requiredQuantity;

  return (
    <Box pointerEvents={isSyncing ? 'none' : 'all'}>
      <HStack
        backgroundColor="mw.panelGrey"
        paddingY={3}
        paddingX={5}
        {...borderProps}
      >
        <VStack>
          <Flex
            justifyContent="space-between"
            alignItems={{ base: 'start', md: 'center' }}
            width="100%"
          >
            <QuoteLinePartDetails
              partId={partId}
              description={description}
              type={type}
              material={material}
              lastWonAt={lastWonAtFormatted}
              drawingId={drawingId}
              drawingRevision={drawingRevision}
              lineManager={lineManager}
            />

            <QuoteLineActions>
              <SyncAction handleSync={syncEngineering} isSyncing={isSyncing} />

              <NoteModal linePath={mainLinePath} part={part} />

              <DocumentsModal
                linePath={mainLinePath}
                partId={part.partId}
                quoteId={quoteId}
              />

              <DetailsToggle
                isOpen={detailsToggled}
                onClick={handleDetailsToggle}
              />

              <DeleteAction handleDelete={() => onDelete(index)} />
            </QuoteLineActions>
          </Flex>

          <Flex
            className="data"
            width={['66%%', '66%%', '100%']}
            borderLeft="1px solid"
            flexDirection={{ base: 'column', md: 'row' }}
            borderColor={{ base: 'rgba(0,0,0,0.1)', md: 'transparent' }}
          >
            {/* Quantity */}
            <ComparisonInput
              title="Quantity"
              type="number"
              commonInputProps={{
                marginBottom: -2,
                leftIcon: faHashtag,
                textAlign: 'right',
              }}
              beforeInputProps={{
                defaultValue: quantity,
              }}
              rightTitle={
                <Text fontSize={11} fontWeight="bold">
                  Today
                </Text>
              }
              hideAfterInformation={true}
              afterInputElement={
                <QuantityBreakButtons
                  showAdd
                  onAdd={handleAddQuantityBreak}
                  showRemove={quantityBreaks.length > 0}
                  onRemove={handleRemoveMainLine}
                />
              }
              // TODO: this input should only accept integers
              // either conditional rhf validation or chakra NumberInputs
              afterInputProps={{
                min: 1,
                ...register(`${mainLinePath}.quantity`, {
                  required: 'Quantity is required',
                  min: {
                    value: 1,
                    message: 'Must order at least 1 unit',
                  },
                  valueAsNumber: true,
                }),
                changeOnBlur: true,
                blurOnEnterKey: true,
                errorIconProps: hasInvalidQuantity
                  ? getErrorPropsBase(minimumQuantityError)
                  : undefined,
                isInvalid:
                  !!get(errors, `${mainLinePath}.quantity.message`) ||
                  hasInvalidQuantity,
                errorMsg:
                  get(errors, `${mainLinePath}.quantity.message`) ||
                  minimumQuantityError.message,
              }}
            />

            {/* Unit Price */}
            <ComparisonInput
              title="Unit Price"
              commonInputProps={{
                marginBottom: -2,
                leftIcon: faDollarSign,
                textAlign: 'right',
              }}
              hideAfterInformation={true}
              rightTitle={
                <Text fontSize={11} fontWeight="bold">
                  Today
                </Text>
              }
              afterLabel="Today"
              beforeInputProps={{
                defaultValue: lastWinUnitPrice,
              }}
              afterInputProps={{
                ...register(`${mainLinePath}.unitPrice`, {
                  validate: (val) =>
                    +val > 0 || 'Unit Price must be greater than 0',
                  valueAsNumber: true,
                }),
                changeOnBlur: true,
                blurOnEnterKey: true,
                isInvalid: !!get(errors, `${mainLinePath}.unitPrice.message`),
                errorMsg: get(errors, `${mainLinePath}.unitPrice.message`),
              }}
              type="number"
            />

            {/* Contribution Margin */}
            {marginViewType === 'contributionMarginPercent' && (
              <ComparisonInput
                commonInputProps={{
                  marginBottom: -2,
                  rightIcon: faPercent,
                  textAlign: 'right',
                }}
                title="Contribution Margin"
                hideAfterInformation={true}
                rightTitle={
                  <Text fontSize={11} fontWeight="bold">
                    Today
                  </Text>
                }
                beforeInputProps={{
                  defaultValue: lastWinContrMarginPercent || '',
                }}
                afterInputProps={{
                  ...register(`${mainLinePath}.contributionMarginPercent`, {
                    valueAsNumber: true,
                  }),
                }}
                type="number"
              />
            )}

            {/* Gross Margin */}
            {marginViewType === 'grossMarginPercent' && (
              <ComparisonInput
                commonInputProps={{
                  marginBottom: -2,
                  rightIcon: faPercent,
                  textAlign: 'right',
                }}
                title="Gross Margin"
                hideAfterInformation={true}
                rightTitle={
                  <Text fontSize={11} fontWeight="bold">
                    Today
                  </Text>
                }
                beforeInputProps={{
                  defaultValue: lastWinGrossMarginPercent || '',
                }}
                afterInputProps={{
                  ...register(`${mainLinePath}.grossMarginPercent`, {
                    valueAsNumber: true,
                  }),
                  changeOnBlur: true,
                  blurOnEnterKey: true,
                }}
                type="number"
              />
            )}

            {/* Lead Time */}
            <Controller
              name={`${mainLinePath}.leadTime`}
              control={control}
              render={({ field }) => (
                <ComparisonInput
                  title="Lead Time (Weeks)"
                  type="number"
                  rightTitle={
                    <Text fontSize={11} fontWeight="bold">
                      Today
                    </Text>
                  }
                  hideAfterInformation={true}
                  commonInputProps={{ marginBottom: -2, textAlign: 'right' }}
                  beforeInputProps={{
                    defaultValue: lastWinCalcs?.leadTimeWeeks || 'N/A',
                  }}
                  afterInputProps={{
                    ...field,
                    value:
                      (field.value && daysToWeeks(field.value)) ?? undefined,
                    variant: 'outline',
                    backgroundColor: 'white',
                    onChange: (e) =>
                      field.onChange(
                        (e.target.value && weeksToDays(+e.target.value)) ||
                          null,
                      ),
                  }}
                />
              )}
            />
          </Flex>

          {/* Quantity Breaks */}
          {quantityBreaks.map((qb, index) => {
            const qbLinePath =
              `${mainLinePath}.quantityBreaks.${index}` as const;
            return (
              <Flex
                key={qb.id}
                className="data"
                width={['66%%', '66%%', '100%']}
                borderLeft="1px solid"
                flexDirection={{ base: 'column', md: 'row' }}
                borderColor={{ base: 'rgba(0,0,0,0.1)', md: 'transparent' }}
              >
                {/* Quantity */}
                <ComparisonInput
                  title="Quantity"
                  hideTitleInformation={true}
                  hideBeforeInformation={true}
                  hideAfterInformation={true}
                  commonInputProps={{
                    marginBottom: -2,
                    leftIcon: faHashtag,
                    textAlign: 'right',
                  }}
                  beforeInputProps={{
                    defaultValue: quantity,
                    leftIconProps: { icon: faHashtag, visibility: 'hidden' },
                  }}
                  // TODO: this input should only accept integers
                  // either conditional rhf validation or chakra NumberInputs
                  afterInputProps={{
                    ...register(`${qbLinePath}.quantity`, {
                      required: 'Quantity is required',
                      min: lastWin?.orderQuantity && {
                        value: 1,
                        message: 'Must order at least 1 unit',
                      },
                      valueAsNumber: true,
                    }),
                    changeOnBlur: true,
                    blurOnEnterKey: true,
                    isInvalid: !!get(errors, `${qbLinePath}.quantity.message`),
                    errorMsg: 'Quantity break quantity cannot be empty',
                  }}
                  afterInputElement={
                    <QuantityBreakButtons
                      showRemove
                      onRemove={() => handleRemoveQuantityBreak(index)}
                    />
                  }
                  type="number"
                />

                {/* Unit Price */}
                <ComparisonInput
                  title="Unit Price"
                  hideTitleInformation={true}
                  hideBeforeInformation={true}
                  hideAfterInformation={true}
                  commonInputProps={{
                    marginBottom: -2,
                    leftIcon: faDollarSign,
                    textAlign: 'right',
                  }}
                  beforeInputProps={{
                    defaultValue: lastWinUnitPrice,
                    leftIconProps: { icon: faDollarSign, visibility: 'hidden' },
                  }}
                  afterInputProps={{
                    ...register(`${qbLinePath}.unitPrice`, {
                      validate: (val) =>
                        +val > 0 || 'Unit Price must be greater than 0',
                      valueAsNumber: true,
                    }),
                    changeOnBlur: true,
                    blurOnEnterKey: true,
                    isInvalid: !!get(errors, `${qbLinePath}.unitPrice.message`),
                    errorMsg: get(errors, `${qbLinePath}.unitPrice.message`),
                  }}
                  type="number"
                />

                {/* Contribution Margin */}
                {marginViewType === 'contributionMarginPercent' && (
                  <ComparisonInput
                    hideTitleInformation={true}
                    hideBeforeInformation={true}
                    hideAfterInformation={true}
                    commonInputProps={{
                      marginBottom: -2,
                      rightIcon: faPercent,
                      textAlign: 'right',
                    }}
                    title="Contribution Margin"
                    beforeInputProps={{
                      defaultValue: lastWinContrMarginPercent || '',
                      rightIconProps: { icon: faPercent, visibility: 'hidden' },
                    }}
                    afterInputProps={{
                      ...register(`${qbLinePath}.contributionMarginPercent`, {
                        valueAsNumber: true,
                      }),
                      changeOnBlur: true,
                      blurOnEnterKey: true,
                    }}
                    type="number"
                  />
                )}

                {/* Gross Margin */}
                {marginViewType === 'grossMarginPercent' && (
                  <ComparisonInput
                    hideTitleInformation={true}
                    hideBeforeInformation={true}
                    hideAfterInformation={true}
                    commonInputProps={{
                      marginBottom: -2,
                      rightIcon: faPercent,
                      textAlign: 'right',
                    }}
                    title="Gross Margin"
                    beforeInputProps={{
                      defaultValue: lastWinGrossMarginPercent || '',
                      rightIconProps: { icon: faPercent, visibility: 'hidden' },
                    }}
                    afterInputProps={{
                      ...register(`${qbLinePath}.grossMarginPercent`, {
                        valueAsNumber: true,
                      }),
                    }}
                    type="number"
                  />
                )}

                {/* Lead Time */}

                <Controller
                  name={`${mainLinePath}.leadTime`}
                  control={control}
                  render={({ field }) => (
                    <ComparisonInput
                      title="Lead Time (Weeks)"
                      hideWholeElement={true}
                      hideTitleInformation={true}
                      hideBeforeInformation={true}
                      hideAfterInformation={true}
                      type="number"
                      commonInputProps={{
                        marginBottom: -2,
                        textAlign: 'right',
                      }}
                      beforeInputProps={{
                        defaultValue: lastWinCalcs?.leadTimeWeeks || 'N/A',
                      }}
                      afterInputProps={{
                        ...field,
                        value:
                          (field.value && daysToWeeks(field.value)) ??
                          undefined,
                        variant: 'outline',
                        backgroundColor: 'white',
                        onChange: (e) =>
                          field.onChange(
                            (e.target.value && weeksToDays(+e.target.value)) ||
                              null,
                          ),
                      }}
                    />
                  )}
                />
              </Flex>
            );
          })}

          {/* Options */}
          <PartOptions
            linePath={mainLinePath}
            options={partOptionsQuery.data}
          />

          <LineItemFooter linePath={mainLinePath} />
        </VStack>
      </HStack>
      <HStack
        backgroundColor={'#EDF2F7'}
        paddingY={3}
        paddingX={5}
        display={detailsToggled ? 'flex' : 'none'}
      >
        <QuoteLineDetails />
      </HStack>
      <Spacer marginBottom={5} />
    </Box>
  );
});

const QuantityBreakButtons = ({
  showAdd = false,
  onAdd,
  showRemove = false,
  onRemove,
}: {
  showAdd?: boolean;
  onAdd?: () => void;
  showRemove?: boolean;
  onRemove?: () => void;
}) => {
  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      gap={1}
      width={4}
      paddingTop={2}
      marginRight={-6}
    >
      {showRemove && onRemove && (
        <RemoveQuantityBreakButton onClick={onRemove} />
      )}
      {showAdd && onAdd && <AddQuantityBreakButton onClick={onAdd} />}
    </Flex>
  );
};

const AddQuantityBreakButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Tooltip label="Add quantity break" placement="top">
      <Link fontSize={12} color="mw.darkBlue" onClick={onClick}>
        <FontAwesomeIcon icon={faCirclePlus} />
      </Link>
    </Tooltip>
  );
};

const RemoveQuantityBreakButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Tooltip label="Remove quantity break" placement="top">
      <Link fontSize={12} color="mw.red" onClick={onClick}>
        <FontAwesomeIcon icon={faCircleMinus} />
      </Link>
    </Tooltip>
  );
};

QuoteLineItem.displayName = 'QuoteLineItem'; // sets dev tools display name of memo'd component
export { QuoteLineItem };
