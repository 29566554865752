import type {
  BuildPartIdData,
  FinishedPartIdBuilder,
  FinishedPartIdBuilderData,
  FinishedPartIdStrategy,
} from '../types';

export const FinishedPartIdStrategyRAF: FinishedPartIdStrategy = class
  implements FinishedPartIdBuilder
{
  private basePartId: string;

  constructor({ partId }: FinishedPartIdBuilderData) {
    this.basePartId = partId;
  }

  public buildFinishedPartId = ({ partOptions }: BuildPartIdData): string => {
    if (!partOptions) {
      return this.basePartId;
    }

    let partId = this.basePartId;

    for (const option of partOptions) {
      if (option.metadata.code) {
        partId += `-${option.metadata.code}`;
      }

      if (option.suboptionName) {
        const suboptionCode = option.metadata.subOptions?.find(
          (suboption) => suboption.name === option.suboptionName,
        )?.code;

        if (suboptionCode) {
          partId += `-${suboptionCode}`;
        }
      }
    }

    return partId;
  };
};
