import { keyframes } from '@emotion/react';
import { RotateIcon } from '@ui/components/Icons';
import { QuoteLineActionsItem } from '@ui/components/QuoteLine';

interface SyncActionProps {
  handleSync: (() => void) | undefined;
  isSyncing?: boolean;
}

const spin = keyframes`  
  from {transform: rotate(0deg);}   
  to {transform: rotate(360deg)} 
`;

const spinAnimation = `${spin} infinite 2s linear`;

export const SyncAction = ({
  handleSync,
  isSyncing = false,
}: SyncActionProps) => {
  return (
    <QuoteLineActionsItem
      label="Sync Line Item Costs With Visual"
      icon={<RotateIcon animation={isSyncing ? spinAnimation : undefined} />}
      onClick={handleSync}
      disabled={isSyncing || !handleSync}
    />
  );
};
