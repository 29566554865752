import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Skeleton,
  Spacer,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import {
  faCalendarDays,
  faCalendarXmark,
  faEnvelope,
  faLocationPin,
  faNoteSticky,
  faPhone,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { CustomerHeaderResponse } from '@lib';
import { FixedPricingSources } from '@prisma/client';
import { Select } from '@ui/components/Select';
import { useGetCustomerTiers } from '@ui/data/customer/customer-tiers';
import { useGetCustomerContacts } from '@ui/data/customer/get-customer-contacts';
import { useAuth } from '@ui/state/auth';
import countryLookup from 'country-code-lookup';
import { get } from 'lodash-es';
import {
  type FC,
  type MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { FormSectionTitle } from '../../components/Text';
import { InputWithIcon } from '../../components/form/InputWithIcon';
import { SimpleInput } from '../../components/form/SimpleInput';
import { SimpleSelect } from '../../components/form/SimpleSelect';
import Modal from '../../components/layout/Modal';
import { useLocalization } from '../../config/localization/getLocalization';
import { CSRInput } from './CSRInput';
import type { QuoteBuilderForm } from './QuoteBuilder';
import { SalesRepInput } from './SalesRepInput';

interface CustomerHeaderProps {
  customerInfo: CustomerHeaderResponse | null;
  isNewCustomer: boolean;
  notes: string | null;
}

export const CustomerHeader: FC<CustomerHeaderProps> = ({
  customerInfo,
  isNewCustomer,
  notes,
}) => {
  const qb_i18n = useLocalization('QuoteBuilder');

  const { register, setValue, getValues, control } =
    useFormContext<QuoteBuilderForm>();
  const { errors } = useFormState({ name: 'customerHeader', control });

  const { currentSite } = useAuth();

  useEffect(() => {
    if (!customerInfo) {
      return;
    }

    setValue('customerHeader', customerInfo);
  }, [setValue, customerInfo]);

  const customerTiersQuery = useGetCustomerTiers();
  const customerContactsQuery = useGetCustomerContacts(
    customerInfo?.customerId,
  );

  // save the current note to this ref when opening the modal
  // in case we hit cancel to discard changes
  const internalNoteDraftRef = useRef<string | null>(null);
  const {
    isOpen: isNoteOpen,
    onClose: onNoteClose,
    onOpen: onNoteOpen,
  } = useDisclosure({
    id: 'internal-notes-modal',
    onOpen: () => {
      internalNoteDraftRef.current = getValues('internalNotes');
    },
  });

  const internalNoteTextAreaRef: MutableRefObject<HTMLTextAreaElement | null> =
    useRef<HTMLTextAreaElement>(null);
  // focus end of input on note textarea mount
  const internalNoteTextAreaRefFocusEnd = useCallback(
    (node: HTMLTextAreaElement | null) => {
      if (node) {
        node.focus();
        node.setSelectionRange(node.value.length, node.value.length);
      }

      internalNoteTextAreaRef.current = node;
    },
    [],
  );

  const handleCustomerContactChange = (name?: string | null) => {
    const contact = customerContactsQuery.data?.find(
      (contact) => contact.name === name,
    );

    setValue('customerHeader.attentionName', name ?? '');
    setValue('customerHeader.email', contact?.email ?? '');
    setValue('customerHeader.phone', contact?.phone ?? '');
  };

  return (
    <Skeleton isLoaded={!!customerInfo} width={'100%'}>
      <Box
        key={customerInfo?.id}
        backgroundColor="mw.panelGrey"
        borderRadius={5}
        paddingX={{ base: 5, md: 10 }}
        paddingY={6}
        display="inline-block"
        width="100%"
      >
        <Box
          as={Flex}
          alignItems="start"
          flexDirection={'row'}
          mb={4}
          alignContent="center"
        >
          {isNewCustomer ? (
            <Box w={{ base: '100%', md: '50%' }} mb={4}>
              <FormSectionTitle marginBottom={3}>
                {qb_i18n('customerInfo')}
              </FormSectionTitle>

              <SimpleInput
                placeholder="Customer Name"
                {...register('customerHeader.name')}
                defaultValue={customerInfo?.name}
              />
            </Box>
          ) : (
            <Heading fontFamily="sans-serif" fontWeight={100} fontSize={32}>
              {customerInfo?.name} ({customerInfo?.customerId})
            </Heading>
          )}
          <Spacer />
          <Box>
            <Button
              fontFamily="navigationItem"
              bgColor="mw.yellow"
              color="black"
              borderRadius={'0px'}
              _hover={{ bg: 'mw.darkYellow' }}
              gap={2}
              onClick={onNoteOpen}
            >
              <Icon as={FontAwesomeIcon} icon={faNoteSticky} /> Edit Internal
              Notes
            </Button>
          </Box>
        </Box>
        <Box as={Flex} gap={5} direction={{ base: 'column', md: 'row' }}>
          <Box
            w={{
              base: '100%',
              md: '50%',
            }}
          >
            <Flex>
              <FormSectionTitle marginBottom={3}>
                Primary Contact
              </FormSectionTitle>
            </Flex>

            <Select
              options={customerContactsQuery.data?.map((contact) => {
                return {
                  value: contact.name,
                  label: contact.name,
                };
              })}
              // Setting the value to null so that this changes the customer fields
              // but does not update this field. This prevents duplication of the
              // customer contact name in the customer header.
              value={null}
              onChange={(e) => handleCustomerContactChange(e?.value)}
              leftElement={
                <Icon as={FontAwesomeIcon} icon={faUser} w={10} color="#ccc" />
              }
              noOptionsMessage={() =>
                'No contacts found. Please enter a new contact below.'
              }
              placeholder="Select Customer Contact"
              isDisabled={
                !customerContactsQuery.data ||
                customerContactsQuery.data?.length === 0
              }
            />

            <Box position="relative" px={1} py={4}>
              <Divider borderColor="mw.grey" />
              <AbsoluteCenter
                bg="mw.panelGrey"
                px={2}
                fontSize="sm"
                color="mw.grey"
              >
                Contact Information
              </AbsoluteCenter>
            </Box>

            <InputWithIcon
              leftIcon={faUser}
              placeholder="Full Name"
              {...register('customerHeader.attentionName', {
                required: 'Name is required',
              })}
              isInvalid={!!get(errors, 'customerHeader.attentionName.message')}
              errorMsg={get(errors, 'customerHeader.attentionName.message')}
              defaultValue={
                (customerInfo?.attentionName?.length &&
                  customerInfo?.attentionName?.length > 0 &&
                  customerInfo?.attentionName) ||
                customerInfo?.name
              }
            />

            <InputWithIcon
              leftIcon={faEnvelope}
              placeholder="Email"
              type="email"
              {...register('customerHeader.email', {})}
              defaultValue={customerInfo?.email}
            />
            <InputWithIcon
              leftIcon={faPhone}
              placeholder="Phone"
              type="tel"
              {...register('customerHeader.phone', {
                maxLength: {
                  value: 20,
                  message: 'Phone number has a maximum 20 character limit',
                },
              })}
              isInvalid={!!get(errors, 'customerHeader.phone.message')}
              errorMsg={get(errors, 'customerHeader.phone.message')}
              defaultValue={customerInfo?.phone}
            />
          </Box>
          <Box
            w={{
              base: '100%',
              md: '50%',
            }}
          >
            <FormSectionTitle marginBottom={3}>
              {qb_i18n('companyAddress')}
            </FormSectionTitle>
            <InputWithIcon
              leftIcon={faLocationPin}
              placeholder="Address"
              {...register('customerHeader.address1')}
              defaultValue={customerInfo?.address1}
            />
            <InputWithIcon
              leftIcon={faLocationPin}
              placeholder="Address 2"
              {...register('customerHeader.address2')}
              defaultValue={customerInfo?.address2}
            />
            <Flex gap={3} marginBottom={3}>
              <SimpleInput
                placeholder="City"
                {...register('customerHeader.city')}
                defaultValue={customerInfo?.city}
              />
              <SimpleInput
                placeholder="State"
                {...register('customerHeader.state')}
                defaultValue={customerInfo?.state}
              />
            </Flex>
            <Flex gap={3} marginBottom={3}>
              <SimpleInput
                placeholder="Postal Code"
                {...register('customerHeader.postalCode')}
                defaultValue={customerInfo?.postalCode}
              />
              <SimpleSelect
                placeholder="Select a Country"
                {...register('customerHeader.countryCode')}
                defaultValue={customerInfo?.countryCode ?? 'US'}
              >
                {countryLookup.countries.map((c, k) => (
                  <option key={`country-${c.iso2}`} value={c.iso2}>
                    {c.country}
                  </option>
                ))}
              </SimpleSelect>
            </Flex>
          </Box>
        </Box>
        <Box as={Flex} gap={5} direction={{ base: 'column', md: 'row' }}>
          <SalesRepInput />
          <Box
            w={{
              base: '100%',
              md: '50%',
            }}
          >
            <Flex gap={5} direction={{ base: 'column', md: 'row' }}>
              <Box
                w={{
                  base: '100%',
                  md: '50%',
                }}
              >
                <FormSectionTitle marginBottom={3}>
                  Days Until Quote Expires
                </FormSectionTitle>
                <InputWithIcon
                  leftIcon={faCalendarXmark}
                  placeholder="Days Until Quote Expires"
                  {...register('daysUntilExpiration')}
                />
              </Box>
              <Box
                w={{
                  base: '100%',
                  md: '50%',
                }}
              >
                <FormSectionTitle marginBottom={3}>
                  Days Until Expected Win
                </FormSectionTitle>
                <InputWithIcon
                  leftIcon={faCalendarDays}
                  placeholder="Days Until Expected Win"
                  {...register('daysUntilExpectedWin')}
                />
              </Box>
            </Flex>
          </Box>
        </Box>

        <CSRInput />

        <Box as={Flex} gap={5} direction={{ base: 'column', md: 'row' }}>
          <Box
            w={{
              base: '100%',
              md: '50%',
            }}
          >
            <FormSectionTitle marginBottom={3}>Note</FormSectionTitle>
            <Textarea
              placeholder="Note"
              {...register('notes')}
              defaultValue={notes || ''}
              bg="white"
            />
          </Box>
          {/* {(customerInfo?.marketCode || customerInfo?.discountCode) && (
            <Box>
              <FormSectionTitle marginBottom={3}>
                Customer Tier
              </FormSectionTitle>
              <Flex gap={4}>
                {customerInfo?.discountCode && (
                  <Tag bg="mw.lightGrey" size="lg">
                    {customerInfo?.discountCode}
                  </Tag>
                )}

                {customerInfo?.marketCode && (
                  <Tag bg="mw.lightGrey" size="lg">
                    {customerInfo?.marketCode}
                  </Tag>
                )}
              </Flex>
            </Box>
          )} */}
          {customerTiersQuery?.data && (
            <HStack>
              {/**
               * TODO (jjosef): need some util components/methods/hook for managing this kinda thing.
               */}
              {currentSite?.siteConfig?.quotingConfig?.pricingConfig
                ?.fixedPricingSrc === FixedPricingSources.MarketPricing && (
                <Box>
                  <FormSectionTitle marginBottom={3}>
                    Customer Market Tier
                  </FormSectionTitle>
                  <Flex gap={4}>
                    <SimpleSelect
                      placeholder="Select a Market Tier"
                      {...register('customerHeader.marketCode')}
                      defaultValue={customerInfo?.marketCode ?? null}
                    >
                      {customerTiersQuery.data.marketCodes.map((m) => (
                        <option
                          key={`marketCode-${m.marketCode}`}
                          value={m.marketCode}
                        >
                          {m.marketCode}
                        </option>
                      ))}
                    </SimpleSelect>
                  </Flex>
                </Box>
              )}
              {currentSite?.siteConfig?.quotingConfig?.pricingConfig
                ?.fixedPricingSrc === FixedPricingSources.DiscountPricing && (
                <Box>
                  <FormSectionTitle marginBottom={3}>
                    Customer Discount Tier
                  </FormSectionTitle>
                  <Flex gap={4}>
                    <SimpleSelect
                      placeholder="Select a Discount Tier"
                      {...register('customerHeader.discountCode')}
                      defaultValue={customerInfo?.discountCode ?? null}
                    >
                      {customerTiersQuery.data.discountCodes.map((d) => (
                        <option
                          key={`discountCode-${d.discountCode}`}
                          value={d.discountCode}
                        >
                          {d.discountCode}
                        </option>
                      ))}
                    </SimpleSelect>
                  </Flex>
                </Box>
              )}
            </HStack>
          )}
        </Box>
      </Box>
      <Modal
        title={'Edit Internal Notes'}
        size="xl"
        isOpen={isNoteOpen}
        onClose={onNoteClose}
        buttons={[
          {
            label: 'Cancel',
            onClick: () => {
              onNoteClose();
              // discard changes
              setValue('internalNotes', internalNoteDraftRef.current);
            },
          },
          {
            label: 'Save',
            onClick: onNoteClose,
            bgColor: '#0b7078',
            color: 'white',
            _hover: { bg: '#046068' },
          },
        ]}
      >
        <Controller
          control={control}
          name="internalNotes"
          render={({ field: { onChange, onBlur, value } }) => (
            <Textarea
              placeholder="Add an internal note for this quote. Notes written here will not be displayed in the customer PDF."
              onBlur={onBlur}
              onChange={onChange}
              value={value ?? ''}
              ref={internalNoteTextAreaRefFocusEnd}
            />
          )}
        />
      </Modal>
    </Skeleton>
  );
};
