import type { InventoryResponse } from '@lib/responses';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import { partService } from '@ui/services';

export const inventoryQuery = (partId: string) => {
  return {
    queryKey: ['part', partId, 'inventory'],
    queryFn: () => partService.getInventory(partId),
  };
};

export function useGetInventory(
  partId: string,
  options?: Omit<UseQueryOptions<InventoryResponse>, 'queryKey' | 'queryFn'>,
) {
  return {
    ...useQuery({
      ...inventoryQuery(partId),
      ...options,
    }),
  };
}
