import { Box, Flex, HStack, Input, Text, VStack } from '@chakra-ui/react';
import {
  type QuoteFeedbackLineResponse,
  type QuoteFeedbackReasonResponse,
  QuoteFeedbackReasons,
  type QuoteFeedbackReasonsResponse,
  type QuoteFeedbackResponse,
  isKeyOf,
  roundToString,
} from '@lib';
import { FeedbackType } from '@prisma/client';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { P, match } from 'ts-pattern';
import PartInfoCard from '../../components/PartInfoCard';
import { SimpleInput, SimpleSelect } from '../../components/form';
import type { LinePathType } from '../QuoteBuilder/types';

interface QuoteFeedbackFieldProps {
  feedback: QuoteFeedbackLineResponse;
  feedbackReasons: QuoteFeedbackReasonsResponse;
  index: number;
}

type ReasonType = Partial<
  Record<
    FeedbackType,
    {
      automationDisplay: string;
      quotedDisplay: string;
      automatedField: AutomatedFeedbackFields;
      quotedField: QuoteFeedbackFields;
      rounding?: {
        min: number;
        small: number;
      };
      isMonetary?: boolean;
    }
  >
>;

type AutomatedFeedbackFields = 'automatedUnitPrice' | 'automatedLeadTime';
type QuoteFeedbackFields = 'unitPrice' | 'leadTime';

export const QuoteFeedbackField: FC<QuoteFeedbackFieldProps> = ({
  feedback,
  feedbackReasons,
  index,
}) => {
  const reasonTypes = {
    [FeedbackType.RECOMMENDED_PRICE_CHANGE]: {
      automationDisplay: 'Suggested Unit Price',
      quotedDisplay: 'Quoted Unit Price',
      automatedField: 'automatedUnitPrice',
      quotedField: 'unitPrice',
      rounding: {
        min: 2,
        small: 4,
      },
      isMonetary: true,
    },
    [FeedbackType.RECOMMENDED_LEAD_TIME_CHANGE]: {
      automationDisplay: 'Suggested Lead Time',
      quotedDisplay: 'Quoted Lead Time',
      automatedField: 'automatedLeadTime',
      quotedField: 'leadTime',
    },
  } as const satisfies ReasonType;

  if (
    !feedbackReasons ||
    !feedbackReasons.length ||
    !isKeyOf(reasonTypes, feedback.type)
  )
    return null;

  const line = feedback.quoteLine;
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext<QuoteFeedbackResponse>();

  const reason = watch(`feedback.${index}.reasonTypeId`);

  const reasons = feedbackReasons.filter(
    (fbr: QuoteFeedbackReasonResponse) => fbr.type === feedback.type,
  );

  type EnabledFeedbackTypes = keyof typeof reasonTypes;

  const getFeedbackTitle = (type: EnabledFeedbackTypes, automated: boolean) => {
    const reasonType = reasonTypes[type];
    if (automated) {
      return reasonType.automationDisplay;
    }
    return reasonType.quotedDisplay;
  };

  // Kind of dirty, but can refactor this later into separate components instead
  const getFeedbackValue = (
    type: EnabledFeedbackTypes,
    automated: boolean,
  ): string | number => {
    const reasonType = reasonTypes[type];
    const lineValue = automated
      ? line[reasonType.automatedField]
      : line[reasonType.quotedField];

    return match({ ...reasonType, type, lineValue: Number(lineValue) })
      .with({ lineValue: P.union(0, Number.NaN) }, () => '')
      .with(
        { rounding: P.not(undefined), isMonetary: P.not(undefined) },
        ({ rounding, lineValue }) =>
          `$${roundToString(lineValue, {
            min: rounding.min,
            small: rounding.small,
          })}`,
      )
      .with(
        { type: FeedbackType.RECOMMENDED_LEAD_TIME_CHANGE },
        ({ lineValue }) => lineValue / 7,
      )
      .with(
        { type: FeedbackType.RECOMMENDED_PRICE_CHANGE },
        ({ lineValue }) => lineValue,
      )
      .exhaustive();
  };

  const thisLinePath: LinePathType = `lineItems.${index}`;

  return (
    <HStack
      backgroundColor={'#EDF2F7'}
      paddingY={3}
      paddingX={5}
      marginBottom={5}
      gap={4}
    >
      <Box flexShrink={2} width="300px">
        <Flex alignItems="center">
          <PartInfoCard
            {...{
              material: feedback.quoteLine.part.material,
              partId: `Part ID: ${feedback.quoteLine.partId}`,
              type: feedback.quoteLine.part.type,
              thisLinePath,
            }}
          />
        </Flex>
      </Box>
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        borderLeft="1px solid rgba(0, 0, 0, .2)"
        paddingLeft={4}
        flexGrow={1}
      >
        <HStack gap={4} flexGrow={1} alignItems="start">
          <VStack alignItems="start">
            <Text fontWeight="bold">
              {getFeedbackTitle(feedback.type, true)}
            </Text>
            <Input
              width="160px"
              value={getFeedbackValue(feedback.type, true)}
              disabled={true}
              isDisabled={true}
            />
          </VStack>
          <VStack alignSelf="center">
            <FontAwesomeIcon icon={faArrowRight} size="2x" />
          </VStack>
          <VStack alignItems="start">
            <Text fontWeight="bold">
              {getFeedbackTitle(feedback.type, false)}
            </Text>
            <Input
              width="160px"
              value={getFeedbackValue(feedback.type, false)}
              disabled={true}
              isDisabled={true}
            />
          </VStack>
          <VStack
            marginLeft={4}
            alignItems="start"
            justifyItems="start"
            maxWidth="250px"
          >
            <Text fontWeight="bold">Change Reason</Text>
            <SimpleSelect
              placeholder="Please select a reason"
              {...register(`feedback.${index}.reasonTypeId`, {
                required: 'Reason is required',
              })}
              defaultValue={feedback.reasonTypeId}
            >
              {reasons.map((r) => (
                <option key={`reason-${r.id}`} value={r.id}>
                  {r.reason}
                </option>
              ))}
            </SimpleSelect>
            <ErrorMessage
              errors={errors}
              name={`feedback.${index}.reasonTypeId`}
              render={({ message }) => <Text color="red">{message}</Text>}
            />
          </VStack>
          <VStack flexGrow={1} alignItems="start" justifyItems="start">
            <Text fontWeight="bold">Comments/Other</Text>
            <SimpleInput
              placeholder="Enter any comments"
              width="100%"
              {...register(`feedback.${index}.comments`, {
                required:
                  reason === QuoteFeedbackReasons.OTHER
                    ? 'Comments are required'
                    : false,
              })}
              defaultValue={`feedback.${index}.comments`}
            />
            <ErrorMessage
              errors={errors}
              name={`feedback.${index}.comments`}
              render={({ message }) => <Text color="red">{message}</Text>}
            />
          </VStack>
        </HStack>
      </Flex>
    </HStack>
  );
};
