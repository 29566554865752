import { type PaginationParams, PaginationParamsModel } from '@lib/models';
import type { QuoteGetManyResponse } from '@lib/responses';
import {
  type UseQueryOptions,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import type { SortingState } from '@tanstack/react-table';
import { quoteService } from '@ui/services';
import { useAuth } from '@ui/state/auth';

/**
 * Useful for invalidating all the cached search results data.
 */
export const quoteQueueSearchQueryKeyPrefix = ['quote', 'search'];

/**
 * @NOTE(shawk): This query key is parameterized by the current site ID so that
 * a change in the user's current site results in a refetch of quote queue data.
 * The backend route handler in `api` pulls the site code from the
 * `user.lastActiveSite` that the user-auth middleware adds, rather than the
 * request params like e.g. the admin config endpoints.
 *
 * @TODO(shawk): Pass `siteId` to the API and use the URL parameter instead?
 */
export const quoteQueueSearchQuery = (
  siteId: string,
  query: string,
  pagination: PaginationParams,
  sortBy: SortingState | undefined,
) => {
  return {
    queryKey: [
      ...quoteQueueSearchQueryKeyPrefix,
      siteId,
      query,
      pagination,
      sortBy,
    ],
    queryFn: () => quoteService.searchQuotes(query, pagination, sortBy),
  };
};

export function useQuoteQueueSearch(
  query = '',
  pagination: PaginationParams = PaginationParamsModel.defaultParams,
  sortBy: SortingState = [],
  options?: Omit<UseQueryOptions<QuoteGetManyResponse>, 'queryKey' | 'queryFn'>,
) {
  const { currentSite } = useAuth();

  return useQuery({
    ...quoteQueueSearchQuery(
      currentSite?.code ?? 'unknown',
      query,
      pagination,
      sortBy,
    ),
    ...options,
    enabled: !!currentSite,
    placeholderData: keepPreviousData,
  });
}
