import { IconButton, ListItem, MenuItem, Tooltip } from '@chakra-ui/react';
import { Show } from '../Show';

export interface QuoteLineActionsItemProps {
  label: string;
  onClick?: () => void;
  icon: React.ReactElement;
  disabled?: boolean;
}

/**
 * Renders actions for a quote line
 *
 * Renders a menu item or an icon button based on screen size
 */
export const QuoteLineActionsItem: React.FC<QuoteLineActionsItemProps> = ({
  label,
  onClick,
  icon,
  disabled,
}) => {
  return (
    <>
      <Show above="md">
        <ListItem>
          <Tooltip label={label} placement="top-end">
            <IconButton
              aria-label={label}
              icon={icon}
              isDisabled={disabled}
              onClick={onClick}
            />
          </Tooltip>
        </ListItem>
      </Show>

      <Show below="md">
        <MenuItem icon={icon} isDisabled={disabled} onClick={onClick}>
          {label}
        </MenuItem>
      </Show>
    </>
  );
};
