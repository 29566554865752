import type {
  QuotingConfigCreateRequest,
  QuotingConfigUpdateRequest,
} from '@lib/requests';
import type { AllConfigsResponse, QuotingConfigResponse } from '@lib/responses';
import {
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useValidationToast } from '@ui/pages/QuoteBuilder/hooks/useToast';
import { adminConfigsService } from '@ui/services';
import { useAuth } from '@ui/state/auth';

/**
 * Having optional params and a fallback to "unknown" is a workaround until we
 * have better options for guarding sections of the app that require a
 * `currentSite` to be defined. These queries and mutations don't really make
 * sense without a valid site id.
 */
export const adminConfigAllQuery = (siteId = 'unknown') => {
  return {
    queryKey: ['admin', 'configs', siteId, 'all'],
    queryFn: () => adminConfigsService.getAll(siteId),
  };
};

export const adminConfigQuotingQuery = (siteId = 'unknown') => {
  return {
    queryKey: ['admin', 'configs', siteId, 'quoting'],
    queryFn: () => adminConfigsService.getQuotingConfigs(siteId),
  };
};

export const adminConfigCreateQuotingMutation = (siteId = 'unknown') => ({
  mutationKey: ['admin', 'configs', siteId, 'quoting', 'create'],
  mutationFn: (data: QuotingConfigCreateRequest) =>
    adminConfigsService.createQuotingConfig(siteId, data),
});

export const adminConfigUpdateQuotingMutation = (
  siteId = 'unknown',
  id = 'unknown',
) => ({
  mutationKey: ['admin', 'configs', siteId, 'quoting', 'update', id],
  mutationFn: (data: QuotingConfigUpdateRequest) =>
    adminConfigsService.updateQuotingConfig(siteId, id, data),
});

export function useAdminAllConfigs(
  options?: Omit<UseQueryOptions<AllConfigsResponse>, 'queryKey' | 'queryFn'>,
) {
  const { currentSite } = useAuth();

  return useQuery({
    ...adminConfigAllQuery(currentSite?.id),
    ...options,
    enabled: !!currentSite?.id && (options?.enabled ?? true),
  });
}

/**
 *
 * @param options.siteId - [Optional] Override the user's current site id to get
 * configs for other sites
 *
 */
export function useAdminQuotingConfigs(
  options?: Omit<
    UseQueryOptions<QuotingConfigResponse[]>,
    'queryKey' | 'queryFn'
  > & { siteId?: string },
) {
  const { currentSite } = useAuth();
  const siteIdKey = options?.siteId ?? currentSite?.id;

  return useQuery({
    ...adminConfigQuotingQuery(siteIdKey),
    ...options,
    enabled: !!siteIdKey && (options?.enabled ?? true),
  });
}

export function useCreateAdminQuotingConfig(
  options?: Omit<
    UseMutationOptions<
      QuotingConfigResponse,
      unknown,
      QuotingConfigCreateRequest
    >,
    'mutationKey' | 'mutationFn'
  >,
) {
  const { currentSite } = useAuth();
  const queryClient = useQueryClient();
  const { genericSuccessToast } = useValidationToast();

  return useMutation({
    ...adminConfigCreateQuotingMutation(currentSite?.id),
    ...options,
    onSuccess: async (_data, _variables, _context) => {
      await queryClient.invalidateQueries({
        queryKey: adminConfigAllQuery(currentSite?.id).queryKey,
      });

      genericSuccessToast('Quoting config created');
    },
  });
}

export function useUpdateAdminQuotingConfig(
  id?: string,
  options?: Omit<
    UseMutationOptions<
      QuotingConfigResponse,
      unknown,
      QuotingConfigUpdateRequest
    >,
    'mutationKey' | 'mutationFn'
  >,
) {
  const { currentSite } = useAuth();
  const queryClient = useQueryClient();
  const { genericSuccessToast } = useValidationToast();

  return useMutation({
    ...adminConfigUpdateQuotingMutation(currentSite?.id, id),
    ...options,
    onSuccess: async (_data, _variables, _context) => {
      await queryClient.invalidateQueries({
        queryKey: adminConfigAllQuery(currentSite?.id).queryKey,
      });

      genericSuccessToast('Quoting config updated');
    },
  });
}
