import { Badge } from '@chakra-ui/react';
import type { FC } from 'react';

export const QuoteStatusBadge: FC<{ status: string | null | undefined }> = ({
  status,
}) => {
  let badgeProps = {
    backgroundColor: 'mw.lightGrey',
    color: 'mw.white',
  };
  let statusDisplay = status;
  switch (status) {
    case 'WON':
      badgeProps = {
        backgroundColor: 'mw.darkGreen',
        color: 'mw.white',
      };
      break;
    case 'LOSS':
      badgeProps = {
        backgroundColor: 'mw.darkRed',
        color: 'mw.white',
      };
      break;
    case 'NEW':
      badgeProps = {
        backgroundColor: 'mw.yellow',
        color: 'mw.white',
      };
      break;
    case 'SENT':
      badgeProps = {
        backgroundColor: 'mw.darkBlue',
        color: 'mw.white',
      };
      break;
    case 'INPROGRESS':
      statusDisplay = 'IN PROGRESS';
      break;
  }
  return (
    <Badge {...badgeProps} borderRadius={4} px={2} py={1}>
      {statusDisplay}
    </Badge>
  );
};
