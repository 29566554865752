import { weeksToDays } from 'date-fns';
import type { LeadTimeStrategy } from './types';

export const DefaultLeadTimeStrategy: LeadTimeStrategy = {
  calculateDays: ({ lastWinCalcs }) => {
    // Use lead time from last win, if available
    if (lastWinCalcs?.leadTimeDays) {
      return lastWinCalcs?.leadTimeDays;
    }

    return weeksToDays(5);
  },
};
