import { createContext, useState } from 'react';
import type { ModalProps } from '../components/layout/Modal/index';
import { useStickyState } from '../hooks';

export interface IGlobalStateContext {
  activeModal: ModalProps | null;
  collapsedNavigation: boolean;
  setActiveModal: (modalProps: ModalProps | null) => void;
  setCollapsedNavigation: (isCollapsed: boolean) => void;
  // activeUser: any;
  // setActiveUser: (user: any) => void;
}

export const GlobalStateContext = createContext<IGlobalStateContext>(null!);

export function GlobalStateProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // const [activeUser, setActiveUser] = useStickyState<any>(null, 'mwqq_user');
  const [activeModal, setActiveModal] = useState<ModalProps | null>(null);
  const [collapsedNavigation, setCollapsedNavigation] = useStickyState<any>(
    true,
    'mwqq_collapsedNavigation',
  );

  const value = {
    activeModal,
    setActiveModal,
    collapsedNavigation,
    setCollapsedNavigation,
    // activeUser,
    // setActiveUser,
  };

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  );
}
