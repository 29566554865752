import {
  Box,
  Icon,
  IconButton,
  ListItem,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { faFileArrowDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useGetDocumentMutation } from '@ui/data/document/get-document';
import type React from 'react';
import { useRef } from 'react';
import type { FormDocument } from './types';

interface LineItemDocumentProps {
  onDelete: () => void;
  document: FormDocument;
}

export const LineItemDocument: React.FC<LineItemDocumentProps> = ({
  onDelete,
  document,
}) => {
  const fileRef = useRef<HTMLAnchorElement>(null);

  const downloadDocument = useGetDocumentMutation({
    onSuccess: (data) => {
      const objectURL = window.URL.createObjectURL(
        new Blob([data], { type: document.fileType }),
      );

      if (fileRef.current) {
        fileRef.current.href = objectURL;
        fileRef.current.download = document.fileName;
        fileRef.current.click();
      }
    },
  });

  if (document.uploadFailed) {
    return null;
  }

  const handleDownload = () => {
    if (!document.id) {
      return;
    }

    downloadDocument.mutate(document.id);
  };

  return (
    <ListItem display="flex" gap={3} justifyContent="space-between">
      {document.createdAt ? (
        <>
          <Box>
            <Text fontWeight="bold">{document.fileName}</Text>
            <Text fontSize="small">{`Uploaded on ${new Date(
              document.createdAt,
            ).toLocaleString()} by ${document.createdBy?.displayName}`}</Text>
          </Box>

          <Box display="flex" gap={2}>
            <Tooltip
              label={`Download ${document.fileName}`}
              placement="bottom-end"
            >
              <IconButton
                aria-label={`Download ${document.fileName}`}
                onClick={handleDownload}
                icon={<Icon as={FontAwesomeIcon} icon={faFileArrowDown} />}
              />
            </Tooltip>

            <Tooltip label={`Delete ${name}`} placement="bottom-end">
              <IconButton
                aria-label={`Delete ${name}`}
                icon={<Icon as={FontAwesomeIcon} icon={faTrash} />}
                backgroundColor="mw.red"
                color="mw.white"
                onClick={onDelete}
              />
            </Tooltip>
          </Box>

          {/* biome-ignore lint/a11y/useValidAnchor: href set by fileRef */}
          <a ref={fileRef} target="_blank" style={{ display: 'none' }}>
            Download
          </a>
        </>
      ) : document.deleting ? (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Text fontWeight="bold">{`Deleting ${document.fileName}`}</Text>
          <Spinner color="mw.yellow" aria-label="Loading..." />
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Text fontWeight="bold">{document.fileName}</Text>
          <Spinner color="mw.yellow" aria-label="Loading..." />
        </Box>
      )}
    </ListItem>
  );
};
