import { Box, Link } from '@chakra-ui/react';
import type { AdminSiteResponse } from '@lib/responses';
import { createColumnHelper } from '@tanstack/react-table';
import DataTable from '@ui/components/DataTable';
import Loading from '@ui/components/Loading';
import { useAdminSites } from '@ui/data/admin';
import { useGlobalState } from '@ui/hooks';
import { isAxiosError } from 'axios';
import { useMemo } from 'react';
import { ConfigErrorAlert } from '../Config/ConfigErrorAlert';
import { AdminSiteEdit } from './AdminSiteEdit';

export const SitesTable = () => {
  const { setActiveModal } = useGlobalState();
  const sitesQuery = useAdminSites();

  const handleSiteSelect = async (site: AdminSiteResponse) => {
    setActiveModal({
      size: 'xl',
      title: `Edit ${site.name}`,
      children: <AdminSiteEdit site={site} />,
    });
  };

  const columns = useMemo(() => {
    const helper = createColumnHelper<AdminSiteResponse>();

    return [
      helper.accessor('code', {
        header: 'Code',
        enableSorting: false,
        cell: (props) => {
          return <>{props.getValue()}</>;
        },
      }),

      helper.accessor('name', {
        header: 'Name',
        enableSorting: false,
        cell: (props) => {
          return (
            <Link
              fontWeight="bold"
              textDecoration="underline"
              onClick={() => handleSiteSelect(props.row.original)}
            >
              {props.getValue()}
            </Link>
          );
        },
      }),
    ];
  }, []);

  if (sitesQuery.isLoading) {
    return (
      <Box padding={2}>
        <Loading />
      </Box>
    );
  }

  if (sitesQuery.isError) {
    return (
      <ConfigErrorAlert
        title="Failed to load quoting configs"
        message={
          isAxiosError(sitesQuery.error)
            ? sitesQuery.error.response?.data.message
            : sitesQuery.error.message
        }
        onRetry={sitesQuery.refetch}
      />
    );
  }

  if (sitesQuery.isSuccess) {
    return <DataTable columns={columns} data={sitesQuery.data} />;
  }

  return null;
};
