import { Select, type SelectProps } from '@chakra-ui/react';
import type { Modify } from '@lib';
import { forwardRef } from 'react';

export type SimpleSelectProps = Modify<
  SelectProps,
  {
    defaultValue?: string | number | null | readonly string[];
    value?: string | number | null | readonly string[];
    //  inputRef?: React.Ref<HTMLInputElement>;
  }
>;

export const SimpleSelect = forwardRef<HTMLInputElement, SimpleSelectProps>(
  ({ defaultValue, value, ...rest }, ref) => {
    return (
      <Select
        ref={ref}
        defaultValue={defaultValue ?? undefined}
        value={value ?? undefined}
        {...rest}
      />
    );
  },
);

SimpleSelect.displayName = 'SimpleSelect';
