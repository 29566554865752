export type ValidationErrorSeverity = number;

export abstract class ValidationError extends Error {
  public abstract name: string;
  public abstract message: string;
  public abstract readonly severity: ValidationErrorSeverity;
  // Severity classes:
  // 0-4999: info
  // 5000-7999: warning
  // 8000-9999: error
}
