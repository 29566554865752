import { Input, type InputProps } from '@chakra-ui/react';
import type { Modify } from '@lib';
import { forwardRef } from 'react';
import type { ChangeHandler as RhfChangeHandler } from 'react-hook-form';

export type SimpleInputProps = Modify<
  InputProps,
  {
    defaultValue?: string | number | readonly string[] | null;
    /** Standard onChange handler or react-hook-form compatible one that returns
     * a promise*/
    onChange?:
      | React.ChangeEventHandler<HTMLInputElement>
      | ((
          e: React.ChangeEvent<HTMLInputElement>,
        ) => ReturnType<RhfChangeHandler>);
    /** Standard onBlur handler or react-hook-form compatible one that returns
     * a promise*/
    onBlur?:
      | React.FocusEventHandler<HTMLInputElement>
      | ((
          e: React.FocusEvent<HTMLInputElement>,
        ) => ReturnType<RhfChangeHandler>);
    /** `onChange` handler will be called before `onBlur`  *not* be called for each input change  */
    changeOnBlur?: boolean;
    /** Fires the 'blur' event when the "Enter" key is pressed while maintaining
     * input focus. Works alongside changeOnBlur:true*/
    blurOnEnterKey?: boolean;
  }
>;

/**
 * @param changeOnBlur - When true, `onChange` handler will be called prior to
 * the `onBlur` handler during blur events.
 * Note: Causes `onChange` to *not* be called for each input change
 * @param blurOnEnterKey - When true, the pressing the "Enter" key will trigger
 * the blur event while maintaining input focus. Works alongside changeOnBlur:true
 */
export const SimpleInput = forwardRef<HTMLInputElement, SimpleInputProps>(
  (
    {
      defaultValue,
      onChange,
      onBlur,
      onKeyDown,
      changeOnBlur = false,
      blurOnEnterKey = false,
      ...rest
    },
    ref,
  ) => {
    const eventHandlers = { onChange, onBlur, onKeyDown };

    if (changeOnBlur) {
      const initOnChange = onChange;
      const initOnBlur = onBlur;

      eventHandlers.onChange = undefined;
      eventHandlers.onBlur = async (e) => {
        const { type, ...rest } = e;
        // Must await change handler before calling blur handler in case of rhf
        // async onChange or state gets very weird
        await initOnChange?.({ type: 'change', ...rest });
        await initOnBlur?.(e);
      };
    }

    if (blurOnEnterKey) {
      const initOnKeyDown = onKeyDown;
      eventHandlers.onKeyDown = (e) => {
        initOnKeyDown?.(e);
        if (e.key === 'Enter') {
          e.currentTarget.blur();
          e.currentTarget.focus();
        }
      };
    }

    return (
      <Input
        ref={ref}
        autoComplete="no-auto-complete"
        defaultValue={defaultValue ?? undefined}
        {...eventHandlers}
        {...rest}
      />
    );
  },
);

SimpleInput.displayName = 'SimpleInput';
// Have to set `id` on any wrapped Chakra Input component for `InputGroup` styling
// to work correctly: https://github.com/chakra-ui/chakra-ui/issues/2269
// @ts-ignore
SimpleInput.id = 'Input';
