import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import type { PartHistoryCalculations } from '@lib';
import { styles } from './styles';

type PriceBookProps = {
  type?: 'discountPricing' | 'marketPricing' | null;
  pricing:
    | PartHistoryCalculations['discountPricing']['priceList']
    | PartHistoryCalculations['marketPricing']['priceList'];
};

export const PriceBook: React.FC<PriceBookProps> = ({ type, pricing }) => {
  if (!pricing) {
    return <Text color="red">No stock pricing available</Text>;
  }

  return (
    <>
      <TableContainer width="100%">
        <Table width="100%" sx={styles} size="sm">
          <Thead>
            <Tr>
              <Th rowSpan={2}>Default Unit Price</Th>
              {type === 'marketPricing' && <Th rowSpan={2}>Market</Th>}
              {type === 'discountPricing' && <Th rowSpan={2}>Site</Th>}
              <Th colSpan={10}>Quantity Breaks</Th>
            </Tr>
            <Tr>
              <Th>{pricing[0].qty_break_1}</Th>
              <Th>{pricing[0].qty_break_2}</Th>
              <Th>{pricing[0].qty_break_3}</Th>
              <Th>{pricing[0].qty_break_4}</Th>
              <Th>{pricing[0].qty_break_5}</Th>
              <Th>{pricing[0].qty_break_6}</Th>
              <Th>{pricing[0].qty_break_7}</Th>
              <Th>{pricing[0].qty_break_8}</Th>
              <Th>{pricing[0].qty_break_9}</Th>
              {pricing[0].qty_break_10 && <Th>{pricing[0].qty_break_10}</Th>}
            </Tr>
          </Thead>

          <Tbody>
            {pricing.map((row: any) => {
              return (
                <Tr key={`oh-${row.rowid}`}>
                  <Td>${row.default_unit_price}</Td>
                  {type === 'marketPricing' && <Td>{row.market_id}</Td>}
                  {type === 'discountPricing' && <Td>{row.site_id}</Td>}
                  <Td>${row.unit_price_1}</Td>
                  <Td>${row.unit_price_2}</Td>
                  <Td>${row.unit_price_3}</Td>
                  <Td>${row.unit_price_4}</Td>
                  <Td>${row.unit_price_5}</Td>
                  <Td>${row.unit_price_6}</Td>
                  <Td>${row.unit_price_7}</Td>
                  <Td>${row.unit_price_8}</Td>
                  <Td>${row.unit_price_9}</Td>
                  {pricing[0].qty_break_10 && <Td>${row.unit_price_10}</Td>}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
