import type { SortingState } from '@tanstack/react-table';
import { decodeSortingState, replaceSortParam } from '@ui/util';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export type Sorter = {
  setSorting: (sort: SortingState) => void;
  updateSortParam: (params: URLSearchParams, sort: SortingState) => void;
};

export const useSortingURLState = (): [SortingState, Sorter] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramsSort = searchParams.getAll('sort');

  // Fetch state from URL params
  const sortingState = useMemo(() => {
    return decodeSortingState(paramsSort) ?? [];
  }, [paramsSort]);

  const updateSortParam = useCallback(
    (params: URLSearchParams, sort: SortingState) => {
      replaceSortParam(params, sort);
    },
    [],
  );

  return useMemo(
    () => [
      sortingState,
      {
        setSorting: (sort: SortingState) => {
          setSearchParams((params) => {
            updateSortParam(params, sort);
            return params;
          });
        },
        updateSortParam,
      },
    ],
    [sortingState, setSearchParams, updateSortParam],
  );
};
