import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import type { CostingConfigResponse } from '@lib/responses';
import {
  type CostingConfigUpdateDefaultFormValues,
  type CostingConfigUpdateFormData,
  costingConfigUpdateSchema,
} from '@lib/validation';
import type { ConfigEntities, CostInputSources } from '@prisma/client';
import {
  CONFIG_ENTITY_PRIORITY_OPTS,
  COST_INPUT_SOURCE_OPTS,
} from '@ui/constants/enum-options';
import type { SelectOptions } from '@ui/util/types';
import { useEffect } from 'react';
import {
  Controller,
  type FieldValues,
  FormProvider,
  type SubmitHandler,
  type UseControllerProps,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Select from 'react-select';

type CostingConfigFormProps = {
  children?: (formState: {
    isDirty: boolean;
    isSubmitting: boolean;
  }) => JSX.Element | null;
  defaultConfig: CostingConfigResponse;
  onSubmit: SubmitHandler<CostingConfigUpdateFormData>;
};

export const CostingConfigForm = ({
  children,
  defaultConfig,
  onSubmit,
}: CostingConfigFormProps) => {
  const form = useForm<
    CostingConfigUpdateDefaultFormValues,
    unknown,
    CostingConfigUpdateFormData
  >({
    defaultValues: defaultConfig,
    resolver: zodResolver(costingConfigUpdateSchema),
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting, isDirty },
  } = form;

  /**
   * When a user changes the selected config, reset RHF form state. Without this,
   * the uncontrolled inputs would retain their old values. This also ensures
   * that the form `isDirty` state is accurate after a successful save.
   */
  useEffect(() => {
    form.reset(defaultConfig);
  }, [defaultConfig]);

  return (
    <FormProvider {...form}>
      <form role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        {children?.({
          isDirty,
          isSubmitting,
        })}

        <VStack gap={4} marginTop={4}>
          <FormControl
            isInvalid={!!errors.name}
            isDisabled={isSubmitting}
            isRequired
          >
            <FormLabel>Name</FormLabel>
            <Input {...register('name')} placeholder="Name" />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl
            id="costingConfigEntityPriorities_input"
            isInvalid={!!errors.configEntityPriorities}
          >
            <FormLabel>Config Priority</FormLabel>

            <Controller
              name="configEntityPriorities"
              control={control}
              disabled={isSubmitting}
              render={({ field: { name, value, onChange, disabled } }) => {
                const selectedValue = value
                  .map((v) =>
                    CONFIG_ENTITY_PRIORITY_OPTS.find((opt) => opt.value === v),
                  )
                  .filter(
                    (val): val is SelectOptions<ConfigEntities>[number] =>
                      !!val,
                  );

                return (
                  <Select
                    name={name}
                    inputId="costingConfigEntityPriorities_input"
                    isMulti
                    options={CONFIG_ENTITY_PRIORITY_OPTS}
                    isDisabled={disabled}
                    value={selectedValue}
                    onChange={(selectedOpts) =>
                      onChange(selectedOpts.map((opt) => opt.value))
                    }
                  />
                );
              }}
            />

            <FormErrorMessage>
              {errors.configEntityPriorities?.message}
            </FormErrorMessage>
          </FormControl>

          <CostInputField
            id="labRunCostPerHrSrc_input"
            label="Labor Run Cost Per Hour Source Priority"
            name="labRunCostPerHrSrc"
          />

          <CostInputField
            id="labRunCostPerUnitSrc_input"
            label="Labor Run Cost Per Unit Source Priority"
            name="labRunCostPerUnitSrc"
          />

          <CostInputField
            id="labSetupCostPerHrSrc_input"
            label="Labor Setup Cost Per Hour Source Priority"
            name="labSetupCostPerHrSrc"
          />

          <CostInputField
            id="burRunCostPerHrSrc_input"
            label="Burden Run Cost Per Hour Source Priority"
            name="burRunCostPerHrSrc"
          />

          <CostInputField
            id="burRunCostPerUnitSrc_input"
            label="Burden Run Cost Per Unit Source Priority"
            name="burRunCostPerUnitSrc"
          />

          <CostInputField
            id="burSetupCostPerHrSrc_input"
            label="Burden Setup Cost Per Hour Source Priority"
            name="burSetupCostPerHrSrc"
          />

          <CostInputField
            id="burCostPerOpSrc_input"
            label="Burden Cost Per Operation Source Priority"
            name="burCostPerOpSrc"
          />

          <CostInputField
            id="serviceIdSrc_input"
            label="Service ID Source Priority"
            name="serviceIdSrc"
          />

          <CostInputField
            id="srvChargePerUnitSrc_input"
            label="Service Charge Per Unit Source Priority"
            name="srvChargePerUnitSrc"
          />

          <CostInputField
            id="srvBaseChargeSrc_input"
            label="Service Base Charge Source Priority"
            name="srvBaseChargeSrc"
          />

          <FormControl
            isInvalid={!!errors.srvBaseChargeDefault}
            isDisabled={isSubmitting}
            isRequired
          >
            <FormLabel>Service Base Charge Default</FormLabel>

            <Input
              {...register('srvBaseChargeDefault')}
              placeholder="Base Default charge"
              type="number"
            />

            <FormErrorMessage>
              {errors.srvBaseChargeDefault?.message}
            </FormErrorMessage>
          </FormControl>

          <CostInputField
            id="srvMinChargeSrc_input"
            label="Service Min Charge Source Priority"
            name="srvMinChargeSrc"
          />

          <FormControl
            isInvalid={!!errors.srvMinChargeDefault}
            isDisabled={isSubmitting}
          >
            <FormLabel>Service Min Charge Default</FormLabel>

            <Input
              {...register('srvMinChargeDefault')}
              placeholder="Min Default charge"
              type="number"
            />

            <FormErrorMessage>
              {errors.srvMinChargeDefault?.message}
            </FormErrorMessage>
          </FormControl>

          <CostInputField
            id="matMinCostSrc_input"
            label="Material Min Cost Source Priority"
            name="matMinCostSrc"
          />

          <FormControl
            isInvalid={!!errors.matMinCostDefault}
            isDisabled={isSubmitting}
          >
            <FormLabel>Material Min Cost Default</FormLabel>

            <Input
              {...register('matMinCostDefault')}
              placeholder="Material Min Default charge"
              type="number"
            />

            <FormErrorMessage>
              {errors.matMinCostDefault?.message}
            </FormErrorMessage>
          </FormControl>

          <CostInputField
            id="matFixedCostSrc_input"
            label="Material Fixed Cost Source Priority"
            name="matFixedCostSrc"
          />

          <CostInputField
            id="matUnitCostSrc_input"
            label="Material Unit Cost Source Priority"
            name="matUnitCostSrc"
          />

          <CostInputField
            id="reqSrvUnitCostSrc_input"
            label="Required Service Unit Cost Source Priority"
            name="reqSrvUnitCostSrc"
          />

          <CostInputField
            id="reqLabUnitCostSrc_input"
            label="Required Labor Unit Cost Source Priority"
            name="reqLabUnitCostSrc"
          />

          <CostInputField
            id="reqBurUnitCostSrc_input"
            label="Required Burden Unit Cost Source Priority"
            name="reqBurUnitCostSrc"
          />

          <CostInputField
            id="reqBurPctSrc_input"
            label="Required Burden Percentage Source Priority"
            name="reqBurPctSrc"
          />

          <CostInputField
            id="reqBurPerUnitSrc_input"
            label="Required Burden Per Unit Source Priority"
            name="reqBurPerUnitSrc"
          />

          <CostInputField
            id="reqFixedQtySrc_input"
            label="Required Fixed Quantity Source Priority"
            name="reqFixedQtySrc"
          />

          <CostInputField
            id="reqBasisQtySrc_input"
            label="Required Basis Quantity Source Priority"
            name="reqBasisQtySrc"
          />

          <CostInputField
            id="reqUnitsPerPieceSrc_input"
            label="Required Units Per Piece Source Priority"
            name="reqUnitsPerPieceSrc"
          />
        </VStack>
      </form>
    </FormProvider>
  );
};

function CostInputField<T extends FieldValues = CostingConfigUpdateFormData>({
  id,
  label,
  name,
}: {
  id: string;
  label: string;
} & Pick<UseControllerProps<T>, 'name'>) {
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext<T>();

  return (
    <FormControl id={id} isInvalid={!!errors[name]}>
      <FormLabel>{label}</FormLabel>

      <Controller
        name={name}
        control={control}
        disabled={isSubmitting}
        render={({ field: { name, value, onChange, disabled } }) => {
          const selectedValue = value
            .map((v: string) =>
              COST_INPUT_SOURCE_OPTS.find((opt) => opt.value === v),
            )
            .filter(
              (
                val: SelectOptions<CostInputSources>[number] | undefined,
              ): val is SelectOptions<CostInputSources>[number] => !!val,
            );

          return (
            <Select
              name={name}
              isMulti
              inputId={id}
              isDisabled={disabled}
              options={COST_INPUT_SOURCE_OPTS}
              value={selectedValue}
              onChange={(selectedOps) =>
                onChange(selectedOps.map((opt) => opt.value))
              }
            />
          );
        }}
      />

      {/* @NOTE(shawk): TS can't easily understand this type - the generics are
          not quite precise enough to express the relationship between the
          `errors` object and the form data/field name  */}
      <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>
    </FormControl>
  );
}
