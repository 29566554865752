import { CurrentSiteGuard } from '@ui/components/CurrentSiteGuard';
import { RoutesConfig, route } from '@ui/config/routes';
import { QuoteBuilder } from '@ui/pages/QuoteBuilder';
import { useNavigate, useParams } from 'react-router-dom';

export const QuoteShow = () => {
  const { id: quoteUUID } = useParams<{ id: string }>();
  const navigate = useNavigate();

  // TODO: load quote here instead of in QuoteBuilder

  if (!quoteUUID) {
    navigate(route(RoutesConfig.quoteQueue));

    return null;
  }

  return (
    <CurrentSiteGuard>
      <QuoteBuilder quoteUUID={quoteUUID} />
    </CurrentSiteGuard>
  );
};
