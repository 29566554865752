import { Box } from '@chakra-ui/react';
import PageWrapper from '@ui/components/PageWrapper';
import PageHeader from '@ui/components/layout/PageHeader';
import { SitesTable } from './SitesTable';

export const AdminSiteIndex = () => {
  return (
    <PageWrapper>
      <PageHeader title="Admin / Site Management" />

      <Box w="100%">
        <SitesTable />
      </Box>
    </PageWrapper>
  );
};
