import {
  Box,
  Button,
  Center,
  Checkbox,
  HStack,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
} from '@chakra-ui/react';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type ReactPDF from '@react-pdf/renderer';
import { useIsFetching } from '@tanstack/react-query';
import Loading from '@ui/components/Loading';
import theme from '@ui/config/theme';
import { useAuth } from '@ui/state/auth';
import { type FC, useEffect } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { P, match } from 'ts-pattern';
import type { QuoteBuilderForm } from './QuoteBuilder';
import { QuotePDFViewer } from './QuotePdfViewer';
import { useValidationToast } from './hooks/useToast';

interface QuotePDFModalProps {
  onPDFPreviewClose: () => void;
  isPDFPreviewOpen: boolean;
  handleSendQuoteToCustomer: () => void;
  isLoading: boolean;
  isSaving: boolean;
  pdfInstance: ReactPDF.UsePDFInstance;
}

export const QuotePDFModal: FC<QuotePDFModalProps> = ({
  onPDFPreviewClose,
  isPDFPreviewOpen,
  pdfInstance,
  isLoading,
  isSaving,
  handleSendQuoteToCustomer,
}) => {
  const { user } = useAuth();
  const { getValues, register, control } = useFormContext<QuoteBuilderForm>();
  const { isValid } = useFormState({ control });
  const quoteId = getValues('quoteId');
  const email = useWatch({
    name: 'customerHeader.email',
    control,
  });
  const { genericErrorToast } = useValidationToast();
  const queriesFetching = useIsFetching();

  const { error: pdfErr } = pdfInstance;

  useEffect(() => {
    if (pdfErr != null) {
      console.error('PDF instance error in QuotePDFModal', { pdfErr });
      genericErrorToast(
        'Unexpected error generating PDF',
        'Please save, reload, and try again. If the problem persists, please contact support.',
      );
    }
  }, [pdfErr, genericErrorToast]);

  const hasCustomerEmail = !!email?.length;
  const canSendToErp = isValid && !isLoading && !isSaving && !queriesFetching;

  return (
    <Modal onClose={onPDFPreviewClose} size={'full'} isOpen={isPDFPreviewOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Quote {quoteId}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {match(pdfInstance)
            .with({ loading: true }, () => (
              <Center>
                <Loading />
              </Center>
            ))
            .with(
              {
                url: P.string,
                blob: P.nonNullable,
                error: null,
                loading: false,
              },
              (instance) => (
                <QuotePDFViewer pdfUrl={instance.url} quoteId={quoteId} />
              ),
            )
            .otherwise(() => null)}
        </ModalBody>
        <ModalFooter justifyContent="start">
          <HStack width="33%" justifySelf="start">
            {!hasCustomerEmail && (
              <Tooltip label="Customer email is not set">
                <Icon
                  mx={2}
                  as={FontAwesomeIcon}
                  icon={faTriangleExclamation}
                  color={theme.colors.validation.error}
                  size="xl"
                />
              </Tooltip>
            )}
            <Checkbox
              {...register('sendToCustomer')}
              isDisabled={!hasCustomerEmail}
              defaultChecked={
                hasCustomerEmail
                  ? user?.userSettings?.emailQuoteToCustomer ?? true
                  : false
              }
            >
              Send email to customer
            </Checkbox>
          </HStack>
          <HStack width="77%" justifyContent="end">
            <Box>
              {isSaving ? (
                <Loading />
              ) : !isValid ? (
                <Tooltip label="Please fix errors before sending">
                  <Icon
                    as={FontAwesomeIcon}
                    icon={faTriangleExclamation}
                    color={theme.colors.validation.warning}
                    size="2x"
                  />
                </Tooltip>
              ) : null}
            </Box>
            {pdfInstance?.url && pdfInstance.blob && (
              <Button
                marginLeft={2}
                gap={2}
                bgColor="#0b7078"
                color="white"
                _hover={{ bg: '#046068', textDecoration: 'none' }}
                as={Link}
                href={pdfInstance.url}
                download={`${quoteId}-${Date.now()}.pdf`}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  fontSize={12}
                  aria-label="Download PDF"
                />
                Download PDF
              </Button>
            )}
            <Button
              marginLeft={2}
              gap={2}
              bgColor="#0b7078"
              color="white"
              _hover={{ bg: '#046068' }}
              _disabled={{ bg: '#aaaaaa' }}
              onClick={onPDFPreviewClose}
            >
              Edit Quote
            </Button>
            <Button
              marginLeft={2}
              gap={2}
              bgColor="#0b7078"
              color="white"
              _hover={{ bg: '#046068' }}
              _disabled={{ bg: '#aaaaaa' }}
              onClick={handleSendQuoteToCustomer}
              isDisabled={!canSendToErp}
            >
              Send to ERP
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
