import type { ValidationError } from '../errors';
import {
  type QbLifecycleValidationInput,
  type QbLifecycleValidatorKey,
  onAddLineKeys,
  onLoadQuoteKeys,
  qbLifecycleValidators,
} from './errors';

// TODO: move these to site config / database
const SITES_USING_VALIDATORS: { [key: string]: QbLifecycleValidatorKey[] } = {
  ASM: ['LastOrder2YearsAgo', 'PriceDifference10Percent', 'Quote15LinesOrMore'],
  RAF: ['PriceDifference10Percent'],
};

const qbLifecycleValidate = (
  validators: readonly QbLifecycleValidatorKey[],
  input: QbLifecycleValidationInput,
) => {
  const results = [];
  for (const v of validators) {
    // TODO: make this a filter on the site config's assigned validators
    if (
      input.siteCode &&
      input.siteCode in SITES_USING_VALIDATORS &&
      SITES_USING_VALIDATORS[input.siteCode].includes(v)
    ) {
      const validationResult = new qbLifecycleValidators[v](input);
      if (validationResult.message) {
        results.push(validationResult);
      }
    }
  }
  return results;
};

export const qbLifecycleValidateOnAddLine = (
  input: QbLifecycleValidationInput,
): ValidationError[] => {
  return qbLifecycleValidate(onAddLineKeys, input);
};

export const qbLifecycleValidateOnLoadQuote = (
  input: QbLifecycleValidationInput,
): ValidationError[] => {
  return qbLifecycleValidate(onLoadQuoteKeys, input);
};
