import { Image, Page, Text, View } from '@react-pdf/renderer';
import { useQuotePdfContext } from '@ui/pages/QuoteBuilder/hooks';
import type { FC } from 'react';
import { pdfStyles } from '../pdf-styles';
import { CustomerQuotePdfTemplate } from './template';

export const CustomerQuote: FC = () => {
  const { quoteId } = useQuotePdfContext();
  return (
    <Page size="A4" style={pdfStyles.page}>
      <View style={pdfStyles.row}>
        <View style={[pdfStyles.section, pdfStyles.alignCenter]}>
          <Image
            src={'/images/ASM/ACCURATE_SCREW_MACHINE_RGB_PRIMARY_LOGO_AW.png'}
            // the default pdfStyles.logo is specialized for CAP
            // customize logo style to preserve logo aspect ratio and margins
            style={[pdfStyles.logo, { height: '25px' }]}
          />
        </View>
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.bold}>Accurate Screw Machine</Text>
          <Text>10 Audrey Place</Text>
          <Text>Fairfield, NJ 07004</Text>
          <Text>Phone (973) 244 - 9200</Text>
          <Text>Fax (877) 285 - 2181</Text>
          <Text>FEIN: 38-2938499</Text>
          <Text>CAGE Code: 51506</Text>
        </View>
        <View
          style={[
            pdfStyles.section,
            pdfStyles.alignCenter,
            pdfStyles.maxWidthFull,
          ]}
        >
          <Text style={[pdfStyles.quoteNumber, pdfStyles.bold]}>
            Quotation {quoteId}
          </Text>
          <Text style={pdfStyles.textSmall}>
            Please refer to the quotation number when ordering
          </Text>
        </View>
      </View>
      <CustomerQuotePdfTemplate />
      <View style={pdfStyles.row}>
        <View style={pdfStyles.fullRow}>
          <Text>*****SHIP POLICY: 15 DAYS EARLY / 2 DAYS LATE *****</Text>
          <Text>{'\n'}</Text>
          <Text style={[pdfStyles.bold, pdfStyles.textSmall]}>
            NOTE: Quantity break prices are stated above, however you can order
            the specific quantity needed. Please include the ASQ number on any
            order. Our MGF CofC is included at no cost with every shipment. If
            chem/phys test reports are required, these are an additional $100
            per item. If a First Article Inspection Report (FAIR) is required,
            this is an additional $750 fee. If the FAIR needs to be completed
            through NetInspect the fee is $1,000. This report includes chem/phys
            certs. Please specific if NADCAP approved processors are required.
            This comes at additional costs. CONDITIONS OF SALE: 1. NO CONTRACT
            SHALL EXIST UNTIL SELLER RECEIVES ORDER FOR DELIVERY PLACED BY
            BUYER. 2. PRICES ARE SUBJECT TO CHANGE TO CONFORM TO PRICES
            PREVAILING AT TIME OF SHIPMENT. 3. PRICES ARE SUBJECT TO PRODUCTION
            RELEASES. WHERE REVISIONS RESULT IN HIGHER COSTS PRICES ARE SUBJECT
            TO CHANGE. 4. ALL PRICES ARE SUBJECT TO CHANGE TO CONFORM TO ALL
            STATE AND FEDERAL LAWS. 5. CHARGES MADE FOR TOOLS AND DIES DO NOT
            CONVEY THE RIGHT TO REMOVE THEM FROM OUR FACTORY. 6. IF ANY MATERIAL
            IS PROVED DEFECTIVE, SELLERS LIABILITY IS LIMITED TO REPLACEMENT
            ONLY AND SELLER WILL NOT PAY ANY CLAIM FOR LABOR ON OR FOR DAMAGE BY
            REASON OF ITS USE. 7. THE SELLER SHALL NOT BE LIABLE FOR
            INFRINGEMENT OF ANY PATENTS REQUIRED TO BE USED BY THE
            SPECIFICATIONS OF THE BUYER AND THE BUYER AGREES TO SAVE THE SELLER
            HARMLESS FOR ALL JUDGEMENTS, DECREES, COSTS AND OTHER LEGAL EXPENSES
            RESULTING FROM ANY SUITS AND AGREES TO DEFEND SELLER SHOULD SUCH A
            SUIT BE INSTITUTED. 8. GOODS SHALL BE INSPECTED WHEN RECEIVED AND
            THE SELLER IS NOT LIABLE FOR ANY DEFECTS UNLESS NOTIFIED WITHIN 30
            DAYS AFTER RECEIPT BY THE BUYER. 9. WE RESERVE THE RIGHT TO OVER AND
            UNDER SHIP BY ANY AMOUNT NOT TO EXCEED TEN PERCENT OF SPECIFIED
            QUANTITY. 10. ALL AGREEMENTS ARE CONTINGENT UPON STRIKES, ACCIDENTS
            AND OTHER DELAYS UNAVOIDABLE OR BEYOND OUR CONTROL.
          </Text>
          <Text>{'\n'}</Text>
          <Text>
            Standard terms and conditions can be found on www.mwcomponents.com
            &lt;https://www.mwcomponents.com/mw-industries-terms-and-conditions&gt;
          </Text>
        </View>
      </View>
    </Page>
  );
};
