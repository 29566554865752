import {
  type PartHistoryCalculations,
  type PricingCalculator,
  PricingCalculatorFactory,
} from '@lib/calculations';
import {
  type ValidatedPartHistoryResponse,
  ValidationError,
} from '@lib/validation';
import { PricingStrategyError } from '@lib/validation/calcs/pricing';
import { useActiveSiteConfig } from '@ui/hooks';
import { useAuth } from '@ui/state/auth';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import type { QuoteBuilderForm } from '../QuoteBuilder';
import type { LinePathType } from '../types';
import { useValidationToast } from './useToast';

interface UsePricingData {
  partHistory?: ValidatedPartHistoryResponse | null;
  partHistoryCalcs?: PartHistoryCalculations | null;
}

export const usePricing = (linePath: LinePathType, data: UsePricingData) => {
  const { currentSite: _currentSite } = useAuth();
  // TODO: temp non-null assertion since they are both gated in wrappers but
  //  will be handled gracefully & typed at the provider level soon
  const currentSite = _currentSite!;
  const { pricingConfig: sitePricingConfig } = useActiveSiteConfig();
  const { validationErrorToast, genericErrorToast } = useValidationToast();

  const { getValues, control } = useFormContext<QuoteBuilderForm>();
  const [quoteUUID, quoteId] = useWatch({
    name: ['id', 'quoteId'],
    control,
  });

  const { partHistory, partHistoryCalcs } = data;
  const pricingConfig =
    partHistoryCalcs?.suggestions.pricingConfigConsolidated ??
    sitePricingConfig; // TODO: extract pricing config consolidation logic from ph calcs so its always defined

  return useMemo(() => {
    const quoteData = {
      id: quoteUUID,
      quoteId,
      customerHeader: getValues('customerHeader'), // only need to init, consumers can update
    };
    try {
      return PricingCalculatorFactory(pricingConfig, {
        site: currentSite,
        partHistoryCalcs,
        partHistory,
        quoteData,
      });
    } catch (error) {
      const title = `Error intializing pricing calculations for part: ${partHistory?.partId}`;
      const toastIdBase = `${linePath}_PricingCalculatorFactory_${partHistory?.partId}`;
      if (!(error instanceof Error)) {
        genericErrorToast(
          `Unexpected error for part: ${partHistory?.partId}`,
          'Cannot calculate any pricing. Please contact support.',
          true,
          false,
          {
            id: `${toastIdBase}_unknown_${
              error instanceof Error ? error?.message : 'non-error'
            }`,
            preventDups: true,
            isClosable: true,
            logMeta: { error, linePath },
          },
        );
      } else {
        // unique to prevent dups
        const toastId = `${toastIdBase}_${error.name}_${error.message}`;
        if (error instanceof ValidationError) {
          validationErrorToast(error, {
            title,
            id: toastId,
            preventDups: true,
            duration: null, //sticky
          });
        } else {
          genericErrorToast(title, error.message, true, false, {
            id: toastId,
            preventDups: true,
            isClosable: true,
          });
        }
      }
      // !TODO: (bb) Stop-gap until more elegant error handling
      const genericThrow = () => {
        throw new PricingStrategyError(
          `Unable to calculate pricing for part: ${partHistory?.partId}`,
        );
      };
      const emptyPricingCalculator: PricingCalculator = {
        calcInitPrice: genericThrow,
        recalcPrice: genericThrow,
        recalcPriceOnQtyChange: genericThrow,
        recalcPriceOnCostChange: genericThrow,
        recalcPriceOnOptionChange: genericThrow,
        recalcPriceOnCustomerTierChange: genericThrow,
      };

      return emptyPricingCalculator;
    }
  }, [
    linePath,
    currentSite,
    partHistory,
    partHistoryCalcs,
    pricingConfig,
    quoteUUID,
    quoteId,
    validationErrorToast,
    genericErrorToast,
    getValues,
  ]);
};
