import { partOptionMetadataSchema } from '@lib/validation/part-option/part-option';
import { z } from 'zod';

export const craftPartOptionsSchema = z.object({
  weight: z.number(),
  optionGroups: z.array(
    z.object({
      name: z.string(),
      options: z.array(partOptionMetadataSchema),
    }),
  ),
});

export type CraftPartOptions = z.infer<typeof craftPartOptionsSchema>;
