import {
  Button,
  ButtonGroup as ChakraButtonGroup,
  Flex,
  type FlexProps,
  FormControl,
  FormLabel,
  Select,
  Text,
} from '@chakra-ui/react';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isUrlPaginator, usePaginationController } from '@ui/hooks/pagination';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { PaginationCtx, usePaginationContext } from './context';
import type { PaginationProps } from './types';

const defaultPerPageOptions = [10, 25, 50, 100] as const;

export const Root = React.memo(
  ({ metadata, paginator, children }: PaginationProps) => {
    const controller = usePaginationController(metadata, paginator);

    return (
      <PaginationCtx.Provider value={{ controller, paginator }}>
        {children}
      </PaginationCtx.Provider>
    );
  },
);

export function RootLayout(props: FlexProps) {
  return <Flex justify="space-between" align="center" {...props} />;
}

export function Metadata() {
  const {
    controller: { model },
  } = usePaginationContext();

  if (model.totalItems <= 0) {
    return null;
  }

  return (
    <Text fontSize="sm">
      Viewing {model.currentPageStart()} - {model.currentPageEnd()} of{' '}
      {model.totalItems} results
    </Text>
  );
}

export function RightArea({ children }: { children: React.ReactNode }) {
  return (
    <Flex ml="auto" gap={2}>
      {children}
    </Flex>
  );
}

export type PerPageSelectProps = { perPageOptions?: readonly number[] };

export function PerPageSelect({
  perPageOptions = defaultPerPageOptions,
}: PerPageSelectProps) {
  const { controller } = usePaginationContext();
  const uuid = useRef(uuidv4());
  const id = `perPage-${uuid}`;

  return (
    <FormControl display="flex" alignItems="center" gap={2}>
      <FormLabel htmlFor={id} fontSize="sm" m={0}>
        Results per page:
      </FormLabel>

      <Select
        id={id}
        width="auto"
        value={controller.model.perPage}
        onChange={(e) =>
          controller.setPerPage(Number.parseInt(e.target.value, 10))
        }
      >
        {perPageOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

export function ButtonGroup({ children }: { children: React.ReactNode }) {
  return (
    <ChakraButtonGroup isAttached variant="outline">
      {children}
    </ChakraButtonGroup>
  );
}

export function PrevPageButton({ children }: { children?: React.ReactNode }) {
  const { controller } = usePaginationContext();

  return (
    <Button
      onClick={controller.onPrev}
      isDisabled={!controller.hasPrevPage()}
      display="flex"
      gap={2}
    >
      {children || (
        <>
          <FontAwesomeIcon icon={faCaretLeft} /> Previous Page
        </>
      )}
    </Button>
  );
}

export function NextPageButton({ children }: { children?: React.ReactNode }) {
  const { controller } = usePaginationContext();

  return (
    <Button
      onClick={controller.onNext}
      isDisabled={!controller.hasNextPage()}
      display="flex"
      gap={2}
    >
      {children || (
        <>
          Next Page <FontAwesomeIcon icon={faCaretRight} />
        </>
      )}
    </Button>
  );
}

export function PrevPageLink({ children }: { children?: React.ReactNode }) {
  const { controller, paginator } = usePaginationContext();

  if (!isUrlPaginator(paginator)) {
    throw new Error('PrevPageLink can only be used with URLPaginator');
  }

  return (
    <Button
      as={Link}
      to={{
        search: paginator.getSearchParams(controller.prevPage()).toString(),
      }}
      isDisabled={!controller.hasPrevPage()}
      display="flex"
      gap={2}
    >
      {children || (
        <>
          <FontAwesomeIcon icon={faCaretLeft} /> Previous Page
        </>
      )}
    </Button>
  );
}

export function NextPageLink({ children }: { children?: React.ReactNode }) {
  const { controller, paginator } = usePaginationContext();

  if (!isUrlPaginator(paginator)) {
    throw new Error('PrevPageLink can only be used with URLPaginator');
  }

  return (
    <Button
      as={Link}
      to={{
        search: paginator.getSearchParams(controller.nextPage()).toString(),
      }}
      isDisabled={!controller.hasNextPage()}
      display="flex"
      gap={2}
    >
      {children || (
        <>
          Next Page <FontAwesomeIcon icon={faCaretRight} />
        </>
      )}
    </Button>
  );
}

export const Pagination = {
  Root,
  RootLayout,
  Metadata,
  RightArea,
  PerPageSelect,
  ButtonGroup,
  PrevPageButton,
  NextPageButton,
  PrevPageLink,
  NextPageLink,
};
