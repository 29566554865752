import { Tooltip } from '@chakra-ui/react';
import type { UIManagerBase } from '@ui/validation/ui-manager';
import { memo } from 'react';
import { Icon } from '../Icon';

export interface QuoteLinePartDetailsErrorIconProps {
  lineManager?: UIManagerBase | null;
}

/**
 * Renders an error icon for a quote line
 */
export const QuoteLinePartDetailsErrorIcon: React.FC<QuoteLinePartDetailsErrorIconProps> =
  memo(({ lineManager }) => {
    if (lineManager?.hasFailed && lineManager.mostSevere) {
      return (
        <Tooltip label={lineManager.mostSevere.userMessage} placement="top-end">
          <Icon
            icon={lineManager.mostSevere.icon}
            color={lineManager.mostSevere.color}
          />
        </Tooltip>
      );
    }

    return null;
  });
