import {
  Container,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { type FC, useState } from 'react';
import { CostingConfigTab } from './CostingConfigTab';
import { PricingConfigTab } from './PricingConfigTab';
import { QuotingConfigTab } from './QuotingConfigTab';

export const ConfigsPanel: FC = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Container maxW="container.lg" py={4}>
      <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>Quoting</Tab>
          <Tab>Pricing</Tab>
          <Tab>Costing</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <QuotingConfigTab isActive={tabIndex === 0} />
          </TabPanel>
          <TabPanel>
            <PricingConfigTab isActive={tabIndex === 1} />
          </TabPanel>
          <TabPanel>
            <CostingConfigTab isActive={tabIndex === 2} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};
