import { msalInstance } from '../config/authConfig';
import { AdminConfigsService } from './admin/configs';
import { AdminMetricsService } from './admin/metrics';
import { AdminRoleService } from './admin/role';
import { AdminSiteService } from './admin/site';
import { AdminUserService } from './admin/user';
import { API } from './api';
import { CustomerService } from './customer';
import { DocumentService } from './document';
import { InventoryService } from './inventory';
import { NegotiatedPriceService } from './negotiated-price';
import { PartService } from './part';
import { PartEngineeringService } from './part-engineering';
import { QuoteService } from './quote';
import { SalesRepService } from './sales-rep';
import { SiteService } from './site';
import { UserService } from './user';

const api = new API({ msalInstance });

export const userService = new UserService(api);
export const customerService = new CustomerService(api);
export const documentService = new DocumentService(api);
export const quoteService = new QuoteService(api);
export const partService = new PartService(api);
export const negotiatedPriceService = new NegotiatedPriceService(api);
export const partEngineeringService = new PartEngineeringService(api);
export const siteService = new SiteService(api);
export const inventoryService = new InventoryService(api);
export const salesRepService = new SalesRepService(api);

export const adminConfigsService = new AdminConfigsService(api);
export const adminRoleService = new AdminRoleService(api);
export const adminSiteService = new AdminSiteService(api);
export const adminUserService = new AdminUserService(api);
export const adminMetricsService = new AdminMetricsService(api);
