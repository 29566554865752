import { type UseMutationOptions, useMutation } from '@tanstack/react-query';
import { documentService } from '@ui/services';

export const deleteDocumentMutation = () => ({
  mutationFn: (documentId: string) =>
    documentService.deleteDocument(documentId),
});

export function useDeleteDocumentMutation(
  options?: Omit<UseMutationOptions<string, unknown, string>, 'mutationFn'>,
) {
  return useMutation({
    ...deleteDocumentMutation(),
    ...options,
  });
}
