import { type MaybeCanParse, maybeRoundToString } from '@lib';
import { commaSeparated } from './numbers';

export const currency = (number: MaybeCanParse, separator = false): string => {
  const rounded = maybeRoundToString(
    number,
    {
      min: 2,
      small: 3,
    },
    '',
  );

  if (rounded === '') {
    return '';
  }

  const formatted = commaSeparated(rounded, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
    useGrouping: separator,
  });

  return `$${formatted}`;
};
