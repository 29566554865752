import type {
  OrderLineCalculations,
  PartHistoryCalculations,
  PartHistoryValidation,
  ValidPartHistoryResponse,
  ValidationError,
} from '@lib';
import type { PartHistoryResponse } from '@lib/responses/part';
import type { LastWinCalcsValidation } from '@lib/validation/calcs/last-win';
import type { PartHistoryError } from '@lib/validation/part/errors';
import { orderBy, startCase } from 'lodash-es';
import { P, match } from 'ts-pattern';
import {
  type ErrorPropsBase,
  type UIManagerBase,
  getErrorPropsBase,
} from '../../../validation/ui-manager';

/*
TODO: Note on extending for other entities:
Originally I had this as a class but you can't really make a single class
return a union of types based on possible discriminators (valid or not valid). 
But being able to extend a base manager class for the same responsibility of
diff entities could be useful. Could achieve it with an additional method to
get the concrete ErrorProps like:
"const props = new PartHistoryUIManager(validation).getProps() " 

We could also just inject different per-entity modifiers (i.e. PartHistory adds
a partId to error props) in a base factory fn and create the concretes in an
index. Good for testing
 */
export type PartHistoryUIManager = UIManagerBase<
  {
    partId: string;
    /** @deprecated Don't use from here, not updated in state */
    partHistory: ValidPartHistoryResponse;
    /** @deprecated Don't use from here, not updated in state */
    partHistoryCalcs: PartHistoryCalculations;
  },
  {
    partId: string;
    /** @deprecated Don't use from here, not updated in state */
    partHistory: PartHistoryResponse;
    /** @deprecated Don't use from here, not updated in state */
    partHistoryCalcs: PartHistoryCalculations;
  }
>;

export type MergedPartHistoryUIManager = UIManagerBase & {
  partId: string;
  lastWinCalcsMgr: LastWinCalcsUIManager;
  partHistoryMgr: PartHistoryUIManager;
};

export const makePartHistoryUIManager = (
  validationResponse: PartHistoryValidation,
  partId: string,
  partHistoryCalcs: PartHistoryCalculations,
): PartHistoryUIManager & {
  addLastWinCalcsValidation: (
    validation: LastWinCalcsValidation,
  ) => MergedPartHistoryUIManager;
} => {
  const makeErrorProps = (e: PartHistoryError) =>
    getErrorPropsBase(
      e,
      (status) => `${startCase(status)} adding part: ${partId}`,
    );

  const partHistoryMgr = match(validationResponse)
    .with(
      {
        valid: false,
        data: P.select('partHistory', { partId: P.select('partId') }),
      },
      ({ partHistory, partId }, { errors, valid, warnings }) => {
        const combined = [...errors, ...warnings];
        const errorProps = orderBy(
          combined.map(makeErrorProps),
          ['severity'],
          ['desc'],
        );
        return {
          errorProps,
          mostSevere: errorProps[0],
          partId,
          valid,
          hasFailed: true as const,
          partHistory,
          partHistoryCalcs,
        };
      },
    )
    .with(
      {
        warnings: P.not([]),
        data: P.select('partHistory', { partId: P.select('partId') }),
      },
      ({ partHistory, partId }, { warnings, valid }) => {
        const errorProps = orderBy(
          warnings.map(makeErrorProps),
          ['severity'],
          ['desc'],
        );
        return {
          errorProps,
          valid,
          hasFailed: true as const,
          mostSevere: errorProps[0],
          partId,
          partHistory,
          partHistoryCalcs,
        };
      },
    )
    .with({ warnings: [] }, ({ data, valid }) => {
      return {
        partId: data.partId,
        valid,
        partHistory: data,
        partHistoryCalcs,
        hasFailed: false as const,
        errorProps: [],
      };
    })
    .exhaustive();

  return {
    ...partHistoryMgr,
    addLastWinCalcsValidation: (
      validation: LastWinCalcsValidation,
    ): MergedPartHistoryUIManager => {
      const { partId } = partHistoryMgr;

      const lastWinCalcsMgr = makeLastWinCalcsUIManager(validation, partId);

      const mgrs = [partHistoryMgr, lastWinCalcsMgr];
      const mergedErrorProps = mgrs
        .reduce((acc, mgr) => {
          acc.push(...mgr.errorProps);
          return acc;
        }, [] as ErrorPropsBase[])
        .sort((a, b) => b.severity - a.severity);

      const mergedMgr = match(mgrs)
        // match all valid (no errors) without any failed (warnings/info)
        .with(P.array({ valid: true, hasFailed: false }), () => ({
          valid: true as const,
          hasFailed: false as const,
          errorProps: [],
        }))
        // match all valid (no errors) with failed
        .with(P.array({ valid: true, hasFailed: true }), () => ({
          valid: true as const,
          hasFailed: true as const,
          errorProps: mergedErrorProps,
          mostSevere: mergedErrorProps[0],
        }))
        // match any invalid, will always have some failed between mgrs
        .otherwise(() => {
          return {
            valid: false as const,
            hasFailed: true as const,
            errorProps: mergedErrorProps,
            mostSevere: mergedErrorProps[0],
          };
        });

      // expose the original/individual mgrs too incase they have a use, f/e
      // guarded data
      return { ...mergedMgr, lastWinCalcsMgr, partHistoryMgr, partId };
    },
  };
};

export type LastWinCalcsUIManager = UIManagerBase<
  {
    calcs: OrderLineCalculations;
  },
  { calcs?: OrderLineCalculations | null }
>;

/** Most likely the last win calcs mgr should be only created by calling the
 * addLastWinCalcsValidation on the part history mgr so that errors and severity
 *can be merged */
export const makeLastWinCalcsUIManager = (
  validationResponse: LastWinCalcsValidation,
  partId: string,
): LastWinCalcsUIManager => {
  const makeErrorProps = (e: ValidationError) =>
    getErrorPropsBase(
      e,
      (status) =>
        `${startCase(status)} calculating suggestions for part: ${partId}`,
    );
  const mgr = match(validationResponse)
    .with({ valid: false }, ({ errors, warnings, valid, data }) => {
      const errorProps = orderBy(
        [...errors, ...warnings].map((e) => makeErrorProps(e)),
        ['severity'],
        ['desc'],
      );
      return {
        errorProps,
        mostSevere: errorProps[0],
        valid,
        hasFailed: true as const,
        calcs: data,
      };
    })
    .with({ valid: true, warnings: P.not([]) }, ({ data, valid, warnings }) => {
      const errorProps = orderBy(
        [...warnings].map((e) => makeErrorProps(e)),
        ['severity'],
        ['desc'],
      );
      return {
        valid,
        calcs: data,
        hasFailed: true as const,
        errorProps,
        mostSevere: errorProps[0],
      };
    })
    .with({ valid: true, warnings: [] }, ({ data, valid }) => ({
      valid,
      calcs: data,
      hasFailed: false as const,
      errorProps: [],
    }))
    .exhaustive();

  return mgr;
};
