import { format, parseISO } from 'date-fns';

export const formatISODate = (
  isoDate: string | null | undefined,
  formatStr: string,
  fallback?: string,
) => {
  if (!isoDate) {
    return fallback ?? '';
  }

  return format(parseISO(isoDate), formatStr);
};

export const monthDayYear = (
  date: string | null | undefined,
  fallback?: string,
) => {
  return formatISODate(date, 'MM/dd/yyyy', fallback);
};
