import {
  Alert,
  AlertIcon,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { type PartEngineeringResponse, calcPartEngineering } from '@lib';
import is from '@sindresorhus/is';
import { currency } from '@ui/util/currency';
import { monthDayYear } from '@ui/util/dates';
import type { FC } from 'react';
import { styles } from './QuoteLineDetails/styles';

// TODO: this should be configurable
const VARIANCE_TOLERANCE = 15;

const calcVarianceTd = (variance: number) => {
  let color;
  if (Math.abs(variance) >= VARIANCE_TOLERANCE) color = '#ff0000';
  return <Td color={color}>{is.infinite(variance) ? '~' : variance || 0}%</Td>;
};

const costRows = [
  {
    name: 'Material',
    value: 'materialCost',
  },
  { name: 'Labor', value: 'laborCost' },
  {
    name: 'Service',
    value: 'serviceCost',
  },
  { name: 'Burden', value: 'burdenCost' },
] as const satisfies { name: string; value: string }[];

export const CostOverviewTable: FC<{
  recentWO: PartEngineeringResponse | null;
}> = ({ recentWO }) => {
  const costOverviewCalcs = recentWO && calcPartEngineering(recentWO);
  return recentWO && costOverviewCalcs ? (
    <TableContainer width="100%">
      <Table width="100%" sx={styles} size="sm">
        {recentWO ? (
          <TableCaption>
            Based on most recent costs for work order {recentWO.baseId} created
            on {monthDayYear(recentWO.createdAt, 'MM/dd/yyyy')}
          </TableCaption>
        ) : (
          <TableCaption>
            <Alert status="warning">
              <AlertIcon />
              Missing estimated cost data
            </Alert>
          </TableCaption>
        )}
        <Thead>
          <Tr>
            <Th width="25%"></Th>
            {recentWO && <Th>Estimated</Th>}
            <Th>Actual</Th>
            {recentWO && <Th>Variance %</Th>}
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Quantity</Td>
            {recentWO && <Td>{costOverviewCalcs.estQty}</Td>}
            <Td>{costOverviewCalcs.actQty}</Td>
            {recentWO && calcVarianceTd(costOverviewCalcs.variance.quantity)}
          </Tr>
          <Tr>
            <Td>Gross unit cost</Td>
            {recentWO && (
              <Td>{currency(costOverviewCalcs.estUnitCosts.grossCost)}</Td>
            )}
            <Td>{currency(costOverviewCalcs.actUnitCosts.grossCost)}</Td>
            {recentWO && calcVarianceTd(costOverviewCalcs.variance.grossCost)}
          </Tr>
          <Tr>
            <Td>Contribution unit cost</Td>
            {recentWO && (
              <Td>
                {currency(costOverviewCalcs.estUnitCosts.contributionCost)}
              </Td>
            )}
            <Td>{currency(costOverviewCalcs.actUnitCosts.contributionCost)}</Td>
            {recentWO &&
              calcVarianceTd(costOverviewCalcs.variance.contributionCost)}
          </Tr>
          {costRows.map((cr) => (
            <Tr key={cr.name}>
              <Td>{cr.name} unit cost</Td>
              {recentWO && (
                <Td>{currency(costOverviewCalcs.estUnitCosts[cr.value])}</Td>
              )}
              {recentWO && (
                <Td>{currency(costOverviewCalcs.actUnitCosts[cr.value])}</Td>
              )}
              {recentWO && calcVarianceTd(costOverviewCalcs.variance[cr.value])}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  ) : (
    <Text>No recent cost data available</Text>
  );
};
