/**
 * Calculates variance for estimated and actual values, typically from a work order.
 *
 * @param estimated
 * @param actual
 * @returns number representing variance percentage
 *
 * NOTE: will return Infinity when `estimated` is 0 and `actual` is non-zero
 */
export const calcVariance = (estimated: number, actual: number): number => {
  // short circuit to skip calculating a zero variance and to avoid returning NaN when both are zero
  if (estimated === actual) return 0;
  const variance = Math.round(((estimated - actual) / estimated) * 100);
  return variance === 0 ? 0 : variance;
};
