import type { SiteConfig } from '@prisma/client';
import type { ValidatedPartHistoryResponse } from '..';
import { ValidationError } from '../errors';

export interface QbLifecycleValidationInput {
  siteCode?: string; // TODO: remove once site config is used for this
  siteConfig?: SiteConfig;
  partHistory?: ValidatedPartHistoryResponse;
  numberOfQuoteLines?: number;
  previousPrice?: number;
  newPrice?: number;
}

export class LastOrder2YearsAgo extends ValidationError {
  name = 'LastOrder2YearsAgo';
  severity = 6000 as const;
  dynamicMessage = '';

  constructor(public input: QbLifecycleValidationInput) {
    super();
    const lastOrderDate =
      // TODO: is 0 index the most recent?
      input.partHistory?.orderHistory?.data[0]?.orderDate;
    if (
      lastOrderDate &&
      new Date().getTime() - new Date(lastOrderDate || 0).getTime() >=
        2 * 365 * 24 * 60 * 60 * 1000
    ) {
      this.dynamicMessage =
        'Last order was at least 2 years ago. Please review with your manager.';
    }
  }

  get message() {
    return this.dynamicMessage;
  }
}

export class PriceDifference10Percent extends ValidationError {
  name = 'PriceDifference10Percent';
  severity = 6000 as const;
  dynamicMessage = '';

  constructor(public input: QbLifecycleValidationInput) {
    super();
    const { previousPrice, newPrice } = input;
    if (
      previousPrice &&
      newPrice &&
      (newPrice >= previousPrice * 1.1 || newPrice <= previousPrice * 0.9)
    ) {
      this.dynamicMessage =
        'The price is at least 10% different than the previous price. Please review with your manager.';
    }
  }
  get message() {
    return this.dynamicMessage;
  }
}

export class Quote15LinesOrMore extends ValidationError {
  name = 'Quote15LinesOrMore';
  severity = 6000 as const;
  dynamicMessage = '';

  constructor(public input: QbLifecycleValidationInput) {
    super();
    const { numberOfQuoteLines } = input;
    if (numberOfQuoteLines && numberOfQuoteLines >= 15) {
      this.dynamicMessage =
        'This quote contains 15 or more lines. Please review with your manager.';
    }
  }

  get message() {
    return this.dynamicMessage;
  }
}

type Constructor<T> = new (...args: any) => T;

export const onAddLineKeys = [
  'LastOrder2YearsAgo',
  'PriceDifference10Percent',
] as const;

export const onLoadQuoteKeys = ['Quote15LinesOrMore'] as const;

export type QbLifecycleValidatorKey =
  | (typeof onAddLineKeys)[number]
  | (typeof onLoadQuoteKeys)[number];

type QbLifecycleValidators = Record<
  QbLifecycleValidatorKey,
  Constructor<ValidationError>
>;

export const qbLifecycleValidators: QbLifecycleValidators = {
  LastOrder2YearsAgo: LastOrder2YearsAgo,
  PriceDifference10Percent: PriceDifference10Percent,
  Quote15LinesOrMore: Quote15LinesOrMore,
};
