import type { PricingStrategy } from '@prisma/client';
import { PricingStrategyMap } from './strategies';
import { FixedPricingStrategy } from './strategies/fixed';
import { TargetContributionStrategy } from './strategies/target-contribution';
import { TargetGrossStrategy } from './strategies/target-gross';

/** Returns true if the pricing strategy is *or extends* FixedPricingStrategy */
export const isFixedPricingStrategy = (name: PricingStrategy) => {
  const stratConstructor = PricingStrategyMap[name];

  return (
    stratConstructor === FixedPricingStrategy ||
    Object.prototype.isPrototypeOf.call(FixedPricingStrategy, stratConstructor)
  );
};
/** Returns true if the pricing strategy is *or extends* TargetGrossStrategy */
export const isTargetGrossStrategy = (name: PricingStrategy) => {
  const stratConstructor = PricingStrategyMap[name];

  return (
    stratConstructor === TargetGrossStrategy ||
    Object.prototype.isPrototypeOf.call(TargetGrossStrategy, stratConstructor)
  );
};
/** Returns true if the pricing strategy is *or extends* FixedPricingStrategy */
export const isTargetContributionStrategy = (name: PricingStrategy) => {
  const stratConstructor = PricingStrategyMap[name];

  return (
    stratConstructor === TargetContributionStrategy ||
    Object.prototype.isPrototypeOf.call(
      TargetContributionStrategy,
      stratConstructor,
    )
  );
};
