import { FinishedPartIdBuilderFactory } from '@lib/util';
import { useAuth } from '@ui/state/auth';
import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import type { QuoteBuilderForm } from '../QuoteBuilder';
import type { LinePathType } from '../types';

export const useFinishedPartIdBuilder = (linePath: LinePathType) => {
  const { currentSite } = useAuth();
  const { control, getValues, setValue } = useFormContext<QuoteBuilderForm>();

  const { partId } = getValues(linePath);

  const builder = useMemo(() => {
    if (currentSite) {
      return FinishedPartIdBuilderFactory(currentSite, { partId });
    }
  }, [partId, currentSite]);

  const partOptions = useWatch({
    control,
    name: `${linePath}.partOptions`,
  });

  useEffect(() => {
    if (builder) {
      setValue(
        `${linePath}.finishedPartId`,
        builder.buildFinishedPartId({ partOptions }),
      );
    }
  }, [partOptions, builder, linePath, setValue]);

  return;
};
