import {
  type CalcPartHistoryConfigsInput,
  type NegotiatedPriceResponse,
  type Nullish,
  type PartHistoryCalculations,
  type PartResponse,
  type PricingCalculator,
  type QuoteLineBaseResponse,
  type QuoteLineCalculations,
  type ValidatedPartHistoryResponse,
  calcPartHistory,
  makeNegotiatedQuotingConfig,
} from '@lib';
import { ConfigEntities } from '@prisma/client';
import { useActiveSiteConfig } from '@ui/hooks';
import type { QuoteBuilderForm } from '@ui/pages/QuoteBuilder/QuoteBuilder';
import { useLineUpdates } from '@ui/pages/QuoteBuilder/hooks/useLineUpdates';
import { usePricing } from '@ui/pages/QuoteBuilder/hooks/usePricing';
import { useValidationToast } from '@ui/pages/QuoteBuilder/hooks/useToast';
import type { MergedPartHistoryUIManager } from '@ui/pages/QuoteBuilder/validation/part-history-manager';
import { partEngineeringService } from '@ui/services';
import {
  type FC,
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';

export const QuoteLineContext = createContext<QuoteLineContext>(null!);

export interface QuoteLineContext
  extends Omit<QuoteLineProviderProps, 'baseProps'>,
    LinePropsBase {
  mainLinePath: `lineItems.${number}`;
  partId: string;
  // syncEngineering triggers the service to get new data from visual, and sets the state
  // optional so we don't need to pass anything in from QB parent
  syncEngineering?: () => void;
  isSyncing?: boolean;
  pricingCalculator: PricingCalculator;
}

export interface LinePropsBase {
  part: PartResponse;
  partHistory: ValidatedPartHistoryResponse | Nullish;
  partHistoryCalcs: PartHistoryCalculations | Nullish;
  lastWinCalcs:
    | {
        contributionMarginPercent: number;
        grossMarginPercent: number;
        unitPrice: number;
        orderQuantity: number;
        leadTimeWeeks: number | null;
        leadTimeDays: number | null;
      }
    | Nullish;
  mostRecentQuoteLine:
    | (QuoteLineBaseResponse & {
        quoteStartDate: string | null;
      } & { calcs: QuoteLineCalculations | null })
    | Nullish;
  lineManager: MergedPartHistoryUIManager | Nullish;
  // including negotiatedPrice in order to recalc part history calcs in the context
  negotiatedPrice: NegotiatedPriceResponse | Nullish;
}

export interface QuoteLineProviderProps {
  index: number;
  onDelete: (index: number) => void;
  setIsSaving: (value: boolean) => void;
  baseProps: LinePropsBase; //TODO:(bb): severely trim
}

export const QuoteLineContextProvider: FC<
  PropsWithChildren<QuoteLineProviderProps>
> = ({
  index,
  onDelete,
  setIsSaving,
  baseProps, // careful not to create any new references of deps
  children,
}) => {
  const [partHistory, setPartHistory] = useState(baseProps.partHistory);
  const [partHistoryCalcs, setPartHistCalcs] = useState(
    baseProps.partHistoryCalcs,
  );
  const [isSyncing, setIsSyncing] = useState(false);

  const { genericErrorToast } = useValidationToast();

  const form = useFormContext<QuoteBuilderForm>();
  const mainLinePath = `lineItems.${index}` as const;
  const quoteUUID = form.getValues().id;
  const { siteConfig, quotingConfig } = useActiveSiteConfig();

  const usePricingData = useMemo(
    () => ({
      partHistory,
      partHistoryCalcs,
    }),
    [partHistory, partHistoryCalcs],
  );
  const pricingCalculator = usePricing(mainLinePath, usePricingData);

  const { updateFromCosts } = useLineUpdates(mainLinePath, pricingCalculator);

  const ctxValue: QuoteLineContext = useMemo(() => {
    const syncEngineering = async () => {
      const { part, negotiatedPrice } = baseProps;
      try {
        setIsSyncing(true);
        // get new engineerings from visual (with our processing)
        const updatedEngineering = await partEngineeringService.syncFromVisual(
          part.partId,
        );
        const updatedPartHistory = {
          ...partHistory,
          engineering: updatedEngineering,
        } as ValidatedPartHistoryResponse;

        // copypasted from QB makePartHistoryValidation
        // TODO(bb): Pass configs object from QB to the provider and maintain config state here
        const entityConfigs: CalcPartHistoryConfigsInput = {
          siteConfig,
          quotingConfigs: {
            [ConfigEntities.SITE]: quotingConfig,
            [ConfigEntities.PART]: negotiatedPrice?.defaultPrice
              ? makeNegotiatedQuotingConfig(
                  negotiatedPrice.defaultPrice,
                  `${quoteUUID}:${part.id}:negotiated-price`,
                  quotingConfig,
                )
              : undefined,
          },
        };

        // recalc part history
        const updatedCalcs = calcPartHistory(
          updatedPartHistory,
          entityConfigs,
          {
            customerHeader: form.getValues('customerHeader'),
          },
        );

        // set context vars
        setPartHistory(updatedPartHistory);
        setPartHistCalcs(updatedCalcs);

        // set the form values
        form.setValue(
          `lineItems.${index}.operationCosts`,
          updatedCalcs?.suggestions.resolvedCostInputs,
        );

        // tell watchers to update values on form edit
        updateFromCosts();
      } catch (error) {
        genericErrorToast(
          'Unexpected error syncing part engineering data.',
          error instanceof Error ? error?.message : '',
          true,
        );
      } finally {
        setIsSyncing(false);
      }
    };

    return {
      index,
      mainLinePath,
      partId: baseProps.part.partId,
      onDelete,
      setIsSaving,
      pricingCalculator,
      syncEngineering,
      isSyncing,
      ...baseProps,
      partHistory,
      partHistoryCalcs,
    };
  }, [
    index,
    mainLinePath,
    quoteUUID,
    onDelete,
    setIsSaving,
    partHistory,
    partHistoryCalcs,
    pricingCalculator,
    isSyncing,
    baseProps,
    updateFromCosts,
    quotingConfig,
    siteConfig,
    genericErrorToast,
    form.getValues,
    form.setValue,
  ]);

  return (
    <QuoteLineContext.Provider value={ctxValue}>
      {children}
    </QuoteLineContext.Provider>
  );
};

export function useQuoteLineContext() {
  const ctx = useContext(QuoteLineContext);
  // assertion for dev mistake
  if (!ctx) {
    throw new Error(
      'useQuoteLineContext can only be used within a `QuoteLineContextProvider',
    );
  }
  return ctx;
}
