import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Select,
  Spacer,
  VisuallyHidden,
} from '@chakra-ui/react';
import type { SelectOptions } from '@ui/util/types';
import type React from 'react';

type Props = {
  configOptions: SelectOptions;
  selectedConfigId?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onSelectConfig: (config: string) => void;
  onCreateConfig: () => void;
};

export const ConfigSelector = ({
  configOptions,
  selectedConfigId,
  onSelectConfig,
  onCreateConfig,
  isLoading = false,
  isDisabled = false,
}: Props) => {
  return (
    <Box>
      <Flex minWidth="max-content" alignItems="center" gap={2}>
        <span>
          <VisuallyHidden as="label" htmlFor="configSelector">
            Config
          </VisuallyHidden>

          <Select
            id="configSelector"
            value={selectedConfigId}
            disabled={isLoading}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onSelectConfig(e.target.value);
            }}
          >
            {configOptions.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </span>

        {/* @TODO(shawk): indicator of which config is currently being used by the site */}

        <Spacer />

        <ButtonGroup>
          <Button onClick={onCreateConfig} variant="outline">
            New
          </Button>

          <Button
            type="submit"
            isDisabled={isDisabled}
            isLoading={isLoading}
            loadingText="Saving..."
            colorScheme="green"
          >
            Save
          </Button>
        </ButtonGroup>
      </Flex>

      <Divider mt={3} />
    </Box>
  );
};
