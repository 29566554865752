import type { SalesRepWithEmployeeResponse } from '@lib/responses';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import { salesRepService } from '@ui/services';

export const salesRepWithEmployeeQuery = (salesRepId: string) => {
  return {
    queryKey: ['sales-rep', salesRepId],
    queryFn: () => salesRepService.getSalesRepWithEmployee({ salesRepId }),
  };
};

export function useSalesRepWithEmployeeQuery(
  salesRepId?: string | null,
  options?: Omit<
    UseQueryOptions<SalesRepWithEmployeeResponse>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery({
    // The state where salesRepId is not defined is handled
    // by the enabled property
    ...salesRepWithEmployeeQuery(salesRepId as string),
    ...options,
    enabled: !!salesRepId && (options?.enabled ?? true),
  });
}
