import type { SalesRepParams, SalesRepWithEmployeeResponse } from '@lib';
import type { API } from './api';

export class SalesRepService {
  constructor(private api: API) {}

  getSalesRepWithEmployee = (params: SalesRepParams) => {
    return this.api.get<SalesRepWithEmployeeResponse>(
      `/sales-rep/${params.salesRepId}`,
    );
  };
}
