import {
  ConfigStatus,
  FixedPricingSources,
  PricingStrategy,
} from '@prisma/client';
import is from '@sindresorhus/is';
import { v4 as uuidv4 } from 'uuid';
import type {
  PricingConfigResponse,
  QuotingConfigResponse,
} from '../responses';

export const makeNegotiatedQuotingConfig = (
  price: number | string,
  name: string,
  baseQuotingConfig: QuotingConfigResponse,
  recalcStrategy: PricingStrategy = baseQuotingConfig.pricingConfig
    .pricingStrategy,
): QuotingConfigResponse => {
  if (!is.numericString(price))
    throw new Error('negotiated price must be a number');

  const negotiatedPricingConfig: PricingConfigResponse = {
    id: uuidv4(),
    name,
    siteId: baseQuotingConfig.siteId,
    status: ConfigStatus.ACTIVE,
    pricingStrategy: PricingStrategy.FIXED,
    recalcPricingStrategy: recalcStrategy,
    configEntityPriorities: [],
    targetContrMarginPct: null,
    targetGrossMarginPct: null,
    fixedPrice: `${price}`,
    fixedPricingSrc: FixedPricingSources.NegotiatedPricing,
    createdById: null,
    createdByService: null,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
  return {
    ...baseQuotingConfig,
    pricingConfigId: negotiatedPricingConfig.id,
    pricingConfig: negotiatedPricingConfig,
    pricingConfigHistoryIds: [negotiatedPricingConfig.id],
  };
};
