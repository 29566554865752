import type { TupleElements } from '..';

export const _SORT_DIRECTIONS = ['asc', 'desc'] as const;
export type SortDirection = TupleElements<typeof _SORT_DIRECTIONS>;

export type SortQuery<
  T extends string | string[] | Record<string, unknown> = Record<
    string,
    unknown
  >,
  D extends SortDirection = SortDirection,
> = T extends string
  ? `${T}:${D}`
  : T extends (infer U)[]
    ? U extends string
      ? SortQuery<U, D>
      : never
    : T extends Record<infer K, any>
      ? K extends string
        ? SortQuery<Extract<K, string>, D>
        : never
      : never;
