import { P, match } from 'ts-pattern';
import type { OrderLineCalculations } from '../../calculations';
import { isEmptyArr } from '../../util';
import type { ValidationError } from '../errors';
import type { ValidationResponse, WithWarnings } from '../types';
import { RequiresOutsideServices } from './errors';

export type LastWinCalcsValidation = WithWarnings<
  ValidationResponse<
    OrderLineCalculations,
    OrderLineCalculations | null | undefined
  >
>;

/** @returns valid:true if can use use suggested price, valid:false if not */
export const makeLastWinCalcsValidation = (
  calcs: OrderLineCalculations | null | undefined,
): LastWinCalcsValidation => {
  const errors: ValidationError[] = [];
  const warnings: ValidationError[] = [];

  const outsideServicesError = getOutsideServicesError();
  outsideServicesError && warnings.push(outsideServicesError);

  return match({ errors, calcs })
    .with(
      { errors: P.when(isEmptyArr), calcs: P.not(P.nullish) },
      ({ calcs }) => ({
        valid: true as const,
        data: calcs,
        warnings,
      }),
    )
    .otherwise(({ errors }) => ({
      valid: false as const,
      errors,
      data: calcs,
      warnings,
    }));

  function getOutsideServicesError() {
    return (
      calcs && calcs.totalCosts.serviceCost > 0 && new RequiresOutsideServices()
    );
  }
};
