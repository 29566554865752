import { type UseMutationOptions, useMutation } from '@tanstack/react-query';
import { documentService } from '@ui/services';

// Files can potentially be very large (up to 10 MB). We don't want to
// download them all by default, so we use a mutation to download files
// on demand.
export const getDocumentMutation = () => ({
  mutationFn: (documentId: string) => documentService.getDocument(documentId),
});

export function useGetDocumentMutation(
  options?: Omit<UseMutationOptions<Uint8Array, unknown, string>, 'mutationFn'>,
) {
  return useMutation({
    ...getDocumentMutation(),
    ...options,
  });
}
