import type { CustomerContactResponse } from '@lib/responses';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import { customerService } from '@ui/services';

export const getCustomerContactsQuery = (customerId: string) => {
  return {
    queryKey: ['customer', customerId, 'contacts'],
    queryFn: () => customerService.getCustomerContacts(customerId),
  };
};

export function useGetCustomerContacts(
  customerId?: string | null,
  options?: Omit<
    UseQueryOptions<CustomerContactResponse>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery({
    // The state where customerId is not defined is handled
    // by the enabled property
    ...getCustomerContactsQuery(customerId as string),
    ...options,
    enabled: !!customerId && (options?.enabled ?? true),
  });
}
