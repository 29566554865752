import type {
  InventoryResponse,
  PaginatedOrderLineHistoryResponse,
  PaginatedQuoteHistoryResponse,
  PaginationParams,
  PartHistoryResponse,
  PartSearchQuery,
  PartSearchResponse,
} from '@lib';
import type { API } from './api';

export class PartService {
  constructor(private api: API) {}

  searchParts = (query: PartSearchQuery) => {
    return this.api.get<PartSearchResponse>('/part', query);
  };

  getPartHistory = (partId: string) => {
    return this.api.get<PartHistoryResponse>(
      `/part/history/${encodeURIComponent(partId)}`,
    );
  };

  getPartOrderLineHistory = (
    partId: string,
    { page, perPage }: PaginationParams,
  ) => {
    return this.api.get<PaginatedOrderLineHistoryResponse>(
      `/part/history/${encodeURIComponent(partId)}/order-line`,
      { page, perPage },
    );
  };

  getPartQuoteHistory = (
    partId: string,
    { page, perPage }: PaginationParams,
  ) => {
    return this.api.get<PaginatedQuoteHistoryResponse>(
      `/part/history/${encodeURIComponent(partId)}/quote`,
      { page, perPage },
    );
  };

  getPartOptions = (siteCode: string, partId: string) => {
    return this.api.get('/part-options', { siteCode, partId });
  };

  getInventory = (partId: string) => {
    return this.api.get<InventoryResponse>('/part-inventory', { partId });
  };
}
