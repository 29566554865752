import type { MarginCalculations } from '../..';
import { MS_PER_YEAR } from '../../util';
import { type CostBuckets, calcCosts } from '../costs';
import {
  type PricingMatrix,
  colBreaksYrsSinceOrder,
  priceMatrix,
  rowBreaksContrMargin,
} from '../pricing-matrix';
import { mapToRangeIndex, round } from '../util';

type SuggestPriceVars = {
  lastPrice: number;
  contributionMarginRatio: number;
  lastOrderDate: Date;
};

export type PricingMatrixInput = PricingMatrix & {
  lastOrderDate: string;
};

/** Finds the max non-red years old cell for a given margin/row
 * @returns the max years old for a given margin, or the max of the
 * whole range if the margin is out of bounds
 */
export const maxYrsForMargin = (
  marginRatio: number,
  pricingMatrix: PricingMatrixInput,
): number => {
  const { incrPriceVals, colBreaksYrsSinceOrder, rowBreaksContrMargin } =
    pricingMatrix;

  const rowIdx = mapToRangeIndex(marginRatio, rowBreaksContrMargin); // can be arr length/ oob
  const row = incrPriceVals[rowIdx] as number[] | undefined;

  const maxYrsIdx = row ? row.length - 1 : colBreaksYrsSinceOrder.length - 1;

  return colBreaksYrsSinceOrder[maxYrsIdx];
};
/** Finds the min non-red margin cell for a given years old/column
 * @returns the min margin for a given years old, or the min of the
 * whole range if the years old is out of bounds
 */
export const minMarginForYrs = (
  yearsOld: number,
  pricingMatrix: PricingMatrixInput,
): number => {
  const { incrPriceVals, colBreaksYrsSinceOrder, rowBreaksContrMargin } =
    pricingMatrix;

  const colIdx = mapToRangeIndex(yearsOld, colBreaksYrsSinceOrder);
  const lastRowIdx = incrPriceVals.findLastIndex(
    (row) => row[colIdx] !== undefined,
  );
  const minMarginIdx =
    lastRowIdx !== -1 ? lastRowIdx : rowBreaksContrMargin.length - 1;

  return rowBreaksContrMargin[minMarginIdx];
};

export const suggestPriceFromMatrix = ({
  lastPrice,
  contributionMarginRatio,
  lastOrderDate,
}: SuggestPriceVars): number | undefined => {
  // find how many years since last order
  const now = new Date();
  const yearsSinceLastOrder =
    (now.getTime() - lastOrderDate.getTime()) / MS_PER_YEAR;

  const colIndex = mapToRangeIndex(yearsSinceLastOrder, colBreaksYrsSinceOrder);
  const rowIndex = mapToRangeIndex(
    contributionMarginRatio,
    rowBreaksContrMargin,
  );

  const priceIncreaseRatio: number | undefined =
    priceMatrix?.[rowIndex]?.[colIndex];

  return priceIncreaseRatio !== undefined
    ? round(lastPrice * (1 + priceIncreaseRatio), { min: 2, small: 4 })
    : undefined;
};

export interface PriceFromMarginCalculations {
  fromContributionMargin(margin: number): number;
  fromContributionMarginRatio(ratio: number): number;
  fromContributionMarginPercent(ratio: number): number;
  fromGrossMargin(margin: number): number;
  fromGrossMarginRatio(ratio: number): number;
  fromGrossMarginPercent(ratio: number): number;
  from(field: keyof MarginCalculations, val: number): number;
}
export const calcPrice = (costs: CostBuckets): PriceFromMarginCalculations => {
  const costCalcs = calcCosts(costs);

  const priceCalcs = {
    fromContributionMargin(margin: number): number {
      return round(margin + costCalcs.contributionCost, { min: 2, small: 2 });
    },
    fromContributionMarginRatio(ratio: number): number {
      return round(costCalcs.contributionCost / (1 - ratio), {
        min: 2,
        small: 2,
      });
    },
    fromContributionMarginPercent(marginPercent: number): number {
      const ratio = marginPercent / 100;
      return priceCalcs.fromContributionMarginRatio(ratio);
    },
    fromGrossMargin(margin: number): number {
      return round(margin + costCalcs.grossCost, { min: 2, small: 2 });
    },
    fromGrossMarginRatio(ratio: number): number {
      return round(costCalcs.grossCost / (1 - ratio), { min: 2, small: 2 });
    },
    fromGrossMarginPercent(marginPercent: number): number {
      const ratio = marginPercent / 100;
      return priceCalcs.fromGrossMarginRatio(ratio);
    },
    from(field: keyof MarginCalculations, val: number): number {
      const fieldMap = {
        contributionMargin: priceCalcs.fromContributionMargin,
        contributionMarginRatio: priceCalcs.fromContributionMarginRatio,
        contributionMarginPercent: priceCalcs.fromContributionMarginPercent,
        grossMargin: priceCalcs.fromGrossMargin,
        grossMarginRatio: priceCalcs.fromGrossMarginRatio,
        grossMarginPercent: priceCalcs.fromGrossMarginPercent,
      } as const satisfies Record<
        keyof MarginCalculations,
        (val: number) => number
      >;

      return fieldMap[field](val);
    },
  };

  return priceCalcs;
};
