import { type ToastId, type UseToastOptions, useToast } from '@chakra-ui/react';
import type { ValidationError } from '@lib/validation';
import is from '@sindresorhus/is';
import theme from '@ui/config/theme';
import { useEffect, useMemo, useRef } from 'react';
import type { UIManagerBase } from '../../../validation/ui-manager';

// TODO: Use standard options object for methods & existing consumers instead of position-based

interface ToastOptions extends UseToastOptions {
  preventDups?: boolean;
  logMeta?: unknown;
}

export const useValidationToast = () => {
  const toast = useToast();
  const closeAllIdsRef = useRef<(string | number)[]>([]);

  useEffect(() => {
    return () => {
      closeAllToasts();
    };
  }, []);

  const toastAndSaveRef = (options: ToastOptions, persist = false): ToastId => {
    const { status, title, description, preventDups, id: inputId } = options;
    status === 'error' &&
      console.error(`[ERROR] ${title}: ${description}`, options.logMeta);
    status === 'warning' &&
      console.warn(`[WARNING] ${title}: ${description}`, options.logMeta);

    if (preventDups && inputId && toast.isActive(inputId)) {
      return inputId;
    }

    const toastId = toast(options);
    if (!persist) {
      closeAllIdsRef.current.push(toastId);
    }
    return toastId;
  };

  const phErrorToast = (
    uiManager: UIManagerBase,
    options: ToastOptions = {},
  ): ToastId[] => {
    return uiManager.errorProps.map((error) => {
      const { status, userMessage, title } = error;
      const { logMeta, ...restOpts } = options;
      return toastAndSaveRef({
        title,
        description: userMessage,
        status,
        isClosable: true,
        duration: null,
        position: 'top-right',
        logMeta: {
          error,
          ...(is.object(logMeta) ? logMeta : { meta: logMeta }),
        },
        ...restOpts,
      });
    });
  };

  /**
   *
   * @param title
   * @param description
   * @param sticky
   * @param persist
   *
   * @returns toastId
   */
  const genericErrorToast = (
    title: string,
    description?: string,
    sticky = false,
    persist = false,
    options: ToastOptions = {},
  ): ToastId => {
    return toastAndSaveRef(
      {
        title,
        description,
        status: 'error',
        isClosable: sticky,
        duration: sticky ? null : 4000,
        position: 'top-right',
        ...options,
      },
      persist,
    );
  };
  const genericWarningToast = (
    title: string,
    description?: string,
    sticky = false,
    persist = false,
    options: ToastOptions = {},
  ): ToastId => {
    return toastAndSaveRef(
      {
        title,
        description,
        status: 'warning',
        isClosable: sticky,
        duration: sticky ? null : 4000,
        position: 'top-right',
        containerStyle: {
          color: theme.colors.validation.warning,
        },
        ...options,
      },
      persist,
    );
  };
  const genericSuccessToast = (
    title: string,
    description?: string,
    sticky = false,
    persist = false,
    options: ToastOptions = {},
  ): ToastId => {
    return toastAndSaveRef(
      {
        title,
        description,
        status: 'success',
        isClosable: sticky,
        duration: sticky ? null : 2000,
        position: 'top-right',
        ...options,
      },
      persist,
    );
  };
  const validationErrorToast = (
    error: ValidationError,
    options: ToastOptions = {},
  ) => {
    const { severity, message } = error;
    const { logMeta, ...restOpts } = options;
    const status =
      severity >= 8000 ? 'error' : severity >= 5000 ? 'warning' : 'info';
    return toastAndSaveRef({
      description: message,
      status,
      isClosable: true,
      duration: 5000,
      position: 'top-right',
      logMeta: { error, ...(is.object(logMeta) ? logMeta : { meta: logMeta }) },
      ...restOpts,
    });
  };

  const closeAllToasts = () => {
    for (const id of closeAllIdsRef.current) {
      toast.close(id);
    }
  };
  /**
   * @param id only nullable so consumers don't have to check; does nothing if null
   */
  const closeToast = (id: ToastId | null) => {
    id && toast.close(id);
  };

  return useMemo(
    () => ({
      phErrorToast,
      genericErrorToast,
      genericWarningToast,
      genericSuccessToast,
      validationErrorToast,
      clearAllToasts: closeAllToasts,
      clearToast: closeToast,
      isActive: toast.isActive,
    }),
    [toast],
  );
};
