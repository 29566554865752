import { Image, Page, Text, View } from '@react-pdf/renderer';
import { useQuotePdfContext } from '@ui/pages/QuoteBuilder/hooks';
import type { FC } from 'react';
import { pdfStyles } from '../pdf-styles';
import { CustomerQuotePdfTemplate } from './template';

export const CustomerQuote: FC = () => {
  const { quoteForm: form } = useQuotePdfContext();
  return (
    <Page size="A4" style={pdfStyles.page}>
      <View style={pdfStyles.row}>
        <View style={[pdfStyles.section, pdfStyles.alignCenter]}>
          <Image
            src={'/images/CSC/CENTURY_SPRING_RGB_PRIMARY_LOGO_AW.png'}
            // the default pdfStyles.logo is specialized for CAP
            // customize logo style to preserve logo aspect ratio and margins
            style={[pdfStyles.logo, { height: '42px' }]}
          />
        </View>
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.bold}>Century Spring Corp. / MW Ind.</Text>
          <Text>5959 Triumph Street</Text>
          <Text>Commerce CA 90040</Text>
          <Text>Phone (800) 237-5225</Text>
          <Text>Fax 213-749-8569</Text>
        </View>
        <View
          style={[
            pdfStyles.section,
            pdfStyles.alignCenter,
            pdfStyles.maxWidthFull,
          ]}
        >
          <Text style={[pdfStyles.quoteNumber, pdfStyles.bold]}>
            Quotation {form.quoteId}
          </Text>
          <Text style={pdfStyles.textSmall}>
            Please refer to the quotation number when ordering
          </Text>
        </View>
      </View>
      <CustomerQuotePdfTemplate showPartDescription={false} />
      <View style={pdfStyles.row}>
        <View style={pdfStyles.fullRow}>
          <Text>
            Please Note: There is an additional fee per report when required:
            $250 for AS9102 and $1500 for PPAP III
          </Text>
          <Text style={[pdfStyles.bold, pdfStyles.textSmall]}>
            Please note: Manufacturing lead time begins upon receipt of the
            correct PO (Price, Quantity and Due Date). The quoted lead time is
            the ship date from CSC location. Please add at least one week for
            transit. Thank you. Customer samples sent to us for quotation will
            be discarded after 2 months or on quotation expiration date, please
            let us know if you need your sample spring back asap **Based on raw
            material availability, the lead times may change at receipt & after
            your order confirmation***
          </Text>
        </View>
      </View>
    </Page>
  );
};
