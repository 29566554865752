import { Box, Text } from '@chakra-ui/react';
import type { FC } from 'react';
import { styles } from '../styles';

type MaterialInfoProps = {
  id: string | null | undefined;
  description: string | null | undefined;
};

export const MaterialInfo: FC<MaterialInfoProps> = ({ id, description }) => {
  // Check for string presence
  const info = [id, description].filter((x) => x && x.length > 0);

  if (info.length == 0) {
    return null;
  }

  const materialStyles = {
    borderWidth: styles.borderWidth,
    borderStyle: styles.borderStyle,
    borderColor: styles.borderColor,
    borderBottom: 0,
    py: '0.5rem',
    px: '1rem',
    bgColor: '#bbbaba',
    color: '#4a5568',
  };

  return (
    <Box sx={{ ...materialStyles }}>
      <Text>
        <span style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>
          REQUIREMENT
        </span>{' '}
        {info.join(' — ')}
      </Text>
    </Box>
  );
};
