import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useQuotePdfContext } from '@ui/pages/QuoteBuilder/hooks';
import { addDays } from 'date-fns';
import type { FC } from 'react';
import { monthDayYear } from '../../util/dates';
import { pdfStyles } from '../pdf-styles';
import { PdfLineItem } from './components/LineItem';
import { SalesRep } from './components/SalesRep';
import { type QuotePdfTemplateConfig, defaultConfig } from './config';
import { termText } from './util';

// TODO: reduce even more by having the template include all boilerplate, and dynamically add header/footer

const templateStyles = StyleSheet.create({
  borderTop: {
    borderTopWidth: '1px',
    borderTopColor: '#000000',
    borderTopStyle: 'dashed',
  },
});

export const CustomerQuotePdfTemplate: FC<Partial<QuotePdfTemplateConfig>> = (
  configOpts,
) => {
  const { user, quoteForm: form } = useQuotePdfContext();
  const customer = form.customerHeader;
  if (!customer) {
    throw Error('Missing customer');
  }

  if (!user) {
    throw Error('No user data');
  }

  const config: QuotePdfTemplateConfig = {
    ...defaultConfig,
    ...configOpts,
  };

  const hasCsr = form.csrName && form.csrEmail;

  return (
    <>
      <View style={pdfStyles.row}>
        <View style={pdfStyles.section}>
          <Text>Attn: {customer.attentionName}</Text>
        </View>
      </View>
      <View style={pdfStyles.row}>
        <View style={pdfStyles.section}>
          <Text>{customer.name}</Text>
          <Text>{customer.address1}</Text>
          <Text>{customer.address2}</Text>
          <Text>
            {customer.city}, {customer.state} {customer.postalCode}
          </Text>
          {customer.countryCode && <Text>{customer.countryCode}</Text>}
        </View>

        <View style={[pdfStyles.section, pdfStyles.maxWidthFull]}>
          {customer.email?.length && <Text>Email: {customer.email}</Text>}
          {customer.phone?.length && <Text>Phone: {customer.phone}</Text>}
        </View>

        <View style={pdfStyles.section}>
          <View style={pdfStyles.sectionRow}>
            <Text>Inquiry Date</Text>
            <Text>{monthDayYear(new Date().toISOString())}</Text>
          </View>

          <View style={pdfStyles.sectionRow}>
            <Text>Expires</Text>
            <Text>
              {monthDayYear(
                addDays(
                  new Date(),
                  Number(form.daysUntilExpiration),
                ).toISOString(),
              )}
            </Text>
          </View>

          <View style={pdfStyles.sectionRow}>
            <Text>Terms</Text>
            <Text>{termText(customer)}</Text>
          </View>

          <View style={pdfStyles.sectionRow}>
            <SalesRep />
          </View>
        </View>
      </View>

      {form.lineItems.map((lineItem, idx) => (
        <PdfLineItem key={lineItem.partId} lineIndex={idx} config={config} />
      ))}

      {form.notes && (
        <View style={pdfStyles.row}>
          <View style={pdfStyles.fullRow}>
            <Text style={[pdfStyles.bold, { paddingBottom: '4px' }]}>
              Notes
            </Text>
            <Text>{form.notes}</Text>
          </View>
        </View>
      )}

      <View style={[pdfStyles.row, templateStyles.borderTop]}>
        <View style={pdfStyles.fullRow}>
          <Text>Quoted By:</Text>
          <Text>
            {user.firstName} {user.lastName}
          </Text>
          <Text>{user.email}</Text>
        </View>
        {hasCsr && (
          <View style={pdfStyles.fullRow}>
            <Text>Customer Service:</Text>
            <Text>{form.csrName}</Text>
            <Text>{form.csrEmail}</Text>
          </View>
        )}
      </View>
    </>
  );
};
