export const commaSeparated = (
  input: number | string,
  options?: Intl.NumberFormatOptions,
): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    useGrouping: true,
    ...options,
  });

  // According to spec, Intl.NumberFormat.format accepts strings, but the type in
  // TS is restricted to numbers. This has been fixed in main and is slated for released in 5.5.
  //
  // https://github.com/microsoft/TypeScript/pull/57595
  //
  // Once our TS is upgraded to 5.5+, we can remove this type assertion.
  //
  // biome-ignore lint/suspicious/noExplicitAny: incorrect type in TS for Intl.NumberFormat.format
  return formatter.format(input as any);
};
