import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  isFixedPricingStrategy,
  isTargetContributionStrategy,
  isTargetGrossStrategy,
} from '@lib/calculations/pricing';
import type { PricingConfigResponse } from '@lib/responses';
import {
  type PricingConfigUpdateFormData,
  pricingConfigUpdateSchema,
} from '@lib/validation';
import type { ConfigEntities } from '@prisma/client';
import {
  CONFIG_ENTITY_PRIORITY_OPTS,
  FIXED_PRICING_SRC_OPTS,
  PRICING_STRATEGY_OPTS,
} from '@ui/constants/enum-options';
import type { SelectOptions } from '@ui/util/types';
import { useEffect } from 'react';
import {
  Controller,
  FormProvider,
  type SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import Select from 'react-select';

type PricingConfigFormProps = {
  children?: (formState: {
    isDirty: boolean;
    isSubmitting: boolean;
  }) => JSX.Element | null;
  defaultConfig: PricingConfigResponse;
  onSubmit: SubmitHandler<PricingConfigUpdateFormData>;
};

export const PricingConfigForm = ({
  children,
  defaultConfig,
  onSubmit,
}: PricingConfigFormProps) => {
  const form = useForm<
    PricingConfigResponse,
    unknown,
    PricingConfigUpdateFormData
  >({
    defaultValues: defaultConfig,
    resolver: zodResolver(pricingConfigUpdateSchema),
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting, isDirty },
  } = form;

  const selectedPricingStrategy = useWatch({
    name: 'pricingStrategy',
    control,
  });

  /**
   * When a user changes the selected config, reset RHF form state. Without this,
   * the uncontrolled inputs would retain their old values. This also ensures
   * that the form `isDirty` state is accurate after a successful save.
   */
  useEffect(() => {
    form.reset(defaultConfig);
  }, [defaultConfig]);

  return (
    <FormProvider {...form}>
      <form role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        {children?.({
          isDirty,
          isSubmitting,
        })}

        <VStack gap={4} marginTop={4}>
          <FormControl
            isInvalid={!!errors.name}
            isDisabled={isSubmitting}
            isRequired
          >
            <FormLabel>Name</FormLabel>

            <Input
              {...register('name', { required: 'Name can’t be blank' })}
              placeholder="Name"
            />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl
            id="pricingConfigUpdate_ConfigEntityPriorities_input"
            isInvalid={!!errors.configEntityPriorities}
          >
            <FormLabel>Config Priority</FormLabel>

            <Controller
              name="configEntityPriorities"
              control={control}
              disabled={isSubmitting}
              render={({ field: { name, value, onChange, disabled } }) => {
                const selectedValue = value
                  .map((v) =>
                    CONFIG_ENTITY_PRIORITY_OPTS.find((opt) => opt.value === v),
                  )
                  .filter(
                    (val): val is SelectOptions<ConfigEntities>[number] =>
                      !!val,
                  );

                return (
                  <Select
                    name={name}
                    inputId="pricingConfigUpdate_ConfigEntityPriorities_input"
                    isMulti
                    options={CONFIG_ENTITY_PRIORITY_OPTS}
                    isDisabled={disabled}
                    value={selectedValue}
                    onChange={(selectedOpts) =>
                      onChange(selectedOpts.map((opt) => opt.value))
                    }
                  />
                );
              }}
            />

            <FormErrorMessage>
              {errors.configEntityPriorities?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            id="pricingConfigUpdate_pricingStrategy_input"
            isInvalid={!!errors.pricingStrategy}
            isRequired
          >
            <FormLabel>Pricing Strategy</FormLabel>
            <Controller
              name="pricingStrategy"
              control={control}
              disabled={isSubmitting}
              render={({ field: { name, value, onChange, disabled } }) => (
                <Select
                  name={name}
                  inputId="pricingConfigUpdate_pricingStrategy_input"
                  isDisabled={disabled}
                  options={PRICING_STRATEGY_OPTS}
                  value={PRICING_STRATEGY_OPTS.find(
                    (opt) => opt.value === value,
                  )}
                  onChange={(selected) => onChange(selected?.value)}
                />
              )}
            />
            <FormErrorMessage>
              {errors.pricingStrategy?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!errors.targetContrMarginPct}
            isDisabled={
              isSubmitting ||
              !isTargetContributionStrategy(selectedPricingStrategy)
            }
            hidden={!isTargetContributionStrategy(selectedPricingStrategy)}
          >
            <FormLabel>Target Contribution Margin Percentage</FormLabel>

            <Input
              {...register('targetContrMarginPct')}
              placeholder="Target Contribution Margin %"
              type="number"
            />
            <FormErrorMessage>
              {errors.targetContrMarginPct?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!errors.targetGrossMarginPct}
            isDisabled={
              isSubmitting || !isTargetGrossStrategy(selectedPricingStrategy)
            }
            hidden={!isTargetGrossStrategy(selectedPricingStrategy)}
          >
            <FormLabel>Target Gross Margin Percentage</FormLabel>
            <Input
              {...register('targetGrossMarginPct')}
              placeholder="Target Gross Margin %"
              type="number"
              step={1}
            />
            <FormErrorMessage>
              {errors.targetGrossMarginPct?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="pricingConfigUpdate_FixedPricingSource_input"
            hidden={!isFixedPricingStrategy(selectedPricingStrategy)}
            isInvalid
            isRequired
            isDisabled={
              !isFixedPricingStrategy(selectedPricingStrategy) || isSubmitting
            }
          >
            <FormLabel>Fixed Pricing Source</FormLabel>
            <Controller
              name="fixedPricingSrc"
              control={control}
              disabled={
                !isFixedPricingStrategy(selectedPricingStrategy) || isSubmitting
              }
              render={({ field: { name, value, onChange, disabled } }) => {
                const selectedValue = FIXED_PRICING_SRC_OPTS.find(
                  (opt) => opt.value === value,
                );

                return (
                  <Select
                    name={name}
                    inputId="pricingConfigUpdate_FixedPricingSource_input"
                    options={FIXED_PRICING_SRC_OPTS}
                    isDisabled={disabled}
                    value={selectedValue}
                    onChange={(selectedOpt) => onChange(selectedOpt?.value)}
                  />
                );
              }}
            />
            <FormErrorMessage>
              {errors.fixedPricingSrc?.message}
            </FormErrorMessage>
          </FormControl>
        </VStack>
      </form>
    </FormProvider>
  );
};
