import type { PartEngineering } from '@prisma/client';
import { mapValues } from 'radash';
import { type CostCalculations, calcCosts } from '.';
import type { PartEngineeringResponse } from '..';
import { calcVariance } from './variance';

export type PartEngineeringVarianceCalculations = {
  laborCost: number;
  materialCost: number;
  burdenCost: number;
  serviceCost: number;
  contributionCost: number;
  grossCost: number;
  quantity: number;
};

export type PartEngineeringCalculations = {
  estQty: number;
  estTotalCosts: CostCalculations;
  estUnitCosts: CostCalculations; // "per unit costs"
  actQty: number;
  actTotalCosts: CostCalculations;
  actUnitCosts: CostCalculations; // "per unit costs"
  // TODO: figure out how if/how to normalize the quantities for a fair & representative comparison
  variance: PartEngineeringVarianceCalculations;
};

export type PartEngineeringCalcInput =
  | PartEngineeringResponse
  | PartEngineering; // can make a common subset of actually used fields similar to QuoteLineCalcInput if this causes some type headaches from unrelated fields

export const calcPartEngineering = (
  workOrder: PartEngineeringCalcInput,
): PartEngineeringCalculations => {
  const estQty = Number(workOrder.desiredQuantity);

  const estTotalCosts: CostCalculations = calcCosts({
    laborCost: Number(workOrder.estimatedLaborCost),
    materialCost: Number(workOrder.estimatedMaterialCost),
    burdenCost: Number(workOrder.estimatedBurdenCost),
    serviceCost: Number(workOrder.estimatedServiceCost),
  });

  const estUnitCosts: CostCalculations = <CostCalculations>(
    mapValues(estTotalCosts, (v) => (estQty ? v / estQty : 0))
  );

  const actQty = Number(workOrder.receivedQuantity);

  const actTotalCosts: CostCalculations = calcCosts({
    laborCost: Number(workOrder.actualLaborCost),
    materialCost: Number(workOrder.actualMaterialCost),
    burdenCost: Number(workOrder.actualBurdenCost),
    serviceCost: Number(workOrder.actualServiceCost),
  });

  const actUnitCosts: CostCalculations = mapValues(actTotalCosts, (v) =>
    actQty ? v / actQty : 0,
  );

  const variance: PartEngineeringVarianceCalculations = {
    laborCost: calcVariance(estUnitCosts.laborCost, actUnitCosts.laborCost),
    materialCost: calcVariance(
      estUnitCosts.materialCost,
      actUnitCosts.materialCost,
    ),
    burdenCost: calcVariance(estUnitCosts.burdenCost, actUnitCosts.burdenCost),
    serviceCost: calcVariance(
      estUnitCosts.serviceCost,
      actUnitCosts.serviceCost,
    ),
    contributionCost: calcVariance(
      estUnitCosts.contributionCost,
      actUnitCosts.contributionCost,
    ),
    grossCost: calcVariance(estUnitCosts.grossCost, actUnitCosts.grossCost),
    quantity: calcVariance(estQty, actQty),
  };

  return {
    estQty,
    estTotalCosts,
    estUnitCosts,
    actQty,
    actTotalCosts,
    actUnitCosts,
    variance,
  };
};
