import { Box } from '@chakra-ui/react';
import type { AdminSiteResponse } from '@lib/responses';
import Loading from '@ui/components/Loading';
import { useAdminQuotingConfigs } from '@ui/data/admin/configs';
import { isAxiosError } from 'axios';
import { ConfigErrorAlert } from '../Config/ConfigErrorAlert';
import { SiteForm } from './SiteForm';

export const AdminSiteEdit = ({ site }: { site: AdminSiteResponse }) => {
  const quotingConfigsQuery = useAdminQuotingConfigs({ siteId: site.id });

  if (quotingConfigsQuery.isLoading) {
    return (
      <Box padding={2}>
        <Loading />
      </Box>
    );
  }

  if (quotingConfigsQuery.isSuccess) {
    return <SiteForm site={site} quotingConfigs={quotingConfigsQuery.data} />;
  }

  if (quotingConfigsQuery.isError) {
    return (
      <ConfigErrorAlert
        title="Failed to load quoting configs"
        message={
          isAxiosError(quotingConfigsQuery.error)
            ? quotingConfigsQuery.error.response?.data.message
            : quotingConfigsQuery.error.message
        }
        onRetry={quotingConfigsQuery.refetch}
      />
    );
  }

  return null;
};
