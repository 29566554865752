import type { PartHistory } from '@lib/models';
import type { PricingConfigResponse, SiteConfigResponse } from '@lib/responses';
import type { ValidatedPartHistoryResponse } from '@lib/validation';
import type { PricingConfig, SiteConfig } from '@prisma/client';
import type { PartHistoryCalculations } from '..';
import { PricingStrategyMap } from './strategies';
import type { CustomerTiersData, PricingCalculator } from './types';

/** Inputs piped to individual strategy constructors*/
export interface PricingCalculatorData {
  site: {
    id: string;
    code: string;
    siteConfig: SiteConfig | SiteConfigResponse;
  };
  /** Note: favor calc values over raw `partHistory` when possible/available */
  partHistoryCalcs?: PartHistoryCalculations | null;
  partHistory?: ValidatedPartHistoryResponse | PartHistory | null;
  quoteData: {
    id: string;
    quoteId: string;
    customerHeader: CustomerTiersData;
  };
}

export const PricingCalculatorFactory = (
  pricingConfig: PricingConfig | PricingConfigResponse,
  data: PricingCalculatorData,
): PricingCalculator => {
  const { pricingStrategy, recalcPricingStrategy } = pricingConfig;
  // !TODO: Hopefully only temp but constructors may throw so catch and append to
  //  errorCtx/similar + return always () => null calc methods?
  const initStrategy = new PricingStrategyMap[pricingStrategy](
    pricingConfig,
    data,
  );
  // Default to init strategy if no recalc strategy specified
  const recalcStrategy =
    recalcPricingStrategy && pricingStrategy !== recalcPricingStrategy
      ? new PricingStrategyMap[recalcPricingStrategy](pricingConfig, data)
      : initStrategy;

  return {
    calcInitPrice: initStrategy.calcInitPrice.bind(initStrategy),
    recalcPrice: recalcStrategy.recalcPrice.bind(recalcStrategy),
    recalcPriceOnQtyChange:
      recalcStrategy.recalcPriceOnQtyChange.bind(recalcStrategy),
    recalcPriceOnCostChange:
      recalcStrategy.recalcPriceOnCostChange.bind(recalcStrategy),
    recalcPriceOnOptionChange:
      recalcStrategy.recalcPriceOnOptionChange.bind(recalcStrategy),
    recalcPriceOnCustomerTierChange:
      recalcStrategy.recalcPriceOnCustomerTierChange.bind(recalcStrategy),
  };
};
