import { type PaginationParams, PaginationParamsModel } from '@lib';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { partService } from '@ui/services';

function quoteHistoryQuery(partId: string, pagination: PaginationParams) {
  return {
    queryKey: ['part', 'history', partId, 'quote', pagination],
    queryFn: () => partService.getPartQuoteHistory(partId, pagination),
  };
}

export function usePartQuoteHistory(
  partId: string,
  pagination: PaginationParams = PaginationParamsModel.defaultParams,
) {
  return useQuery({
    ...quoteHistoryQuery(partId, pagination),
    placeholderData: keepPreviousData,
  });
}
