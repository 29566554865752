import { type PaginationParams, PaginationParamsModel } from '@lib/models';
import type { PaginatedUserWithSettingsResponse } from '@lib/responses';
import {
  type UseQueryOptions,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import type { SortingState } from '@tanstack/react-table';
import { adminUserService } from '@ui/services';

export const adminUsersSearchQueryKeyPrefix = ['admin', 'users', 'search'];

export const adminUsersSearchQuery = (
  query: string,
  pagination: PaginationParams,
  sortBy: SortingState | undefined,
) => {
  return {
    queryKey: [...adminUsersSearchQueryKeyPrefix, query, pagination, sortBy],
    queryFn: () => adminUserService.searchUsers(query, pagination, sortBy),
  };
};

export function useAdminUsersSearch(
  query = '',
  pagination: PaginationParams = PaginationParamsModel.defaultParams,
  sortBy: SortingState = [],
  options?: Omit<
    UseQueryOptions<PaginatedUserWithSettingsResponse>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery({
    ...adminUsersSearchQuery(query, pagination, sortBy),
    ...options,
    placeholderData: keepPreviousData,
  });
}
