import { Image, Page, Text, View } from '@react-pdf/renderer';
import { useQuotePdfContext } from '@ui/pages/QuoteBuilder/hooks';
import type { FC } from 'react';
import { pdfStyles } from '../pdf-styles';
import { CustomerQuotePdfTemplate } from './template';

export const CustomerQuote: FC = () => {
  const { quoteId } = useQuotePdfContext();
  return (
    <Page size="A4" style={pdfStyles.page}>
      <View style={pdfStyles.row}>
        <View style={[pdfStyles.section, pdfStyles.alignCenter]}>
          <Image
            src={'/images/GRE/GRE_FOX_VALLY_RGB_PRIMARY_LOGO_AW.png'}
            // the default pdfStyles.logo is specialized for CAP
            // customize logo style to preserve logo aspect ratio and margins
            style={[pdfStyles.logo, { height: '39px' }]}
          />
          <Text style={pdfStyles.textSmall}>ISO 9001:2015 Registered</Text>
        </View>
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.bold}>Fox Valley Spring</Text>
          <Text>N915 Craftsmen Drive</Text>
          <Text>Greenville WI, 54942</Text>
          <Text>Ph (920) 757-7777</Text>
          <Text>Fx (920) 757-8888</Text>
        </View>
        <View
          style={[
            pdfStyles.section,
            pdfStyles.alignCenter,
            pdfStyles.maxWidthFull,
          ]}
        >
          <Text style={[pdfStyles.quoteNumber, pdfStyles.bold]}>
            Quotation {quoteId}
          </Text>
          <Text style={pdfStyles.textSmall}>
            Please refer to the quotation number when ordering
          </Text>
        </View>
      </View>
      <CustomerQuotePdfTemplate />
      <View style={pdfStyles.row}>
        <View style={pdfStyles.fullRow}>
          <Text style={[pdfStyles.bold, pdfStyles.textSmall]}>
            Due to variables in production and finishing methods, we reserve the
            right to over or under ship a maximum of 5% on confirmed shipment
            quantities. Any defective material claims must be made within thirty
            (30) days of shipment. If the claim is sustained, seller reserves
            the right to remanufacure defective material or refund the cost of
            the defective parts with no further action allowable by purchaser.
            All dimensions, if not specifically toleranced, will be held to SMI
            tolerances, and not held to any generic block drawing tolerance.
            Cancellations shall be subject to seller’s confirmation as well as
            receipt of costs generated in good faith to fulfill customer’s
            orders. If quantity is lowered, we reserve the right to increase the
            unit cost. Seller is indemnified against liability for patent
            infringement, misuse of product, or use in purchaser’s application.
            Issuance of purchase order by purchaser will be considered
            acceptance of all prior conditions.
          </Text>
          <Text>{'\n'}</Text>
          <Text>
            Standard terms and conditions can be found on www.mwcomponents.com
            &lt;https://www.mwcomponents.com/mw-industries-terms-and-conditions&gt;
          </Text>
        </View>
      </View>
    </Page>
  );
};
