import { StyleSheet } from '@react-pdf/renderer';

export const pdfStyles = StyleSheet.create({
  alignCenter: {
    alignItems: 'center',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  fullRow: {
    margin: 10,
    paddingLeft: 10,
    paddingRight: 10,
    flexGrow: 1,
  },
  gap4: {
    gap: 4,
  },
  gap10: {
    gap: 10,
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  logo: {
    width: '104px',
    height: '46px',
    marginBottom: 8,
  },
  maxWidthFull: {
    maxWidth: '100%',
  },
  page: {
    display: 'flex',
    backgroundColor: '#ffffff',
    padding: 16,
    fontSize: '10px',
    fontFamily: 'Helvetica',
  },
  quoteNumber: {
    fontSize: '14px',
    marginBottom: 8,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  section: {
    maxWidth: '33%',
    margin: 10,
    paddingLeft: 10,
    paddingRight: 10,
    flexGrow: 1,
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textSmall: {
    fontSize: '8px',
  },
  underline: {
    textDecoration: 'underline',
  },
  widthFull: {
    width: '100%',
  },
});
