import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';

import type { ValidatedPartHistoryResponse } from '@lib';
import { match } from 'ts-pattern';
import type { UIManagerBase } from '../../validation/ui-manager';
interface PartInfoProps {
  partId: string;
  material?: string | null;
  type: string;
  lineManager?: UIManagerBase | null;
  lastWonAtFormatted?: string;
  readOnly?: boolean;
  partHistory?: ValidatedPartHistoryResponse | null;
}

type DrawingDetailsProps = {
  partHistory?: ValidatedPartHistoryResponse | null;
};

const DrawingDetails: FC<DrawingDetailsProps> = ({ partHistory }) => {
  if (partHistory?.engineering.masters[0]?.drawing) {
    return (
      <Box>
        <Text fontSize={11}>Drawing ID:</Text>
        <Text fontSize={11}>{partHistory.engineering.masters[0].drawing}</Text>
        {partHistory.engineering.masters[0].drawingRevision && (
          <>
            <Text fontSize={11}>Revision #:</Text>
            <Text fontSize={11}>
              {partHistory.engineering.masters[0].drawingRevision}
            </Text>
          </>
        )}
      </Box>
    );
  }

  return null;
};

type LastWonDetailsProps = {
  lastWonAtFormatted?: string;
};

const LastWonDetails: FC<LastWonDetailsProps> = ({ lastWonAtFormatted }) => {
  if (lastWonAtFormatted) {
    return (
      <Box>
        <Text fontSize={11}>Last Ordered:</Text>
        <Text fontSize={11}>{lastWonAtFormatted}</Text>
      </Box>
    );
  }

  return null;
};

const PartInfoCard: FC<PartInfoProps> = ({
  partId,
  material,
  type,
  partHistory,
  lineManager,
  lastWonAtFormatted,
  readOnly = false,
}) => {
  if (readOnly) {
    return (
      <Flex gap={4} width={'100%'} flexWrap="wrap">
        <Flex direction={'column'} flexGrow={1}>
          <Flex pb="4px" justify="space-between">
            <Text fontWeight="bold" fontSize={16}>
              {partId}
            </Text>
            {match(lineManager)
              .with(
                { hasFailed: true },
                //@ts-ignore
                ({ mostSevere }) =>
                  mostSevere && (
                    <Tooltip label={mostSevere.userMessage} placement="top-end">
                      <Box>
                        <FontAwesomeIcon
                          icon={mostSevere.icon}
                          size="lg"
                          color={mostSevere.color}
                        />
                      </Box>
                    </Tooltip>
                  ),
              )
              .otherwise(() => null)}
          </Flex>
          <Text fontWeight="medium" fontSize={14}>
            {type}
          </Text>
          <Text>{material}</Text>
          <Flex flexDirection="column" gap={2}>
            <LastWonDetails lastWonAtFormatted={lastWonAtFormatted} />
            <DrawingDetails partHistory={partHistory} />
          </Flex>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex gap={4} width={'100%'} flexWrap="wrap">
      <Flex direction={'column'} flexGrow={1}>
        <Flex pb="4px" justify="space-between">
          <Text fontWeight="bold" fontSize={16}>
            {partId}
          </Text>
          {match(lineManager)
            .with(
              { hasFailed: true },
              //@ts-ignore
              ({ mostSevere }) =>
                mostSevere && (
                  <Tooltip label={mostSevere.userMessage} placement="top-end">
                    <Box>
                      <FontAwesomeIcon
                        icon={mostSevere.icon}
                        size="lg"
                        color={mostSevere.color}
                      />
                    </Box>
                  </Tooltip>
                ),
            )
            .otherwise(() => null)}
        </Flex>
        <Text fontWeight="medium" fontSize={14}>
          {type}
        </Text>
        <Text>{material}</Text>
        <Flex flexDirection="column" gap={2}>
          <LastWonDetails lastWonAtFormatted={lastWonAtFormatted} />
          <DrawingDetails partHistory={partHistory} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PartInfoCard;
