import { Select, Td } from '@chakra-ui/react';
import type { CostInputSources } from '@prisma/client';
import {
  SimpleInput,
  type SimpleInputProps,
} from '@ui/components/form/SimpleInput';
import { type FC, forwardRef } from 'react';
import { useWatch } from 'react-hook-form';
import type { QuoteBuilderForm } from '../../QuoteBuilder';
import type { ValidOpCostPath, ValidReqCostPath } from './types';

const CostInputSourceNames: Record<CostInputSources, string> = {
  HIST_WORK_ORDER_ACT: 'Actual Historical Work Order Costs',
  HIST_WORK_ORDER_EST: 'Estimated Historical Work Order Costs',
  MASTER_EST: 'Engineering Master',
  MANUAL_ENTRY: 'Manual Entries',
  CONFIG_DEFAULT: 'Default',
};

type StaticCellProps = {
  path: ValidOpCostPath | ValidReqCostPath;
};

export const StaticCell: FC<StaticCellProps> = ({ path }) => {
  const value = useWatch<QuoteBuilderForm>({ name: path }) as
    | number
    | string
    | null
    | undefined;
  return <Td>{value ?? undefined}</Td>;
};

interface CostCellComponentProps extends SimpleInputProps {
  showSources: boolean;
}

export const CostCell = forwardRef<HTMLInputElement, CostCellComponentProps>(
  ({ showSources, defaultValue, ...inputProps }, ref) => {
    return (
      <Td>
        {showSources ? (
          <Select size="xs">
            {Object.entries(CostInputSourceNames).map(([key, value]) => (
              <option key={key}>{value}</option>
            ))}
          </Select>
        ) : null}
        <SimpleInput
          size="xs"
          ref={ref}
          defaultValue={defaultValue ?? undefined}
          changeOnBlur
          blurOnEnterKey
          {...inputProps}
        />
      </Td>
    );
  },
);

CostCell.displayName = 'CostCell';
