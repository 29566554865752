import { Textarea, useDisclosure } from '@chakra-ui/react';
import type { PartResponse } from '@lib/responses';
import { StickyNoteIcon } from '@ui/components/Icons';
import { QuoteLineActionsItem } from '@ui/components/QuoteLine';
import Modal from '@ui/components/layout/Modal';
import type { QuoteBuilderForm } from '@ui/pages/QuoteBuilder/QuoteBuilder';
import { useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import type { LinePathType } from '../../types';

export interface NoteModalProps {
  linePath: LinePathType;
  part: PartResponse;
}

/**
 * Manages an active line item's notes
 */
export const NoteModal: React.FC<NoteModalProps> = ({ linePath, part }) => {
  const noteDraftRef = useRef<HTMLTextAreaElement | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { setValue } = useFormContext<QuoteBuilderForm>();

  const note: string | null | undefined = useWatch({
    name: `${linePath}.note`,
  });

  // We only update the form field when the user saves their note.
  // If they close the modal, we discard their changes.
  const handleSave = () => {
    setValue(`${linePath}.note`, noteDraftRef.current?.value);
    onClose();
  };

  return (
    <>
      <QuoteLineActionsItem
        label="Manage Notes"
        icon={<StickyNoteIcon badge={{ color: 'yellow', show: !!note }} />}
        onClick={onOpen}
      />

      <Modal
        title={`Manage Notes for ${part.partId}`}
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
        buttons={[
          {
            label: 'Cancel',
            onClick: onClose,
          },
          {
            label: 'Save',
            bgColor: '#0b7078',
            color: 'white',
            _hover: { bg: '#046068' },
            onClick: handleSave,
          },
        ]}
      >
        <Textarea
          placeholder="Add a note for this line item"
          defaultValue={note ?? ''}
          ref={noteDraftRef}
        />
      </Modal>
    </>
  );
};
