import { weeksToDays } from 'date-fns';
import type { LeadTimeStrategy } from './types';

export const RAFLeadTimeStrategy: LeadTimeStrategy = {
  calculateDays: ({ part: { partId } }) => {
    // NAS parts require 10 weeks
    if (partId.startsWith('NAS')) {
      return weeksToDays(10);
    }

    return weeksToDays(8);
  },
};
