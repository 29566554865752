import type { FC } from 'react';

import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { FormSectionTitle } from '../../components/Text';

import type { CustomerResponse } from '@lib';
import { TextWithLabel } from '../../components/Text';
import { useLocalization } from '../../config/localization/getLocalization';

interface CustomerHeaderProps {
  customerInfo: CustomerResponse | null | undefined;
}
export const ViewCustomerHeader: FC<CustomerHeaderProps> = ({
  customerInfo,
}) => {
  const qb_i18n = useLocalization('QuoteBuilder');

  return (
    <Box
      key={customerInfo?.id}
      backgroundColor="#EDF2F7"
      borderRadius={5}
      paddingX={{ base: 5, md: 10 }}
      paddingY={6}
      display="inline-block"
    >
      <Box
        as={Flex}
        alignItems="start"
        flexDirection={'row'}
        mb={4}
        alignContent="center"
      >
        <Heading fontFamily="sans-serif" fontWeight={100} fontSize={32}>
          {customerInfo?.name} ({customerInfo?.customerId})
        </Heading>
      </Box>
      <Box as={Flex} gap={5} direction={{ base: 'column', md: 'row' }}>
        <Box
          w={{
            base: '100%',
            md: '50%',
          }}
        >
          <FormSectionTitle marginBottom={3}>
            {qb_i18n('primaryContact')}
          </FormSectionTitle>
          <TextWithLabel label="Full Name" text={customerInfo?.attentionName} />
          <TextWithLabel label="Email" text={customerInfo?.email} />
          <TextWithLabel label="Phone" text={customerInfo?.phone} />
        </Box>
        <Box
          w={{
            base: '100%',
            md: '50%',
          }}
        >
          <FormSectionTitle marginBottom={3}>
            {qb_i18n('companyAddress')}
          </FormSectionTitle>
          <Text>{customerInfo?.address1}</Text>
          <Text>{customerInfo?.address2}</Text>
          <Flex gap={1}>
            <Text>{customerInfo?.city},</Text>
            <Text>{customerInfo?.state}</Text>
            <Text>{customerInfo?.postalCode}</Text>
            <Text>{customerInfo?.countryCode}</Text>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
