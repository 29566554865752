import {
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { faEllipsis, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ImmutableQuoteStates,
  type QuoteGetManyResponse,
  type QuoteWithCustomerResponse,
} from '@lib';
import is from '@sindresorhus/is';
import { type UseQueryResult, useQueryClient } from '@tanstack/react-query';
import {
  type SortingState,
  type Updater,
  createColumnHelper,
} from '@tanstack/react-table';
import { BasicPagination } from '@ui/components';
import { QueryError, QueryLoading, QueryNoResults } from '@ui/components/query';
import { RoutesConfig } from '@ui/config/routes';
import {
  quoteQueueSearchQueryKeyPrefix,
  useQuoteQueueSearch,
} from '@ui/data/quote/queue';
import { useSortingURLState } from '@ui/hooks';
import { type URLPaginator, usePaginationURLState } from '@ui/hooks/pagination';
import { AxiosError } from 'axios';
import { debounce } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import DataTable from '../components/DataTable';
import { DeleteConfirmButton } from '../components/DeleteConfirmButton';
import { QuoteStatusBadge } from '../components/QuoteStatusBadge';
import { InputWithIcon } from '../components/form/InputWithIcon';
import PageHeader from '../components/layout/PageHeader';
import { quoteService } from '../services';
import { monthDayYear } from '../util/dates';
import { useValidationToast } from './QuoteBuilder/hooks/useToast';

const DEBOUNCE_WAIT = 400;
const perPageOptions = [25, 50, 100];

export const QuoteQueue = () => {
  const queryClient = useQueryClient();
  const { genericErrorToast, genericSuccessToast } = useValidationToast();

  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationState, paginator] = usePaginationURLState({ perPage: 25 });
  const [sortingState, sorter] = useSortingURLState();

  const query = searchParams.get('q') ?? undefined;

  const quoteQueueQuery = useQuoteQueueSearch(
    query,
    paginationState,
    sortingState,
  );

  const handleSearch = useCallback(
    (query: string) => {
      setSearchParams((params) => {
        paginator.updatePageParam(params, 1);

        if (query) {
          params.set('q', query);
        } else {
          params.delete('q');
        }

        return params;
      });
    },
    [paginator, setSearchParams],
  );

  const handleSort = useCallback(
    (updaterOrValue: Updater<SortingState>) => {
      const sort = is.function(updaterOrValue)
        ? updaterOrValue(sortingState)
        : updaterOrValue;

      sorter.setSorting(sort);
    },
    [sortingState, sorter],
  );

  const debouncedSetSearchInput = useMemo(
    () => debounce(handleSearch, DEBOUNCE_WAIT),
    [handleSearch],
  );

  const handleDeleteQuote = async (id: string) => {
    try {
      await quoteService.deleteQuote(id);

      /**
       * @NOTE(shawk): invalidating here is a stop-gap until `deleteQuote`
       * is moved to a `react-query` mutation, where we can invalidate
       * dependent queries
       */
      await queryClient.invalidateQueries({
        queryKey: quoteQueueSearchQueryKeyPrefix,
      });

      genericSuccessToast('Quote deleted!');
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        genericErrorToast(
          'Quote failed to delete!',
          err.response?.data.message ?? 'Unknown Error',
        );
      }
    }
  };

  return (
    <QuoteQueueUI
      query={query}
      quoteQueueQuery={quoteQueueQuery}
      paginator={paginator}
      sortingState={sortingState}
      handleSort={handleSort}
      debouncedSetSearchInput={debouncedSetSearchInput}
      handleDeleteQuote={handleDeleteQuote}
    />
  );
};

export type QuoteQueueUIProps = {
  query: string | undefined;
  quoteQueueQuery: UseQueryResult<QuoteGetManyResponse>;
  paginator: URLPaginator;
  sortingState: SortingState;
  handleSort: (updaterOrValue: Updater<SortingState>) => void;
  debouncedSetSearchInput: (query: string) => void;
  handleDeleteQuote: (id: string) => void;
};

export function QuoteQueueUI({
  query,
  quoteQueueQuery,
  paginator,
  sortingState,
  handleSort,
  debouncedSetSearchInput,
  handleDeleteQuote,
}: QuoteQueueUIProps) {
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<QuoteWithCustomerResponse>();

    return [
      columnHelper.accessor('quoteId' as const, {
        header: 'Quote ID',
        cell: (info) => {
          const quote: QuoteWithCustomerResponse = info.row.original;
          let to = `/quote/${quote.id}`;
          if (ImmutableQuoteStates.includes(quote.status)) {
            to = `/quote/view/${quote.id}`;
          }

          return (
            <Link as={RouterLink} to={to} fontWeight="bold">
              {info.getValue()}
            </Link>
          );
        },
      }),
      columnHelper.accessor(
        (quote) => {
          if (quote.customer) {
            return `${quote.customer.name} (${quote.customer.customerId})`;
          }

          return quote.customerHeader?.name ?? '';
        },
        {
          id: 'customer.name',
          header: 'Customer Name',
          cell: (info) => info.getValue(),
        },
      ),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => {
          return <QuoteStatusBadge status={info.getValue()} />;
        },
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Last Updated',
        cell: (info) => monthDayYear(info.getValue()),
      }),
      columnHelper.accessor(
        (quote) => {
          return quote.createdBy ? quote.createdBy.displayName : 'ERP';
        },
        {
          id: 'createdBy',
          header: 'Created By',
          cell: (info) => info.getValue(),
        },
      ),
      columnHelper.display({
        id: 'actions',
        cell: (info) => {
          const quote: QuoteWithCustomerResponse = info.row.original;
          let to = `/quote/${quote.id}`;
          let name = 'Edit';
          let canDelete = true;
          if (ImmutableQuoteStates.includes(quote.status)) {
            to = `/quote/view/${quote.id}`;
            name = 'View';
            canDelete = false;
          }

          return (
            <Menu>
              <MenuButton
                as={IconButton}
                background="none"
                icon={<FontAwesomeIcon icon={faEllipsis} />}
              />
              <MenuList>
                <MenuItem as={RouterLink} to={to}>
                  {name}
                </MenuItem>
                {canDelete && (
                  <DeleteConfirmButton
                    name="Delete"
                    modalTitle={`Delete quote ${quote.quoteId}`}
                    as={MenuItem}
                    description="Please confirm you would like to reset this quote. It will be permanently deleted."
                    onConfirm={() => handleDeleteQuote(quote.id)}
                    bg="none"
                    _hover={{ background: '#EDF2F7' }}
                    justifyContent="start"
                    fontWeight="normal"
                    borderRadius="0px"
                    outline="0"
                    outlineOffset="0"
                    maxWidth="100%"
                    paddingInlineStart={3}
                    paddingInlineEnd={3}
                    height="auto"
                  />
                )}
              </MenuList>
            </Menu>
          );
        },
      }),
    ];
  }, [handleDeleteQuote]);

  return (
    <Box width={'100%'}>
      <PageHeader
        title="Quote Queue"
        bgColor="mw.grey"
        textColor="mw.white"
        subtitle="Create and manage quotes"
      >
        <InputWithIcon
          key={query}
          type={'text'}
          onChange={(e) => debouncedSetSearchInput(e.target.value)}
          bgColor={'white'}
          placeholder="Search quotes..."
          defaultValue={query}
          maxWidth={[
            'unset', // base
            'unset', // 480px upwards
            '300px', // 768px upwards
          ]}
          width={[
            '100%', // base
            '200px', // 480px upwards
            '300px', // 768px upwards
          ]}
          autoFocus
          leftIcon={faSearch}
          fontFamily="navigationItem"
          groupProperties={{
            marginLeft: '48px',
          }}
        />
        <Button
          as={RouterLink}
          to={{ pathname: RoutesConfig.quoteNew.path }}
          fontFamily="navigationItem"
          bgColor="mw.yellow"
          color="black"
          borderRadius={'0px'}
          _hover={{ bg: 'mw.darkYellow' }}
          gap={2}
        >
          Create Quote
        </Button>
      </PageHeader>

      {quoteQueueQuery.isLoading && <QueryLoading title="Loading quotes..." />}

      {quoteQueueQuery.isError && (
        <QueryError
          title="Error loading quotes!"
          error={quoteQueueQuery.error}
          onRetry={() => quoteQueueQuery.refetch()}
          isRetrying={quoteQueueQuery.isFetching}
        />
      )}

      {quoteQueueQuery.isSuccess && (
        <>
          <Box
            transition="opacity 100ms linear"
            opacity={quoteQueueQuery.isFetching ? 0.5 : 1}
          >
            {quoteQueueQuery.data.data.length > 0 ? (
              <DataTable
                columns={columns}
                data={quoteQueueQuery.data.data}
                sorting={sortingState}
                onSort={handleSort}
              />
            ) : (
              <QueryNoResults
                title="No Quotes found"
                description="Try entering a different search term."
              />
            )}
          </Box>

          <BasicPagination
            metadata={quoteQueueQuery.data.pagination}
            paginator={paginator}
            perPageOptions={perPageOptions}
            px={6}
            py={4}
          />
        </>
      )}
    </Box>
  );
}
