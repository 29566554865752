import { Flex, Text } from '@chakra-ui/react';
import type {
  PartOptionMetadata,
  QuoteLinePartOptionCreateRequest,
} from '@lib';
import { hasPartOptionPrice } from '@lib/calculations/pricing/part-options';
import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { SimpleSelect } from '../../../components/form';
import type { QuoteBuilderForm } from '../QuoteBuilder';
import type { PartOptionPathType } from '../types';
import { PartOptionSuboptionSelect } from './PartOptionSuboptionSelect';

interface PartOptionGroupSelectProps {
  groupName: string;
  options: PartOptionMetadata[];
  optionPath: PartOptionPathType;
  weight: number;
}

function findOption(options: PartOptionMetadata[], name: string) {
  return options.find((option) => option.name === name) ?? options[0];
}

export const PartOptionGroupSelect: React.FC<PartOptionGroupSelectProps> = ({
  groupName,
  options,
  optionPath,
  weight,
}) => {
  const { control, setValue } = useFormContext<QuoteBuilderForm>();

  const partOptionName: string = useWatch({
    name: `${optionPath}.name`,
  });

  const selectedOption = findOption(options, partOptionName);

  const doesChangePrice = hasPartOptionPrice(selectedOption);

  useEffect(() => {
    if (!partOptionName) {
      setValue(optionPath, {
        groupName,
        weight,
        name: selectedOption.name,
        suboptionName: selectedOption.subOptions?.[0]?.name,
        metadata: selectedOption,
      } satisfies QuoteLinePartOptionCreateRequest);
    }
  }, [
    partOptionName,
    groupName,
    optionPath,
    selectedOption,
    setValue,
    weight,
    selectedOption.subOptions?.[0]?.name,
  ]);

  return (
    <Flex flexDirection="column" width="full" paddingTop={2}>
      <Text as="label" htmlFor={optionPath} fontSize={11} fontWeight="bold">
        {groupName}
      </Text>
      <Controller
        name={optionPath}
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <SimpleSelect
              data-testid="option-select"
              size={'xs'}
              value={value?.name}
              onChange={(e) => {
                const option = findOption(options, e.target.value);

                onChange({
                  groupName,
                  weight,
                  name: option.name,
                  suboptionName: option.subOptions?.[0]?.name,
                  metadata: option,
                } satisfies QuoteLinePartOptionCreateRequest);
              }}
            >
              {options.map((option) => (
                <option key={option.name} value={option.name ?? ''}>
                  {option.name}
                </option>
              ))}
            </SimpleSelect>
          );
        }}
      />

      <PartOptionSuboptionSelect
        subOptionLabel={selectedOption.subOptionLabel}
        subOptions={selectedOption.subOptions}
        optionPath={optionPath}
      />

      {!doesChangePrice && selectedOption?.code && (
        <Text p={2} fontSize={16} fontWeight="bold">
          DOES NOT CHANGE PRICE. MUST BE QUOTED IN VISUAL.
        </Text>
      )}
    </Flex>
  );
};
