import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { type OrderLineResponse, calcOrderLine } from '@lib';
import { BasicPagination } from '@ui/components';
import { QueryError, QueryLoading, QueryNoResults } from '@ui/components/query';
import { usePartOrderLineHistory } from '@ui/data/part';
import { usePaginationState } from '@ui/hooks/pagination';
import { currency, monthDayYear } from '@ui/util';
import { styles } from './styles';

const perPageOptions = [10, 25, 50];

function grossMargin(line: OrderLineResponse): number {
  const calcs = calcOrderLine(line);

  return calcs.margins.grossMarginPercent;
}

type OrderHistoryTableProps = {
  partId: string;
  perPage?: number;
};

export const OrderHistoryTable = ({
  partId,
  perPage = 10,
}: OrderHistoryTableProps) => {
  const [paginationState, paginator] = usePaginationState({ perPage });

  const query = usePartOrderLineHistory(partId, paginationState);

  if (query.isLoading) {
    return <QueryLoading title="Loading order history..." />;
  }

  if (query.isError) {
    return (
      <QueryError
        title="Error loading order history!"
        error={query.error}
        onRetry={() => query.refetch()}
        isRetrying={query.isFetching}
      />
    );
  }

  if (query.isSuccess) {
    if (query.data.data.length === 0) {
      return <QueryNoResults title="No order history found" />;
    }

    return (
      <>
        <TableContainer
          width="100%"
          transition="opacity 100ms linear"
          opacity={query.isFetching ? 0.5 : 1}
          mb={4}
        >
          <Table width="100%" sx={styles} size="sm">
            <Thead>
              <Tr>
                <Th>Order Date</Th>
                <Th>Order ID</Th>
                <Th>Site</Th>
                <Th>Customer ID</Th>
                <Th>Customer</Th>
                <Th>Quantity Ordered</Th>
                <Th>Unit Price</Th>
                <Th>Gross Margin</Th>
                <Th>Desired Ship Date</Th>
                <Th>Last Ship Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {query.data.data.map((row) => {
                return (
                  <Tr key={`oh-${row.id}`}>
                    <Td>{monthDayYear(row.orderDate)}</Td>
                    <Td fontWeight="bold">{row.orderId}</Td>
                    <Td>{row.siteId}</Td>
                    <Td>{row.customer.customerId}</Td>
                    <Td>{row.customer.name}</Td>
                    <Td>{row.orderQuantity}</Td>
                    <Td>{currency(row.unitPrice)}</Td>
                    <Td>{grossMargin(row)}%</Td>
                    <Td>{monthDayYear(row.desiredShipDate, '-')}</Td>
                    <Td>{monthDayYear(row.lastShippedDate, '-')}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>

        <BasicPagination
          metadata={query.data.pagination}
          paginator={paginator}
          perPageOptions={perPageOptions}
        />
      </>
    );
  }

  return null;
};
