import { strategies } from './strategies';
import type { MarketPricing, MinQuantityStrategy, Site } from './types';

export const MinQuantityCalculator = {
  calculateRequirement: (
    site: Site,
    pricing: MarketPricing,
    marketCode?: string | null,
  ) => {
    const strategy = MinQuantityCalculator.findStrategy(site);

    return strategy.calculateRequirement(site, pricing, marketCode);
  },

  findStrategy: (site: Site): MinQuantityStrategy => {
    const code = site.code.toUpperCase();

    if (strategies[code] !== undefined) {
      return strategies[code];
    }

    return strategies.default;
  },
};
