import type { CustomerContactResponse, CustomerManyResponse } from '@lib';
import type { CustomerTiers } from '@prisma-types';
import type { API } from './api';

export class CustomerService {
  constructor(private api: API) {}

  searchCustomers = (searchInput: string, limit?: number, offset?: number) => {
    return this.api.get<CustomerManyResponse>('/customer', {
      search: searchInput,
      limit: limit,
      offset: offset,
    });
  };

  getCustomerTiers = () => {
    return this.api.get<CustomerTiers>('/customer/tiers');
  };

  getCustomerContacts = (customerId: string) => {
    return this.api.get<CustomerContactResponse>(
      `/customer/${customerId}/contacts`,
    );
  };
}
