import { type PermissionsReqInput, hasPermissions } from '@lib';
import { useAuth } from '../../state/auth';
import ErrorUnauthorized from '../Errors/ErrorUnauthorized';

type WithUserType = {
  children: React.ReactNode;
  permissions: PermissionsReqInput;
  page?: boolean;
};

export const WithPermission = ({
  children,
  permissions,
  page,
}: WithUserType) => {
  const { user } = useAuth();

  if (!Object.keys(permissions).length) {
    return <>{children}</>;
  }

  if (!user) {
    return null;
  }

  if (hasPermissions(permissions, user)) {
    return <>{children}</>;
  }

  return page ? (
    <>
      <ErrorUnauthorized />
    </>
  ) : null;
};
