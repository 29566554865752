import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import type { FC } from 'react';
import { useInventory } from '../hooks';
import type { LinePathType } from '../types';

interface InventoryFooterCellProps {
  caption: string;
  value: number;
}

const InventoryFooterCell: FC<InventoryFooterCellProps> = ({
  caption,
  value,
}) => {
  return (
    <VStack align="left" spacing="0">
      <Text fontWeight="bold">{caption}:</Text>
      <Text>{value.toString()}</Text>
    </VStack>
  );
};

interface InventoryFooterProps {
  linePath: LinePathType;
}

export const InventoryFooter: FC<InventoryFooterProps> = ({ linePath }) => {
  const inventory = useInventory(linePath);

  if (inventory.status === 'pending') {
    return (
      <Flex width="full">
        <Text fontSize="xs">Loading inventory...</Text>
      </Flex>
    );
  }

  if (inventory.status === 'error') {
    return (
      <Flex width="full">
        <Text fontSize="xs">No inventory data found.</Text>
      </Flex>
    );
  }

  return (
    <HStack alignItems="baseline" fontSize="xs" paddingTop={4} gap={8}>
      <InventoryFooterCell caption="On Hand" value={inventory.data.qtyOnHand} />
      <InventoryFooterCell
        caption="In Demand"
        value={inventory.data.qtyInDemand}
      />
      <InventoryFooterCell
        caption="Available for MRP"
        value={inventory.data.qtyAvailableForMRP}
      />
      <InventoryFooterCell
        caption="On Order"
        value={inventory.data.qtyOnOrder}
      />
      <InventoryFooterCell
        caption="Committed"
        value={inventory.data.qtyCommitted}
      />
      {/* TODO: get planning leadtime data */}
      {/* <InventoryFooterCell
        caption="Planning Leadtime"
        value={0}
      ></InventoryFooterCell> */}
    </HStack>
  );
};
