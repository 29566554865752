import { Flex } from '@chakra-ui/react';
import PageWrapper from '@ui/components/PageWrapper';
import { SimpleSelect } from '@ui/components/form';
import PageHeader from '@ui/components/layout/PageHeader';
import {
  eachMonthOfInterval,
  endOfMonth,
  format,
  startOfMonth,
} from 'date-fns';
import { useState } from 'react';
import { AdminMetricsTable } from './AdminMetricsTable';

// Limit metric time periods to the beginning of 2024
// Offset by 1 day to lazily handle timezones
const METRICS_START_DATE = new Date('2024-01-02');

export const AdminMetrics = () => {
  const [startDate, setStartDate] = useState<Date>(
    new Date(startOfMonth(Date.now())),
  );
  const [endDate, setEndDate] = useState<Date>(
    new Date(endOfMonth(Date.now())),
  );

  const months = eachMonthOfInterval({
    start: METRICS_START_DATE,
    end: new Date(),
  }).reverse();

  const handleChange = (date: string) => {
    const newDate = new Date(date);

    setStartDate(newDate);
    setEndDate(new Date(endOfMonth(newDate)));
  };

  return (
    <PageWrapper>
      <PageHeader title="Admin / Metrics" />

      <Flex pt={3} w="100%" flexDirection="column" gap={3} alignItems="end">
        <Flex px={3}>
          <SimpleSelect onChange={(e) => handleChange(e.target.value)}>
            {months.map((month) => (
              <option key={month.toISOString()} value={month.toISOString()}>
                {format(month, 'MMMM yyyy')}
              </option>
            ))}
          </SimpleSelect>
        </Flex>

        <Flex
          borderTopColor="mw.darkGrey"
          borderTopWidth="1px"
          borderTopStyle="solid"
          w="100%"
        >
          <AdminMetricsTable startDate={startDate} endDate={endDate} />
        </Flex>
      </Flex>
    </PageWrapper>
  );
};
