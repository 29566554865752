import { ScrapYieldType } from '@prisma/client';
import is from '@sindresorhus/is';
import { orderBy } from 'lodash-es';
import { mapValues, omit } from 'radash';
import {
  type MarginCalculations,
  type NestedOpCostCalcs,
  calcCostAggs,
  calcMargins,
  calculateOperationInputQty,
  maybeDivide,
  maybeMultiply,
  maybeParseNum,
  round,
} from '.';
import type { Nullable } from '..';
import {
  type AggCostCalculations,
  type CostCalculations,
  calcCosts,
} from './costs';
import type { ResolvedOpCostsInput } from './part-history';
import { type PriceFromMarginCalculations, calcPrice } from './pricing/helpers';

interface QuoteLinePricing extends PriceFromMarginCalculations {
  unitPrice: number;
  baseUnitPrice: number | null;
  totalPrice: number;
}

interface QuoteLineMarginCalcs extends MarginCalculations {
  useContributionMargin: boolean;
}

export interface QuoteLineCalculations {
  /** Will be null if missing cost data and/or price*/
  quantity: number;
  pricing: QuoteLinePricing;
  margins: QuoteLineMarginCalcs | null;
  unitCosts: CostCalculations;
  totalCosts: CostCalculations;
  operationCosts: ResolvedOpCostsInput[];
  nestedCostCalcs: NestedOpCostCalcs | null;
  /**! @deprecated use main lvl */
  detailed: {
    margins: QuoteLineMarginCalcs | null;
    operationCosts: ResolvedOpCostsInput[];
    nestedCostCalcs: NestedOpCostCalcs | null;
    unitCosts: CostCalculations;
    totalCosts: CostCalculations;
    pricing: QuoteLinePricing;
    quantity: number;
  };
}

export interface QuoteLineCalcInput {
  unitPrice: number | string | null;
  baseUnitPrice?: number | string | null;
  unitLaborCost?: number | string | null;
  unitBurdenCost?: number | string | null;
  unitMaterialCost?: number | string | null;
  unitServiceCost?: number | string | null;
  automatedUnitPrice?: number | string | null;
  /**! Don't pass this for new/active lines as it won't hold up when quantities
   * change. May have a use as a backup for historic quotes where quantity is
   * constant though */
  calculatedUnitCost?: number | string | null;
  quantity: number | string;
  operationCosts?: ResolvedOpCostsInput[] | null;
  /**! @deprecated pass in on main lvl */
  detailed?: {
    operationCosts?: ResolvedOpCostsInput[] | null;
  };
}

/**
 * Generates calculations for a quote line response
 *
 * @returns margin calculations
 */
export const calcQuoteLine = (
  quoteLine: QuoteLineCalcInput,
): QuoteLineCalculations => {
  const {
    unitLaborCost,
    unitBurdenCost,
    unitMaterialCost,
    unitServiceCost,
    calculatedUnitCost,
    operationCosts,
  } = quoteLine;

  const costInputs = operationCosts ?? quoteLine?.operationCosts ?? [];

  const _round = <T extends null | undefined>(
    val: number | T,
    { min = 2, small = 3 } = {},
  ): number | T => {
    if (val == null) return val;
    return round(val, { min, small });
  };

  let unitPrice = maybeParseNum(quoteLine.unitPrice) ?? 0; //TODO:(bb) better to handle as nullable

  // TODO:(bb) clean up typing
  interface OpCostCalcsMemo extends Nullable<AggCostCalculations> {
    resolvedOpCosts: ResolvedOpCostsInput[];
  }

  let opCostCalcsMemo: OpCostCalcsMemo | undefined;
  /** Calcs, sets memo, and returns agg cost calcs from updated qty */
  const getOpCostCalcs = (): OpCostCalcsMemo => {
    if (!is.undefined(opCostCalcsMemo)) {
      return opCostCalcsMemo;
    }

    // inputQty is a rolling total to calculate scrap back from the final operation output
    // 1. reverse the op inputs array
    // 2. starting from the final operation and desired lineQty,
    //    map the ops and compute the inputQty from the scrap and desired output qty
    // 3. reverse the output array to get the correct order again

    let inputQty: number | null;

    // for calculating input units via scrap from the last op back
    const sortedOpsBySequenceNumReversed = orderBy(
      costInputs,
      'sequenceNum',
      'desc',
    );

    // recalc cost qty's
    const costsWithUpdQty = sortedOpsBySequenceNumReversed
      .map((opCostInput): ResolvedOpCostsInput => {
        const {
          calcQty: _,
          calcRunHrs: __,
          calcStartQty: ___,
          requirements,
          scrapFixedUnits,
          scrapYieldType,
          scrapYieldPercent,
          ...restOpCostInput
        } = opCostInput;
        const { qtyPerRunHr } = opCostInput;
        const lineQty = maybeParseNum(quoteLine.quantity);

        let percentYield = 1;
        if (scrapYieldPercent && scrapYieldType === ScrapYieldType.SCRAP) {
          percentYield = 1 - scrapYieldPercent / 100;
        }
        if (scrapYieldPercent && scrapYieldType === ScrapYieldType.YIELD) {
          percentYield = scrapYieldPercent / 100;
        }

        // the output qty of an operation is equivalent to the inputQty of the next operation
        // if inputQty is null (final operation), the qty is the desired lineQty
        const updCalcQty = inputQty ? inputQty : lineQty ?? null;
        const calcStartQty =
          calculateOperationInputQty(
            updCalcQty,
            scrapFixedUnits,
            scrapYieldType,
            scrapYieldPercent,
          ) ?? null;
        inputQty = calcStartQty;

        const updCalcRunHrs =
          qtyPerRunHr === 0
            ? 0
            : _round(maybeDivide(updCalcQty, qtyPerRunHr) ?? null, {
                small: 2,
              });

        const updReqs = requirements?.map((req) => {
          const restReq = omit(req, ['calcQtyFromLine', 'calcQtyFromOp']);
          const { qtyPerLineQty, unitsPerPiece, fixedQty } = req;

          //TODO: figure out unitsPerPieceType and how it affects this calculation
          let calcQtyFromOp = maybeMultiply(inputQty, unitsPerPiece) ?? null;
          // requirements also have a "fixed qty" that is always included
          if (calcQtyFromOp && fixedQty) {
            calcQtyFromOp += fixedQty;
          }
          return {
            ...restReq,
            calcQtyFromLine: _round(
              maybeMultiply(lineQty, qtyPerLineQty) ?? null,
            ),
            calcQtyFromOp: _round(calcQtyFromOp),
          };
        });

        return {
          ...restOpCostInput,
          calcQty: updCalcQty,
          calcRunHrs: updCalcRunHrs,
          requirements: updReqs,
          scrapFixedUnits,
          scrapYieldPercent,
          scrapYieldType,
          calcStartQty,
        };
      })
      .toReversed();
    const { agg = null, nested = null } = calcCostAggs(costsWithUpdQty) ?? {};

    const ret = {
      agg,
      nested,
      resolvedOpCosts: costsWithUpdQty,
    };

    opCostCalcsMemo = ret;
    return ret;
  };

  let totalCostsMemo: CostCalculations | undefined;

  const qlCalcs = {
    get margins(): QuoteLineMarginCalcs | null {
      const {
        pricing: { unitPrice },
        unitCosts,
      } = qlCalcs;

      if (!unitPrice) {
        return null;
      }

      if (!unitCosts.grossCost) {
        return null;
      }

      const baseMarginCalcs = calcMargins(
        qlCalcs.pricing.unitPrice,
        qlCalcs.unitCosts,
      );

      return {
        ...baseMarginCalcs,
        get useContributionMargin() {
          return Boolean(baseMarginCalcs.contributionMarginPercent);
        },
      };
    },
    get pricing() {
      /** Updates unit price for all getters when any pricing method is called*/
      const wrapWithUpdPriceProxy = (
        obj: PriceFromMarginCalculations,
      ): PriceFromMarginCalculations => {
        return new Proxy(obj, {
          get(target, propKey: keyof PriceFromMarginCalculations) {
            const origMethod = target[propKey];
            if (typeof origMethod === 'function') {
              return (...args: any[]) => {
                const result: ReturnType<typeof origMethod> = Reflect.apply(
                  origMethod,
                  target,
                  args,
                );
                unitPrice = result;
                return result;
              };
            }
            return origMethod;
          },
        });
      };
      const _fromMarginCalcs = calcPrice(qlCalcs.unitCosts);
      const fromMarginCalcs = wrapWithUpdPriceProxy(_fromMarginCalcs);
      const pricing = {
        get unitPrice(): number {
          return unitPrice;
        },
        set unitPrice(price: number) {
          unitPrice = price;
        },
        get baseUnitPrice(): number | null {
          return maybeParseNum(quoteLine.baseUnitPrice) ?? null;
        },
        get totalPrice(): number {
          return pricing.unitPrice * qlCalcs.quantity;
        },
        ...fromMarginCalcs,
      };
      return pricing;
    },
    get quantity(): number {
      return +quoteLine.quantity || 0;
    },
    /** Gross cost defaults to the calculated (estimated total) cost if it's all we
     * have  */
    get unitCosts(): CostCalculations {
      const qty = qlCalcs.quantity;
      const unitCostCalcs = mapValues(qlCalcs.totalCosts, (cost) =>
        qty ? cost / qty : 0,
      );
      return unitCostCalcs;
    },
    get totalCosts(): CostCalculations {
      if (totalCostsMemo) {
        return totalCostsMemo;
      }

      const totalCostCalcsFromOps = getOpCostCalcs().agg;

      let ret: CostCalculations;

      if (!totalCostCalcsFromOps || !totalCostCalcsFromOps.grossCost) {
        // TODO: rethink
        const qty = qlCalcs.quantity;
        // Non-operation based aggs persisted in db (could be vis quote), only use if we can't calculate
        // detailed costs from operations (workorder/master)
        const fallbackUnitCosts = calcCosts({
          laborCost: unitLaborCost,
          burdenCost: unitBurdenCost,
          materialCost: unitMaterialCost,
          serviceCost: unitServiceCost,
        });
        // if we don't have individual costs but we do have a
        // `calculatedUnitCost` (from imports), use that for gross cost  as a
        // last resort
        const calcUnitCostNum = maybeParseNum(calculatedUnitCost);
        if (!fallbackUnitCosts.grossCost && calcUnitCostNum) {
          ret = mapValues(
            {
              ...fallbackUnitCosts,
              grossCost: calcUnitCostNum,
            },
            (cost) => cost * qty,
          );
        } else {
          ret = mapValues(fallbackUnitCosts, (cost) => cost * qty);
        }
      } else {
        ret = totalCostCalcsFromOps;
      }

      totalCostsMemo = ret;
      return ret;
    },
    get operationCosts(): ResolvedOpCostsInput[] {
      return getOpCostCalcs().resolvedOpCosts;
    },
    get nestedCostCalcs(): NestedOpCostCalcs | null {
      return getOpCostCalcs().nested;
    },
    // TODO: temp as it's own, should be the default costing method
    /** @deprecated Access from the root object going forward*/
    get detailed() {
      const detailed = {
        get margins() {
          return qlCalcs.margins;
        },
        get pricing() {
          return qlCalcs.pricing;
        },
        get quantity() {
          return qlCalcs.quantity;
        },
        get operationCosts() {
          return qlCalcs.operationCosts;
        },
        get nestedCostCalcs() {
          return qlCalcs.nestedCostCalcs;
        },
        get unitCosts() {
          return qlCalcs.unitCosts;
        },
        get totalCosts() {
          return qlCalcs.totalCosts;
        },
      };

      return detailed;
    },
  };

  return qlCalcs;
};
