import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import type {
  CostingConfigResponse,
  PricingConfigResponse,
} from '@lib/responses';
import {
  type QuotingConfigCreateDefaultFormValues,
  type QuotingConfigCreateFormData,
  quotingConfigCreateSchema,
} from '@lib/validation';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Select from 'react-select';

type Props = {
  costingConfigs: CostingConfigResponse[];
  pricingConfigs: PricingConfigResponse[];
  onSubmit: (data: QuotingConfigCreateFormData) => void;
};

export const QuotingConfigCreateForm = ({
  costingConfigs,
  pricingConfigs,
  onSubmit,
}: Props) => {
  const form = useForm<
    QuotingConfigCreateDefaultFormValues,
    unknown,
    QuotingConfigCreateFormData
  >({
    resolver: zodResolver(quotingConfigCreateSchema),
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = form;

  const pricingConfigOpts = pricingConfigs.map((config) => ({
    label: config.name,
    value: config.id,
  }));

  const costingConfigOpts = costingConfigs.map((config) => ({
    label: config.name,
    value: config.id,
  }));

  return (
    <FormProvider {...form}>
      <form role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <VStack gap={4}>
          <FormControl
            isInvalid={!!errors?.name}
            isDisabled={isSubmitting}
            isRequired
          >
            <FormLabel>Name</FormLabel>

            <Input {...register('name')} placeholder="Name" />
            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl
            id="pricingConfigId_input"
            isInvalid={!!errors?.pricingConfigId}
            isRequired
          >
            <FormLabel>Pricing Config</FormLabel>

            <Controller
              name="pricingConfigId"
              control={control}
              disabled={isSubmitting}
              render={({ field: { name, value, onChange, disabled } }) => (
                <Select
                  name={name}
                  inputId="pricingConfigId_input"
                  options={pricingConfigOpts}
                  isDisabled={disabled}
                  value={pricingConfigOpts.find((opt) =>
                    value?.includes(opt.value),
                  )}
                  onChange={(selected) => onChange(selected?.value)}
                />
              )}
            />

            <FormErrorMessage>
              {errors.pricingConfigId?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            id="costingConfigId_input"
            isInvalid={!!errors?.costingConfigId}
            isRequired
          >
            <FormLabel>Costing Config</FormLabel>

            <Controller
              name="costingConfigId"
              control={control}
              disabled={isSubmitting}
              render={({ field: { name, value, onChange, disabled } }) => (
                <Select
                  name={name}
                  inputId="costingConfigId_input"
                  options={costingConfigOpts}
                  isDisabled={disabled}
                  value={costingConfigOpts.find((opt) =>
                    value?.includes(opt.value),
                  )}
                  onChange={(selected) => onChange(selected?.value)}
                />
              )}
            />

            <FormErrorMessage>
              {errors.costingConfigId?.message}
            </FormErrorMessage>
          </FormControl>

          <ButtonGroup marginTop={4} width="full">
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Creating..."
              colorScheme="green"
              width="full"
            >
              Create
            </Button>
          </ButtonGroup>
        </VStack>
      </form>
    </FormProvider>
  );
};
