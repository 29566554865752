import type {
  FinishedPartIdBuilder,
  FinishedPartIdBuilderData,
  FinishedPartIdStrategy,
} from '../types';

export const FinishedPartIdStrategyDefault: FinishedPartIdStrategy = class
  implements FinishedPartIdBuilder
{
  private basePartId: string;

  constructor({ partId }: FinishedPartIdBuilderData) {
    this.basePartId = partId;
  }

  public buildFinishedPartId = (): string => {
    return this.basePartId;
  };
};
