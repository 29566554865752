import { Flex, Text } from '@chakra-ui/react';
import { memo } from 'react';
import { Show } from '../Show';
import {
  QuoteLinePartDetailsErrorIcon,
  type QuoteLinePartDetailsErrorIconProps,
} from './QuoteLinePartDetailsErrorIcon';

export interface QuoteLinePartDetailsProps
  extends QuoteLinePartDetailsErrorIconProps {
  partId: string;
  description?: string | null;
  type?: string;
  material?: string | null;
  lastWonAt?: string;
  drawingId?: string | null;
  drawingRevision?: string | null;
}

/**
 * Renders part details for a quote line
 */
export const QuoteLinePartDetails: React.FC<QuoteLinePartDetailsProps> = memo(
  ({
    partId,
    description,
    type,
    material,
    lastWonAt,
    drawingId,
    drawingRevision,
    lineManager,
  }) => {
    return (
      <Flex flexDirection="column" gap="1">
        <Flex
          gap={{ base: '1', md: '5' }}
          alignItems={{ base: 'start', md: 'end' }}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Flex gap="2" alignItems="center">
            <QuoteLinePartDetailsErrorIcon lineManager={lineManager} />

            <Text fontWeight="bold" fontSize="md" as="h2">
              {partId}
            </Text>
          </Flex>

          <Flex columnGap="3" rowGap="1" alignItems="center" flexWrap="wrap">
            <Show when={description}>
              <Text fontWeight="medium" fontSize="sm" textTransform="uppercase">
                {description}
              </Text>
            </Show>

            <Show when={type}>
              <Text fontWeight="medium" fontSize="sm" textTransform="uppercase">
                {type}
              </Text>
            </Show>

            <Show when={material}>
              <Text fontWeight="medium" fontSize="sm" textTransform="uppercase">
                {material}
              </Text>
            </Show>
          </Flex>
        </Flex>

        <Flex columnGap="3" rowGap="1" alignItems="center" flexWrap="wrap">
          <Show when={lastWonAt}>
            <Text fontSize="xs">
              <strong>Last Ordered:</strong> {lastWonAt}
            </Text>
          </Show>

          <Show when={drawingId}>
            <Text fontSize="xs">
              <strong>Drawing ID:</strong> {drawingId}
            </Text>
          </Show>

          <Show when={drawingRevision}>
            <Text fontSize="xs">
              <strong>Drawing Revision:</strong> {drawingRevision}
            </Text>
          </Show>
        </Flex>
      </Flex>
    );
  },
);
