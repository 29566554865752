import {
  Button,
  type ButtonProps,
  HStack,
  Modal as M,
  type ModalProps as MProps,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import type { FC } from 'react';

import { useLocalization } from '../../../config/localization/getLocalization';
import { useGlobalState } from '../../../hooks';

// extending ButtonProps to include a new property "label"

export type ButtonPropsWithLabel = ButtonProps & {
  label?: string | null;
};

export type ModalProps = {
  title?: string | null;
  children?: any | null;
  isOpen?: boolean;
  onClose?: () => void | null;
  buttons?: ButtonPropsWithLabel[] | null;
  selectionRequired?: boolean | null;
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl';
  initialFocusRef?: MProps['initialFocusRef'];
};

const Modal: FC<ModalProps> = ({
  isOpen = true,
  onClose,
  title,
  children,
  buttons,
  selectionRequired,
  size,
  initialFocusRef,
}) => {
  const { setActiveModal } = useGlobalState();
  const i18n = useLocalization('Modal');

  // Support for taking getting a callback when the modal is closed
  const handleClose = () => {
    onClose && onClose();
    setActiveModal(null);
  };
  return (
    <M
      size={size}
      isOpen={isOpen}
      onClose={handleClose}
      closeOnOverlayClick={!selectionRequired}
      closeOnEsc={!selectionRequired}
      initialFocusRef={initialFocusRef}
    >
      <ModalOverlay />
      <ModalContent>
        {title && <ModalHeader>{title}</ModalHeader>}

        {!selectionRequired && <ModalCloseButton title={i18n('close')} />}
        {children && <ModalBody>{children}</ModalBody>}

        <ModalFooter>
          <HStack>
            {buttons?.map((button, i) => (
              <Button
                key={i}
                {...{ borderRadius: '0px' }}
                {...button}
                {...(button.label ? { children: button.label } : {})}
              />
            ))}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </M>
  );
};

export default Modal;
