import type { RouteItem } from '@ui/config/routes';

export const route = (
  route: RouteItem | string,
  params?: Record<string, string | number>,
): string => {
  let path = '';

  if (typeof route === 'object') {
    path = route.path;
  } else {
    path = route;
  }

  if (!params) {
    return path;
  }

  Object.keys(params).forEach((param) => {
    path = path.replace(`:${param}`, params[param].toString());
  });

  return path;
};
