import { useGetInventory } from '@ui/data/inventory';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import type { QuoteBuilderForm } from '../QuoteBuilder';
import type { LinePathType } from '../types';

export const useInventory = (linePath: LinePathType) => {
  const { control, setValue } = useFormContext<QuoteBuilderForm>();

  const partId = useWatch({
    control,
    name: `${linePath}.partId`,
  });

  const getInventory = useGetInventory(partId);

  useEffect(() => {
    if (getInventory.status === 'success') {
      setValue(`${linePath}.inventory`, getInventory.data);
    }

    if (getInventory.status === 'error') {
      setValue(`${linePath}.inventory`, null);
    }
  }, [getInventory.status, getInventory.data, linePath, setValue]);

  return getInventory;
};
