import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { PermActions } from '@lib/permissions';
import type { AdminSiteResponse, QuotingConfigResponse } from '@lib/responses';
import {
  type AdminSiteUpdateDefaultFormValues,
  type AdminSiteUpdateFormData,
  adminSiteUpdateSchema,
} from '@lib/validation/admin';
import { ConfigEntities, ERPDB, ResourceEnum } from '@prisma/client';
import { WithPermission } from '@ui/components/Permission';
import { useUpdateAdminSite } from '@ui/data/admin';
import { useGlobalState } from '@ui/hooks';
import { useValidationToast } from '@ui/pages/QuoteBuilder/hooks/useToast';
import { isAxiosError } from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { default as ReactSelect } from 'react-select';

type ConfigEntityOption = {
  value: string;
  label: string;
};

function configEntityToOption(entity: string): ConfigEntityOption {
  return { value: entity, label: entity };
}

type SiteFormProps = {
  site: AdminSiteResponse;
  quotingConfigs: QuotingConfigResponse[];
};

export const SiteForm = ({ site, quotingConfigs }: SiteFormProps) => {
  // !TODO: rm once site configs are required in schema
  const { siteConfig } = site;
  if (!siteConfig) {
    // temp guard, not a concern for dev & prod instances where all sites
    // have site configs but might throw on local as a reminder to run seed:cd
    throw new Error('DEV error: site config is not set');
  }

  const { setActiveModal } = useGlobalState();
  const { genericErrorToast } = useValidationToast();

  const updateSiteMutation = useUpdateAdminSite(site.id);

  const form = useForm<
    AdminSiteUpdateDefaultFormValues,
    unknown,
    AdminSiteUpdateFormData
  >({
    resolver: zodResolver(adminSiteUpdateSchema),
    defaultValues: { ...site, siteConfig }, // extra fields get stripped by zod
  });

  const {
    formState: { errors, isDirty, isSubmitting },
  } = form;

  const configEntityOptions = Object.keys(ConfigEntities)
    .map(configEntityToOption)
    .sort((a, b) => a.label.localeCompare(b.label));

  function handleCancelForm() {
    setActiveModal(null);
  }

  async function updateSite(data: AdminSiteUpdateFormData) {
    try {
      await updateSiteMutation.mutateAsync(data);

      setActiveModal(null);
    } catch (error) {
      genericErrorToast(
        'Failed to save site!',
        isAxiosError(error) ? error.response?.data.message : 'Unknown Error',
      );

      console.error('Failed to save site', error);
    }
  }

  return (
    <form
      noValidate
      autoComplete="do-not-autofill"
      onSubmit={form.handleSubmit(updateSite)}
    >
      <VStack spacing="12px">
        <WithPermission
          permissions={{ [ResourceEnum.SUPERADMIN]: [PermActions.READ] }}
        >
          <FormControl isDisabled={isSubmitting}>
            <FormLabel>ERP DB</FormLabel>
            <Select {...form.register('erpDB')}>
              {Object.keys(ERPDB).map((name, index) => (
                <option key={name}>{name}</option>
              ))}
            </Select>
          </FormControl>
        </WithPermission>

        <FormControl isDisabled={isSubmitting}>
          <FormLabel>Config Entity Priorities</FormLabel>
          <Controller
            name="siteConfig.configEntityPriorities"
            control={form.control}
            disabled={isSubmitting}
            render={({ field: { name, value, onChange, disabled } }) => (
              <ReactSelect
                isMulti
                isDisabled={disabled}
                options={configEntityOptions}
                value={value.map(configEntityToOption)}
                onChange={(opt) => onChange(opt.map(({ value }) => value))}
              />
            )}
          />
        </FormControl>

        <FormControl
          isDisabled={isSubmitting}
          isInvalid={!!errors.siteConfig?.historicWorkOrderMaxMonthsOld}
        >
          <FormLabel>Historic Work Order Max Months Old</FormLabel>
          <Input
            type="number"
            {...form.register('siteConfig.historicWorkOrderMaxMonthsOld')}
          />
          <FormErrorMessage>
            {errors.siteConfig?.historicWorkOrderMaxMonthsOld?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isDisabled={isSubmitting}
          isInvalid={!!errors.siteConfig?.quotingConfigId}
        >
          <FormLabel>Quoting Config</FormLabel>
          <Select {...form.register('siteConfig.quotingConfigId')}>
            {quotingConfigs.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Select>

          <FormErrorMessage>
            {errors.siteConfig?.quotingConfigId?.message}
          </FormErrorMessage>
        </FormControl>
      </VStack>

      <Box marginTop="24px">
        <HStack justifyContent={'end'}>
          <Button
            colorScheme="gray"
            onClick={handleCancelForm}
            isDisabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            colorScheme="green"
            type="submit"
            isDisabled={isSubmitting || !isDirty}
            isLoading={isSubmitting}
            loadingText="Saving..."
          >
            Save
          </Button>
        </HStack>
      </Box>
    </form>
  );
};
