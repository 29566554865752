import type { FC } from 'react';
import { CustomerQuote as CustomerQuoteASM } from './asm';
import { CustomerQuote as CustomerQuoteCAP } from './cap';
import { CustomerQuote as CustomerQuoteCSC } from './csc';
import { CustomerQuote as CustomerQuoteGRE } from './gre';
import { CustomerQuote as CustomerQuoteMWS } from './mws';
import { CustomerQuote as CustomerQuotePON } from './pon';
import { CustomerQuote as CustomerQuoteRAF } from './raf';
import { CustomerQuote as CustomerQuoteSPM } from './spm';

interface PDFMap {
  [key: string]: FC | undefined;
  default: FC;
}

export const sitePDFs: PDFMap = {
  ASM: CustomerQuoteASM,
  CAP: CustomerQuoteCAP,
  GRE: CustomerQuoteGRE,
  RAF: CustomerQuoteRAF,
  CSC: CustomerQuoteCSC,
  MWS: CustomerQuoteMWS,
  PON: CustomerQuotePON,
  SPM: CustomerQuoteSPM,
  default: CustomerQuoteCAP, // TODO: prob not what we want since branded, could use corp branding + Site address, etc
};
