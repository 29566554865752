import { Text } from '@chakra-ui/react';
import type { PartResponse } from '@lib';
import type { FC } from 'react';

export const PartSearchResultItem: FC<{ result: PartResponse }> = ({
  result: part,
}) => {
  return (
    <Text isTruncated>
      {part.partId} ({part.type})
    </Text>
  );
};
