import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  type CostingConfigCreateDefaultFormValues,
  type CostingConfigCreateFormData,
  costingConfigCreateSchema,
} from '@lib/validation';
import { FormProvider, useForm } from 'react-hook-form';

type Props = {
  onSubmit: (data: CostingConfigCreateFormData) => void;
};

export const CostingConfigCreateForm = ({ onSubmit }: Props) => {
  const form = useForm<
    CostingConfigCreateDefaultFormValues,
    unknown,
    CostingConfigCreateFormData
  >({
    resolver: zodResolver(costingConfigCreateSchema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = form;

  return (
    <FormProvider {...form}>
      <form role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <VStack gap={4}>
          <FormControl
            isInvalid={!!errors?.name}
            isDisabled={isSubmitting}
            isRequired
          >
            <FormLabel>Name</FormLabel>

            <Input {...register('name')} placeholder="Name" />
            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!errors?.srvBaseChargeDefault}
            isDisabled={isSubmitting}
            isRequired
          >
            <FormLabel>Service Base Charge Default</FormLabel>

            <Input
              {...register('srvBaseChargeDefault')}
              placeholder="Base Default charge"
              type="number"
            />
            <FormErrorMessage>
              {errors?.srvBaseChargeDefault?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!errors?.srvMinChargeDefault}
            isDisabled={isSubmitting}
          >
            <FormLabel>Service Min Charge Default</FormLabel>

            <Input
              {...register('srvMinChargeDefault')}
              placeholder="Min Default charge"
              type="number"
            />

            <FormErrorMessage>
              {errors?.srvMinChargeDefault?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!errors?.matMinCostDefault}
            isDisabled={isSubmitting}
          >
            <FormLabel>Material Min Cost Default</FormLabel>

            <Input
              {...register('matMinCostDefault')}
              placeholder="Material Min Default charge"
              type="number"
            />

            <FormErrorMessage>
              {errors?.matMinCostDefault?.message}
            </FormErrorMessage>
          </FormControl>

          <ButtonGroup marginTop={4} width="full">
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Creating..."
              colorScheme="green"
              width="full"
            >
              Create
            </Button>
          </ButtonGroup>
        </VStack>
      </form>
    </FormProvider>
  );
};
