import { CaretDownIcon, CaretUpIcon } from '@ui/components/Icons';
import { QuoteLineActionsItem } from '@ui/components/QuoteLine';

export interface DetailsToggleProps {
  isOpen: boolean;
  onClick: () => void;
}

/**
 * Toggles active line item details
 */
export const DetailsToggle: React.FC<DetailsToggleProps> = ({
  isOpen,
  onClick,
}) => {
  return (
    <QuoteLineActionsItem
      label={`${isOpen ? 'Hide' : 'Show'} Details`}
      icon={isOpen ? <CaretUpIcon /> : <CaretDownIcon />}
      onClick={onClick}
    />
  );
};
