import {
  Button,
  Checkbox,
  Menu,
  MenuButton,
  type MenuButtonProps,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ChangeEvent, FC } from 'react';

export type CheckboxMenuOption = {
  title: string;
  varName: string;
  enabled: boolean;
  editable: boolean;
  filterable: boolean;
  nested?: any;
  handleChange?: (evt: ChangeEvent<HTMLInputElement>, varName: string) => void;
};

export type CheckboxMenuProps = {
  buttonName: string;
  buttonIcon: IconDefinition;
  buttonProps?: MenuButtonProps;
  menuOptions: CheckboxMenuOption[];
};

export const CheckboxMenu: FC<CheckboxMenuProps> = ({
  buttonName,
  buttonIcon,
  buttonProps,
  menuOptions,
}) => {
  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        {...buttonProps}
        rightIcon={<FontAwesomeIcon icon={buttonIcon} />}
      >
        {buttonName}
      </MenuButton>
      <MenuList>
        {menuOptions.map((mo, index) => {
          if (!mo.filterable) return null;
          return (
            <MenuItem key={`${index}.${mo.title}`}>
              <Checkbox
                isChecked={mo.enabled}
                onChange={(evt) => {
                  if (!mo.handleChange) return;
                  mo.handleChange(evt, String(mo.varName));
                }}
              >
                {mo.title}
              </Checkbox>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
