import type { ResolvedOpCostsInput } from '@lib/calculations/part-history';
import type { QuoteLineContext } from '@ui/state/QuoteLineContextProvider';
import { P, match } from 'ts-pattern';
import type { Get } from 'type-fest';
import { monthDayYear } from '../../util/dates';

/** sorts and adds seq/piece as id fields*/
export const prepCostFormInputs = (costInputs: ResolvedOpCostsInput[]) => {
  return costInputs
    .sort((a, b) => a.sequenceNum - b.sequenceNum)
    .map((op) => {
      const requirements = op.requirements;
      const preppedReqs = requirements
        .sort((a, b) => a.pieceNum - b.pieceNum)
        .map((req) => {
          // TODO: recurse when we reveal subops
          // const { subOps } = req;
          // const preppedSubOps = prepCostFormInputs(subOps);
          return { ...req, id: req.pieceNum };
        });
      return { ...op, requirements: preppedReqs, id: op.sequenceNum };
    });
};

export const makeLastWinProps = (
  input: Pick<QuoteLineContext, 'mostRecentQuoteLine' | 'lastWinCalcs'> & {
    lastWin: Get<QuoteLineContext, 'partHistory.lastWin'>;
  },
) => {
  const useQlContr =
    input.mostRecentQuoteLine?.calcs?.margins?.useContributionMargin;

  const lastWinProps = match({ ...input, useQlContr })
    .with(
      {
        lastWin: P.not(P.nullish).select('lw'),
        lastWinCalcs: P.not(P.nullish).select('c'),
      },
      ({ lw, c }) => {
        return {
          lastWinContrMarginPercent: c.contributionMarginPercent ?? '',
          lastWinGrossMarginPercent: c.grossMarginPercent ?? '',
          unitPrice: c.unitPrice,
          lastWonAtFormatted: monthDayYear(lw.createdAt),
          quantity: lw.orderQuantity,
          isContributionMargin: true as const,
        };
      },
    )
    .with(
      {
        useQlContr: true,
        mostRecentQuoteLine: P.select('ql', {
          calcs: P.not(P.nullish).select('c'),
        }),
      },
      ({ ql, c }) => ({
        lastWinContrMarginPercent: c.margins?.contributionMarginPercent ?? '',
        lastWinGrossMarginPercent: c.margins?.grossMarginPercent ?? '',
        unitPrice: c.pricing.unitPrice,
        lastWonAtFormatted: monthDayYear(ql.quoteStartDate ?? ql.createdAt),
        quantity: ql.quantity,
        isContributionMargin: true as const,
      }),
    )
    //TODO: rethink useQlContr stuff
    .with(
      {
        useQlContr: false,
        mostRecentQuoteLine: P.select('ql', {
          calcs: P.not(P.nullish).select('c'),
        }),
      },
      ({ ql, c }) => ({
        lastWinContrMarginPercent: '', // TODO: ??
        //   lastWinContrMarginPercent: c.margins.contributionMarginPercent,
        lastWinGrossMarginPercent: c.margins?.grossMarginPercent ?? '',
        unitPrice: c.pricing.unitPrice,
        lastWonAtFormatted: monthDayYear(ql.quoteStartDate ?? ql.createdAt),
        quantity: ql.quantity,
        isContributionMargin: false as const,
      }),
    )
    // we still want to use some default historical values even if we didn't calc for whatever reason
    .with(
      {
        mostRecentQuoteLine: P.not(P.nullish).select('ql'),
      },
      ({ ql }) => ({
        lastWinContrMarginPercent: '',
        lastWinGrossMarginPercent: '',
        unitPrice: ql.unitPrice,
        lastWonAtFormatted: monthDayYear(ql.quoteStartDate ?? ql.createdAt),
        quantity: ql.quantity,
        isContributionMargin: false as const,
      }),
    )
    .otherwise(() => ({
      lastWonAtFormatted: 'Missing data',
      unitPrice: '',
      lastWinContrMarginPercent: '',
      lastWinGrossMarginPercent: '',
      quantity: '',
      isContributionMargin: true as const,
    }));

  return {
    ...lastWinProps,
  };
};
