import { Text } from '@chakra-ui/react';
import type { MSGraphUser } from '@lib';
import { adminUserService } from '@ui/services';
import { Search } from './form/Search';

export type UserSearchProps = {
  onSelection: (user: MSGraphUser) => void;
};

export const UserSearch = ({ onSelection }: UserSearchProps) => {
  const handleSearch = (query: string) => {
    return adminUserService.searchMSGraphUsers(query);
  };

  return (
    <Search
      handleSearch={handleSearch}
      ResultItem={UserSearchResultItem}
      searchInputLabel="Search users by email"
      onSelection={onSelection}
    />
  );
};

type UserSearchResultItemProps = {
  result: MSGraphUser;
};

const UserSearchResultItem = ({ result: user }: UserSearchResultItemProps) => {
  return (
    <Text>
      {user.mail} ({user.displayName})
    </Text>
  );
};
