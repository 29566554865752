import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../Icon';
import type { CustomIconProps } from './types';

/**
 * Renders a Caret Down icon with an optional badge.
 *
 * @see Docs https://fontawesome.com/icons/caret-down?f=classic&s=solid
 */
export const CaretDownIcon: React.FC<CustomIconProps> = (props) => {
  return <Icon icon={faCaretDown} {...props} />;
};
