import {
  type PricingCalculator,
  type QuoteLineCalcInput,
  type QuoteLineCalculations,
  type RecalcLineData,
  calcQuoteLine,
} from '@lib/calculations';
import type { Paths } from '@lib/transform/types';
import { split } from '@lib/util';
import { ValidationError } from '@lib/validation';
import { PricingStrategyError } from '@lib/validation/calcs/pricing';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type EventType, useFormContext } from 'react-hook-form';
import { P, isMatching, match } from 'ts-pattern';
import type { Function as Fn } from 'ts-toolbelt/out/Function/Function';
import type { PartialDeep } from 'type-fest';
import type { QuoteBuilderForm } from '../QuoteBuilder';
import { prepCostFormInputs } from '../history-helpers';
import type { LinePathType, MarginTypes, QtyBrkLinePathType } from '../types';
import { useValidationToast } from './useToast';

interface Ret {
  updateFromCosts: () => void;
}

type UserUpdate = {
  readonly curFormVal: PartialDeep<
    QuoteBuilderForm,
    { recurseIntoArrays: true }
  >;
} & (
  | {
      type: 'change';
      name: Paths<QuoteBuilderForm>;
    }
  | {
      type?: EventType;
      name: 'customerHeader.marketCode' | 'customerHeader.discountCode';
    }
);

/** Handles updating calculations and form updates from user input */
export const useLineUpdates = (
  mainLinePath: LinePathType,
  pricingCalculator: PricingCalculator,
): Ret => {
  const { setValue, getValues, watch } = useFormContext<QuoteBuilderForm>();

  const { validationErrorToast, genericErrorToast } = useValidationToast();
  // Used to pass user updates for this line into the hook phase from watch global
  // render phase subscriptions. Change handlers in hooks triggered from these
  // changes will already have updated dependencies like pricingCalculator
  // whereas the global render phase watch subscriptions will always be one
  // render behind
  const [userUpdate, setUserUpdate] = useState<UserUpdate | null>(null);

  useEffect(() => {
    const subscription = watch((curFormVal, { name, type }) => {
      const userUpd = { curFormVal, type, name };
      if (
        isMatching(
          P.union(
            { name: P.string.startsWith(mainLinePath), type: 'change' },
            {
              name: P.union(
                'customerHeader.marketCode',
                'customerHeader.discountCode',
              ),
            },
          ),
          userUpd,
        )
      ) {
        setUserUpdate(userUpd);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, mainLinePath]);

  /** Update handlers/setters wrapped in a single memoized object in lieue of
   * wrapping all in individual `useCallback` and having to explictly specify
   * all deps over and over. Also dot notation helps devs know what's available
   * and somewhat mitigate mis-usage, and encapsulation.
   *
   * NOTE(bb): If you're using these in an effect that you want to use the freshest
   *  handlers, but only trigger off of some other change, then you *should not*
   *  use this directly in the hook/include in deps array. Instead use the
   *  stable `updateHandlersRef.current` with always fresh deps (i.e.
   *  calculator, part history, etc) and only include the trigger you
   *  actually want in the effect's deps array.
   * */
  const updateHandlers = useMemo(() => {
    /**
     *
     * @param path
     * @param calcInput
     * @param skipUpdates - Allows not updating specific values to avoid
     * overriding explicit user input with rounded values
     * @param skipUpdates.skipUnitPriceUpdate - Skips updating `unitPrice`, `baseUnitPrice`, and `automatedUnitPrice`
     * @returns Updated calcs for further use if needed
     */
    const setUpdatedValues = (
      path: LinePathType | QtyBrkLinePathType,
      calcInput: QuoteLineCalcInput,
      {
        skipUnitPriceUpdate = false,
        skipContrbMarginUpdate = false,
        skipGrossMarginUpdate = false,
      }: {
        skipUnitPriceUpdate?: boolean;
        skipMarginUpdate?: boolean;
        skipContrbMarginUpdate?: boolean;
        skipGrossMarginUpdate?: boolean;
      } = {},
    ) => {
      const lineCalcs = calcQuoteLine(calcInput);
      const { margins, pricing } = lineCalcs;
      const newUnitPrice = pricing.unitPrice;
      const newBaseUnitPrice = pricing.baseUnitPrice;
      const newContrbMargin = margins?.contributionMarginPercent;
      const newGrossMargin = margins?.grossMarginPercent;

      // We always want our displayed values to be up to date with actual calcs
      // even if we we aren't able to calc values (i.e. invalid/missing input)
      // NOTE(bb): Ideally we would validate our final values but disabling for
      //  the time being due to perf issues. For whatever reason, any components
      //  subscribed to `formState.errors` with these fields in scope will
      //  re-render no matter what (i.e. no errors changed still triggers), and
      //  a little further re-factoring of the `QuoteLineItem` tree is needed to
      //  not ultimately result in notice-able input lag. Even worse is
      //  subscriptions to `formState.isValidating` which will trigger one
      //  re-render for each individual set field.
      const setOps = { shouldValidate: false };
      if (!skipUnitPriceUpdate) {
        setValue(`${path}.unitPrice`, newUnitPrice ?? '', setOps);
        setValue(`${path}.baseUnitPrice`, newBaseUnitPrice ?? '', setOps);
        newUnitPrice &&
          setValue(`${path}.automatedUnitPrice`, newUnitPrice, setOps);
      }
      // If we are updating a margin field, we want to skip updating the relevant margin
      // so that we don't overwrite the user's input
      !skipContrbMarginUpdate &&
        setValue(`${path}.contributionMarginPercent`, newContrbMargin, setOps);
      !skipGrossMarginUpdate &&
        setValue(`${path}.grossMarginPercent`, newGrossMargin, setOps);

      return lineCalcs;
    };

    const handleUpdateError = (
      path: LinePathType | QtyBrkLinePathType,
      error: unknown,
      toastOpts: { title: string; toastIdBase: string },
    ): void => {
      const { title, toastIdBase } = toastOpts;
      let toastId = `${mainLinePath}_${toastIdBase}`;

      if (!(error instanceof Error)) {
        console.error(
          'Unexpected error type in QuoteLineItem.handleUpdateError',
          { error, path, toastOpts },
        );
        genericErrorToast(
          toastOpts.title,
          'Please contact support',
          false,
          false,
          {
            id: `${toastId}_unknown`,
            duration: null,
            isClosable: true,
            preventDups: true,
          },
        );
        return;
      }
      toastId = `${toastId}_${error.name}_${error.message}`;
      // unique to prevent dups
      if (error instanceof ValidationError) {
        validationErrorToast(error, {
          title,
          id: `${toastId}_${error.severity}`,
          duration: null,
          preventDups: true,
        });
      } else {
        genericErrorToast(toastOpts.title, error.message, true, false, {
          id: toastId,
          preventDups: true,
        });
      }
    };

    /** Merges main-line-only data (operation costs, options) with [maybe] qty
     * brk data for a common shape regardless of line type. */
    const getRecalcData = (
      path: LinePathType | QtyBrkLinePathType,
    ): RecalcLineData => {
      const [curValue, operationCosts, partOptions] = getValues([
        path,
        `${mainLinePath}.operationCosts`,
        `${mainLinePath}.partOptions`,
      ]);

      return { ...curValue, operationCosts, partOptions };
    };

    /**
     *
     * @param path Path to the main line *or* quantity break being updated
     */
    const setValuesFromUnitPriceUpdate = (
      path: LinePathType | QtyBrkLinePathType,
    ) => {
      return setUpdatedValues(path, getRecalcData(path), {
        skipUnitPriceUpdate: true,
      });
    };

    /**
     * @param path Path to the main line *or* quantity break being updated
     * @param marginUpdField form field name of the margin being updated
     * */
    const setValuesFromMarginUpdate = (
      path: LinePathType | QtyBrkLinePathType,
      marginUpdField: MarginTypes,
    ) => {
      // !TODO:(bb) handle in pricing calculator
      // first recalc with the current price and then adjust the
      // price for the original desired margin
      const curInput = getRecalcData(path);
      const newUnitPrice = calcQuoteLine({
        ...curInput,
      }).pricing.from(marginUpdField, curInput[marginUpdField] ?? 0); // TODO: (bb) zero vs undefined handling (price of margin 0 = cost)

      return setUpdatedValues(
        path,
        { ...curInput, unitPrice: newUnitPrice },
        {
          skipContrbMarginUpdate:
            marginUpdField === 'contributionMarginPercent',
          skipGrossMarginUpdate: marginUpdField === 'grossMarginPercent',
        },
      );
    };

    /**
     * @param path Path to the main line *or* quantity break being updated
     * @param marginUpdField form field name of the margin being updated
     * @returns Updated calcs for further use if needed
     */
    const setValuesFromQuantityUpdate = (
      path: LinePathType | QtyBrkLinePathType,
    ): QuoteLineCalculations | undefined => {
      const curData = getRecalcData(path);

      try {
        const { unitPrice, baseUnitPrice } =
          pricingCalculator.recalcPriceOnQtyChange({ ...curData });
        // !TODO:(bb) always return a price or throw from calculator
        if (!unitPrice) {
          throw new PricingStrategyError('Unable to suggest a new price');
        }
        return setUpdatedValues(path, {
          ...curData,
          unitPrice,
          baseUnitPrice,
        });
      } catch (error) {
        handleUpdateError(path, error, {
          title: `Error updating line item for part ${getValues(
            `${mainLinePath}.partId`,
          )}`,
          toastIdBase: 'setValuesFromQuantityUpdate',
        });
      }
    };

    /**
     * @param path Path to the main line *or* quantity break being updated
     * @returns Updated detailed calcs for further use if needed
     */
    const setValuesFromOperationCostsUpdate = (
      path: LinePathType | QtyBrkLinePathType,
    ): QuoteLineCalculations | undefined => {
      const curData = getRecalcData(path);

      try {
        const { unitPrice, baseUnitPrice } =
          pricingCalculator.recalcPriceOnCostChange({
            ...curData,
          });
        // !TODO:(bb) always return a price or throw from calculator
        if (!unitPrice) {
          throw new PricingStrategyError('Unable to suggest a new price');
        }
        return setUpdatedValues(path, {
          ...curData,
          unitPrice,
          baseUnitPrice,
        });
      } catch (error) {
        handleUpdateError(path, error, {
          title: `Error updating line item for part ${getValues(
            `${mainLinePath}.partId`,
          )}`,
          toastIdBase: 'setValuesFromOperationCostsUpdate',
        });
      }
    };

    const setValuesFromPartOptionsUpdate = (
      path: LinePathType | QtyBrkLinePathType,
    ) => {
      const curData = getRecalcData(path);

      try {
        const { unitPrice, baseUnitPrice } =
          pricingCalculator.recalcPriceOnOptionChange(curData);
        // !TODO:(bb) always return a price or throw from calculator
        if (!unitPrice) {
          throw new PricingStrategyError('Unable to suggest a new price');
        }
        return setUpdatedValues(path, { ...curData, unitPrice, baseUnitPrice });
      } catch (error) {
        handleUpdateError(path, error, {
          title: `Error updating line item for part ${getValues(
            `${mainLinePath}.partId`,
          )}`,
          toastIdBase: 'setValuesFromPartOptionsUpdate',
        });
      }
    };

    const setValuesFromCustomerTierUpdate = (
      path: LinePathType | QtyBrkLinePathType,
    ) => {
      const curData = getRecalcData(path);

      try {
        const { unitPrice, baseUnitPrice } =
          pricingCalculator.recalcPriceOnCustomerTierChange(
            curData,
            getValues('customerHeader'),
          );
        // !TODO:(bb) always return a price or throw from calculator
        if (!unitPrice) {
          throw new PricingStrategyError('Unable to suggest a new price');
        }
        return setUpdatedValues(path, {
          ...curData,
          unitPrice,
          baseUnitPrice,
        });
      } catch (error) {
        handleUpdateError(path, error, {
          title: `Error updating line item for part ${getValues(
            `${mainLinePath}.partId`,
          )}`,
          toastIdBase: 'setValuesFromCustomerTierUpdate',
        });
      }
    };

    const makeQtyBrkPath = (qtyBreakIdx: number) => {
      return `${mainLinePath}.quantityBreaks.${qtyBreakIdx}` as const;
    };

    const iterateQtyBreaks = <CB extends Fn<[QtyBrkLinePathType]>>(
      callback: CB,
    ): void => {
      const qtyBreaks = getValues(`${mainLinePath}.quantityBreaks`);
      for (let i = 0; i < qtyBreaks.length; i++) {
        callback(makeQtyBrkPath(i));
      }
    };

    return {
      setValuesFromUnitPriceUpdate,
      setValuesFromMarginUpdate,
      setValuesFromQuantityUpdate,
      setValuesFromOperationCostsUpdate,
      setValuesFromPartOptionsUpdate,
      setValuesFromCustomerTierUpdate,
      iterateQtyBreaks,
    };
  }, [
    mainLinePath,
    pricingCalculator,
    genericErrorToast,
    validationErrorToast,
    getValues,
    setValue,
  ]);

  /** This will always have the freshest value/deps (during hook phase after
   * initial render-phase watch subscription callback(s) run) and can be used
   * directly in effects without including in deps array or triggering any
   * effects itself.*/
  const updateHandlersRef = useRef(updateHandlers);

  useEffect(() => {
    updateHandlersRef.current = updateHandlers;
  }, [updateHandlers]);

  // Watches for user updates to this line only that we have isolated in
  // render's global watch subscription and sent to state and consumes update handlers
  useEffect(() => {
    // We use the handler ref (always fresh in here) to isolate this effect to
    // only trigger from our own filtered relevent user updates, and not dep
    // updates themselves
    const {
      setValuesFromUnitPriceUpdate,
      setValuesFromMarginUpdate,
      setValuesFromQuantityUpdate,
      setValuesFromOperationCostsUpdate,
      setValuesFromPartOptionsUpdate,
      setValuesFromCustomerTierUpdate,
      iterateQtyBreaks,
    } = updateHandlersRef.current;

    userUpdate &&
      match(userUpdate)
        .with(
          {
            name: P.union(
              'customerHeader.marketCode',
              'customerHeader.discountCode',
            ),
          },
          () => {
            setValuesFromCustomerTierUpdate(mainLinePath);
            iterateQtyBreaks(setValuesFromCustomerTierUpdate);
          },
        )
        // recalc margins when user changes price
        .with({ name: `${mainLinePath}.unitPrice` }, () => {
          setValuesFromUnitPriceUpdate(mainLinePath);
        })
        // recalc price when user changes margin, qty, or costs
        .with(
          {
            name: P.union(
              `${mainLinePath}.contributionMarginPercent`,
              `${mainLinePath}.grossMarginPercent`,
            ),
          },
          ({ name }) => {
            const marginField = split(name, '.')[2];
            setValuesFromMarginUpdate(mainLinePath, marginField);
          },
        )
        .with(
          {
            name: `${mainLinePath}.quantity`,
          },
          () => {
            const updCalcs = setValuesFromQuantityUpdate(mainLinePath);
            // Reset costs with updated qty's (*only* for main line)
            updCalcs &&
              setValue(
                `${mainLinePath}.operationCosts`,
                prepCostFormInputs(updCalcs.operationCosts),
              );
          },
        )
        // !TODO (bb): should we still be recalculating the price on [finished] id change?
        // recalculate price and part id on part options change
        .with(
          {
            name: P.string.startsWith(`${mainLinePath}.partOptions`),
          },
          () => {
            setValuesFromPartOptionsUpdate(mainLinePath);
            iterateQtyBreaks(setValuesFromPartOptionsUpdate);
          },
        )
        .with(
          {
            name: P.string.startsWith(`${mainLinePath}.operationCosts.`),
          },
          () => {
            setValuesFromOperationCostsUpdate(mainLinePath);
            iterateQtyBreaks(setValuesFromOperationCostsUpdate);
          },
        )
        // recalc indiv qty brks if user edits one of their fields
        .with(
          {
            name: P.string.startsWith(`${mainLinePath}.quantityBreaks.`),
          },
          ({ name }) => {
            const nameSplit = split(name, '.');
            const [qtyBrkIdx, field] = [nameSplit[3], nameSplit[4]];
            const qtyBrkLinePath: QtyBrkLinePathType = `${mainLinePath}.quantityBreaks.${qtyBrkIdx}`;
            // recalc qty brk price when user changes brk margin or qty
            match(field)
              .with(
                'contributionMarginPercent',
                'grossMarginPercent',
                (updField) => {
                  setValuesFromMarginUpdate(qtyBrkLinePath, updField);
                },
              )
              // recalc qty brk margins when user changes brk price or qty
              .with('unitPrice', () => {
                setValuesFromUnitPriceUpdate(qtyBrkLinePath);
              })
              .with('quantity', () => {
                setValuesFromQuantityUpdate(qtyBrkLinePath);
              })
              .otherwise(() => {});
          },
        )
        .otherwise(() => {});
  }, [userUpdate, mainLinePath, setValue]);

  const updateFromCosts = useCallback(() => {
    updateHandlers.setValuesFromOperationCostsUpdate(mainLinePath);
    updateHandlers.iterateQtyBreaks(
      updateHandlers.setValuesFromOperationCostsUpdate,
    );
  }, [mainLinePath, updateHandlers]);

  return useMemo(() => ({ updateFromCosts }), [updateFromCosts]);
};
