import {
  Button,
  List,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import type { QuoteLineDocumentCreateResponse } from '@lib/responses';
import { FileIcon } from '@ui/components/Icons';
import { QuoteLineActionsItem } from '@ui/components/QuoteLine';
import type React from 'react';
import { LineItemDocument } from './LineItemDocument';

interface LineItemDocumentsModalProps {
  documents: QuoteLineDocumentCreateResponse[];
  partId: string;
}

export const DocumentsModal: React.FC<LineItemDocumentsModalProps> = ({
  documents,
  partId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <QuoteLineActionsItem
        label="View Attachments"
        onClick={onOpen}
        icon={
          <FileIcon badge={{ color: 'yellow', show: !!documents.length }} />
        }
      />

      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`View Attachments for ${partId}`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <List spacing={3}>
              {documents.map((document, index) => {
                return (
                  <LineItemDocument key={document.id} document={document} />
                );
              })}
            </List>
          </ModalBody>
          <ModalFooter marginTop={2}>
            <Button borderRadius="0" onClick={onClose} variant="ghost">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
