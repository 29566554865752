import type { RoleWithPermissions } from '@lib/prisma/prisma-types/role';

import { type SortingState, createColumnHelper } from '@tanstack/react-table';
import DataTable from '@ui/components/DataTable';
import { adminRoleService } from '@ui/services';
import { useEffect, useMemo, useState } from 'react';

export const RoleTable = () => {
  const [roles, setRoles] = useState<RoleWithPermissions[]>([]);

  const [userSorting, setUserSorting] = useState<SortingState>([
    { id: 'name', desc: false },
  ]);
  const [tblSorting, setTblSorting] = useState<SortingState>(userSorting);

  useEffect(() => {
    async function fetchUsers() {
      try {
        const resp = await adminRoleService.getAll();
        setRoles(resp);
        setTblSorting(userSorting);
      } catch (err) {
        console.error(`Failed to get users: ${err}`);
        setTblSorting(userSorting);
      }
    }

    fetchUsers();
  }, [userSorting]);

  const columns = useMemo(() => {
    const ch = createColumnHelper<RoleWithPermissions>();
    const columnDef = [
      ch.accessor('name', {
        header: 'Name',
        cell: (props) => props.getValue(),
      }),
    ];

    return columnDef;
  }, []);

  return (
    <DataTable
      columns={columns}
      data={roles}
      sorting={tblSorting}
      onSort={setUserSorting}
    />
  );
};
