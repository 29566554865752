import { forwardRef } from 'react';
import { SimpleInput, type SimpleInputProps } from './SimpleInput';

export const NumberInput = forwardRef<HTMLInputElement, SimpleInputProps>(
  ({ defaultValue, ...rest }, ref) => {
    return (
      <SimpleInput
        ref={ref}
        type="number"
        defaultValue={defaultValue ?? undefined}
        {...rest}
      />
    );
  },
);

NumberInput.displayName = 'NumberInput';
