import { Box } from '@chakra-ui/react';
import { FixedPricingSources } from '@prisma/client';
import { useQueryClient } from '@tanstack/react-query';
import { CurrentSiteGuard } from '@ui/components/CurrentSiteGuard';
import { PageHeader } from '@ui/components/layout/PageHeader';
import { RoutesConfig, route } from '@ui/config/routes';
import { quoteQueueSearchQueryKeyPrefix } from '@ui/data/quote/queue';
import { useNavigate } from 'react-router-dom';
import { useActiveSiteConfig, useGlobalState } from '../hooks';
import { quoteService } from '../services';
import {
  CustomerPicker,
  type CustomerPickerResult,
} from './QuoteBuilder/CustomerPicker';

export const QuoteNew = () => {
  return (
    <CurrentSiteGuard>
      <QuoteNewUI />
    </CurrentSiteGuard>
  );
};

export const QuoteNewUI = () => {
  const navigate = useNavigate();
  const { pricingConfig } = useActiveSiteConfig();
  const { setActiveModal } = useGlobalState();
  const queryClient = useQueryClient();

  const handleCustomerSelection = async ({
    customer,
    action,
  }: CustomerPickerResult) => {
    let customerId = null;
    let marketCode = customer?.marketCode ?? null;

    if (action === 'selected') {
      customerId = customer.customerId;
    }

    /**
     * @NOTE(shawk): Avoid hard-coding "OEM" here and instead add a config
     * option to associated a Default Market to use for Market Pricing.
     */
    if (
      !marketCode &&
      pricingConfig.fixedPricingSrc === FixedPricingSources.MarketPricing
    ) {
      marketCode = 'OEM';
    }

    // Create a draft quote
    quoteService.createQuote({ customerId, marketCode }).then((quote) => {
      setActiveModal(null);

      /**
       * Invalidate the query cache (lazily) so that updates to this quote will
       * be reflected in the quote queue.
       *
       * @TODO(shawk): remove this once we move the mutations in quote builder
       * to react-query, and make sure that those RQ mutations invalidate the
       * necessary cache keys.
       */
      queryClient.invalidateQueries({
        queryKey: quoteQueueSearchQueryKeyPrefix,
      });

      navigate(route(RoutesConfig.quoteShow, { id: quote.id }));
    });
  };

  return (
    <Box width="100%">
      <PageHeader title="Build a Quote" subtitle="Select a customer" />

      <Box mt="8" mx="auto" w="28rem">
        <CustomerPicker onSelection={handleCustomerSelection} />
      </Box>
    </Box>
  );
};
