import {
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { type QuoteLineBaseResponse, calcQuoteLine } from '@lib';
import { BasicPagination, QuoteStatusBadge } from '@ui/components';
import { QueryError, QueryLoading, QueryNoResults } from '@ui/components/query';
import { usePartQuoteHistory } from '@ui/data/part';
import { usePaginationState } from '@ui/hooks/pagination';
import { useQuoteLineContext } from '@ui/state/QuoteLineContextProvider';
import { currency, monthDayYear } from '@ui/util';
import { Link as RouterLink } from 'react-router-dom';
import { styles } from './styles';

const perPageOptions = [10, 25, 50];

export const QuoteHistoryTable = () => {
  const { partId, partHistoryCalcs, partHistory } = useQuoteLineContext();
  const [paginationState, paginator] = usePaginationState({ perPage: 10 });
  const query = usePartQuoteHistory(partId, paginationState);

  const { historicalWorkOrder, lastOrder } = partHistoryCalcs ?? {};

  const recentWO = historicalWorkOrder?.workOrder ?? null;
  const lastWinCalcs = lastOrder?.calcs;

  // TODO: all calcs should be centralized in lib

  // TODO: this can go, also was already in lastWinCalcs
  const getUnitCost = (val?: string | number | null) => {
    if (!lastWinCalcs) {
      return 0;
    }

    if (!val) {
      return 0.0;
    }

    return (
      +val / +(lastWinCalcs.invoicedQuantity || lastWinCalcs.orderQuantity)
    );
  };

  // TODO: this can go
  const getEstimatedCost = (cost?: string | number | null) => {
    if (!recentWO) {
      return 0;
    }

    if (!lastWinCalcs) {
      return 0;
    }

    if (!cost) {
      return 0;
    }

    return (
      +cost *
      (+(lastWinCalcs?.invoicedQuantity || lastWinCalcs.orderQuantity) /
        +recentWO.desiredQuantity)
    );
  };

  // !TODO(bb): This is NOT calculating relevant costs/margins for any particular
  //  quote line...I believe it was copied from an old version of CostOverview
  //  table which had the similar problems
  // TODO: concept should be in engineeringCalcs/similar
  const getQuoteLineMargin = (line: QuoteLineBaseResponse, gross = false) => {
    //TODO: this is being called 10x per input change
    if (!recentWO) {
      return 'No cost data';
    }

    if (!line.unitMaterialCost && !line.unitLaborCost) {
      line.unitMaterialCost = `${getUnitCost(
        getEstimatedCost(recentWO.estimatedMaterialCost),
      )}`;

      line.unitLaborCost = `${getUnitCost(
        getEstimatedCost(recentWO.estimatedLaborCost),
      )}`;

      line.unitServiceCost = `${getUnitCost(
        getEstimatedCost(recentWO.estimatedServiceCost),
      )}`;

      line.unitBurdenCost = `${getUnitCost(
        getEstimatedCost(recentWO.estimatedBurdenCost),
      )}`;
    }

    const { margins } = calcQuoteLine(line);

    if (!margins) {
      return 'No cost data';
    }

    return gross
      ? `${margins.grossMarginPercent}%`
      : `${margins.contributionMarginPercent}%`;
  };

  if (query.isLoading) {
    return <QueryLoading title="Loading quote history..." />;
  }

  if (query.isError) {
    return (
      <QueryError
        title="Error loading quote history!"
        error={query.error}
        onRetry={() => query.refetch()}
        isRetrying={query.isFetching}
      />
    );
  }

  if (query.isSuccess) {
    if (query.data.data.length === 0) {
      return <QueryNoResults title="No quote history found" />;
    }

    return (
      <>
        <TableContainer
          width="100%"
          transition="opacity 100ms linear"
          opacity={query.isFetching ? 0.5 : 1}
          mb={4}
        >
          <Table width="100%" sx={styles} size="sm">
            <Thead>
              <Tr>
                <Th>Quote Date</Th>
                <Th>Quote ID</Th>
                <Th>Quantity Quoted</Th>
                <Th>Unit Price</Th>
                <Th>Contrib. Margin</Th>
                <Th>Gross Margin</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {query.data.data.map((quote) => {
                const line =
                  partHistory &&
                  quote.lineItems.find(
                    (li) => li.partId === partHistory.partId,
                  );

                return (
                  <Tr key={`qh-${quote.id}`}>
                    <Td>{monthDayYear(quote.startDate)}</Td>
                    <Td>
                      <Link
                        fontWeight="bold"
                        as={RouterLink}
                        to={`/quote/view/${quote.id}`}
                        target="_blank"
                      >
                        {quote.quoteId}
                      </Link>
                    </Td>
                    <Td>{line ? line.quantity : 'Part Missing'}</Td>
                    <Td>{line ? currency(line.unitPrice) : 'Part Missing'}</Td>
                    <Td>{line ? getQuoteLineMargin(line) : 'Part Missing'}</Td>
                    <Td>
                      {line ? getQuoteLineMargin(line, true) : 'Part Missing'}
                    </Td>
                    <Td>
                      <QuoteStatusBadge status={quote.status} />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>

        <BasicPagination
          metadata={query.data.pagination}
          paginator={paginator}
          perPageOptions={perPageOptions}
        />
      </>
    );
  }

  return null;
};
