import type { AdminSiteUpdateRequest } from '@lib/requests';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useValidationToast } from '@ui/pages/QuoteBuilder/hooks/useToast';
import { adminSiteService } from '@ui/services';

export const adminSitesQuery = () => {
  return {
    queryKey: ['admin', 'sites', 'all'],
    queryFn: () => adminSiteService.getAll(),
  };
};

export const adminUpdateSiteMutation = (id: string) => ({
  mutationKey: ['admin', 'sites', 'update', id],
  mutationFn: (data: AdminSiteUpdateRequest) =>
    adminSiteService.update(id, data),
});

export function useAdminSites() {
  return useQuery({
    ...adminSitesQuery(),
  });
}

export function useUpdateAdminSite(id: string) {
  const queryClient = useQueryClient();
  const { genericSuccessToast } = useValidationToast();

  return useMutation({
    ...adminUpdateSiteMutation(id),
    onSuccess: async (_data, _variables, _context) => {
      await queryClient.invalidateQueries({
        queryKey: adminSitesQuery().queryKey,
      });

      genericSuccessToast('Site successfully updated!');
    },
  });
}
