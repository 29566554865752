import { FinishedPartIdStrategyDefault } from './strategies/default';
import { FinishedPartIdStrategyRAF } from './strategies/raf';
import type {
  FinishedPartIdBuilder,
  FinishedPartIdBuilderData,
  FinishedPartIdStrategy,
} from './types';

export type FinishedPartIdStrategyMap = {
  readonly [siteCode: string]: FinishedPartIdStrategy | undefined;
  readonly default: FinishedPartIdStrategy;
};

const strategies: FinishedPartIdStrategyMap = {
  default: FinishedPartIdStrategyDefault,
  RAF: FinishedPartIdStrategyRAF,
};

export const FinishedPartIdBuilderFactory = (
  site: { code: string },
  data: FinishedPartIdBuilderData,
): FinishedPartIdBuilder => {
  const Strategy = strategies[site.code] ?? strategies.default;
  return new Strategy(data);
};
