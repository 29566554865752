import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  type PricingConfigCreateDefaultFormValues,
  type PricingConfigCreateFormData,
  pricingConfigCreateSchema,
} from '@lib/validation';
import { PRICING_STRATEGY_OPTS } from '@ui/constants/enum-options';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Select from 'react-select';

type Props = {
  onSubmit: (data: PricingConfigCreateFormData) => void;
};

export const PricingConfigCreateForm = ({ onSubmit }: Props) => {
  const form = useForm<
    PricingConfigCreateDefaultFormValues,
    unknown,
    PricingConfigCreateFormData
  >({
    resolver: zodResolver(pricingConfigCreateSchema),
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = form;

  return (
    <FormProvider {...form}>
      <form role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <VStack gap={4}>
          <FormControl
            isInvalid={!!errors?.name}
            isDisabled={isSubmitting}
            isRequired
          >
            <FormLabel>Name</FormLabel>

            <Input {...register('name')} placeholder="Name" />
            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl
            id="createPricingConfig_pricingStrategy_input"
            isInvalid={!!errors?.pricingStrategy}
            isRequired
          >
            <FormLabel>Pricing Strategy</FormLabel>

            <Controller
              name="pricingStrategy"
              control={control}
              disabled={isSubmitting}
              render={({ field: { name, value, onChange, disabled } }) => (
                <Select
                  name={name}
                  inputId="createPricingConfig_pricingStrategy_input"
                  options={PRICING_STRATEGY_OPTS}
                  isDisabled={disabled}
                  value={PRICING_STRATEGY_OPTS.find(
                    (opt) => opt.value === value,
                  )}
                  onChange={(selected) => onChange(selected?.value)}
                />
              )}
            />

            <FormErrorMessage>
              {errors?.pricingStrategy?.message}
            </FormErrorMessage>
          </FormControl>

          <ButtonGroup marginTop={4} width="full">
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Creating..."
              colorScheme="green"
              width="full"
            >
              Create
            </Button>
          </ButtonGroup>
        </VStack>
      </form>
    </FormProvider>
  );
};
