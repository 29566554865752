import { useMsal } from '@azure/msal-react';
import { Button } from '@chakra-ui/react';
import { loginRequest } from '../config/authConfig';

export function LoginButton() {
  const { instance } = useMsal();
  //   const auth = useAuth();
  const handleLogin = () => {
    instance.loginRedirect({
      ...loginRequest,
      redirectStartPage: '/',
    });
  };

  return <Button onClick={() => handleLogin()}>Login</Button>;
}
