import { Box, HStack, Heading } from '@chakra-ui/react';
import { calcQuoteLine } from '@lib';
import type { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { EnvConfig } from '../../../config/env';
import type { QuoteBuilderForm } from '../QuoteBuilder';

export const _CalcsPrint: FC<{ lineIndex: number }> = ({ lineIndex }) => {
  if (EnvConfig.getEnv() === 'prod') return null;

  const { control } = useFormContext<QuoteBuilderForm>();
  const ql = useWatch({
    name: `lineItems.${lineIndex}` as const,
    control,
  });

  const qlCalcs = calcQuoteLine({
    ...ql,
    detailed: {
      operationCosts: ql.operationCosts,
    },
  });

  const { detailed } = qlCalcs ?? {};
  const {
    nestedCostCalcs = {},
    operationCosts = [],
    totalCosts = {},
    unitCosts = {},
  } = detailed ?? {};

  return (
    <HStack alignItems="flex-start">
      <Box>
        <Heading as="h3" size="med">
          {`Nested Cost Calcs {[seqNum]: aggCosts}, {requirements: {[pieceNum]: aggCosts}:  `}
        </Heading>
        <pre>{JSON.stringify(nestedCostCalcs, null, 2)}</pre>
      </Box>
      <Box ml="50px" mr="50px">
        <Heading as="h3" size="med">
          {`Total Costs: `}
        </Heading>
        <pre>{JSON.stringify(totalCosts, null, 2)}</pre>
        <Heading as="h3" size="med" mt="50px">
          {`Unit Costs (not variable just total/qty): `}
        </Heading>
        <pre>{JSON.stringify(unitCosts, null, 2)}</pre>
      </Box>
      <Box ml="50px">
        <Heading as="h3" size="med">
          {`Operation Costs: `}
        </Heading>
        <pre>{JSON.stringify(operationCosts, null, 2)}</pre>
      </Box>
    </HStack>
  );
};
