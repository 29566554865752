import { type PaginationParams, PaginationParamsModel } from '@lib';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { partService } from '@ui/services';

function orderHistoryQuery(partId: string, pagination: PaginationParams) {
  return {
    queryKey: ['part', 'history', partId, 'order-line', pagination],
    queryFn: () => partService.getPartOrderLineHistory(partId, pagination),
  };
}

export function usePartOrderLineHistory(
  partId: string,
  pagination: PaginationParams = PaginationParamsModel.defaultParams,
) {
  return useQuery({
    ...orderHistoryQuery(partId, pagination),
    placeholderData: keepPreviousData,
  });
}
