import { round } from '@lib/calculations/util';
import { PricingStrategy } from '@prisma/client';
import { calculatePartOptions } from '../part-options';
import type {
  PriceParts,
  PricingCalculatorStrategy,
  RecalcLineData,
} from '../types';
import { FixedPricingStrategy } from './fixed';

export class RafCustomPricingStrategy
  extends FixedPricingStrategy
  implements PricingCalculatorStrategy
{
  public readonly name = PricingStrategy.RAF_CUSTOM;

  calcInitPrice = (qty: number): number | null => {
    const initPrice = super.calcInitPrice(qty);
    return initPrice === null
      ? null
      : round(initPrice, { min: 2, small: 2, max: 2 });
  };

  /**
   * Recalculates price for an *existing* line item (main or qty break) when a
   * pricing-dependent input changes (i.e. qty, costs, options, etc) is changed.
   * Do *not* call for margin input updates  as they have static methods per margin
   * input in `calcQuoteLine`
   * @param lineData The current line data (main or qty break)
   * @returns promise that resolves price */
  recalcPrice = (lineData: RecalcLineData): PriceParts => {
    return calculatePartOptions.bind(this)(lineData);
  };
}
