import { Flex, Text } from '@chakra-ui/react';
import type { PartOptionMetadata } from '@lib';
import { Controller, useFormContext } from 'react-hook-form';
import { SimpleSelect } from '../../../components/form';
import type { QuoteBuilderForm } from '../QuoteBuilder';
import type { PartOptionPathType } from '../types';

interface PartOptionSuboptionSelectProps {
  subOptionLabel: string | null;
  subOptions: PartOptionMetadata['subOptions'];
  optionPath: PartOptionPathType;
}

export const PartOptionSuboptionSelect: React.FC<
  PartOptionSuboptionSelectProps
> = ({ subOptionLabel, subOptions, optionPath }) => {
  const { control } = useFormContext<QuoteBuilderForm>();

  if (subOptions.length) {
    return (
      <Flex flexDirection="column" paddingTop={1} paddingLeft={4} width="full">
        <Text
          as="label"
          htmlFor={`${optionPath}.subOptionName`}
          fontSize={11}
          fontWeight="bold"
        >
          {`Suboption: ${subOptionLabel}`}
        </Text>

        <Controller
          name={`${optionPath}.suboptionName`}
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <SimpleSelect
                data-testid="suboption-select"
                size={'xs'}
                value={value}
                onChange={(e) => {
                  const suboption = subOptions.find(
                    (subOption) => subOption.name === e.target.value,
                  );

                  onChange(suboption?.name);
                }}
              >
                {subOptions.map((subOption) => (
                  <option key={subOption.name} value={subOption.name}>
                    {subOption.name}
                  </option>
                ))}
              </SimpleSelect>
            );
          }}
        />
      </Flex>
    );
  }

  return null;
};
