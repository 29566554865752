import { noRequirement } from './constants';
import type { MinQuantityStrategy } from './types';

/**
 * @TODO(shawk): Later, we'll want to associate these Site/Market-specific
 * requirements with database records and layered config.
 */
export const getTotalRequirement = (marketCode?: string | null): number => {
  switch (marketCode) {
    case 'SPD':
    case 'DIS':
      return 250;

    // expected to be used for OEM and as a fallback for all others
    default:
      return 500;
  }
};

/**
 * @NOTE(shawk): This calculation approach is designed to match how the Craft
 * site calculates minimum quantities for RAF markets. Ideally, these equations
 * would take into account finish options, but for now we only consider the
 * prices and quantities in the MarketPricing (price book).
 */
export const RAFMinQuantityStrategy = {
  calculateRequirement: (_site, pricing, marketCode) => {
    if (!pricing || !marketCode || !pricing[marketCode]) {
      return noRequirement;
    }

    const marketPricing = pricing[marketCode];
    const requiredTotal = getTotalRequirement(marketCode);

    let requiredQuantity = 1;

    /**
     * To find the required quantity, we need to iterate over the quantity breaks
     * and calculate whether the required total price can be met by each break.
     *
     * Eventually, we expect to find a quantity break that satisfies the
     * required total and from that we can calculate the minimum quantity needed.
     */
    for (let i = 0; i < marketPricing.length; i++) {
      const qtyBreak = marketPricing[i];
      const nextBreak = marketPricing[i + 1];

      requiredQuantity = Math.ceil(requiredTotal / qtyBreak.price);

      if (!nextBreak) {
        break;
      }

      const maxQuantityForCurrentBreak = nextBreak.qty - 1;
      const satisfiesRequiredTotal =
        qtyBreak.price * maxQuantityForCurrentBreak >= requiredTotal;

      if (satisfiesRequiredTotal) {
        break;
      }
    }

    return {
      enforced: true,
      requiredTotal,
      requiredQuantity,
    };
  },
} satisfies MinQuantityStrategy;
