import { Box, Button, Checkbox, Flex } from '@chakra-ui/react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import type { UserSettingsUpdateRequest } from '@lib';
import { AxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import PageWrapper from '../components/PageWrapper';
import PageHeader from '../components/layout/PageHeader';
import { userService } from '../services';
import { useAuth } from '../state/auth';
import { useValidationToast } from './QuoteBuilder/hooks/useToast';

export const Profile = () => {
  const { user, logout } = useAuth();

  const { genericErrorToast, genericSuccessToast } = useValidationToast();

  const handleFormSubmit = async (silent = true) => {
    const data = form.getValues();
    try {
      await userService.saveUserProfile(data);
      if (!silent) {
        genericSuccessToast('Profile saved');
      }
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        genericErrorToast('Unexpected error saving profile');
      }
    }
  };

  const form = useForm<UserSettingsUpdateRequest>();

  return (
    <PageWrapper>
      <PageHeader
        title="Account Settings"
        subtitle="Manage your account settings"
      >
        {/* TODO: update on post/patch /user */}
        <Button
          onClick={() => handleFormSubmit(false)}
          title="Save"
          fontFamily="navigationItem"
          bgColor="mw.yellow"
          color="black"
          borderRadius={'0px'}
          _hover={{ bg: 'mw.darkYellow' }}
        >
          Save
        </Button>
      </PageHeader>
      <Flex width={'100%'} marginTop={6}>
        <Box
          backgroundColor="mw.panelGrey"
          borderRadius={5}
          paddingX={{ base: 5, md: 10 }}
          paddingY={6}
          display="inline-block"
          width="100%"
        >
          <FormProvider {...form}>
            <form
              // Chrome hack
              autoComplete="do-not-autofill"
              onSubmit={() => handleFormSubmit()}
            >
              <FormControl mb={5}>
                <FormLabel fontWeight={600}>Display Name</FormLabel>
                <Input
                  type="text"
                  defaultValue={user?.displayName}
                  {...form.register('displayName')}
                />
              </FormControl>
              <FormControl mb={5}>
                <FormLabel fontWeight={600}>Phone Number</FormLabel>
                <Input
                  type="phone"
                  defaultValue={user?.userSettings?.phoneNumber || ''}
                  {...form.register('phoneNumber')}
                />
              </FormControl>
              <FormControl mb={5}>
                <FormLabel fontWeight={600}>Mobile Phone Number</FormLabel>
                <Input
                  type="phone"
                  defaultValue={user?.userSettings?.mobilePhoneNumber || ''}
                  {...form.register('mobilePhoneNumber')}
                />
              </FormControl>
              <FormControl mb={5}>
                <FormLabel fontWeight={600}>Signature Title</FormLabel>
                <Input
                  type="text"
                  defaultValue={user?.userSettings?.signatureTitle || ''}
                  {...form.register('signatureTitle')}
                />
              </FormControl>
              <FormControl mb={5}>
                <FormLabel fontWeight={600}>
                  Email Quotes to Customers by Default
                </FormLabel>
                <Checkbox
                  defaultChecked={
                    user?.userSettings?.emailQuoteToCustomer ?? true
                  }
                  {...form.register('emailQuoteToCustomer')}
                />
              </FormControl>
              <FormControl mb={5}>
                <FormLabel fontWeight={600}>Visual User Name</FormLabel>
                <Input
                  type="text"
                  defaultValue={user?.userSettings?.visualUserName || ''}
                  {...form.register('visualUserName')}
                />
              </FormControl>
            </form>
          </FormProvider>
        </Box>
        {/*
        <Box width="600px" marginLeft={'auto'} backgroundColor={'#333'}>
          <Box color={'white'} padding={10}>
            <pre>
              <Text>{JSON.stringify(user, null, 2)}</Text>
            </pre>
          </Box>
        </Box>
  */}
      </Flex>
    </PageWrapper>
  );
};
