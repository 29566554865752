import { Text } from '@react-pdf/renderer';
import { useQuotePdfContext } from '@ui/pages/QuoteBuilder/hooks';
import { commaSeparated } from '@ui/util';

interface EstimatedQuantityOnHandProps {
  lineIndex: number;
}

export const EstimatedQuantityOnHand: React.FC<EstimatedQuantityOnHandProps> =
  ({ lineIndex }) => {
    const { getInventory } = useQuotePdfContext();
    const inventory = getInventory(lineIndex);

    if (inventory) {
      return (
        <Text>
          Estimated Quantity On Hand: {commaSeparated(inventory.qtyOnHand)}
        </Text>
      );
    }

    return null;
  };
