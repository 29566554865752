import { HStack } from '@chakra-ui/react';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import type { FC, HTMLInputTypeAttribute } from 'react';
import { InputWithIcon, type InputWithIconProps } from '../InputWithIcon';
import ComparisonField, {
  type ComparisonFieldBaseProps,
  visibilityValue,
} from './ComparisonField';

export interface ComparisonInputProps extends ComparisonFieldBaseProps {
  type: HTMLInputTypeAttribute;
  afterInputProps?: InputWithIconProps & { ref?: React.Ref<HTMLInputElement> };
  afterInputElement?: React.ReactElement;
  commonInputProps?: InputWithIconProps;
}

/**
 * @param changeOnBlur - When true, `onChange` handler will be called prior to
 * the `onBlur` handler during blur events.
 * Note: Causes `onChange` to *not* be called for each input change
 * @param blurOnEnterKey - When true, the pressing the "Enter" key will trigger
 * the blur event while maintaining input focus. Works alongside changeOnBlur:true
 * */
export const ComparisonInput: FC<ComparisonInputProps> = ({
  type,
  afterInputProps,
  afterInputElement,
  beforeInputProps,
  commonInputProps,
  hideBeforeInformation,
  hideWholeElement,
  ...rest
}) => {
  const AfterComponent = (
    <HStack>
      <InputWithIcon
        type={type}
        errorIcon={faTriangleExclamation}
        {...{ ...commonInputProps, ...afterInputProps }}
        sx={visibilityValue(hideWholeElement)}
      />
      {afterInputElement && afterInputElement}
    </HStack>
  );

  const BeforeComponent = beforeInputProps ? (
    <InputWithIcon
      type="text"
      textAlign="right"
      isReadOnly
      backgroundColor={'#efefef'}
      color="black"
      sx={visibilityValue(hideWholeElement || hideBeforeInformation)}
      tabIndex={-1}
      {...{ ...commonInputProps, ...beforeInputProps }}
    />
  ) : null;

  return (
    <ComparisonField
      BeforeComponent={BeforeComponent}
      AfterComponent={AfterComponent}
      hideBeforeInformation={hideBeforeInformation}
      hideWholeElement={hideWholeElement}
      {...rest}
    />
  );
};
