import { capitalize } from 'lodash-es';
import { match } from 'ts-pattern';
import type { ParsedOrderLineCosts } from '../../models';
import { ValidationError } from '../errors';

export interface PartHistoryError extends ValidationError {}

export class NoLastWinAndNoQuoteHistory
  extends ValidationError
  implements PartHistoryError
{
  name = 'NoLastWinAndNoQuoteHistory';
  severity = 9010 as const;
  constructor(public partId: string) {
    super();
  }

  get message() {
    return 'Part has no last win, nor any quote history';
  }
}

export class NoLastWin extends ValidationError implements PartHistoryError {
  name = 'NoLastWin';
  severity = 9000 as const;
  constructor(public partId: string) {
    super();
  }
  get message() {
    return 'No previous win found for part, using most recent quote data';
  }
}

export class ZeroCost extends ValidationError implements PartHistoryError {
  name = 'ZeroCost';
  severity = 1050 as const;
  constructor(
    public partId: string,
    public costName: keyof ParsedCheckCosts,
  ) {
    super();
  }
  get message() {
    return `${capitalize(this.friendlyCostName)} is 0`;
  }
  public get friendlyCostName() {
    return getCostType(this.costName);
  }
}

export class MissingCost extends ValidationError implements PartHistoryError {
  name = 'MissingCost';
  severity = 1060 as const;
  constructor(
    public partId: string,
    public costName: keyof ParsedCheckCosts,
  ) {
    super();
  }
  get message() {
    return `${capitalize(
      this.friendlyCostName,
    )} is missing from last win. Defaulting to 0`;
  }
  public get friendlyCostName() {
    return getCostType(this.costName);
  }
}

export class MissingAllCosts
  extends ValidationError
  implements PartHistoryError
{
  name = 'MissingAllCosts';
  severity = 6010 as const;
  constructor(public partId: string) {
    super();
  }
  get message() {
    return `Cost cannot be missing`;
  }
}

export class NoQuoteHistory
  extends ValidationError
  implements PartHistoryError
{
  name = 'NoQuoteHistory';
  severity = 8010 as const;
  constructor(public partId: string) {
    super();
  }

  get message() {
    return 'Part has no quote history';
  }
}

export class HasNegotiatedPricing
  extends ValidationError
  implements PartHistoryError
{
  name = 'HasNegotiatedPricing';
  severity = 7010 as const;
  constructor(public partId: string) {
    super();
  }
  get message() {
    return 'This part is using negotiated pricing';
  }
}

const getCostType = (key: keyof ParsedCheckCosts) => {
  return match(key)
    .with('totalLaborCost', () => 'labor cost')
    .with('totalMaterialCost', () => 'material cost')
    .exhaustive();
};

export type ParsedCheckCosts = Pick<
  ParsedOrderLineCosts,
  'totalLaborCost' | 'totalMaterialCost'
>;

export type ParsedCheckedCosts = ParsedCheckCosts & {
  warnings: PartHistoryError[];
};

export class RAF1000PieceMinimumParts
  extends ValidationError
  implements PartHistoryError
{
  name = 'RAF1000PieceMinimum';
  severity = 5009 as const;
  constructor(public partId: string) {
    super();
  }
  get message() {
    return 'There is a 1,000 piece minimum on this part, please review your quantity';
  }
}
