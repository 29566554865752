import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  type ButtonProps,
  useDisclosure,
} from '@chakra-ui/react';
import { type FC, useRef } from 'react';

type DeleteConfirmButtonProps = ButtonProps & {
  name: string;
  modalTitle?: string;
  description: string;
  onConfirm?: () => void;
};

export const DeleteConfirmButton: FC<DeleteConfirmButtonProps> = ({
  name,
  modalTitle,
  description,
  onConfirm,
  ...buttonProps
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleOnConfirm = () => {
    onClose();
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <>
      <Button {...buttonProps} onClick={onOpen}>
        {name}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {modalTitle ?? name}
            </AlertDialogHeader>

            <AlertDialogBody>{description}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleOnConfirm} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
