import type {
  SiteWithConfigResponse,
  UserWithSettingsResponse,
} from '@lib/responses';
import { Document } from '@react-pdf/renderer';
import { sitePDFs } from '@ui/pdfs/customer-quote';
import type { FC } from 'react';
import { QuotePdfProvider } from '../state/QuotePdfContext';
import type { LinePropsMap, QuoteBuilderForm } from './QuoteBuilder';

export interface QuotePdfDocumentProps {
  quoteForm: QuoteBuilderForm;
  linePropMap: LinePropsMap;
  user: UserWithSettingsResponse;
  currentSite: SiteWithConfigResponse;
}

/**  Pass this to react-pdf render or usePDF hook update method only when needed.
   It is meant as a static component, so don't use any async methods or
   state/hooks other than what's available through useQuotePdfContext 
   */
export const QuotePdfDocument: FC<QuotePdfDocumentProps> = (docProps) => {
  {
    const CustomerQuote =
      sitePDFs[docProps.currentSite.code ?? 'default'] ?? sitePDFs.default;

    return (
      <Document>
        <QuotePdfProvider {...docProps}>
          <CustomerQuote />
        </QuotePdfProvider>
      </Document>
    );
  }
};
