import type { ColumnSort, SortingState } from '@tanstack/react-table';

export function encodeSortingState(sort: SortingState): string[] {
  return sort.map(({ id, desc }) =>
    encodeURIComponent(`${id}:${desc ? 'desc' : 'asc'}`),
  );
}

export function decodeSortingState(sorts?: string[]): SortingState | undefined {
  if (!sorts || sorts.length === 0) {
    return undefined;
  }

  return sorts.map<ColumnSort>((sort) => {
    const [id, desc] = decodeURIComponent(sort).split(':');
    return { id, desc: desc === 'desc' };
  });
}

export function replaceSortParam(
  params: URLSearchParams,
  sort: SortingState,
): void {
  params.delete('sort');
  encodeSortingState(sort).map((sort) => params.append('sort', sort));
}
