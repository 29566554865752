import { PaginationParamsModel } from '@lib/models';
import { useMemo, useState } from 'react';
import {
  type PaginationState,
  type Paginator,
  PaginatorTypeState,
} from './types';

const defaultPagination = PaginationParamsModel.defaultParams;

export const usePaginationState = (
  initial?: Partial<PaginationState>,
): [PaginationState, Paginator] => {
  const [page, setPage] = useState<number>(
    initial?.page || defaultPagination.page,
  );
  const [perPage, setPerPage] = useState<number>(
    initial?.perPage || defaultPagination.perPage,
  );

  return useMemo(
    () => [
      { page, perPage },
      { type: PaginatorTypeState, setPage, setPerPage },
    ],
    [page, perPage],
  );
};
