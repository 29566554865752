import { type FC, memo } from 'react';

export interface QuotePdfViewerProps {
  quoteId: string;
  pdfUrl: string;
}

export const QuotePDFViewer: FC<QuotePdfViewerProps> = memo(
  ({ quoteId, pdfUrl }) => {
    return (
      <iframe
        src={`${pdfUrl}#toolbar=0`}
        width="100%"
        height={window.innerHeight - 152}
        title={`Quote ${quoteId}`}
      />
    );
  },
);
