import { Center, Spinner } from '@chakra-ui/react';

const Loading = () => {
  return (
    <Center>
      <Spinner>
        <Spinner size="xl" color="mw.yellow" aria-label="Loading..." />
      </Spinner>
    </Center>
  );
};

export default Loading;
