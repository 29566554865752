import { useEffect } from 'react';
import { useAuth } from '../state/auth';

const WithUser = ({ children }: { children: JSX.Element }) => {
  const { user, isError, getProfile } = useAuth();
  useEffect(() => {
    // if we know logged in but no auth.user -> fetch user profile
    if (!isError && !user) {
      getProfile();
    }
  }, [user, isError]);

  if (!user) {
    return isError ? (
      <div>Error getting user information</div>
    ) : (
      <div>fetching user data...</div>
    );
  }
  return <>{children}</>;
};

export default WithUser;
