import { Text } from '@chakra-ui/react';

export const TextWithLabel = ({
  label,
  text,
}: {
  label: string;
  text: string | number | null | undefined;
}) => {
  if (!text) {
    return null;
  }

  return (
    <>
      <Text as="b">{label}:</Text>
      <Text>{text}</Text>
    </>
  );
};
