import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { EnvConfig } from '@ui/config/env';
import { _CalcsPrint } from '@ui/pages/QuoteBuilder/_dev/_CalcsPrint';
import { useQuoteLineContext } from '@ui/state/QuoteLineContextProvider';
import { CostOverviewTable } from '../CostOverviewTable';
import { CostEstimator } from './CostEstimator';
import { OrderHistoryTable } from './OrderHistoryTable';
import { PriceBook } from './PriceBook';
import { QuoteHistoryTable } from './QuoteHistoryTable';

export const QuoteLineDetails = () => {
  const {
    partId,
    partHistory,
    partHistoryCalcs,
    index: lineIndex,
  } = useQuoteLineContext();

  const { historicalWorkOrder, lastOrder } = partHistoryCalcs ?? {};

  const recentWO = historicalWorkOrder?.workOrder ?? null;

  const priceBookPricing =
    partHistory && (partHistory.discountPricing || partHistory.marketPricing);

  const priceBookType =
    priceBookPricing &&
    (partHistory.discountPricing ? 'discountPricing' : 'marketPricing');

  return (
    <Tabs width="100%" defaultIndex={2} isLazy lazyBehavior="keepMounted">
      <TabList>
        <Tab>Cost Overview</Tab>
        {/* <Tab>Cost Breakdown</Tab> */}
        <Tab>Quote History</Tab>
        <Tab>Cost Estimator</Tab>
        <Tab>Order History</Tab>
        {priceBookPricing && <Tab>Price Book</Tab>}
        {EnvConfig.getEnv() !== 'prod' ? <Tab> [DEV] Cost Calcs</Tab> : null}
      </TabList>

      <TabPanels>
        <TabPanel>{CostOverviewTable({ recentWO })}</TabPanel>

        {/* <TabPanel>
          <>
            {CostBreakDownTable}
            <div>Totals: {JSON.stringify(totalCosts, null, 2)}</div>
            <div>Unit: {JSON.stringify(unitCosts)}</div>
          </>
        </TabPanel> */}

        <TabPanel>
          <QuoteHistoryTable />
        </TabPanel>

        <TabPanel>
          <CostEstimator lineIndex={lineIndex} />
        </TabPanel>

        <TabPanel>
          <OrderHistoryTable partId={partId} />
        </TabPanel>

        {priceBookPricing && (
          <TabPanel>
            <PriceBook type={priceBookType} pricing={priceBookPricing} />
          </TabPanel>
        )}

        {EnvConfig.getEnv() !== 'prod' ? (
          <TabPanel>
            <_CalcsPrint lineIndex={lineIndex} />
          </TabPanel>
        ) : null}
      </TabPanels>
    </Tabs>
  );
};
