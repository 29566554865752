import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PageWrapper from '@ui/components/PageWrapper';
import { InputWithIcon } from '@ui/components/form';
import PageHeader from '@ui/components/layout/PageHeader';
import { useGlobalState } from '@ui/hooks';
import { usePaginationURLState } from '@ui/hooks/pagination';
import { debounce } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RoleForm } from './RoleForm';
import { RoleTable } from './RoleTable';
import { UserForm } from './UserForm';
import { UserTable } from './UserTable';

const DEBOUNCE_WAIT = 400;

export const AdminUserIndex = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { setActiveModal } = useGlobalState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [_paginationState, paginator] = usePaginationURLState();

  const query = searchParams.get('q') ?? '';

  const handleTabChange = (idx: number) => {
    setSearchParams((params) => {
      paginator.updatePageParam(params, 1);
      params.delete('q');

      return params;
    });
    setTabIndex(idx);
  };

  const AddModals = [
    { title: 'Add User', component: UserForm },
    { title: 'Add Role', component: RoleForm },
  ];

  const handleAddModal = () => {
    const Component = AddModals[tabIndex].component;
    setActiveModal({
      size: 'xl',
      title: AddModals[tabIndex].title,
      children: <Component />,
    });
  };

  const handleSearch = useCallback(
    (query: string) => {
      setSearchParams((params) => {
        paginator.updatePageParam(params, 1);

        if (query) {
          params.set('q', query);
        } else {
          params.delete('q');
        }

        return params;
      });
    },
    [paginator, setSearchParams],
  );

  const debouncedSetSearchInput = useMemo(
    () => debounce(handleSearch, DEBOUNCE_WAIT),
    [handleSearch],
  );

  return (
    <PageWrapper>
      <PageHeader title="Admin / User Management">
        {/* Only display the search input when the "Users" tab is selected */}
        {tabIndex === 0 && (
          <InputWithIcon
            type={'text'}
            onChange={(e) => debouncedSetSearchInput(e.target.value)}
            bgColor={'white'}
            placeholder="Search users..."
            defaultValue={query}
            maxWidth={[
              'unset', // base
              'unset', // 480px upwards
              '300px', // 768px upwards
            ]}
            width={[
              '100%', // base
              '200px', // 480px upwards
              '300px', // 768px upwards
            ]}
            autoFocus
            leftIcon={faSearch}
            fontFamily="navigationItem"
            groupProperties={{
              marginLeft: '48px',
            }}
            mb={0}
          />
        )}
        <Button
          onClick={handleAddModal}
          fontFamily="navigationItem"
          bgColor="mw.yellow"
          color="black"
          borderRadius={'0px'}
          _hover={{ bg: 'mw.darkYellow' }}
          gap={2}
        >
          {AddModals[tabIndex].title}
        </Button>
      </PageHeader>

      <Box>
        <HStack justifyItems={'start'} width="100%">
          <Tabs width="100%" index={tabIndex} onChange={handleTabChange}>
            <TabList>
              <Tab>
                {/*
                    Re-think on this. Slightly annoying to set the tab based on the URI. Also annoying to setup
                    another route just for roles. Dig the automated styles and component functionality of tabs though.
                 */}
                Users
              </Tab>
              <Tab>Roles</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <UserTable />
              </TabPanel>
              <TabPanel>
                <RoleTable />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </HStack>
      </Box>
    </PageWrapper>
  );
};
