import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { faCircleXmark, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { MSGraphUser } from '@lib';
import { FormSectionTitle } from '@ui/components/Text';
import { UserSearch } from '@ui/components/UserSearch';
import { useGlobalState } from '@ui/hooks';
import { useFormContext, useWatch } from 'react-hook-form';
import type { QuoteBuilderForm } from './QuoteBuilder';

export const CSRInput = () => {
  const { control, register, setValue } = useFormContext<QuoteBuilderForm>();
  const { setActiveModal } = useGlobalState();

  const handleUserSelection = (user: MSGraphUser) => {
    setValue('csrOid', user.id);
    setValue('csrName', user.displayName);
    setValue('csrEmail', user.mail);

    setActiveModal(null);
  };

  const showCsrModal = () => {
    setActiveModal({
      size: 'xl',
      title: 'Search for a CSR',
      children: <UserSearch onSelection={handleUserSelection} />,
    });
  };

  const clearCsr = () => {
    setValue('csrOid', null);
    setValue('csrName', null);
    setValue('csrEmail', null);
  };

  return (
    <>
      <Box as={Flex} gap={5} direction={{ base: 'column', md: 'row' }}>
        <Box flexBasis="50%" mb="3">
          <FormSectionTitle mb="3">
            Customer Service Representative
          </FormSectionTitle>

          <Box
            as={Flex}
            minH="65px"
            alignItems="stretch"
            bgColor="white"
            borderWidth="1px"
            borderRadius="6px"
          >
            {/* Search button */}
            <Link
              onClick={showCsrModal}
              display="flex"
              alignItems="center"
              px="4"
              borderTopLeftRadius="6px"
              borderBottomLeftRadius="6px"
              borderRightWidth="1px"
              bgColor="mw.yellow"
              _hover={{
                bgColor: 'mw.darkYellow',
              }}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Link>

            <CSRLabel />

            <CSRClearButton onClick={clearCsr} />
          </Box>
        </Box>
      </Box>

      <input type="hidden" {...register('csrOid')} />
      <input type="hidden" {...register('csrName')} />
      <input type="hidden" {...register('csrEmail')} />
    </>
  );
};

const CSRLabel = () => {
  const { control } = useFormContext<QuoteBuilderForm>();
  const [name, email] = useWatch({ control, name: ['csrName', 'csrEmail'] });

  const hasCsr = !!name && !!email;

  return (
    <Text
      display="flex"
      alignItems="center"
      w="100%"
      px="5"
      py="2"
      overflow="hidden"
    >
      {hasCsr ? (
        <>
          {name}
          <br />
          {email}
        </>
      ) : (
        <em>None selected</em>
      )}
    </Text>
  );
};

const CSRClearButton = ({ onClick }: { onClick: () => void }) => {
  const { control } = useFormContext<QuoteBuilderForm>();
  const oid = useWatch({ control, name: 'csrOid' });

  if (!oid) {
    return null;
  }

  return (
    <Link
      onClick={onClick}
      display="flex"
      alignItems="center"
      pl="2"
      pr="4"
      color="mw.lightGrey"
      _hover={{
        color: 'mw.darkGrey',
      }}
    >
      <FontAwesomeIcon icon={faCircleXmark} />
    </Link>
  );
};
