import type {
  PricingConfigCreateRequest,
  PricingConfigUpdateRequest,
} from '@lib/requests';
import type { PricingConfigResponse } from '@lib/responses';
import {
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useValidationToast } from '@ui/pages/QuoteBuilder/hooks/useToast';
import { adminConfigsService } from '@ui/services';
import { useAuth } from '@ui/state/auth';
import { adminConfigAllQuery } from './quoting';

/**
 * Having optional params and a fallback to "unknown" is a workaround until we
 * have better options for guarding sections of the app that require a
 * `currentSite` to be defined. These queries and mutations don't really make
 * sense without a valid site id.
 */
export const adminConfigPricingQuery = (siteId = 'unknown') => {
  return {
    queryKey: ['admin', 'configs', siteId, 'pricing'],
    queryFn: () => adminConfigsService.getPricingConfigs(siteId),
  };
};

export const adminConfigCreatePricingMutation = (siteId = 'unknown') => ({
  mutationKey: ['admin', 'configs', siteId, 'pricing', 'create'],
  mutationFn: (data: PricingConfigCreateRequest) =>
    adminConfigsService.createPricingConfig(siteId, data),
});

export const adminConfigUpdatePricingMutation = (
  siteId = 'unknown',
  id = 'unknown',
) => ({
  mutationKey: ['admin', 'configs', siteId, 'pricing', 'update', id],
  mutationFn: (data: PricingConfigUpdateRequest) =>
    adminConfigsService.updatePricingConfig(siteId, id, data),
});

export function useAdminPricingConfigs(
  options?: Omit<
    UseQueryOptions<PricingConfigResponse[]>,
    'queryKey' | 'queryFn'
  >,
) {
  const { currentSite } = useAuth();

  return useQuery({
    ...adminConfigPricingQuery(currentSite?.id),
    ...options,
    enabled: !!currentSite?.id && (options?.enabled ?? true),
  });
}

export function useCreateAdminPricingConfig(
  options?: Omit<
    UseMutationOptions<
      PricingConfigResponse,
      unknown,
      PricingConfigCreateRequest
    >,
    'mutationKey' | 'mutationFn'
  >,
) {
  const { currentSite } = useAuth();
  const queryClient = useQueryClient();
  const { genericSuccessToast } = useValidationToast();

  return useMutation({
    ...adminConfigCreatePricingMutation(currentSite?.id),
    ...options,
    onSuccess: async (_data, _variables, _context) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: adminConfigPricingQuery(currentSite?.id).queryKey,
        }),
        queryClient.invalidateQueries({
          queryKey: adminConfigAllQuery(currentSite?.id).queryKey,
          type: 'inactive',
        }),
      ]);

      genericSuccessToast('Pricing config created');
    },
  });
}

export function useUpdateAdminPricingConfig(
  id?: string,
  options?: Omit<
    UseMutationOptions<
      PricingConfigResponse,
      unknown,
      PricingConfigUpdateRequest
    >,
    'mutationKey' | 'mutationFn'
  >,
) {
  const { currentSite } = useAuth();
  const queryClient = useQueryClient();
  const { genericSuccessToast } = useValidationToast();

  return useMutation({
    ...adminConfigUpdatePricingMutation(currentSite?.id, id),
    ...options,
    onSuccess: async (_data, _variables, _context) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: adminConfigPricingQuery(currentSite?.id).queryKey,
        }),
        queryClient.invalidateQueries({
          queryKey: adminConfigAllQuery(currentSite?.id).queryKey,
          type: 'inactive',
        }),
      ]);

      genericSuccessToast('Pricing config updated');
    },
  });
}
