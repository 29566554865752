import {
  Flex,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  chakra,
} from '@chakra-ui/react';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  type ColumnDef,
  type Header,
  type OnChangeFn,
  type Row,
  type SortingState,
  type TableOptions,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { memo } from 'react';

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  sorting?: SortingState;
  onSort?: OnChangeFn<SortingState>;
};

const DataTable = <Data extends object>({
  data,
  columns,
  sorting,
  onSort,
}: DataTableProps<Data>) => {
  if ((sorting && !onSort) || (!sorting && onSort)) {
    // dev mistake
    throw new Error('sorting and onSort must be set together');
  }

  const options: TableOptions<Data> = {
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {},
  };

  // only add sorting arguments if they are set, react-table treats undefined as a value
  if (sorting && onSort) {
    options.onSortingChange = onSort;
    options.manualSorting = true;
    options.state = options.state ? { ...options.state, sorting } : { sorting };
  }
  const table = useReactTable(options);

  const renderHeader = (header: Header<Data, unknown>) => {
    const meta = header.column.columnDef.meta;
    return (
      <Th
        key={header.id}
        onClick={header.column.getToggleSortingHandler()}
        isNumeric={meta?.isNumeric}
        fontFamily="sans-serif"
        cursor={header?.column.getCanSort() ? 'pointer' : 'default'}
        userSelect={'none'}
        whiteSpace={'nowrap'}
      >
        <Flex align="center" gap={4}>
          {flexRender(header.column.columnDef.header, header.getContext())}

          {header.column.getIsSorted() && (
            <chakra.span>
              {header.column.getIsSorted() === 'desc' ? (
                <FontAwesomeIcon icon={faSortDown} />
              ) : (
                <FontAwesomeIcon icon={faSortUp} />
              )}
            </chakra.span>
          )}
        </Flex>
      </Th>
    );
  };

  const renderRow = (row: Row<Data>) => {
    return (
      <Tr key={row.id}>
        {row.getVisibleCells().map((cell) => {
          const meta = cell.column.columnDef.meta;
          return (
            <Td key={cell.id} isNumeric={meta?.isNumeric}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          );
        })}
      </Tr>
    );
  };

  const renderFooter = (header: Header<Data, unknown>) => {
    return (
      <Td key={header.id}>
        {header.column.columnDef.footer
          ? flexRender(header.column.columnDef.footer, header.getContext())
          : null}
      </Td>
    );
  };
  // doesn't seem to be a built-in way to not render empty footer row
  const hasFooters = table
    .getFooterGroups()
    .some((group) =>
      group.headers.some((header) => header.column.columnDef.footer),
    );
  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => renderHeader(header))}
          </Tr>
        ))}
      </Thead>
      <Tbody>{table.getRowModel().rows.map((row) => renderRow(row))}</Tbody>
      {hasFooters ? (
        <Tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <Tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => renderFooter(header))}
            </Tr>
          ))}
        </Tfoot>
      ) : null}
    </Table>
  );
};

// DataTable is expensive to render causing input lag
const DataTableMemo = memo(DataTable) as typeof DataTable;
export default DataTableMemo;
