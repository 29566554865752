import { useCallback, useRef } from 'react';
import useIsMounted from './useIsMounted';

/**
 * Custom hook that provides a safe async handler.
 * Useful when you want to ignore async results when the component is unmounted or
 * no longer in a desired state. Useful in async event handlers
 *
 * @returns An object containing:
 *   - safeAsyncHandler: A safe async handler that wraps an async
 *     function so that it only returns the resolved value if the component
 *     is still mounted and cancel method hasn't been called. Returns undefined
 *     otherwise.
 *       -- takes optional onSuccess callback that will be called with the result if the promise
 *     hasn't been cancelled. Useful to distinguish if the resolved promise can undefined
 *   - cancelPromises: A function that cancels all ongoing promises initiated by the
 *     safeAsyncHandler.
 */
export const useSafeAsync = () => {
  const isMounted = useIsMounted();
  const cancelCounter = useRef(0);

  const safeAsyncHandler = useCallback(
    <T, A extends any[]>(
      asyncFn: (...args: A) => Promise<T>,
      onSuccess?: (result: T) => void,
    ) => {
      return async (...args: A): Promise<T | undefined> => {
        const currentPromiseResetCounter = cancelCounter.current;

        const result = await asyncFn(...args);
        if (
          isMounted() &&
          currentPromiseResetCounter === cancelCounter.current
        ) {
          onSuccess && onSuccess(result);
          return result;
        }
      };
    },
    [isMounted],
  );

  const cancelPromises = useCallback(() => {
    cancelCounter.current++;
  }, []);

  return {
    safeAsyncHandler,
    cancelPromises,
  };
};
