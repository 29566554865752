import type { PartEngineeringRequirementResponse } from '../responses';
import type { Nullable } from '../util';
import type {
  ConfiguredReqCostsInput,
  ReqCostsBasisInput,
} from './costs/requirement';
import { maybeParseObjVals } from './util';

export const makeEstReqInputs = (
  req: PartEngineeringRequirementResponse,
): Nullable<ConfiguredReqCostsInput> => {
  const baseVals = maybeParseObjVals(
    {
      basisQty: req.estQty,
      fixedQty: req.estFixedQty,
      matUnitCost: req.estUnitMatCost,
      matFixedCost: req.estMatFixedCost,
      matMinCost: req.minMtrlCost || 0,
      labUnitCost: req.estUnitLabCost,
      burUnitCost: req.estUnitBurCost,
      srvUnitCost: req.estUnitSrvCost,
      burPct: req.estBurPct,
      burPerUnit: req.estBurPerUnit,
      unitsPerPiece: req.unitsPerPiece,
    },
    { defaultVal: null },
  );

  return {
    ...baseVals,
    ...{
      usageUM: req.usageUM,
      unitsPerPieceType: req.unitsPerPieceType,
    },
  };
};

export const makeActReqInputs = (
  req: PartEngineeringRequirementResponse,
): Nullable<ConfiguredReqCostsInput & ReqCostsBasisInput> => {
  const baseVals = maybeParseObjVals(
    {
      basisQty: req.actQty,
      fixedQty: null,
      matFixedCost: null,
      unitMatCost: null, // TODO: might be able to deduce from act totals & qty but messed by unknown actual fixed, would be calcd after this though
      matUnitCost: null,
      matMinCost: null,
      labUnitCost: null,
      srvUnitCost: null,
      burUnitCost: null,
      burPct: null, // TODO:  might be able to deduce from act totals?
      burPerUnit: null,
      unitsPerPiece: null,
    },
    { defaultVal: null },
  );

  return {
    ...baseVals,
    ...{ usageUM: req.usageUM, unitsPerPieceType: req.unitsPerPieceType },
  };
};
