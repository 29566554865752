import { Text, type TextProps } from '@chakra-ui/react';

type FormSectionTitleProps = TextProps;

const FormSectionTitle: React.FC<FormSectionTitleProps> = ({
  children,
  ...rest
}) => {
  return (
    <Text as="h3" {...rest} fontWeight="bold">
      {children}
    </Text>
  );
};

export default FormSectionTitle;
