import {
  FormControl,
  InputGroup,
  type InputGroupProps,
  InputLeftElement,
  type InputLeftElementProps,
  InputRightElement,
  type InputRightElementProps,
  Tooltip,
} from '@chakra-ui/react';
import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';

import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { omit } from 'lodash-es';
import theme from '../../config/theme';
import { SimpleInput, type SimpleInputProps } from './SimpleInput';

export interface InputWithIconProps extends SimpleInputProps {
  leftIcon?: IconProp;
  leftIconProps?: FontAwesomeIconProps;
  rightIcon?: IconProp;
  rightIconProps?: FontAwesomeIconProps;
  errorIcon?: IconProp;
  errorIconProps?: FontAwesomeIconProps;
  groupProperties?: InputGroupProps;
  isInvalid?: boolean;
  errorMsg?: string;
}

/**
 * @param changeOnBlur - When true, `onChange` handler will be called prior to
 * the `onBlur` handler during blur events.
 * Note: Causes `onChange` to *not* be called for each input change
 * @param blurOnEnterKey - When true, the pressing the "Enter" key will trigger
 * the blur event while maintaining input focus. Works alongside changeOnBlur:true
 */
export const InputWithIcon = forwardRef<HTMLInputElement, InputWithIconProps>(
  (
    {
      leftIcon,
      leftIconProps,
      rightIcon,
      rightIconProps,
      errorIcon = faTriangleExclamation,
      errorIconProps,
      groupProperties,
      isInvalid,
      errorMsg,
      visibility,
      ...rest
    },
    ref,
  ) => {
    const leftInputProps: InputLeftElementProps | undefined =
      leftIcon &&
      ({
        pointerEvents: 'none',
        children: leftIcon ? (
          <FontAwesomeIcon icon={leftIcon} color="#ccc" {...leftIconProps} />
        ) : null,
      } as const);

    const rightInputProps: InputRightElementProps | undefined = rightIcon && {
      pointerEvents: 'none',
      children: rightIcon ? (
        <FontAwesomeIcon icon={rightIcon} color="#ccc" {...rightIconProps} />
      ) : null,
    };

    const errorIconElement = errorIcon ? (
      <FontAwesomeIcon
        icon={errorIcon}
        color={theme.colors.validation.error}
        // @NOTE(shawk): when passing the result of `getErrorPropsBase`, these extra
        // properties are included in the error props but are not valid DOM attrs
        {...omit(errorIconProps, [
          'userMessage',
          'status',
          'severity',
          'title',
        ])}
      />
    ) : null;

    return (
      <FormControl isInvalid={isInvalid} visibility={visibility}>
        <Tooltip
          label={errorMsg}
          hasArrow
          isDisabled={!isInvalid}
          marginTop={-3}
        >
          <InputGroup {...groupProperties}>
            {isInvalid ? (
              <InputLeftElement>{errorIconElement}</InputLeftElement>
            ) : (
              leftInputProps && <InputLeftElement {...leftInputProps} />
            )}
            {rightInputProps && <InputRightElement {...rightInputProps} />}
            <SimpleInput
              ref={ref}
              autoComplete="no-auto-complete"
              marginBottom={3}
              borderRadius={0}
              {...rest}
            />
          </InputGroup>
        </Tooltip>
      </FormControl>
    );
  },
);

InputWithIcon.displayName = 'InputWithIcon';
