import { Box } from '@chakra-ui/react';
import type { PermissionsReqInput } from '@lib/requests';
import { definedEntries } from '@lib/util';
import type { FC } from 'react';

export const DisplayPagePermsSuccess: FC<{ perms: PermissionsReqInput }> = ({
  perms,
}) => (
  <Box marginBottom={'3em'}>
    If you can see this you are authorized to see it with{' '}
    {definedEntries(perms)
      .map(
        ([resrc, actions]) =>
          `${resrc} (${[...actions].map((a) => a.toUpperCase()).join(', ')})`,
      )
      .join(', ')}{' '}
    permission(s).
  </Box>
);
