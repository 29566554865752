import { Image, Page, Text, View } from '@react-pdf/renderer';
import { useQuotePdfContext } from '@ui/pages/QuoteBuilder/hooks';
import type { FC } from 'react';
import { pdfStyles } from '../pdf-styles';
import { QuoteLink } from './components/QuoteLink';
import { CustomerQuotePdfTemplate } from './template';

export const CustomerQuote: FC = () => {
  const { quoteId } = useQuotePdfContext();

  return (
    <Page size="A4" style={pdfStyles.page}>
      <View style={pdfStyles.row}>
        <View style={[pdfStyles.section, pdfStyles.alignCenter]}>
          <Image
            src={'/images/RAF/RAF_ELECTRONIC_HARDWARE_RGB_PRIMARY_LOGO_AW.png'}
            // the default styles.logo is specialized for CAP
            // customize logo style to preserve logo aspect ratio and margins
            style={[pdfStyles.logo, { height: '25px' }]}
          />
        </View>
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.bold}>RAF Electronic Hardware</Text>
          <Text>95 Silvermine Road</Text>
          <Text>Seymour, CT 06483</Text>
          <Text>Phone (203) 888 - 2133</Text>
          <Text>Fax (203) 888 - 9860</Text>
        </View>
        <View
          style={[
            pdfStyles.section,
            pdfStyles.alignCenter,
            pdfStyles.maxWidthFull,
          ]}
        >
          <Text style={[pdfStyles.quoteNumber, pdfStyles.bold]}>
            Quotation {quoteId}
          </Text>
          <Text style={pdfStyles.textSmall}>
            Please refer to the quotation number when ordering
          </Text>
          <QuoteLink quoteId={quoteId} />
        </View>
      </View>
      <CustomerQuotePdfTemplate showQuantityOnHand={true} showDrawing={false} />
      <View style={pdfStyles.row}>
        <View style={pdfStyles.fullRow}>
          <Text style={[pdfStyles.bold, pdfStyles.textSmall]}>
            CONDITIONS OF SALE: 1. NO CONTRACT SHALL EXIST UNTIL SELLER RECEIVES
            ORDER FOR DELIVERY PLACED BY BUYER. 2. PRICES ARE SUBJECT TO CHANGE
            TO CONFORM TO PRICES PREVAILING AT TIME OF SHIPMENT. 3. PRICES ARE
            SUBJECT TO PRODUCTION RELEASES. WHERE REVISIONS RESULT IN HIGHER
            COSTS PRICES ARE SUBJECT TO CHANGE. 4. ALL PRICES ARE SUBJECT TO
            CHANGE TO CONFORM TO ALL STATE AND FEDERAL LAWS. 5. CHARGES MADE FOR
            TOOLS AND DIES DO NOT CONVEY THE RIGHT TO REMOVE THEM FROM OUR
            FACTORY. 6. IF ANY MATERIAL IS PROVED DEFECTIVE, SELLERS LIABILITY
            IS LIMITED TO REPLACEMENT ONLY AND SELLER WILL NOT PAY ANY CLAIM FOR
            LABOR ON OR FOR DAMAGE BY REASON OF ITS USE. 7. THE SELLER SHALL NOT
            BE LIABLE FOR INFRINGEMENT OF ANY PATENTS REQUIRED TO BE USED BY THE
            SPECIFICATIONS OF THE BUYER AND THE BUYER AGREES TO SAVE THE SELLER
            HARMLESS FOR ALL JUDGEMENTS, DECREES, COSTS AND OTHER LEGAL EXPENSES
            RESULTING FROM ANY SUITS AND AGREES TO DEFEND SELLER SHOULD SUCH A
            SUIT BE INSTITUTED. 8. GOODS SHALL BE INSPECTED WHEN RECEIVED AND
            THE SELLER IS NOT LIABLE FOR ANY DEFECTS UNLESS NOTIFIED WITHIN 30
            DAYS AFTER RECEIPT BY THE BUYER. 9. WE RESERVE THE RIGHT TO OVER AND
            UNDER SHIP BY ANY AMOUNT NOT TO EXCEED TEN PERCENT OF SPECIFIED
            QUANTITY. 10. ALL AGREEMENTS ARE CONTINGENT UPON STRIKES, ACCIDENTS
            AND OTHER DELAYS UNAVOIDABLE OR BEYOND OUR CONTROL.
          </Text>
          <Text>{'\n'}</Text>
          <Text>
            View Your Order Status & Tracking Online:{'\n'}
            For updates on your order status and tracking information, log in to
            your account on MWComponents.com using the same email address
            associated with your order. Navigate to My Account, then select
            Orders. If you do not already have an account, you can create one.
            Orders associated with the account email address will automatically
            populate.
          </Text>
          <Text>{'\n'}</Text>
          <Text>
            Quantity on hand is an estimate at the time of quote and will be
            validated when order is placed.
          </Text>
          <Text>{'\n'}</Text>
          <Text>
            Standard terms and conditions can be found on www.mwcomponents.com
            &lt;https://www.mwcomponents.com/mw-industries-terms-and-conditions&gt;
          </Text>
        </View>
      </View>
    </Page>
  );
};
