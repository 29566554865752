import { mapValues } from 'lodash-es';
import type { OrderLineResponse } from '../responses';
import type { ValidLastWin } from '../validation';
import { type CostCalculations, calcCosts } from './costs';
import { type MarginCalculations, calcMargins } from './margin';
import { maybeParseNum } from './util';

export type OrderLineCalculations = {
  margins: MarginCalculations;
  unitCosts: CostCalculations;
  totalCosts: CostCalculations;
  pricing: {
    unitPrice: number;
    totalPrice: number;
  };
  /** quantity used for calculations (invoiced w/backup order) */
  calcQuantity: number;
  orderQuantity: number;
  invoicedQuantity: number;
  leadTimeWeeks: number | null;
  leadTimeDays: number | null;
};

// export type CalcOrderLine
/**
 * Generates calculations for an order line response if all cost values are
 * present. Does not silently fail, consumers responsible for validation
 * deciding if they want to use calculations (i.e. suggested pricing)
 * @returns margin calculations with default 0 values for any that could not be calculated
 */
// TODO: change name to calcPartHistory or leave and make outer wrapper for it
// and separate for calcWorkOrder (cleaner)
export const calcOrderLine = (
  orderLine: OrderLineResponse | ValidLastWin,
): OrderLineCalculations => {
  const {
    totalLaborCost,
    totalBurdenCost,
    totalMaterialCost,
    totalServiceCost,
    unitPrice,
    orderQuantity,
    invoicedQuantity,
    createdAt,
    totalInvoicedAmount,
  } = orderLine;

  const calculations: OrderLineCalculations = {
    get margins(): MarginCalculations {
      return calcMargins(this.pricing.totalPrice, this.totalCosts);
    },
    get totalCosts(): CostCalculations {
      return calcCosts({
        laborCost: totalLaborCost,
        burdenCost: totalBurdenCost,
        materialCost: totalMaterialCost,
        serviceCost: totalServiceCost,
      });
    },
    get unitCosts(): CostCalculations {
      return mapValues(this.totalCosts, (cost) => cost / this.calcQuantity);
    },
    get pricing() {
      return {
        get unitPrice(): number {
          return Number.parseFloat(unitPrice);
        },
        get totalPrice(): number {
          return (
            maybeParseNum(totalInvoicedAmount) ??
            this.unitPrice * calculations.calcQuantity
          );
        },
      };
    },
    orderQuantity: Number(orderQuantity),
    invoicedQuantity: Number(invoicedQuantity),
    calcQuantity: Number(invoicedQuantity || orderQuantity),
    get leadTimeWeeks(): number | null {
      const { leadTime } = orderLine;
      return leadTime && Math.ceil(leadTime / 7);
    },
    get leadTimeDays(): number | null {
      return orderLine.leadTime;
    },
  };

  return calculations;
};
