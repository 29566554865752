import type {
  UserSettingsUpdateRequest,
  UserWithSettingsAndActiveSiteResponse,
  UserWithSettingsResponse,
} from '@lib';
import type { API } from './api';

export class UserService {
  constructor(private api: API) {}

  getUser = () => {
    return this.api.get<UserWithSettingsResponse>('/user');
  };

  saveUserProfile = (data: UserSettingsUpdateRequest) => {
    return this.api.put<UserWithSettingsResponse>('/user', data);
  };

  setActiveSite = (siteId: string) => {
    return this.api.put<UserWithSettingsAndActiveSiteResponse>(
      '/user/setActiveSite',
      { siteId },
    );
  };
}
