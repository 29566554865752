import { Box } from '@chakra-ui/react';
import { faIdCard } from '@fortawesome/free-solid-svg-icons';
import { useSalesRepWithEmployeeQuery } from '@ui/data/sales-rep';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormSectionTitle } from '../../components/Text';
import { InputWithIcon } from '../../components/form/InputWithIcon';
import { useLocalization } from '../../config/localization/getLocalization';
import type { QuoteBuilderForm } from './QuoteBuilder';

export const SalesRepInput = () => {
  const qb_i18n = useLocalization('QuoteBuilder');

  const { setValue, register } = useFormContext<QuoteBuilderForm>();

  const salesRepId = useWatch({ name: 'salesRepId' });

  const salesRep = useSalesRepWithEmployeeQuery(salesRepId);

  useEffect(() => {
    setValue('salesRepName', salesRep.data?.name ?? '');
    setValue('salesRepEmail', salesRep.data?.emailAddr ?? '');
    setValue('salesRepPhone', salesRep.data?.phone ?? '');
  }, [salesRep.data]);

  useEffect(() => {
    if (!salesRepId) {
      setValue('salesRepName', '');
      setValue('salesRepEmail', '');
      setValue('salesRepPhone', '');
    }
  }, [salesRepId]);

  return (
    <Box
      w={{
        base: '100%',
        md: '50%',
      }}
    >
      <FormSectionTitle marginBottom={3}>
        {qb_i18n('salesRepresentative')}
      </FormSectionTitle>
      <InputWithIcon
        leftIcon={faIdCard}
        placeholder="Sales Representative ID"
        {...register('salesRepId')}
      />
    </Box>
  );
};
