import type { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { CustomNavigationClient } from './auth/navigationClient';
import { WithPermission } from './components/Permission';
import ProtectedRoute from './components/ProtectedRoute';
import PageLayout from './components/layout/PageLayout';
import { RoutesConfig } from './config/routes';
import { Admin } from './pages/Admin';
import { ConfigsPanel } from './pages/Admin/Config/ConfigsPanel';
import { AdminMetrics } from './pages/Admin/Metrics';
import { AdminSiteIndex } from './pages/Admin/Site';
import { AdminUserIndex } from './pages/Admin/User';
import { LoggedOut } from './pages/LoggedOut';
import { NotFound } from './pages/NotFound';
import { Profile } from './pages/Profile';
import { QuoteFeedback } from './pages/QuoteFeedback';
import { QuoteNew } from './pages/QuoteNew';
import { QuoteQueue } from './pages/QuoteQueue';
import { QuoteShow } from './pages/QuoteShow';
import { QuoteView } from './pages/QuoteView';
import { UnderConstruction } from './pages/UnderConstruction';
import { AuthProvider } from './state/auth';
import { GlobalStateProvider } from './state/globalState';

type AppProps = {
  msalInstance: IPublicClientApplication;
};

export function App({ msalInstance }: AppProps) {
  // configure MSAL redirects between pages to use internal routing
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  msalInstance.setNavigationClient(navigationClient);

  const {
    quoteQueue,
    quoteNew,
    quoteView,
    quoteShow,
    quoteFeedback,
    profile,
    admin,
    customers,
    metrics,
    dashboard,
    adminConfig: adminQuoting,
    adminUsers,
    adminSites,
    adminMetrics,
  } = RoutesConfig;

  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <GlobalStateProvider>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <PageLayout />
                </ProtectedRoute>
              }
            >
              <Route
                path={dashboard.path}
                element={<UnderConstruction {...dashboard} />}
              />
              <Route index element={<Navigate to={quoteQueue.path} />} />
              <Route
                path={quoteQueue.path}
                element={
                  <WithPermission permissions={quoteQueue.permissions}>
                    <QuoteQueue />
                  </WithPermission>
                }
              />
              <Route path={quoteNew.path} element={<QuoteNew />} />
              <Route path={`${quoteShow.path}`} element={<QuoteShow />} />
              <Route path={`${quoteView.path}`} element={<QuoteView />} />
              <Route
                path={`${quoteFeedback.path}`}
                element={<QuoteFeedback />}
              />
              <Route
                path={metrics.path}
                element={
                  <WithPermission permissions={metrics.permissions}>
                    <UnderConstruction {...metrics} />
                  </WithPermission>
                }
              />
              <Route
                path={customers.path}
                element={
                  <WithPermission permissions={customers.permissions} page>
                    <UnderConstruction {...customers} />
                  </WithPermission>
                }
              />
              <Route
                path={admin.path}
                element={
                  <WithPermission permissions={admin.permissions} page>
                    <Admin />
                  </WithPermission>
                }
              >
                <Route
                  path={adminQuoting.path}
                  element={
                    <WithPermission permissions={adminQuoting.permissions} page>
                      <ConfigsPanel />
                    </WithPermission>
                  }
                />
                <Route
                  path={adminUsers.path}
                  element={
                    <WithPermission permissions={adminUsers.permissions} page>
                      <AdminUserIndex />
                    </WithPermission>
                  }
                />
                <Route
                  path={adminSites.path}
                  element={
                    <WithPermission permissions={adminSites.permissions} page>
                      <AdminSiteIndex />
                    </WithPermission>
                  }
                />
                <Route
                  path={adminMetrics.path}
                  element={
                    <WithPermission permissions={adminMetrics.permissions} page>
                      <AdminMetrics />
                    </WithPermission>
                  }
                />
              </Route>
              <Route path={profile.path} element={<Profile />} />
            </Route>
            <Route path="/auth/redirect" element={null} />
            <Route path="/logout/redirect" element={<LoggedOut />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </GlobalStateProvider>
      </AuthProvider>
    </MsalProvider>
  );
}
