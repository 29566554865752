import { Box, Link } from '@chakra-ui/react';
import type { UserWithSettingsResponse } from '@lib/responses';
import is from '@sindresorhus/is';
import {
  type SortingState,
  type Updater,
  createColumnHelper,
} from '@tanstack/react-table';
import { BasicPagination } from '@ui/components';
import DataTable from '@ui/components/DataTable';
import { QueryError, QueryLoading, QueryNoResults } from '@ui/components/query';
import { useAdminUsersSearch } from '@ui/data/admin/user';
import { useGlobalState, useSortingURLState } from '@ui/hooks';
import { usePaginationURLState } from '@ui/hooks/pagination';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserForm } from './UserForm';

export const UserTable = () => {
  const { setActiveModal } = useGlobalState();
  const [searchParams] = useSearchParams();
  const [paginationState, paginator] = usePaginationURLState();
  const [sortingState, sorter] = useSortingURLState();

  const query = searchParams.get('q') ?? '';

  const adminUsersQuery = useAdminUsersSearch(
    query,
    paginationState,
    sortingState,
  );

  const handleUserSelect = useCallback(
    (user: UserWithSettingsResponse) => {
      setActiveModal({
        size: 'xl',
        title: 'Edit User',
        children: <UserForm user={user} />,
      });
    },
    [setActiveModal],
  );

  const handleSort = useCallback(
    (updaterOrValue: Updater<SortingState>) => {
      const sort = is.function(updaterOrValue)
        ? updaterOrValue(sortingState)
        : updaterOrValue;

      sorter.setSorting(sort);
    },
    [sortingState, sorter],
  );

  const columns = useMemo(() => {
    const ch = createColumnHelper<UserWithSettingsResponse>();
    const columnDef = [
      ch.accessor('displayName', {
        header: 'Name',
        cell: (props) => {
          return (
            <Link
              fontWeight="bold"
              textDecoration="underline"
              onClick={() => handleUserSelect(props.row.original)}
            >
              {props.getValue()}
            </Link>
          );
        },
      }),
      ch.accessor('roles', {
        header: 'Roles',
        enableSorting: false,
        // @TODO(shawk): display roles?
        cell: (_props) => {},
      }),
      ch.accessor('email', {
        header: 'Email',
        cell: (props) => props.getValue(),
      }),
      ch.accessor((user) => user.userSettings?.phoneNumber ?? '', {
        header: 'Phone Number',
        enableSorting: false,
        cell: (props) => props.getValue(),
      }),
      ch.accessor((user) => user.userSettings?.mobilePhoneNumber ?? '', {
        header: 'Mobile Number',
        enableSorting: false,
        cell: (props) => props.getValue(),
      }),
      ch.accessor((user) => user.userSettings?.signatureTitle ?? '', {
        header: 'Title',
        enableSorting: false,
        cell: (props) => props.getValue(),
      }),
    ];

    return columnDef;
  }, [handleUserSelect]);

  if (adminUsersQuery.isLoading) {
    return <QueryLoading title="Loading users..." />;
  }

  if (adminUsersQuery.isError) {
    return (
      <QueryError
        title="Error loading users!"
        error={adminUsersQuery.error}
        onRetry={() => adminUsersQuery.refetch()}
        isRetrying={adminUsersQuery.isFetching}
      />
    );
  }

  if (adminUsersQuery.isSuccess) {
    return (
      <>
        <Box
          transition="opacity 100ms linear"
          opacity={adminUsersQuery.isFetching ? 0.5 : 1}
        >
          {adminUsersQuery.data.data.length > 0 ? (
            <DataTable
              columns={columns}
              data={adminUsersQuery.data.data}
              sorting={sortingState}
              onSort={handleSort}
            />
          ) : (
            <QueryNoResults
              title="No Users found"
              description="Try entering a different search term."
            />
          )}
        </Box>

        <BasicPagination
          metadata={adminUsersQuery.data.pagination}
          paginator={paginator}
        />
      </>
    );
  }

  return null;
};
