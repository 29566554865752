import type { $, O } from 'hotscript';
import { pick } from 'lodash-es';
import type { CostingConfigResponse } from '../../responses';
import { invert } from '../../util/invert';
import type { Simplify } from '../../util/types';
import type {
  ConfiguredOpCostsInput,
  OpCostsBasisInput,
  OpCostsInput,
} from './operation';
import type { ReqCostsBasisInput, ReqCostsInput } from './requirement';

export type CostingConfigSources = Simplify<
  CostingConfigOpSources & CostingConfigReqSources
>;
export type CostingConfigOpSources = Pick<
  CostingConfigResponse,
  CostingConfigOpSrcKeys
>;
export type CostingConfigReqSources = Pick<
  CostingConfigResponse,
  CostingConfigReqSrcKeys
>;

const costingOpConfigSrcKeys = [
  'runHrsSrc',
  'opBasisQtySrc',
  'setupHrsSrc',
  'runRateSrc',
  'labRunCostPerHrSrc',
  'labRunCostPerUnitSrc',
  'labSetupCostPerHrSrc',
  'burRunCostPerHrSrc',
  'burRunCostPerUnitSrc',
  'burSetupCostPerHrSrc',
  'burCostPerOpSrc',
  'srvChargePerUnitSrc',
  'srvBaseChargeSrc',
  'srvMinChargeSrc',
  'serviceIdSrc',
  'scrapFixedUnitsSrc',
  'scrapYieldPercentSrc',
] as const satisfies readonly (keyof CostingConfigResponse)[];
type CostingConfigOpSrcKeys = (typeof costingOpConfigSrcKeys)[number];

const opCostBasisKeys = [
  'basisRunHrs',
  'basisQty',
  //   'basisUnitIncrRatio',
] as const satisfies readonly (keyof OpCostsBasisInput)[];
type OpCostBasisKeys = (typeof opCostBasisKeys)[number];

const costingReqConfigSrcKeys = [
  'matFixedCostSrc',
  'matMinCostSrc',
  'matUnitCostSrc',
  'reqBurPctSrc',
  'reqBurPerUnitSrc',
  'reqFixedQtySrc',
  'reqLabUnitCostSrc',
  'reqSrvUnitCostSrc',
  'reqBurUnitCostSrc',
  'reqBasisQtySrc',
  'unitsPerPieceSrc',
] as const satisfies readonly (keyof CostingConfigResponse)[];
type CostingConfigReqSrcKeys = (typeof costingReqConfigSrcKeys)[number];

// TODO: these will be needed to better estimate scaled mtrl costs
const reqCostBasisKeys = [
  'usageUM',
  'unitsPerPieceType',
] as const satisfies readonly (keyof ReqCostsBasisInput)[];
export type ReqCostBasisKeys = (typeof reqCostBasisKeys)[number];

//TODO: flip this and config costs as key so we are dependent on a stable base,
//can invert if needed to match pick util shape
export type CostingConfigToOpInputMap = $<
  O.Record<CostingConfigOpSrcKeys>,
  keyof ConfiguredOpCostsInput
>;
export const costingConfigToOpInputMap = {
  runHrsSrc: 'basisRunHrs',
  opBasisQtySrc: 'basisQty',
  setupHrsSrc: 'setupHrs',
  runRateSrc: 'runRate',
  labRunCostPerHrSrc: 'labRunCostPerHr',
  labRunCostPerUnitSrc: 'labRunCostPerUnit',
  labSetupCostPerHrSrc: 'labSetupCostPerHr',
  burRunCostPerHrSrc: 'burRunCostPerHr',
  burRunCostPerUnitSrc: 'burRunCostPerUnit',
  burSetupCostPerHrSrc: 'burSetupCostPerHr',
  burCostPerOpSrc: 'burCostPerOperation',
  scrapFixedUnitsSrc: 'scrapFixedUnits',
  scrapYieldPercentSrc: 'scrapYieldPercent',
  srvChargePerUnitSrc: 'srvChargePerUnit',
  srvBaseChargeSrc: 'srvBaseCharge',
  srvMinChargeSrc: 'srvMinCharge',
  serviceIdSrc: 'serviceId',
} as const satisfies CostingConfigToOpInputMap;

export type OpCostsInputToCostingConfigMap = $<
  O.Record<keyof Omit<OpCostsInput, 'requirements'>>,
  CostingConfigOpSrcKeys
>;
export const opCostsInputToCostingConfigMap = invert(costingConfigToOpInputMap);

export type CostingConfigToReqInputMap = $<
  O.Record<CostingConfigReqSrcKeys>,
  keyof ReqCostsInput
>;
export const costingConfigToReqInputMap = {
  matFixedCostSrc: 'matFixedCost',
  matMinCostSrc: 'matMinCost',
  matUnitCostSrc: 'matUnitCost',
  reqSrvUnitCostSrc: 'srvUnitCost',
  reqLabUnitCostSrc: 'labUnitCost',
  reqBurUnitCostSrc: 'burUnitCost',
  reqBurPctSrc: 'burPct',
  reqBurPerUnitSrc: 'burPerUnit',
  reqFixedQtySrc: 'fixedQty',
  reqBasisQtySrc: 'basisQty',
  unitsPerPieceSrc: 'unitsPerPiece',
} as const satisfies CostingConfigToReqInputMap;

export type ReqCostsInputToCostingConfigMap = $<
  O.Record<keyof Omit<ReqCostsInput, 'subEng'>>,
  CostingConfigReqSrcKeys
>;
export const reqCostsInputToCostingConfigMap = invert(
  costingConfigToReqInputMap,
);

type SplitCostingConfig = Simplify<{
  costingOpConfig: CostingConfigOpSources;
  costingReqConfig: CostingConfigReqSources;
}>;
/**
 *
 * @param config costing config
 * @returns
 * split on op and req of picked src fields from costing config
 */
export const getSplitCostingConfig = (
  config: CostingConfigResponse,
): SplitCostingConfig => ({
  costingOpConfig: getCostingOpConfig(config),
  costingReqConfig: getCostingReqConfig(config),
});

/**
 *
 * @param config costing config
 * @returns pick of op src fields from costing config
 */
export const getCostingOpConfig = (
  config: CostingConfigResponse,
): CostingConfigOpSources => pick(config, costingOpConfigSrcKeys);

/**
 *
 * @param config costing config
 * @returns
 * pick of req src fields from costing config
 */
export const getCostingReqConfig = (
  config: CostingConfigResponse,
): CostingConfigReqSources => pick(config, costingReqConfigSrcKeys);
