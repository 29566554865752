import { CloseIcon } from '@ui/components/Icons';
import { QuoteLineActionsItem } from '@ui/components/QuoteLine';

interface DeleteActionProps {
  handleDelete: () => void;
}

/**
 * Deletes an active line item
 */
export const DeleteAction: React.FC<DeleteActionProps> = ({ handleDelete }) => {
  return (
    <QuoteLineActionsItem
      label="Delete Line Item"
      icon={<CloseIcon color="mw.darkRed" />}
      onClick={handleDelete}
    />
  );
};
