import {
  Box,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import type { ResolvedOpCostsInput } from '@lib';
import { type ChangeEvent, type FC, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  CheckboxMenu,
  type CheckboxMenuOption,
} from '../../../../components/form/CheckboxMenu';
import type { QuoteBuilderForm } from '../../QuoteBuilder';
import { styles } from '../styles';
import { CostCell, StaticCell } from './Cells';
import { Requirements } from './Requirements';
import type { CostFieldOptionType } from './types';

export const CostTableColumns: CostFieldOptionType<ResolvedOpCostsInput>[] = [
  {
    editable: false,
    enabled: true,
    varName: 'sequenceNum',
    filterable: false,
    title: 'Seq No.',
    type: 'number',
  },
  {
    editable: false,
    enabled: true,
    varName: 'resourceId',
    filterable: false,
    title: 'Resource ID',
    type: 'text',
  },
  {
    editable: false,
    enabled: true,
    varName: 'calcQty',
    filterable: false,
    title: 'Qty',
    type: 'number',
  },
  {
    editable: true,
    enabled: false,
    varName: 'qtyPerLineQty',
    filterable: false,
    title: 'Qty/Line Qty',
    type: 'number',
  },
  {
    editable: false,
    enabled: true,
    varName: 'calcRunHrs',
    filterable: true,
    title: 'Run Hrs',
    type: 'number',
  },
  {
    editable: true,
    enabled: true,
    varName: 'runHrsPerLineQty',
    filterable: true,
    title: 'Run Hrs/Line Qty',
    type: 'number',
  },
  {
    editable: true,
    enabled: true,
    varName: 'qtyPerRunHr',
    filterable: true,
    title: 'Run Qty/Hr',
    type: 'number',
  },
  {
    editable: true,
    enabled: true,
    varName: 'runRate',
    filterable: true,
    title: 'Run Rate',
    type: 'number',
  },
  {
    editable: false,
    enabled: true,
    varName: 'runRateType',
    filterable: true,
    title: 'Run Rate Type',
    type: 'number',
  },
  {
    editable: true,
    enabled: true,
    varName: 'setupHrs',
    filterable: true,
    title: 'Setup Hours',
    type: 'number',
  },
  {
    editable: true,
    enabled: true,
    varName: 'labRunCostPerHr',
    filterable: true,
    title: 'Labor Run Cost/Hr',
    type: 'number',
  },
  {
    editable: true,
    enabled: true,
    varName: 'labRunCostPerUnit',
    filterable: true,
    title: 'Labor Run Cost/Unit',
    type: 'number',
  },
  {
    editable: true,
    enabled: true,
    varName: 'labSetupCostPerHr',
    filterable: true,
    title: 'Labor Setup Cost/Hr',
    type: 'number',
  },
  {
    editable: true,
    enabled: true,
    varName: 'burRunCostPerHr',
    filterable: true,
    title: 'Burden Run Cost/Hr',
    type: 'number',
  },
  {
    editable: true,
    enabled: true,
    varName: 'burRunCostPerUnit',
    filterable: true,
    title: 'Burden Run Cost/Unit',
    type: 'number',
  },
  {
    editable: true,
    enabled: true,
    varName: 'burSetupCostPerHr',
    filterable: true,
    title: 'Burden Setup Cost/Hr',
    type: 'number',
  },
  {
    editable: true,
    enabled: true,
    varName: 'burCostPerOperation',
    filterable: true,
    title: 'Burden Cost/Op',
    type: 'number',
  },
  {
    editable: true,
    enabled: true,
    varName: 'srvBaseCharge',
    filterable: true,
    title: 'Base Service Charge',
    type: 'number',
  },
  {
    editable: true,
    enabled: true,
    varName: 'srvMinCharge',
    filterable: true,
    title: 'Min Service Charge',
    type: 'number',
  },
  {
    editable: true,
    enabled: true,
    varName: 'srvChargePerUnit',
    filterable: true,
    title: 'Service Charge/Unit',
    type: 'number',
  },
  {
    editable: true,
    enabled: true,
    varName: 'scrapFixedUnits',
    filterable: true,
    title: 'Scrap - Fixed Units',
    type: 'number',
  },
  {
    editable: false,
    enabled: true,
    varName: 'scrapYieldType',
    filterable: true,
    title: 'Scrap/Yield',
    type: 'text',
  },
  {
    editable: true,
    enabled: true,
    varName: 'scrapYieldPercent',
    filterable: true,
    title: 'Scrap/Yield %',
    type: 'number',
  },
  {
    editable: false,
    enabled: true,
    varName: 'calcStartQty',
    filterable: false,
    title: 'Start Qty',
    type: 'number',
  },
];

type CostRowProps = {
  filters: CheckboxMenuOption[];
  lineIndex: number;
  costIndex: number;
  showSources: boolean;
};

export const CostRow: FC<CostRowProps> = ({
  filters,
  lineIndex,
  costIndex,
  showSources = false,
}) => {
  const { getValues, register } = useFormContext<QuoteBuilderForm>();

  const path = `lineItems.${lineIndex}.operationCosts.${costIndex}` as const;

  const enabledColumns = CostTableColumns.filter((column) => {
    const filter = filters.find((f) => f.varName === column.varName);

    return filter?.enabled;
  });

  const hasRequirements = getValues(path).requirements.length > 0;

  return (
    <>
      <Tr>
        {enabledColumns.map((column) => {
          // This line is required to avoid a type error on
          // StaticCell's path property.
          if (column.varName === 'requirements') {
            return null;
          }

          if (column.editable) {
            return (
              <CostCell
                key={column.varName}
                {...register(`${path}.${column.varName}`, {
                  valueAsNumber: column.type === 'number',
                })}
                type={column.type}
                showSources={showSources}
              />
            );
          }

          return (
            <StaticCell
              key={column.varName}
              path={`${path}.${column.varName}`}
            />
          );
        })}
      </Tr>

      {hasRequirements && (
        <Tr>
          <Td colSpan={enabledColumns.length}>
            <Requirements
              lineIndex={lineIndex}
              costIndex={costIndex}
              showSources={showSources}
            />
          </Td>
        </Tr>
      )}
    </>
  );
};

type CostEstimatorProps = {
  lineIndex: number;
};

export const CostEstimator: FC<CostEstimatorProps> = ({ lineIndex }) => {
  const defaultFilters: CheckboxMenuOption[] = CostTableColumns.map(
    (column) => {
      return {
        title: column.title,
        varName: column.varName,
        editable: column.editable,
        filterable: column.filterable,
        enabled: true, // can be a user setting
        nested: column.nested,
        handleChange: (
          event: ChangeEvent<HTMLInputElement>,
          varName: string,
        ) => {
          const updatedFilters = filters.map((filter) => {
            if (filter.varName === varName) {
              filter.enabled = event.target.checked;
            }

            return filter;
          });

          setFilters(updatedFilters);
        },
      };
    },
  );

  const [showSources] = useState(false);
  const [filters, setFilters] = useState<CheckboxMenuOption[]>(defaultFilters);
  const { control } = useFormContext<QuoteBuilderForm>();

  const { fields: pickedCostInputs } = useFieldArray({
    name: `lineItems.${lineIndex}.operationCosts`,
    control,
  });

  return (
    <Box width="100%" overflow="hidden">
      <HStack justifyContent={'flex-end'}>
        {/* <Checkbox
          isChecked={showSources}
          onChange={(e) => setShowSources(e.target.checked)}
        >
          Show sources
        </Checkbox> */}
        <VStack flexShrink={1} justify="flex-start" alignItems="flex-end">
          {/* <Alert status="info">
             <AlertIcon />
             Use the icons next to fields to review previous work order,
             engineering master, or enter a customer cost to be used in calculating
             unit prices for different quantities.
           </Alert> */}
          <CheckboxMenu
            buttonName="Filter Fields"
            buttonIcon={faChevronDown}
            buttonProps={{
              background: 'mw.lightGrey',
              _hover: { bg: 'mw.darkGrey', color: 'mw.white' },
              _expanded: { bg: 'mw.darkGrey', color: 'mw.white' },
            }}
            menuOptions={filters}
          />
        </VStack>
      </HStack>
      <TableContainer>
        <Table sx={styles} size="sm">
          <Thead>
            <Tr>
              {filters
                .filter((f) => f.enabled)
                .map((f) => (
                  <Th key={`cost-estimator.${String(f.varName)}`}>{f.title}</Th>
                ))}
            </Tr>
          </Thead>
          <Tbody>
            {pickedCostInputs?.map((opCostsInput, index) => {
              return (
                <CostRow
                  filters={filters}
                  showSources={showSources}
                  lineIndex={lineIndex}
                  costIndex={index}
                  key={opCostsInput.id}
                />
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
