import type { Inventory } from '@lib/models/inventory';
import type {
  SiteWithConfigResponse,
  UserWithSettingsResponse,
} from '@lib/responses';
import type { ValidatedPartHistoryResponse } from '@lib/validation';
import { type FC, type PropsWithChildren, createContext } from 'react';
import type {
  QuoteBuilderForm,
  QuoteFormLine,
} from '../QuoteBuilder/QuoteBuilder';
import type { QuotePdfDocumentProps } from '../QuoteBuilder/QuotePdfDocument';

interface QuotePdfContextValue {
  user: UserWithSettingsResponse;
  currentSite: SiteWithConfigResponse;
  /** READABLE quote.quoteId */
  quoteId: string;
  quoteForm: QuoteBuilderForm;
  getLineForm: (lineIndex: number) => QuoteFormLine;
  getPartHistory: (
    partId: string,
  ) => ValidatedPartHistoryResponse | null | undefined;
  getInventory: (lineIndex: number) => Inventory | null | undefined;
}

export const QuotePdfContext = createContext<QuotePdfContextValue | undefined>(
  undefined,
);

export const QuotePdfProvider: FC<PropsWithChildren<QuotePdfDocumentProps>> = ({
  user,
  currentSite,
  linePropMap,
  quoteForm,
  children,
}) => {
  const value: QuotePdfContextValue = {
    currentSite,
    user,
    quoteForm,
    quoteId: quoteForm.quoteId,
    getLineForm: (lineIndex: number) => {
      return quoteForm.lineItems[lineIndex];
    },
    getPartHistory: (partId: string) => {
      return linePropMap[partId]?.partHistory;
    },
    getInventory: (lineIndex: number) => {
      return quoteForm.lineItems[lineIndex].inventory;
    },
  };

  return (
    <QuotePdfContext.Provider value={value}>
      {children}
    </QuotePdfContext.Provider>
  );
};
