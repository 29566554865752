import {
  type AuthenticationResult,
  type EventMessage,
  EventType,
} from '@azure/msal-browser';
import * as Sentry from '@sentry/react';
import type { ScopeContext, User } from '@sentry/types';
import { msalInstance } from '@ui/config/authConfig';
import { EnvConfig } from '@ui/config/env';

type ExceptionCtx = Partial<ScopeContext>;

let initialized = false;

export const ErrorMonitoring = {
  /**
   * Initialize the Sentry client
   */
  init() {
    if (initialized) {
      return;
    }

    const sentryDsn = EnvConfig.getKey('SENTRY_DSN');

    if (!sentryDsn) {
      console.warn('Sentry DSN not found. Error monitoring is disabled.');
      return;
    }

    Sentry.init({
      dsn: sentryDsn,
      debug: EnvConfig.getKey('SENTRY_DEBUG'),
      integrations: [
        Sentry.extraErrorDataIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      environment: EnvConfig.getEnv(),
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/mwquickquote\.com/,
        /^https:\/\/qq-ui\.dev\.mwcdev\.io/,
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    this.subscribeToAuthEvents();

    initialized = true;
  },

  /**
   * Subscribe to MSAL auth events to capture user information
   */
  subscribeToAuthEvents() {
    msalInstance.addEventCallback((event: EventMessage) => {
      if (
        event.eventType === EventType.ACCOUNT_REMOVED ||
        event.eventType === EventType.LOGOUT_SUCCESS
      ) {
        ErrorMonitoring.clearUser();
      }

      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload
      ) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;

        if (account) {
          ErrorMonitoring.setUser({
            username: account.name,
            email: account.username,
            id: payload.uniqueId,
          });
        }
      }
    });
  },

  /**
   * Manually capture an error and send it to Sentry
   */
  captureException(error: Error, context?: ExceptionCtx) {
    Sentry.captureException(error, context);
  },

  /**
   * Send an arbitrary message to Sentry
   */
  captureMessage(message: string, context?: ExceptionCtx) {
    Sentry.captureMessage(message, context);
  },

  /**
   * Set the user context for Sentry
   */
  setUser(user: User) {
    Sentry.setUser(user);
  },

  /**
   * Unset the user context for Sentry
   */
  clearUser() {
    Sentry.setUser(null);
  },
};
