import {
  Button,
  Flex,
  List,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { MAX_FILE_SIZE } from '@lib/consts';
import is from '@sindresorhus/is';
import { useIsMutating } from '@tanstack/react-query';
import { FileIcon } from '@ui/components/Icons';
import { QuoteLineActionsItem } from '@ui/components/QuoteLine';
import { SimpleFileUpload } from '@ui/components/SimpleFileUpload';
import { useDeleteDocumentMutation } from '@ui/data/document/delete-document';
import { useUploadQuoteLineDocument } from '@ui/data/quote/quote-line-document';
import type { QuoteBuilderForm } from '@ui/pages/QuoteBuilder/QuoteBuilder';
import { useValidationToast } from '@ui/pages/QuoteBuilder/hooks/useToast';
import type { LinePathType } from '@ui/pages/QuoteBuilder/types';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { LineItemDocument } from './LineItemDocument';

interface DocumentsModalProps {
  linePath: LinePathType;
  partId: string;
  quoteId: string;
}

export const DocumentsModal: React.FC<DocumentsModalProps> = ({
  linePath,
  partId,
  quoteId,
}) => {
  const { genericErrorToast } = useValidationToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { control, setValue } = useFormContext<QuoteBuilderForm>();
  const {
    fields: arrayDocuments,
    remove,
    append,
  } = useFieldArray({
    name: `${linePath}.documents`,
    control,
  });
  const watchedDocuments = useWatch({ name: `${linePath}.documents`, control });
  const documents = arrayDocuments.map((field, index) => {
    return {
      ...field,
      ...watchedDocuments[index],
    };
  });
  const lineItemId = useWatch({ name: `${linePath}.id`, control });

  const uploadQuoteLineDocument = useUploadQuoteLineDocument(
    quoteId,
    lineItemId ?? 'unknown',
  );

  const deleteDocument = useDeleteDocumentMutation();

  const isMutating = useIsMutating();
  const uploadDisabled =
    isMutating > 0 && documents.some((document) => document.uploadFailed);

  // biome-ignore lint/correctness/useExhaustiveDependencies: remove and files unnecessary dependencies
  useEffect(() => {
    if (isMutating === 0) {
      remove(
        documents
          .map((document, index) => {
            if (document.uploadFailed) {
              return index;
            }
          })
          .filter(is.number),
      );
    }
  }, [isMutating]);

  const uploadFile = async (document: File, index: number) => {
    // https://tanstack.com/query/latest/docs/framework/react/guides/mutations#consecutive-mutations
    // onSuccess and onError callbacks are only fired once, so we are using
    // the mutateAsync functionality to handle updating the field state
    try {
      const upload = await uploadQuoteLineDocument.mutateAsync(document);
      setValue(`${linePath}.documents.${index}`, upload);
    } catch (error) {
      // Instead of removing the file and potentially causing mismatches between
      // expected indices in uploadFile and handleFileChange, we set them to
      // uploadFailed: true and prevent them from being rendered
      setValue(`${linePath}.documents.${index}.uploadFailed`, true);

      genericErrorToast(`Failed to upload ${name}`, `${error}`);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const uploadingFiles = e.target.files ? [...e.target.files] : [];

      for (const i in uploadingFiles) {
        const file = uploadingFiles[i];

        if (file.size > MAX_FILE_SIZE) {
          return genericErrorToast(
            `${file.name} is too large`,
            'File uploads cannot exceed 10 MB.',
          );
        }

        const index = documents.length + +i;

        append({ fileName: file.name, fileType: file.type });

        uploadFile(file, index);
      }
    }
  };

  const handleFileDelete = async (index: number, id: string) => {
    try {
      setValue(`${linePath}.documents.${index}.deleting`, true);
      await deleteDocument.mutateAsync(id);
      remove(index);
    } catch (error) {
      setValue(`${linePath}.documents.${index}.deleting`, false);
      genericErrorToast(`Failed to delete ${name}`, `${error}`);
    }
  };

  return (
    <>
      <QuoteLineActionsItem
        label="Manage Attachments"
        onClick={onOpen}
        icon={
          <FileIcon badge={{ color: 'yellow', show: !!documents.length }} />
        }
      />

      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`Manage Attachments for ${partId}`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <List spacing={3}>
              {documents.map((document, index) => {
                return (
                  <LineItemDocument
                    key={document.id}
                    document={document}
                    onDelete={() => handleFileDelete(index, document.id)}
                  />
                );
              })}
            </List>
          </ModalBody>
          <ModalFooter justifyContent="space-between" marginTop={2}>
            <Text>Max file size: 10MB</Text>
            <Flex gap={4}>
              <SimpleFileUpload
                onChange={handleFileChange}
                disabled={uploadDisabled}
              />
              <Button borderRadius="0" onClick={onClose} variant="ghost">
                Close
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
