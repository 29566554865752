import { Box, Center, VStack } from '@chakra-ui/react';
import type React from 'react';
import { LoginButton } from '../components/LoginButton';

export const LoggedOut: React.FC = () => {
  return (
    <Center h="100vh">
      <VStack>
        <Box>You have been logged out</Box>
        <LoginButton></LoginButton>
      </VStack>
    </Center>
  );
};
