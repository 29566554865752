import { z } from 'zod';

export const costBucketsSchema = z.object({
  laborCost: z.number(),
  materialCost: z.number(),
  burdenCost: z.number(),
  serviceCost: z.number(),
});

export const costCalculationsSchema = z
  .object({
    /** Total combined variable costs */
    contributionCost: z.number(),
    /** Total combined costs */
    grossCost: z.number(),
  })
  .merge(costBucketsSchema);
