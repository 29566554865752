import { ScrapYieldType } from '@prisma/client';
import is from '@sindresorhus/is';
import { Decimal } from 'decimal.js';
import { sortedIndexBy } from 'lodash-es';
import type { Simplify } from 'type-fest';
import { type IsTuple, type Nullable, hasElements } from '../util';

export type RoundPrecision = {
  min: number;
  small: number;
  max?: number;
};
/**
 * Rounds a number based on precision
 * @param value - The number to be rounded.
 * @ param precision - precision params
 * @param precision.min - The number of decimal places to round to if
 * value is >= 1
 * @param precision.small - The number of significant digits to round
 * to if value < 1
 * @param precision.max - [Optional] The maximum number of decimal places if
 * value < 1. Can prevent excessively small numbers close to 0.
 * @returns The rounded number
 * @example
 * round(1234.679, {min:2, small: 4}); // returns 1234.68
 * round(0.012346, {min:2, small: 4}); // returns .01235
 * round(0.012346, {min:2, small: 2}); // returns .012
 * round(-1234.567, {min:2, small: 4})); // returns 1234.57
 * round(-0.12345, {min:2, small: 4})); // returns -0.1235
 * round(0.00012345, {min:2, small: 4, max: 4})); // returns 0.0001
 * round(0.000000001, {min:2, small: 4, max: 4})); // returns 0
 */
export const round = (
  value: number,
  { min, small, max }: RoundPrecision,
): number => {
  if (min < 0 || small < 0) {
    throw new Error(
      'minimum decimals and precision must be a non-negative integer.',
    );
  }

  let val = value;

  if (Math.abs(val) >= 1) {
    const minFactor = 10 ** min;
    return Math.round(val * minFactor) / minFactor;
  }
  // if max is provided, pre-round to max decimal places
  if (!is.undefined(max)) {
    const maxFactor = 10 ** small;
    val = Math.round(val * maxFactor) / maxFactor;
  }
  return Number.parseFloat(val.toPrecision(small));
};

/**
 *  Rounds a number to a string based on precision to maintain trailing zeros
 *
 * @param emptyOnInfinite - [Optional] When true, returns an empty string if the
 * value is +-Infinity. If false, Infiinity and -Infinity (valid numbers) will
 * return string representations 'Infinity' & '-Infinite (Default: true)
 */
export function roundToString(
  value: number,
  { min, small }: RoundPrecision,
  emptyOnInfinite?: true,
): `${number}` | '';
export function roundToString(
  value: number,
  { min, small }: RoundPrecision,
  emptyOnInfinite: false,
): `${number}`;
export function roundToString(
  value: number,
  { min, small }: RoundPrecision,
  emptyOnInfinite = true,
): `${number}` | '' {
  const isBig = Math.abs(value) >= 1;
  const rounded = round(value, { min, small });

  if (emptyOnInfinite && is.infinite(rounded)) {
    return '';
  }

  return isBig
    ? (rounded.toFixed(min) as `${number}`)
    : (rounded.toPrecision(small) as `${number}`);
}

/**
 * Maps an input number to the index of a range.
 * Analogous to a finding the appropriate row/column in a spreadsheet.
 *
 * @param input
 * @param rangeBreaks Ranges represented as a **sorted** array of break points.
 * Can be sorted in ascending or descending order. Assumed that the first break
 * point would include all previous values (less if ascending, greater if descending)
 * @param inclusiveBounds - Whether or not the value should be included in the break
 * index if they are equal. If false, it would be included in the next break.
 * Default is true.
 * @returns The index of the range that the input falls into, or the array length
 * if the input is beyond the last range
 *
 * @example
 * mapToRangeIndex(1, [1, 2, 3, 4]); // returns 0
 */
export const mapToRangeIndex = (
  input: number,
  rangeBreaks: number[],
  inclusiveBounds = true,
) => {
  // sort order could be descending
  const isDesc = rangeBreaks[0] > rangeBreaks[rangeBreaks.length - 1];
  const invertValues = isDesc ? (val: number) => -val : undefined;

  let index = sortedIndexBy(rangeBreaks, input, invertValues);
  if (!inclusiveBounds && input === rangeBreaks[index]) {
    index++;
  }
  return index;
};
export type CanParse = `${number}` | number | Decimal;
export type MaybeCanParse = CanParse | string | null | undefined;
type MaybeParseNumOpts<D = undefined> = {
  defaultVal: D;
  parseType?: 'int' | 'float';
};

export type MaybeParsedNum<V, D> = V extends number
  ? V
  : V extends Decimal
    ? number
    : V extends `${infer N extends number}`
      ? N
      : D;

export type MaybeParsedNums<V extends MaybeCanParse[], D> = V extends unknown[]
  ? [V['length']] extends 0
    ? never[]
    :
        | { [i in keyof V]: MaybeParsedNum<V[i], D> }
        | (IsTuple<V> extends false ? never[] : never)
  : never;

/** Optionally parses a nullishable numeric string
 * @param value - The possible string to be parsed
 * @returns the parsed number or undefined if the value is null, undefined,
 * or cannot be parsed to a number. Returns numbers as is.
 */

/** Optionally parses a number string
 * Note: Infinity and -Infinity are considered valid numbers
 * @param value - The possible string to be parsed
 * @param opts.defaultVal - The default value to return if the value is null or undefined.
 * Pass null if you want that to be the default. Default is undefined
 * @returns the parsed number or the default if the value is null, undefined,
 * or cannot be parsed to a number. Returns numbers as is.
 */
export function maybeParseNum<
  const V extends MaybeCanParse,
  const D = undefined,
>(value: V, defaultVal?: D): MaybeParsedNum<V, D>;
export function maybeParseNum<V extends MaybeCanParse, D = undefined>(
  value: V,
  defaultVal?: D,
): number | D | undefined {
  if (is.number(value) || value instanceof Decimal || is.numericString(value)) {
    return +value;
  }

  return defaultVal;
}

/** Optionally parses an array of nullishable number strings
 * Note: Infinity and -Infinity are considered valid numbers
 * @param vals - Array of possible strings to be parsed
 * @param opts.defaultVal - The default value to return if the value is null or undefined.
 * Pass null if you want that to be the default. Default is undefined
 * @param opt.onlyIfAll - [Optional] If true, only returns the array if all values can be
 * parsed. Note that this can only be set if there is no default val provided (Default: false)
 * @returns the parsed number or the default if the value is null, undefined,
 * or cannot be parsed to a number. Returns numbers as is.
 */
export function maybeParseNums<const V extends CanParse[]>(
  vals: V,
  opts: { onlyIfAll: true },
): MaybeParsedNums<V, number>;
export function maybeParseNums<const V extends MaybeCanParse[]>(
  vals: V,
  opts: { onlyIfAll: true },
): MaybeParsedNums<V, number> | undefined;
export function maybeParseNums<
  const V extends MaybeCanParse[],
  const D = undefined,
>(vals: V, opts?: { onlyIfAll?: false; defaultVal?: D }): MaybeParsedNums<V, D>;
export function maybeParseNums<
  const V extends MaybeCanParse[],
  const D = undefined,
>(
  vals: V,
  {
    onlyIfAll = false,
    defaultVal,
  }: {
    onlyIfAll?: boolean;
    defaultVal?: D;
  } = {},
): (number | D | undefined)[] | undefined {
  const maybeParsed = vals.map((val) => maybeParseNum(val, defaultVal));
  if (onlyIfAll) {
    return !is.emptyArray(maybeParsed) && hasElements(maybeParsed)
      ? maybeParsed
      : undefined;
  }
  return maybeParsed;
}

type MaybeCanParseObjNumVals = Record<string, MaybeCanParse>;
type MaybeParsedObjNumVals<O extends MaybeCanParseObjNumVals, D = never> = {
  [K in keyof O]: MaybeParsedNum<O[K], D>;
};
/**
 * Optionally parses all values in an object to numbers
 * Note: Infinity and -Infinity are considered valid numbers
 * @param obj - Object with values to parse
 * @param defaultVal - [Optional] Default value if parsing fails
 * @returns Object with same keys but values parsed to numbers or default
 */
export function maybeParseObjVals<
  const O extends MaybeCanParseObjNumVals,
  const D = undefined,
>(obj: O, opts?: MaybeParseNumOpts<D>) {
  const { defaultVal } = opts ?? {};
  const parsedObj = {} as Simplify<MaybeParsedObjNumVals<O, D>>;

  for (const key in obj) {
    const val = obj[key];
    parsedObj[key] = maybeParseNum(val, defaultVal);
  }

  return parsedObj;
}

/** Tries to parse vals to numbers and multiply all
 *
 * @returns product of all vals if possible or undefined
 */
export function maybeMultiply(
  ...vals: [CanParse, CanParse, ...CanParse[]]
): number;
export function maybeMultiply(
  ...vals: [MaybeCanParse, MaybeCanParse, ...MaybeCanParse[]]
): number | undefined;
export function maybeMultiply(...vals: MaybeCanParse[]): number | undefined {
  const parsed = maybeParseNums(vals, { onlyIfAll: true });
  if (!parsed || !parsed.length) return;

  return parsed.reduce((a, b) => a * b);
}

/** Tries to parse vals to numbers and divide all
 *
 * @returns quotient of all vals if possible or undefined. Div by 0 will return undefined
 */
export const maybeDivide = (...vals: MaybeCanParse[]): number | undefined => {
  const parsed = maybeParseNums(vals, { onlyIfAll: true });
  // any 0s other than the first will cause a div by zero problem
  if (!parsed?.length || vals.slice(1).includes(0)) return;

  return parsed.reduce((a, b) => a / b);
};

/** Note: Infinity and -Infinity are considered valid numbers */
export function maybeRound<const V extends MaybeCanParse, const D = undefined>(
  val: V,
  precision: RoundPrecision,
  defaultVal?: D,
): MaybeParsedNum<V, D> | number;
export function maybeRound<const V extends MaybeCanParse, const D = undefined>(
  val: V,
  precision: RoundPrecision,
  defaultVal?: D,
): MaybeParsedNum<V, D> | number;
export function maybeRound<
  const Vals extends MaybeCanParse[],
  const D = undefined,
>(vals: Vals, precision: RoundPrecision, defaultVal?: D): number[] | undefined;
export function maybeRound<D = undefined>(
  vals: MaybeCanParse | MaybeCanParse[],
  precision: RoundPrecision,
  defaultVal?: D,
): (number | D) | (number[] | undefined) {
  if (Array.isArray(vals)) {
    const parsed = maybeParseNums(vals, {
      onlyIfAll: true,
    });
    return parsed?.length
      ? parsed.map((val) => round(val, precision))
      : undefined;
  }
  const parsed = maybeParseNum(vals, defaultVal);
  return is.number(parsed) ? round(parsed, precision) : parsed;
}

/**
 * Note: Infinity and -Infinity will return the default value since output is
 * a string, make sure to pre-handle if different behavior is desired.
 */
export function maybeRoundToString<const D = undefined>(
  val: MaybeCanParse,
  precision: RoundPrecision,
  defaultVal?: D,
): `${number}` | D;
export function maybeRoundToString(
  vals: MaybeCanParse[],
  precision: RoundPrecision,
): `${number}`[] | undefined;
export function maybeRoundToString<const D = undefined>(
  vals: MaybeCanParse | MaybeCanParse[],
  precision: RoundPrecision,
  defaultVal?: D,
): (`${number}` | D) | (`${number}`[] | undefined) {
  if (Array.isArray(vals)) {
    const parsed = maybeParseNums(vals, { onlyIfAll: true });
    return parsed?.every((v) => !is.infinite(v))
      ? parsed.map((val) => roundToString(val, precision, false))
      : undefined;
  }
  const parsed = maybeParseNum(vals, { defaultVal });

  if (!is.number(parsed) || is.infinite(parsed)) return defaultVal;
  return roundToString(parsed, precision, false);
}

//TODO: needs doc & tests def not obvious
/** Input src = currently the enums
 *
 * @template InputSource - Union of all possible input source field names
 * @example CostInputSources.HIST_WORK_ORDER_ACT | CostInputSources.HIST_WORK_ORDER_EST
 * @example CostInputSources
 * @template Config - Config object with input source arrays
 *
 * @example {burden: [CostInputSources.HIST_WORK_ORDER_ACT, CostInputSources.HIST_WORK_ORDER_EST]}
 */
export const pickInputsFromSources = <
  InputSource extends string,
  Config extends Record<string, InputSource[]>,
  Inputs extends Record<string, any>,
>(
  sources: Partial<Record<InputSource, Inputs | null>>,
  sourceConfig: Config,
  configToInputFieldMap: Record<keyof Config, keyof Inputs>,
): {
  pickedInputs: Nullable<Required<Inputs>>;
  configUsed: Nullable<Record<keyof Config, InputSource>>;
} => {
  // make null defaults for all input fields
  const pickedInputs = Object.values(configToInputFieldMap).reduce(
    (inputs, inputKey) => {
      inputs[inputKey] = null;
      return inputs;
    },
    {} as Nullable<Required<Inputs>>,
  );

  // keep track of which config was used for each config field
  const configSrcsUsed = Object.keys(configToInputFieldMap).reduce(
    (srcsUsed, configSrcKey: keyof Config) => {
      srcsUsed[configSrcKey] = null;

      return srcsUsed;
    },
    {} as Nullable<Record<keyof Config, InputSource>>,
  );

  let srcKey: keyof Config;
  for (srcKey in sourceConfig) {
    const configuredInputSources = sourceConfig[srcKey]; // array of sources in order of priority
    const inputField = configToInputFieldMap[srcKey];

    // loop through configured sources until we find a val or are out of sources
    for (const src of configuredInputSources) {
      const sourceInputs = sources[src] as Inputs | null | undefined;
      const inputSrcVal = sourceInputs?.[inputField];
      // const inputSrcVal = sources[src]?.[inputField];

      // add to parsed & exit if we found a val
      if (inputSrcVal != null) {
        pickedInputs[inputField] = inputSrcVal;
        // save the src used
        configSrcsUsed[srcKey] = src;
        break;
      }
    }
  }
  return { pickedInputs, configUsed: configSrcsUsed };
};

/**
 * Given an output quantity and scrap values, determine the input quantity of an operation
 *
 * @param outputQty - output quantity of the operation
 * @param scrapFixedUnits - fixed amount of scrap for this operation
 * @param scrapYieldType - whether scrapYieldPercent indicates the % scrap or % yield of the operation
 * @param scrapYieldPercent - percent of scrap or yield for this operation
 */
export const calculateOperationInputQty = (
  outputQty: number | null,
  scrapFixedUnits: number | null,
  scrapYieldType: ScrapYieldType | null,
  scrapYieldPercent: number | null,
): number | null => {
  if (outputQty === null) {
    return null;
  }

  // get the % yield of the operation
  let percentYield = 1;
  if (scrapYieldPercent && scrapYieldType === ScrapYieldType.SCRAP) {
    percentYield = 1 - scrapYieldPercent / 100;
  }
  if (scrapYieldPercent && scrapYieldType === ScrapYieldType.YIELD) {
    percentYield = scrapYieldPercent / 100;
  }

  // divide by the percent yield and add fixed scrap to get the input amount

  let inputQty = Math.ceil(outputQty / percentYield);

  if (scrapFixedUnits) {
    inputQty += scrapFixedUnits;
  }
  return inputQty;
};
