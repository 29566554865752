import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  HStack,
  Input,
  Select,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import type { MSGraphUser } from '@lib/models';
import { PermActions } from '@lib/permissions';
import type { UserWithSettingsResponse } from '@lib/responses';
import type { AvailableSite } from '@prisma-types';
import { ResourceEnum, UserStatus } from '@prisma/client';
import { UserSearch } from '@ui/components/UserSearch';
import { Heading } from '@ui/components/layout/Heading';
import { useGlobalState } from '@ui/hooks';
import { adminUserService, siteService } from '@ui/services';
import { type FC, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

// Should be able to pass in optional, existing data to the form.
type UserFormProps = {
  user?: UserWithSettingsResponse;
};

export const UserForm: FC<UserFormProps> = ({ user }) => {
  const form = useForm<UserWithSettingsResponse>({ defaultValues: user });
  const { register, getValues, reset, setValue } = form;
  const [userSelected, setUserSelected] = useState<boolean>(false);
  const [sites, setSites] = useState<AvailableSite[]>([]);
  const { setActiveModal } = useGlobalState();

  const {
    fields: userSites,
    remove: removeUserSite,
    append: appendUserSite,
    replace: replaceUserSites,
  } = useFieldArray({
    name: 'sites',
    control: form.control,
  });

  const {
    fields: userPermissions,
    remove: removeUserPermission,
    append: appendUserPermission,
    update: updateUserPermission,
    replace: replaceUserPermission,
  } = useFieldArray({
    name: 'permissions',
    control: form.control,
  });

  useEffect(() => {
    if (user?.email) {
      setUserSelected(true);
      reset(user);
    }
  }, [user]);

  useEffect(() => {
    const fetchSites = async () => {
      const sites = await siteService.getAllSites();
      setSites(sites);
    };

    fetchSites();
  }, []);

  const handleFormSubmit = async () => {
    const userVals = getValues();
    try {
      await adminUserService.save(userVals);
      setActiveModal(null);
    } catch (err: unknown) {
      // TODO: show proper error toast
      console.log(err);
    }
  };

  const handleUserSelection = (user: MSGraphUser) => {
    setUserSelected(true);

    const newUser = {
      oid: user.id,
      displayName: user.displayName,
      firstName: user.givenName,
      lastName: user.surname,
      email: user.mail.toLowerCase(),
      status: UserStatus.ACTIVE,
      userSettings: {
        phoneNumber: null,
        mobilePhoneNumber: null,
        signatureTitle: user.jobTitle,
        emailQuoteToCustomer: true,
        visualUserName: null,
      },
      sites: [],
      permissions: [],
      roles: [],
    };

    reset(newUser);
  };

  const handleCancelForm = () => {
    reset(undefined);
    setActiveModal(null);
  };

  // TODO: Shorthand for simple site
  const handleSiteToggle = (evt: any, site: AvailableSite) => {
    if (evt.target.checked) {
      appendUserSite(site);
    } else {
      const idx = userSites.findIndex((s) => s.code === site.code);
      removeUserSite(idx);
    }
  };

  const handlePermissionToggle = (
    evt: any,
    resource: ResourceEnum,
    action: 'read' | 'create' | 'update' | 'delete',
  ) => {
    const idx = userPermissions.findIndex((p) => p.resource === resource);
    if (evt.target.checked) {
      if (idx < 0) {
        const perm = {
          resource,
          [action]: true,
          userId: user?.id,
        };
        // dont like this but can't find a workaround atm, using Prisma Create* types causes CPU overload. F Prisma.
        appendUserPermission(perm as any);
      } else {
        updateUserPermission(idx, { ...userPermissions[idx], [action]: true });
      }
    } else {
      updateUserPermission(idx, { ...userPermissions[idx], [action]: false });
    }
  };

  return (
    <FormProvider {...form}>
      <form autoComplete="do-not-autofill" onSubmit={() => handleFormSubmit()}>
        {!userSelected && (
          <Box>
            <UserSearch onSelection={handleUserSelection} />
          </Box>
        )}
        {userSelected && (
          <>
            <Box>
              <FormLabel>Email Address</FormLabel>
              <Input isReadOnly={true} {...register('email')} type="email" />
              <FormLabel>Status</FormLabel>
              <Select {...register('status')}>
                <option value="ACTIVE">Active</option>
                <option value="PENDING">Pending</option>
                <option value="DISABLED">Disabled</option>
              </Select>
              <FormLabel>Display Name</FormLabel>
              <Input {...register('displayName')} />
              <FormLabel>Phone Number</FormLabel>
              <Input {...register('userSettings.phoneNumber')} />
              <FormLabel>Mobile Number</FormLabel>
              <Input {...register('userSettings.mobilePhoneNumber')} />
              <FormLabel>Signature Title</FormLabel>
              <Input {...register('userSettings.signatureTitle')} />
              <FormLabel>Visual User Name</FormLabel>
              <Input {...register('userSettings.visualUserName')} />
              <Checkbox
                defaultChecked={
                  user?.userSettings?.emailQuoteToCustomer ?? true
                }
                {...form.register('userSettings.emailQuoteToCustomer')}
              >
                Email Quotes to Customer by default
              </Checkbox>
            </Box>
            <Box>
              <Heading>Sites</Heading>
              <Wrap>
                {sites
                  .sort((a, b) => a.code.localeCompare(b.code))
                  .map((site) => (
                    <WrapItem key={site.code}>
                      <Checkbox
                        isChecked={
                          !!getValues().sites.find(
                            (userSites) => userSites.code === site.code,
                          )
                        }
                        onChange={(evt) => handleSiteToggle(evt, site)}
                      >
                        {site.code}
                      </Checkbox>
                    </WrapItem>
                  ))}
              </Wrap>
            </Box>
            <Box>
              <Heading>Permissions</Heading>
              {(
                Object.keys(ResourceEnum) as Array<keyof typeof ResourceEnum>
              ).map((resource) => (
                <Box key={resource}>
                  <Text>{resource}</Text>
                  <HStack>
                    {(
                      Object.keys(PermActions) as Array<
                        keyof typeof PermActions
                      >
                    ).map((action) => (
                      <Checkbox
                        key={`${resource}.${action}`}
                        isChecked={
                          !!getValues().permissions.find(
                            (userPerms) =>
                              userPerms.resource === resource &&
                              userPerms[PermActions[action]] === true,
                          )
                        }
                        onChange={(evt) =>
                          handlePermissionToggle(
                            evt,
                            resource,
                            PermActions[action],
                          )
                        }
                      >
                        {action}
                      </Checkbox>
                    ))}
                  </HStack>
                </Box>
              ))}
            </Box>
            <Box>
              <HStack justifyContent={'end'}>
                <Button colorScheme="gray" onClick={handleCancelForm}>
                  Cancel
                </Button>
                <Button colorScheme="green" onClick={handleFormSubmit}>
                  Save
                </Button>
              </HStack>
            </Box>
          </>
        )}
      </form>
    </FormProvider>
  );
};
