import { Box, Button, Flex } from '@chakra-ui/react';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { QuoteFeedbackReasonsResponse, QuoteFeedbackResponse } from '@lib';
import { RoutesConfig, route } from '@ui/config/routes';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { PageFooter } from '../../components/layout/PageFooter';
import PageHeader from '../../components/layout/PageHeader';
import { quoteService } from '../../services';
import { useValidationToast } from '../QuoteBuilder/hooks/useToast';
import { QuoteFeedbackField } from './QuoteFeedbackField';

export const QuoteFeedback = () => {
  const { id: quoteId } = useParams<{ id: string }>();
  const [feedbackReasons, setFeedbackReasons] = useState<
    QuoteFeedbackReasonsResponse | []
  >([]);
  const [feedback, setFeedback] = useState<QuoteFeedbackResponse | null>(null);
  const form = useForm<QuoteFeedbackResponse>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });
  const { fields: feedbackFields } = useFieldArray({
    name: 'feedback',
    control: form.control,
  });
  const { genericErrorToast, genericSuccessToast } = useValidationToast();
  const navigate = useNavigate();

  useEffect(() => {
    async function loadQuoteFeedback() {
      if (!quoteId) return;
      const [feedbackReasons, result] = await Promise.all([
        quoteService.getQuoteFeedbackReasons(),
        quoteService.getQuoteFeedback(quoteId),
      ]);
      form.setValue('feedback', result.feedback);
      setFeedback(result);
      setFeedbackReasons(feedbackReasons);
    }
    loadQuoteFeedback();
  }, [quoteId]);

  const handleFormSubmit = async () => {
    if (!quoteId) {
      return;
    }
    const formData = form.getValues();
    try {
      await quoteService.sendQuoteFeedback(quoteId, {
        data: formData.feedback,
      });
      genericSuccessToast(
        'Thanks for your feedback!',
        'It helps make the app better',
      );
      navigate(route(RoutesConfig.quoteQueue));
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        genericErrorToast('Failed to save feedback, please contact support.');
      }
    }
  };

  return feedback ? (
    <Box width="100%" minHeight="100%">
      <FormProvider {...form}>
        <form
          autoComplete="do-not-autofill"
          onSubmit={form.handleSubmit(handleFormSubmit)}
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'stretch',
            flexDirection: 'column',
          }}
        >
          <PageHeader
            title={`Provide Feedback for ${feedback.quote.quoteId}`}
            subtitle={'Please provide feedback on each of the line items below'}
          />

          <Box flexGrow={1} marginTop={8}>
            {feedbackFields.map((fbf, index) => (
              <QuoteFeedbackField
                feedback={fbf}
                feedbackReasons={feedbackReasons}
                index={index}
                key={`feedback-${fbf.id}`}
              />
            ))}
          </Box>
          <PageFooter>
            <Box
              display={'flex'}
              width="100%"
              justifyContent="space-between"
              flexDirection={'row'}
            >
              <Flex
                alignContent={'end'}
                direction={'row'}
                gap={2}
                flexGrow={1}
                justifyContent={'end'}
              >
                <Button
                  marginLeft={2}
                  type="submit"
                  gap={2}
                  bgColor="#0b7078"
                  color="white"
                  _hover={{ bg: '#046068' }}
                  borderRadius={'0px'}
                >
                  <FontAwesomeIcon
                    icon={faComment}
                    fontSize={12}
                    aria-label="Send Feedback"
                  />
                  Submit Feedback
                </Button>
              </Flex>
            </Box>
          </PageFooter>
        </form>
      </FormProvider>
    </Box>
  ) : (
    <Loading />
  );
};
