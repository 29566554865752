import { Box, Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { useGlobalState } from '../../../hooks';
import Modal from '../Modal';
import { NavigationBar } from '../NavigationBar';

const PageLayout = () => {
  const { activeModal } = useGlobalState();
  return (
    <Box>
      <Modal isOpen={activeModal ? true : false} {...activeModal} />
      <Flex direction={'row'} transition={'padding-left 200ms'}>
        <NavigationBar />
        <Outlet />
      </Flex>
    </Box>
  );
};

export default PageLayout;
