import { strategies } from './strategies';
import type { LeadTimeCalculatorData, LeadTimeStrategy, Site } from './types';

export const LeadTimeCalculator = {
  calculateDays: (site: Site, data: LeadTimeCalculatorData): number => {
    const strategy = LeadTimeCalculator.findStrategy(site);

    return strategy.calculateDays(data);
  },

  findStrategy: (site: Site): LeadTimeStrategy => {
    const code = site.code.toUpperCase();

    if (strategies[code] !== undefined) {
      return strategies[code];
    }

    return strategies.default;
  },
};
