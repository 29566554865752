import type { FlexProps } from '@chakra-ui/react';
import { Pagination, type PerPageSelectProps } from './Pagination';
import type { PaginationProps } from './types';

export function BasicPagination(
  props: PaginationProps & PerPageSelectProps & Omit<FlexProps, 'children'>,
) {
  const { perPageOptions, metadata, paginator, ...rootLayoutProps } = props;
  const useLinks = paginator.type === 'url';

  return (
    <Pagination.Root metadata={metadata} paginator={paginator}>
      <Pagination.RootLayout {...rootLayoutProps}>
        <Pagination.Metadata />

        <Pagination.RightArea>
          <Pagination.PerPageSelect perPageOptions={perPageOptions} />

          <Pagination.ButtonGroup>
            {useLinks ? (
              <>
                <Pagination.PrevPageLink />
                <Pagination.NextPageLink />
              </>
            ) : (
              <>
                <Pagination.PrevPageButton />
                <Pagination.NextPageButton />
              </>
            )}
          </Pagination.ButtonGroup>
        </Pagination.RightArea>
      </Pagination.RootLayout>
    </Pagination.Root>
  );
}
