import { Center, Spinner } from '@chakra-ui/react';

export type QueryLoadingProps = {
  title?: string;
};

export function QueryLoading({ title }: QueryLoadingProps) {
  return (
    <Center padding={10} width="100%">
      <Spinner label={title} />
    </Center>
  );
}
