import type { CustomerHeaderResponse } from '@lib';

export const termText = (customer: CustomerHeaderResponse) => {
  const isNewCustomer = !customer.id;
  const termDays = customer.termsNetDays;

  if (isNewCustomer) {
    return 'TBD';
  }

  if (termDays == null || termDays <= 0) {
    return 'Prepaid';
  }

  return `NET ${termDays}`;
};
