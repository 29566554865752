import { Text, View } from '@react-pdf/renderer';
import { useQuotePdfContext } from '@ui/pages/QuoteBuilder/hooks';
import { pdfStyles } from '@ui/pdfs/pdf-styles';

export const SalesRep = () => {
  const { quoteForm: form } = useQuotePdfContext();

  if (form.salesRepName) {
    return (
      <View style={[pdfStyles.column, pdfStyles.widthFull]}>
        <View style={[pdfStyles.row, pdfStyles.justifyBetween]}>
          <Text>Salesman</Text>
          <Text>{form.salesRepName}</Text>
        </View>
        <View style={[pdfStyles.column, pdfStyles.alignEnd]}>
          {form.salesRepEmail && <Text>{form.salesRepEmail}</Text>}
          {form.salesRepPhone && <Text>{form.salesRepPhone}</Text>}
        </View>
      </View>
    );
  }

  return (
    <>
      <Text>Salesman</Text>
      <Text>{form.salesRepId}</Text>
    </>
  );
};
