import { Flex } from '@chakra-ui/react';
import type { FC } from 'react';

interface PageFooterProps {
  children?: React.ReactNode;
  bgColor?: string;
  textColor?: string;
}

export const PageFooter: FC<PageFooterProps> = ({ children, bgColor }) => {
  return (
    <Flex
      width={'100%'}
      alignItems="center"
      padding={'20px'}
      flexShrink={0}
      bg={bgColor ?? 'mw.panelGrey'}
      justifyContent={'space-between'}
    >
      {children}
    </Flex>
  );
};
