import { Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import type { LinePathType } from '../types';
import { InventoryFooter } from './InventoryFooter';
import { LineItemTotal } from './LineItemTotal';

interface LineItemFooterProps {
  linePath: LinePathType;
}

export const LineItemFooter: FC<LineItemFooterProps> = ({ linePath }) => {
  return (
    <Flex justifyContent="space-between" alignItems="end" width="full" px={4}>
      <InventoryFooter linePath={linePath} />

      <LineItemTotal linePath={linePath} />
    </Flex>
  );
};
