import {
  ConfigEntities,
  CostInputSources,
  FixedPricingSources,
  PricingStrategy,
  QtyBasisSources,
} from '@prisma/client';
import type { SelectOptions } from '@ui/util/types';

export const QTY_BASIS_SRC_OPTS = [
  { label: 'Last Order', value: QtyBasisSources.LAST_ORDER },
  { label: 'Master Estimate', value: QtyBasisSources.MASTER_EST },
  {
    label: 'Historic WO Actual',
    value: QtyBasisSources.HIST_WORK_ORDER_ACT,
  },
  {
    label: 'Historic WO Estimate',
    value: QtyBasisSources.HIST_WORK_ORDER_EST,
  },
  {
    label: 'Fixed Pricing',
    value: QtyBasisSources.FIXED_PRICING,
  },
  {
    label: 'Last Quote',
    value: QtyBasisSources.LAST_QUOTE,
  },
  {
    label: 'Default',
    value: QtyBasisSources.CONFIG_DEFAULT,
  },
] satisfies SelectOptions<QtyBasisSources>;

export const CONFIG_ENTITY_PRIORITY_OPTS = [
  { label: 'Site', value: ConfigEntities.SITE },
  { label: 'User', value: ConfigEntities.USER },
  { label: 'Customer', value: ConfigEntities.CUSTOMER },
  { label: 'Part', value: ConfigEntities.PART },
  { label: 'Quote', value: ConfigEntities.QUOTE },
  { label: 'Quote Line', value: ConfigEntities.QUOTE_LINE },
  { label: 'Quantity Break', value: ConfigEntities.QTY_BRK },
  { label: 'Part Requirement', value: ConfigEntities.REQ_PART },
] satisfies SelectOptions<ConfigEntities>;

export const COST_INPUT_SOURCE_OPTS = [
  { label: 'Historic WO Actual', value: CostInputSources.HIST_WORK_ORDER_ACT },
  {
    label: 'Historic WO Estimate',
    value: CostInputSources.HIST_WORK_ORDER_EST,
  },
  { label: 'Master Estimate', value: CostInputSources.MASTER_EST },
  { label: 'Manual Entry', value: CostInputSources.MANUAL_ENTRY },
] satisfies SelectOptions<CostInputSources>;

export const PRICING_STRATEGY_OPTS = [
  {
    label: 'Target Contribution Margin',
    value: PricingStrategy.TARGET_CONTR_MARGIN,
  },
  { label: 'Target Gross Margin', value: PricingStrategy.TARGET_GROSS_MARGIN },
  { label: 'Fixed', value: PricingStrategy.FIXED },
  { label: 'RAF Custom', value: PricingStrategy.RAF_CUSTOM },
] satisfies SelectOptions<PricingStrategy>;

export const FIXED_PRICING_SRC_OPTS = [
  { label: 'Negotiated Pricing', value: FixedPricingSources.NegotiatedPricing },
  { label: 'Market Pricing', value: FixedPricingSources.MarketPricing },
  { label: 'Discount Pricing', value: FixedPricingSources.DiscountPricing },
] satisfies SelectOptions<FixedPricingSources>;
