export function calculateLeadTimeWeeks(leadTime: number) {
  return Math.ceil(leadTime / 7);
}

export function calculateAndFormatLeadTimeWeeks(leadTime: number) {
  const weeks = calculateLeadTimeWeeks(leadTime);

  if (weeks === 1) {
    return `${weeks} Week`;
  }

  return `${weeks} Weeks`;
}
