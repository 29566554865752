import { Button, Text, VStack } from '@chakra-ui/react';
import {
  type CustomerManyResponse,
  type CustomerResponse,
  RESULTS_PER_PAGE,
} from '@lib';
import { type FC, useEffect, useRef, useState } from 'react';
import Search from '../../components/form/Search';
import { customerService } from '../../services';
import { CustomerSearchResultItem } from './CustomerSearchResultItem';

interface CustomerPickerProps {
  onSelection: (result: CustomerPickerResult) => void;
  startWithSearchString?: string;
}

export type CustomerPickerSelected = {
  customer: CustomerResponse;
  action: 'selected';
};

export type CustomerPickerSkipped = {
  customer: null;
  action: 'skipped';
};

export type CustomerPickerResult =
  | CustomerPickerSelected
  | CustomerPickerSkipped;

type ResultsCache = {
  [key: string]: CustomerManyResponse[];
};

export const CustomerPicker: FC<CustomerPickerProps> = ({
  onSelection,
  startWithSearchString,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [results, setResults] = useState<CustomerManyResponse>([]);
  // use object instead of int to trigger setPage on initial search where page==1
  const [page, setPage] = useState({ currentPage: 1 });
  const [loading, setLoading] = useState(false);

  // cache results
  const resultsCache = useRef<ResultsCache>({});

  const fetchResources = async () => {
    if (searchInput.length > 0) {
      // first check the results cache for the current search term and page
      if (
        resultsCache.current &&
        resultsCache.current[searchInput] &&
        resultsCache.current[searchInput][page.currentPage - 1]
      ) {
        setResults(resultsCache.current[searchInput][page.currentPage - 1]);
        return;
      }

      // if not present in the cache, do the API call
      // we're asking for a set of items 1 greater than what we show, so that
      // if our shown number or fewer is returned, we know we're on the last page
      // (see Search component)
      setLoading(true);
      const data = await customerService.searchCustomers(
        searchInput,
        RESULTS_PER_PAGE + 1,
        (page.currentPage - 1) * RESULTS_PER_PAGE,
      );
      setResults(data);
      // cache the API call so we don't need to do it again on this page
      if (!resultsCache.current[searchInput]) {
        resultsCache.current[searchInput] = [];
      }
      resultsCache.current[searchInput][page.currentPage - 1] = data;
      setLoading(false);
    } else {
      // reset the results when search box is empty
      setResults([]);
    }
  };

  useEffect(() => {
    // on search change, set page to 1 to trigger search
    setPage({ currentPage: 1 });
  }, [searchInput]);

  useEffect(() => {
    // always run on page change
    (async () => {
      await fetchResources();
    })();
  }, [page]);

  const handleCustomerSelection = (customer: CustomerResponse): void => {
    onSelection({ customer, action: 'selected' });
  };

  const handleSkipCustomer = () => {
    onSelection({ customer: null, action: 'skipped' });
  };

  return (
    <VStack w="100%" align="stretch" spacing={4}>
      <Search
        {...{
          results: results,
          ResultItem: CustomerSearchResultItem,
          searchInputLabel: 'Search for a customer...',
          setSearchInput,
          onSelection: handleCustomerSelection,
          startWithSearchString,
          page,
          setPage,
          loading,
          setLoading,
        }}
      />

      <Text display="block" textAlign="center" fontWeight="bold">
        or
      </Text>

      <Button
        bgColor="mw.yellow"
        fontFamily="navigationItem"
        onClick={handleSkipCustomer}
      >
        Skip Customer Selection
      </Button>
    </VStack>
  );
};
