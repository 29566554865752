import { roundToString } from './util';

//TODO: Refactor consumer to use `QuoteLineCalcs.pricing.totalPrice`
export function totalLineItem(unitPrice: number, quantity: number) {
  const total = unitPrice * quantity;

  if (Number.isNaN(total)) {
    return '-';
  }

  return roundToString(total, {
    min: 2,
    small: 2,
  });
}
