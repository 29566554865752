import type {
  CostingConfigCreateRequest,
  CostingConfigUpdateRequest,
} from '@lib/requests';
import type { CostingConfigResponse } from '@lib/responses';
import {
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useValidationToast } from '@ui/pages/QuoteBuilder/hooks/useToast';
import { adminConfigsService } from '@ui/services';
import { useAuth } from '@ui/state/auth';
import { adminConfigAllQuery } from './quoting';

/**
 * Having optional params and a fallback to "unknown" is a workaround until we
 * have better options for guarding sections of the app that require a
 * `currentSite` to be defined. These queries and mutations don't really make
 * sense without a valid site id.
 */
export const adminConfigCostingQuery = (siteId = 'unknown') => {
  return {
    queryKey: ['admin', 'configs', siteId, 'costing'],
    queryFn: () => adminConfigsService.getCostingConfigs(siteId),
  };
};

export const adminConfigCreateCostingMutation = (siteId = 'unknown') => ({
  mutationKey: ['admin', 'configs', siteId, 'costing', 'create'],
  mutationFn: (data: CostingConfigCreateRequest) =>
    adminConfigsService.createCostingConfig(siteId, data),
});

export const adminConfigUpdateCostingMutation = (
  siteId = 'unknown',
  id = 'unknown',
) => ({
  mutationKey: ['admin', 'configs', siteId, 'costing', 'update', id],
  mutationFn: (data: CostingConfigUpdateRequest) =>
    adminConfigsService.updateCostingConfig(siteId, id, data),
});

export function useAdminCostingConfigs(
  options?: Omit<
    UseQueryOptions<CostingConfigResponse[]>,
    'queryKey' | 'queryFn'
  >,
) {
  const { currentSite } = useAuth();

  return useQuery({
    ...adminConfigCostingQuery(currentSite?.id),
    ...options,
    enabled: !!currentSite?.id && (options?.enabled ?? true),
  });
}

export function useCreateAdminCostingConfig(
  options?: Omit<
    UseMutationOptions<
      CostingConfigResponse,
      unknown,
      CostingConfigCreateRequest
    >,
    'mutationKey' | 'mutationFn'
  >,
) {
  const { currentSite } = useAuth();
  const queryClient = useQueryClient();
  const { genericSuccessToast } = useValidationToast();

  return useMutation({
    ...adminConfigCreateCostingMutation(currentSite?.id),
    ...options,
    onSuccess: async (_data, _variables, _context) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: adminConfigCostingQuery(currentSite?.id).queryKey,
        }),
        queryClient.invalidateQueries({
          queryKey: adminConfigAllQuery(currentSite?.id).queryKey,
          type: 'inactive',
        }),
      ]);

      genericSuccessToast('Costing config created');
    },
  });
}

export function useUpdateAdminCostingConfig(
  id?: string,
  options?: Omit<
    UseMutationOptions<
      CostingConfigResponse,
      unknown,
      CostingConfigUpdateRequest
    >,
    'mutationKey' | 'mutationFn'
  >,
) {
  const { currentSite } = useAuth();
  const queryClient = useQueryClient();
  const { genericSuccessToast } = useValidationToast();

  return useMutation({
    ...adminConfigUpdateCostingMutation(currentSite?.id, id),
    ...options,
    onSuccess: async (_data, _variables, _context) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: adminConfigCostingQuery(currentSite?.id).queryKey,
        }),
        queryClient.invalidateQueries({
          queryKey: adminConfigAllQuery(currentSite?.id).queryKey,
          type: 'inactive',
        }),
      ]);

      genericSuccessToast('Costing config updated');
    },
  });
}
