import {
  Box,
  Flex,
  HStack,
  type SystemStyleObject,
  Text,
} from '@chakra-ui/react';
import type { FC } from 'react';
import { FormSectionTitle } from '../../Text';
import type { InputWithIconProps } from '../InputWithIcon';

export type ComparisonFieldBaseProps = {
  title: string;
  rightTitle?: React.ReactNode;
  beforeLabel?: string;
  afterLabel?: string;
  beforeInputProps?: InputWithIconProps & { ref?: React.Ref<HTMLInputElement> };
  hideTitleInformation?: boolean;
  hideBeforeInformation?: boolean;
  hideAfterInformation?: boolean;
  hideWholeElement?: boolean;
  sx?: SystemStyleObject;
};

export type ComparisonFieldProps = ComparisonFieldBaseProps & {
  BeforeComponent?: React.ReactElement | null;
  AfterComponent: React.ReactElement;
};

export const visibilityValue = (hidden?: boolean | null, resize = false) => {
  if (hidden === undefined || null) {
    return undefined;
  }

  return {
    visibility: hidden ? 'hidden' : 'visible',
    height: hidden && resize ? 0 : undefined,
  };
};

export const ComparisonField: FC<ComparisonFieldProps> = ({
  title,
  rightTitle,
  beforeLabel,
  afterLabel,
  AfterComponent,
  BeforeComponent,
  hideBeforeInformation = false,
  hideTitleInformation = false,
  hideAfterInformation = false,
  hideWholeElement = false,
}) => {
  return (
    <Box
      borderLeft="1px solid"
      borderColor={{ base: 'rgba(0,0,0,0.1)', md: 'transparent' }}
      mx={4}
      mb={{ base: 0, md: 0 }}
      sx={visibilityValue(hideWholeElement)}
    >
      <HStack justifyContent="space-between">
        <FormSectionTitle
          fontSize={11}
          sx={visibilityValue(hideTitleInformation, true)}
        >
          {title}
        </FormSectionTitle>
        {rightTitle ?? rightTitle}
      </HStack>
      <Flex direction={'row'} gap={1}>
        <Box mt={1} width="50%">
          {BeforeComponent}
          {beforeLabel && (
            <Text
              fontSize={12}
              fontWeight={400}
              textAlign="right"
              mr={1}
              sx={visibilityValue(hideBeforeInformation, true)}
            >
              {beforeLabel}
            </Text>
          )}
        </Box>
        <Box mt={1} width="50%">
          {AfterComponent}
          {afterLabel && (
            <Text
              fontSize={12}
              fontWeight={800}
              textAlign="right"
              mr={1}
              sx={visibilityValue(hideAfterInformation, true)}
            >
              {afterLabel}
            </Text>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default ComparisonField;
