import { Button } from '@chakra-ui/react';
import type React from 'react';
import { useRef } from 'react';

type SimpleFileUploadProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

export const SimpleFileUpload: React.FC<SimpleFileUploadProps> = ({
  onChange,
  disabled,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  return (
    <>
      <Button
        isDisabled={disabled}
        borderRadius="0"
        backgroundColor="#0b7078"
        color="white"
        // TODO: Refactor one-off styles (https://mwcomponents.atlassian.net/browse/MW-558)
        _hover={{ backgroundColor: '#046068' }}
        _disabled={{ backgroundColor: '#aaaaaa' }}
        onClick={handleUploadClick}
      >
        Upload Files
      </Button>
      <input
        disabled={disabled}
        ref={inputRef}
        type="file"
        onChange={onChange}
        style={{ display: 'none' }}
        multiple
      />
    </>
  );
};
