import {
  Box,
  Icon,
  IconButton,
  ListItem,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { QuoteLineDocumentCreateResponse } from '@lib/responses';
import { useGetDocumentMutation } from '@ui/data/document/get-document';
import type React from 'react';
import { useRef } from 'react';

interface LineItemDocumentProps {
  document: QuoteLineDocumentCreateResponse;
}

export const LineItemDocument: React.FC<LineItemDocumentProps> = ({
  document,
}) => {
  const fileRef = useRef<HTMLAnchorElement>(null);

  const downloadDocument = useGetDocumentMutation({
    onSuccess: (data) => {
      const objectURL = window.URL.createObjectURL(
        new Blob([data], { type: document.fileType }),
      );

      if (fileRef.current) {
        fileRef.current.href = objectURL;
        fileRef.current.download = document.fileName;
        fileRef.current.click();
      }
    },
  });

  const handleDownload = () => {
    if (!document.id) {
      return;
    }

    downloadDocument.mutate(document.id);
  };

  return (
    <ListItem display="flex" gap={3} justifyContent="space-between">
      <Box>
        <Text fontWeight="bold">{document.fileName}</Text>
        <Text fontSize="small">{`Uploaded on ${new Date(
          document.createdAt,
        ).toLocaleString()} by ${document.createdBy?.displayName}`}</Text>
      </Box>

      <Box display="flex" gap={2}>
        <Tooltip label={`Download ${document.fileName}`} placement="bottom-end">
          <IconButton
            aria-label={`Download ${document.fileName}`}
            onClick={handleDownload}
            icon={<Icon as={FontAwesomeIcon} icon={faFileArrowDown} />}
          />
        </Tooltip>
      </Box>

      {/* biome-ignore lint/a11y/useValidAnchor: href set by fileRef */}
      <a ref={fileRef} target="_blank" style={{ display: 'none' }}>
        Download
      </a>
    </ListItem>
  );
};
