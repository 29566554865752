import { Box, Table, Tbody, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import type { ResolvedReqCostsInput } from '@lib';
import type { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import type { QuoteBuilderForm } from '../../QuoteBuilder';
import { styles } from '../styles';
import { CostCell, StaticCell } from './Cells';
import { MaterialInfo } from './MaterialInfo';
import type { CostFieldOptionType } from './types';

export const RequirementTableColumns: CostFieldOptionType<ResolvedReqCostsInput>[] =
  [
    {
      editable: false,
      enabled: true,
      varName: 'pieceNum',
      filterable: false,
      title: 'Piece No.',
      type: 'number',
    },
    {
      editable: false,
      enabled: true,
      varName: 'calcQtyFromOp',
      filterable: false,
      title: 'Qty',
      type: 'number',
    },
    {
      editable: true,
      enabled: true,
      varName: 'qtyPerOpQty',
      filterable: false,
      title: 'Qty Per Op',
      type: 'number',
    },
    {
      editable: true,
      enabled: true,
      varName: 'fixedQty',
      filterable: false,
      title: 'Fixed Qty',
      type: 'number',
    },
    {
      editable: true,
      enabled: true,
      varName: 'basisQty',
      filterable: false,
      title: 'Basis Qty',
      type: 'number',
    },
    {
      editable: true,
      enabled: true,
      varName: 'calcQtyFromLine',
      filterable: false,
      title: 'Calc Qty from Line',
      type: 'number',
    },
    {
      editable: true,
      enabled: true,
      varName: 'qtyPerLineQty',
      filterable: false,
      title: 'Qty/Line Qty',
      type: 'number',
    },
    {
      editable: false,
      enabled: true,
      varName: 'usageUM',
      filterable: false,
      title: 'Usage UM',
      type: 'string',
    },
    {
      editable: true,
      enabled: true,
      varName: 'unitsPerPieceType',
      filterable: false,
      title: 'Units/Piece Type',
      type: 'string',
    },
    {
      editable: true,
      enabled: true,
      varName: 'matUnitCost',
      filterable: false,
      title: 'Unit Material Cost',
      type: 'number',
    },
    {
      editable: true,
      enabled: true,
      varName: 'matMinCost',
      filterable: false,
      title: 'Min Material Cost',
      type: 'number',
    },
    {
      editable: true,
      enabled: true,
      varName: 'matFixedCost',
      filterable: false,
      title: 'Fixed Material Cost',
      type: 'number',
    },
    {
      editable: true,
      enabled: true,
      varName: 'labUnitCost',
      filterable: false,
      title: 'Labor Unit Cost',
      type: 'number',
    },
    {
      editable: true,
      enabled: true,
      varName: 'burUnitCost',
      filterable: false,
      title: 'Burden Unit Cost',
      type: 'number',
    },
    {
      editable: true,
      enabled: true,
      varName: 'srvUnitCost',
      filterable: false,
      title: 'Service Unit Cost',
      type: 'number',
    },
    {
      editable: true,
      enabled: true,
      varName: 'burPct',
      filterable: false,
      title: 'Burden % of Labor',
      type: 'number',
    },
    {
      editable: true,
      enabled: true,
      varName: 'burPerUnit',
      filterable: false,
      title: 'Burden Per Unit',
      type: 'number',
    },
  ];

type RequirementsProps = {
  lineIndex: number;
  costIndex: number;
  showSources: boolean;
};

export const Requirements: FC<RequirementsProps> = ({
  lineIndex,
  costIndex,
  showSources,
}) => {
  const { control, register } = useFormContext<QuoteBuilderForm>();

  const path =
    `lineItems.${lineIndex}.operationCosts.${costIndex}.requirements` as const;

  const { fields: requirements } = useFieldArray({
    name: path,
    control,
  });

  const enabledColumns = RequirementTableColumns.filter(
    (column) => column.enabled,
  );

  return (
    <VStack spacing={2} alignItems="flex-start">
      {requirements.map((requirement, index) => {
        return (
          <Box key={requirement.id}>
            <MaterialInfo
              id={requirement.reqPartId}
              description={requirement.reqPartDescription}
            />

            <Table sx={{ ...styles, marginTop: 0 }} size="sm">
              <Thead>
                <Tr>
                  {enabledColumns.map((column) => {
                    return <Th key={column.varName}>{column.title}</Th>;
                  })}
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  {enabledColumns.map((column) => {
                    if (column.editable) {
                      return (
                        <CostCell
                          key={column.varName}
                          {...register(`${path}.${index}.${column.varName}`, {
                            valueAsNumber: column.type === 'number',
                          })}
                          type={column.type}
                          showSources={showSources}
                        />
                      );
                    }

                    return (
                      <StaticCell
                        key={column.varName}
                        path={`${path}.${index}.${column.varName}`}
                      />
                    );
                  })}
                </Tr>
              </Tbody>
            </Table>
          </Box>
        );
      })}
    </VStack>
  );
};
