import {
  Box,
  Button,
  HStack,
  List,
  ListItem,
  Spinner,
  Text,
} from '@chakra-ui/react';
import {
  faChevronLeft,
  faChevronRight,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RESULTS_PER_PAGE } from '@lib/consts';
import { debounce } from 'lodash-es';
import {
  type ComponentType,
  type RefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { InputWithIcon } from './InputWithIcon';

interface SearchProps<T> {
  results?: T[];
  handleSearch?: (val: string) => Promise<T[]>;
  handleSearchError?: (err: unknown) => void;
  ResultItem: ComponentType<{ result: T }>;
  searchInputLabel: string;
  setSearchInput?: (value: string) => void;
  onSelection: (selection: T) => void;
  startWithSearchString?: string;
  debounceWait?: number;
  searchInputRef?: RefObject<HTMLInputElement>;
  page?: { currentPage: number };
  setPage?: (page: { currentPage: number }) => void;
  loading?: boolean;
}

export const Search = <T extends Record<string, any>>({
  results,
  handleSearch,
  handleSearchError = (err: unknown) => console.error(err),
  ResultItem,
  searchInputLabel,
  setSearchInput,
  onSelection,
  startWithSearchString,
  debounceWait = 300,
  searchInputRef,
  page,
  setPage,
  loading,
}: SearchProps<T>) => {
  const [curResults, setCurResults] = useState<T[] | undefined>(results);
  const debouncedInputHandler = useMemo(
    () =>
      debounce(async (val) => {
        if (setSearchInput) {
          setSearchInput(val);
        }
        if (handleSearch) {
          try {
            if (!val.length) {
              return setCurResults([]);
            }
            const newResults = await handleSearch(val);
            setCurResults(newResults);
          } catch (err: unknown) {
            handleSearchError(err);
          }
        }
      }, debounceWait),
    [],
  );
  useEffect(
    () => () => {
      debouncedInputHandler.cancel();
    },
    [],
  );

  useEffect(() => {
    setCurResults(results);
  }, [results]);

  return (
    <div>
      <InputWithIcon
        defaultValue={startWithSearchString}
        leftIcon={faSearch}
        placeholder={searchInputLabel}
        type="text"
        onChange={(e) => debouncedInputHandler(e.target.value)}
        autoFocus={true}
        ref={searchInputRef}
        marginBottom="-1"
      />
      {/* loading spinner for when a part/customer is being searched */}
      {loading && (!curResults || (curResults && curResults.length === 0)) ? (
        <Box height="30px" marginTop="30px" textAlign={'center'}>
          <Spinner size="lg" />
        </Box>
      ) : null}

      {curResults && curResults.length > 0 ? (
        <>
          {/* loading spinner for page change */}
          {loading ? (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: 2,
              }}
            >
              <Spinner
                size="lg"
                style={{ position: 'relative', top: '-50%', left: '-50%' }}
              />
            </div>
          ) : null}

          <List
            style={{ opacity: loading ? 0.3 : 1.0 }}
            pointerEvents={loading ? 'none' : 'auto'}
            border="1px solid rgb(226,232,240)"
            borderRadius={4}
          >
            {curResults?.slice(0, RESULTS_PER_PAGE).map((result, i) => {
              return (
                <ListItem
                  key={result?.id ?? i}
                  onClick={() => onSelection(result)}
                  // Chakra
                  as={Button}
                  textAlign={'left'}
                  variant="unstyled"
                  role="button"
                  gap={2}
                  alignItems={'center'}
                  borderRadius={0}
                  width={'100%'}
                  p={0}
                  px={3}
                  py={5}
                  height={'64px'}
                  display={'flex'}
                  justifyContent={'flex-start'}
                  borderBottom={'1px solid rgb(226,232,240)'}
                  fontSize={'16px'}
                  lineHeight={'18px'}
                  fontWeight={400}
                  color={'mw.black'}
                  _last={{ borderBottomWidth: 0 }}
                  _odd={{ bg: 'gray.50' }}
                  _hover={{
                    bg: 'gray.200',
                    cursor: 'pointer',
                    fontWeight: 600,
                  }}
                >
                  <ResultItem result={result} />
                </ListItem>
              );
            })}
          </List>
          {page ? (
            <HStack
              style={{ opacity: loading ? 0.3 : 1.0, marginTop: '10px' }}
              pointerEvents={loading ? 'none' : 'auto'}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Button
                isDisabled={page.currentPage === 1}
                onClick={() => {
                  setPage &&
                    page &&
                    setPage({ currentPage: page.currentPage - 1 });
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} fontSize={16} />
              </Button>
              <Text>Page {page.currentPage}</Text>
              <Button
                isDisabled={curResults.length <= RESULTS_PER_PAGE}
                onClick={() => {
                  setPage &&
                    page &&
                    setPage({ currentPage: page.currentPage + 1 });
                }}
              >
                <FontAwesomeIcon icon={faChevronRight} fontSize={16} />
              </Button>
            </HStack>
          ) : null}
        </>
      ) : (
        searchInputRef?.current?.value &&
        !loading && (
          <Text marginTop={'30px'} textAlign="center">
            No results
          </Text>
        )
      )}
    </div>
  );
};

export default Search;
