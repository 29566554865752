import type { QuoteLineDocumentCreateResponse } from '@lib/responses';
import { type UseMutationOptions, useMutation } from '@tanstack/react-query';
import { quoteService } from '@ui/services';

export const uploadQuoteLineDocumentMutation = (
  quoteId: string,
  lineItemId = 'unknown',
) => ({
  mutationFn: (data: File) =>
    quoteService.createQuoteLineDocument(quoteId, lineItemId, data),
});

export function useUploadQuoteLineDocument(
  quoteId: string,
  lineItemId: string,
  options?: Omit<
    UseMutationOptions<QuoteLineDocumentCreateResponse, unknown, unknown>,
    'mutationFn'
  >,
) {
  return useMutation({
    ...uploadQuoteLineDocumentMutation(quoteId, lineItemId),
    ...options,
  });
}
