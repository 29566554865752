import {
  type NegotiatedPriceResponse,
  type OrderLineHistoryResponse,
  type OrderLineResponse,
  type QuoteLineBaseResponse,
  type QuoteWithBaseLinesResponse,
  isEmptyArr,
  isFixedPricingStrategy,
} from '@lib';
import type { PricingStrategy } from '@prisma/client';
import { orderBy, pick } from 'lodash-es';
import { P, match } from 'ts-pattern';
import type { Merge } from 'type-fest';
import { maybeParseNum, maybeParseObjVals } from '../../calculations/util';
import type { ParsedOrderLineCosts } from '../../models';
import type { PartHistoryResponse } from '../../responses/part';
import type { ValidationResponse } from '../types';
import {
  HasNegotiatedPricing,
  NoLastWin,
  NoLastWinAndNoQuoteHistory,
  type ParsedCheckedCosts,
  type PartHistoryError,
  RAF1000PieceMinimumParts,
} from './errors';

// TODO: should return boolean flags for each error type, need to change base type
/** Contains valid part history that can be used for calculations if valid, or raw input if not */
export type PartHistoryValidation = ValidationResponse<
  ValidPartHistoryResponse,
  InvalidPartHistoryResponse,
  PartHistoryError
> & {
  warnings: PartHistoryError[] | [];
};

/** @returns valid:true if can use for calcs, valid:false if not */
export const makePartHistoryValidation = (
  partHistory: PartHistoryWithNegotiated,
  activeQuoteId: string | null,
  pricingStrategy: PricingStrategy,
): PartHistoryValidation => {
  const { partId } = partHistory;

  // RAF specific crap, we need to update the validation logic in a more serious
  // and customizable manner but this is the easiest path to get what they want
  // right now.
  const earlyWarnings: PartHistoryError[] = [];
  if (!Number.isNaN(partId)) {
    const partNumber = Number(partId);
    if (partNumber >= 5000 && partNumber <= 5264) {
      earlyWarnings.push(new RAF1000PieceMinimumParts(partId));
    }
    if (partNumber >= 5500 && partNumber <= 5907) {
      earlyWarnings.push(new RAF1000PieceMinimumParts(partId));
    }
  } else if (!Number.isNaN(partId.substring(1)) && partId[0] === 'M') {
    const partNumber = Number(partId.substring(1));
    if (partNumber >= 2600 && partNumber <= 2823) {
      earlyWarnings.push(new RAF1000PieceMinimumParts(partId));
    }
    if (partNumber >= 2900 && partNumber <= 3145) {
      earlyWarnings.push(new RAF1000PieceMinimumParts(partId));
    }
  }

  // Return early if pricing strategy is fixed.
  //
  // Part history and calcs aren't used for fixed pricing, so we set `valid`
  // to false, but don't include any errors or warnings.
  if (isFixedPricingStrategy(pricingStrategy)) {
    return {
      valid: false,
      errors: [],
      warnings: [...earlyWarnings],
      data: {
        ...partHistory,
      },
    };
  }

  const lastWinErrors = getLastWinError();

  let errors: [] | PartHistoryError[] = [...lastWinErrors.errors];
  let warnings: [] | PartHistoryError[] = [
    ...lastWinErrors.warnings,
    ...earlyWarnings,
  ];

  // negotiated price warning
  partHistory.negotiatedPrice &&
    warnings.push(new HasNegotiatedPricing(partId));

  // sort errors by severity in descending order
  errors = orderBy(errors, [(e) => -e.severity]);
  warnings = orderBy(warnings, [(e) => -e.severity]);

  return match(lastWinErrors)
    .with({ errors: P.when(isEmptyArr) }, ({ lastWin, mostRecentQuoteLine }) =>
      // we pass a guarded valid lastWin here so consumers don't all have to
      // keep running nullish checks themselves
      ({
        valid: true as const,
        data: { ...partHistory, lastWin, mostRecentQuoteLine },
        warnings,
      }),
    )
    .otherwise(({ mostRecentQuoteLine }) => ({
      // TODO: change/add flag like "canCalc" so it's clear what has been validated
      valid: false as const,
      errors,
      warnings,
      data: { ...partHistory, mostRecentQuoteLine },
    }));

  function getLastWinError() {
    const { lastQuotes } = partHistory;
    // if the most recent quote is the active quote or doesn't have a start date
    // ignore it. API is now responsible for this but just in case
    const mostRecentQuote = lastQuotes?.find(
      (q) => q.id !== activeQuoteId && q.startDate,
    );

    // get the quote line for this part
    const mostRecentQuoteLine = mostRecentQuote?.lineItems.find(
      (li) => li.partId === partId,
    );

    const result = match({ ...partHistory, mostRecentQuoteLine })
      .with(
        {
          mostRecentQuoteLine: P.nullish.optional(),
          lastWin: P.nullish.optional(),
        },
        ({ partId }) => ({
          errors: [new NoLastWinAndNoQuoteHistory(partId)],
          warnings: [],
        }),
      )
      .with(
        {
          mostRecentQuoteLine: P.not(P.nullish),
          lastWin: P.nullish.optional(),
        },
        ({ mostRecentQuoteLine }) => ({
          errors: [new NoLastWin(partId)],
          warnings: [],
          mostRecentQuoteLine,
        }),
      )
      /*
      .with(
        P.intersection(
          {
            lastWin: { totalServiceCost: P.nullish.optional() },
          },
          {
            lastWin: { totalLaborCost: P.nullish.optional() },
          },
          {
            lastWin: { totalMaterialCost: P.nullish.optional() },
          }
        ),
        (partHistory) => ({
          errors: [new MissingAllCosts(partId)],
          warnings: [],
          lastWin: partHistory.lastWin,
        })
      )
      */
      .otherwise(({ lastWin }) => {
        if (!lastWin) {
          return {
            errors: [new NoLastWin(partId)],
            warnings: [],
            lastWin: partHistory.lastWin,
          };
        }

        //   const checkCosts = pick(lastWin, [
        //     // 'totalLaborCost',
        //     // 'totalMaterialCost',
        //   ]);

        // get warning flags parse to nums with default 0
        //   const costsWithWarnings = reduce(
        //     checkCosts,
        //     (result, cost, _key) => {
        //       const key = _key as keyof ParsedCheckCosts;
        //       const parsed = maybeParseNum(cost);
        //       const matchRes = match(parsed)
        //         .with(0, () => ({
        //           cost: 0,
        //           warning: new ZeroCost(partId, key),
        //         }))
        //         .with(undefined, () => ({
        //           cost: 0,
        //           warning: new MissingCost(partId, key),
        //         }))
        //         .otherwise((cost) => ({ cost, warning: null }));
        //       matchRes?.warning && result.warnings.push(matchRes.warning);
        //       return {
        //         ...result,
        //         [key]: matchRes.cost,
        //       };
        //     },
        //     {
        //       warnings: [] as PartHistoryError[],
        //     } as ParsedCheckedCosts,
        //   );
        const checkCosts = pick(lastWin, [
          'totalLaborCost',
          'totalMaterialCost',
        ]);
        // NOTE(bb): TS eventually caught up with `checkCosts` always being
        //  empty from prior comment-outing and didn't like (correctly so) it so
        //  adding this as a placeholder to match runtime & static
        const costsWithWarnings: ParsedCheckedCosts = {
          warnings: [] as PartHistoryError[],
          ...maybeParseObjVals(checkCosts, { defaultVal: 0 }),
        };

        const { warnings } = costsWithWarnings;

        return {
          lastWin: {
            ...lastWin,
            // ...costs, // commenting re: above note
            totalBurdenCost: maybeParseNum(lastWin?.totalBurdenCost, 0),
            totalServiceCost: maybeParseNum(lastWin?.totalServiceCost, 0),
            totalLaborCost: maybeParseNum(lastWin?.totalLaborCost, 0),
            totalMaterialCost: maybeParseNum(lastWin?.totalMaterialCost, 0),
          },
          errors: [],
          warnings,
        };
      });

    return { ...result, mostRecentQuoteLine };
  }
};

export type PartHistoryWithNegotiated = PartHistoryResponse & {
  negotiatedPrice: NegotiatedPriceResponse | null;
};

export type ValidatedMostRecentQuoteResponse = {
  mostRecentQuoteLine?: QuoteLineBaseResponse;
  mostRecentQuote?: QuoteWithBaseLinesResponse;
};

export type InvalidPartHistoryResponse = PartHistoryWithNegotiated &
  ValidatedMostRecentQuoteResponse;

export type ValidPartHistoryResponse = Merge<
  PartHistoryWithNegotiated,
  {
    lastWin: ValidLastWin;
  } & ValidatedMostRecentQuoteResponse
>;

export type ValidatedPartHistoryResponse =
  | ValidPartHistoryResponse
  | InvalidPartHistoryResponse;

export type ValidLastWin = Merge<OrderLineResponse, ParsedOrderLineCosts>;
export type InvalidLastWin = OrderLineHistoryResponse;
