import type { MinQuantityRequirement } from '@lib/calculations/min-quantity/types';
import { ValidationError } from '../errors';

export class LowOrderLineMargin extends ValidationError {
  name = 'LowOrderLineMargin';
  severity = 7000 as const;
  private minContrMarginPct: number;
  constructor(private minContrMarginRatio: number) {
    super();
    this.minContrMarginPct = minContrMarginRatio * 100;
  }
  get message() {
    return `Last won contribution margin is less than ${this.minContrMarginPct}%. Part must be re-cost to ensure money is made.`;
  }
}

export class OldLastWin extends ValidationError {
  name = 'OldLastWin';
  severity = 6000 as const;

  constructor(public maxYrsOld: number) {
    super();
  }
  get message() {
    return `Last win was too far in the past. Cannot be more than ${this.maxYrsOld} years ago`;
  }
}

export class RequiresOutsideServices extends ValidationError {
  name = 'RequiresOutsideServices';
  severity = 1020 as const;
  get message() {
    return 'This part requires outside services, please confirm service costs.';
  }
}

export class DefaultOrderLineCalcError {
  name = 'DefaultOrderLineCalcError';
  severity = 9999 as const;
  get message() {
    return `Unexpected error calculating last order.`;
  }
}

export class MinimumOrderLineQuantityError extends ValidationError {
  name = 'MinimumOrderLineQuantityError';
  severity = 5000 as const;

  constructor(public marketRequirement: MinQuantityRequirement) {
    super();
  }

  get message() {
    return `Must order at least ${this.marketRequirement.requiredQuantity} unit(s) to meet the minimum line total of $${this.marketRequirement.requiredTotal}`;
  }
}
