import type { $, U } from 'hotscript';
import type { QtyPrice } from './strategy';

type BrkRange = $<U.Range<1, 10>>;

/** Common shape of visual records with qty_brk & unit_price 1 - 10 i.e.
 * discount & market pricing */
type VisQtyPricing = { default_unit_price: number | null } & {
  [num in BrkRange as `qty_break_${num}` | `unit_price_${num}`]: number | null;
};

/** Extracts qty & price from visual pricing records (discount, market, etc)
 * with common shape of qty_brk & unit_price 1 - 10
 * @returns *sorted* (qty asc) array of qty + price, with default price as qty 0
 * */
export const extractVisQtyPricing = (visPricing: VisQtyPricing): QtyPrice[] => {
  const pricing: QtyPrice[] = [];

  const defaultPrice = visPricing.default_unit_price;
  if (defaultPrice) {
    pricing.push({
      qty: 0,
      price: defaultPrice,
    });
  }
  // find price at quantity level
  for (let _brkNum = 1; _brkNum <= 10; _brkNum++) {
    const brkNum = _brkNum as BrkRange;

    const qty = visPricing[`qty_break_${brkNum}`];
    const price = visPricing[`unit_price_${brkNum}`];
    if (qty && price) {
      pricing.push({ qty, price });
    }
  }

  return pricing;
};
