import { Image, Page, Text, View } from '@react-pdf/renderer';
import { useQuotePdfContext } from '@ui/pages/QuoteBuilder/hooks';
import type { FC } from 'react';
import { pdfStyles } from '../pdf-styles';
import { CustomerQuotePdfTemplate } from './template';

export const CustomerQuote: FC = () => {
  const { quoteId } = useQuotePdfContext();
  return (
    <Page size="A4" style={pdfStyles.page}>
      <View style={pdfStyles.row}>
        <View style={[pdfStyles.section, pdfStyles.alignCenter]}>
          <Image
            src={'/images/PON/PONTOTOC_SPRING_RGB_PRIMARY_LOGO_AW.png'}
            // the default pdfStyles.logo is specialized for CAP
            // customize logo style to preserve logo aspect ratio and margins
            style={[pdfStyles.logo, { height: '40px' }]}
          />
          <Text style={pdfStyles.textSmall}>ISO 9001 Registered</Text>
        </View>
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.bold}>
            Pontotoc Spring - A MW Components Company
          </Text>
          <Text>260 Industrial Drive</Text>
          <Text>Pontotoc, MS 38863</Text>
          <Text>Fax (662) 489 - 3713</Text>
          <Text>Made in USA</Text>
        </View>
        <View
          style={[
            pdfStyles.section,
            pdfStyles.alignCenter,
            pdfStyles.maxWidthFull,
          ]}
        >
          <Text style={[pdfStyles.quoteNumber, pdfStyles.bold]}>Quotation</Text>
          <Text>
            Quotation Number: <Text style={pdfStyles.bold}>{quoteId}</Text>
          </Text>
          <Text style={pdfStyles.textSmall}>
            Please refer to the quotation number when ordering
          </Text>
        </View>
      </View>
      <CustomerQuotePdfTemplate showPartDescription={false} />
      <View style={pdfStyles.row}>
        <View style={pdfStyles.fullRow}>
          <Text>FOB:</Text>
          <Text style={[pdfStyles.bold, pdfStyles.textSmall]}>
            Block Tolerances DO NOT APPLY to items on this quotation. Commercial
            tolerances per DIN 2089 will apply if not otherwise referenced on
            the drawing. We reserve the right to pass through increases in
            material, energy, freight and/or outside service cost in effect at
            the time of manufacture. Items ordered as part of a blanket or
            multiple release agreement must be taken with 12 months manufacture.
            Quoted delivery subject to material availability at time of order.
            DFAR items subject to material availability at time of order. To
            prevent any problems, parts not previously manufactured will have a
            quality assurance review prior to the start of production
          </Text>
        </View>
      </View>
    </Page>
  );
};
