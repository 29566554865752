import type { PermissionsReqInput } from '@lib/requests/permissions';
import type { UserWithRoles } from '@prisma-types';
import { ResourceEnum, type ResourcePermission } from '@prisma/client';
import is from '@sindresorhus/is';
import {
  type UserWithSettingsResponse,
  definedEntries,
  isDefinedProp,
} from '..';

export enum PermActions {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}
export type PermissionMap = {
  [key in ResourceEnum]?: Set<PermActions>;
};

/**
 * Return a true or false response to whether a list of roles has the permissions required
 *
 * @param roles
 * @param permissions
 * @returns true|false
 */
export const hasPermissions = (
  permissions: PermissionsReqInput,
  user: UserWithRoles | UserWithSettingsResponse,
  // site: SiteWithRoles , TODO: define and parse as well
): boolean => {
  const userPermissions: ResourcePermission[] = [
    ...user.roles.map((r) => r.permissions),
    ...user.permissions,
    //   ...site.roles.map((r) => r.permissions),
    //  ...site.permissions
  ].flat();
  // map bool flags to actions
  const hasPermReqs: PermissionMap = userPermissions.reduce(
    (acc, resourcePerm) => {
      const { resource, read, create, update, delete: del } = resourcePerm;
      const actions: PermActions[] = [];
      if (read) actions.push(PermActions.READ);
      if (create) actions.push(PermActions.CREATE);
      if (update) actions.push(PermActions.UPDATE);
      if (del) actions.push(PermActions.DELETE);

      if (isDefinedProp(acc, resource)) {
        actions.forEach((action) => {
          acc[resource].add(action);
        });
      } else {
        acc[resource] = new Set(actions);
      }
      return acc;
    },
    {} as PermissionMap,
  );

  // Special case for Super Admin
  if (hasPermReqs[ResourceEnum.SUPERADMIN]) return true;

  return definedEntries(permissions).every(([resource, actions]) => {
    const actionsSet = is.array(actions) ? new Set(actions) : actions;
    for (const action of actionsSet) {
      if (!hasPermReqs[resource]?.has(action)) return false;
    }
    return true;
  });
};
