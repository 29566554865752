export const styles = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#999999',
  borderCollapse: 'collapse',
  marginTop: '16px',
  thead: {
    backgroundColor: 'mw.lightGrey',
    th: {
      borderBottom: '1px solid #999999',
      borderLeft: '1px solid #999999',
      fontFamily: 'sans-serif',
    },
  },
  td: {
    borderBottom: '1px solid #999999',
    borderLeft: '1px solid #999999',
  },
};
