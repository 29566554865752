import { calculateAndFormatLeadTimeWeeks } from '@lib/calculations/lead-time';
import { Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '@ui/pdfs/pdf-styles';
import type { QuotePdfTemplateConfig } from '../config';

type PdfLineItemDetailsProps = {
  drawing?: string | null;
  drawingRevision?: string | null;
  leadTime?: number | null;
  config: QuotePdfTemplateConfig;
};

export const PdfLineItemDetails: React.FC<PdfLineItemDetailsProps> = ({
  drawing,
  drawingRevision,
  leadTime,
  config,
}) => {
  return (
    <View style={{ width: '110pt' }}>
      {config.showDrawing && drawing && (
        <View
          style={[pdfStyles.gap10, pdfStyles.row, pdfStyles.justifyBetween]}
        >
          <Text>DWG ID</Text>
          <Text>{drawing}</Text>
        </View>
      )}

      {config.showDrawing && drawingRevision && (
        <View
          style={[pdfStyles.gap10, pdfStyles.row, pdfStyles.justifyBetween]}
        >
          <Text>Revision No</Text>
          <Text>{drawingRevision}</Text>
        </View>
      )}

      {leadTime && (
        <View
          style={[pdfStyles.gap10, pdfStyles.row, pdfStyles.justifyBetween]}
        >
          <Text>Lead Time</Text>
          <Text>{calculateAndFormatLeadTimeWeeks(leadTime)}</Text>
        </View>
      )}
    </View>
  );
};
