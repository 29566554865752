import {
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { PageFooter } from '@ui/components/layout/PageFooter';
import { RoutesConfig } from '@ui/config/routes';
import { isAxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/layout/PageHeader';
import { quoteService } from '../../services';
import { monthDayYear } from '../../util/dates';
import { useValidationToast } from '../QuoteBuilder/hooks/useToast';
import { ViewCustomerHeader } from './ViewCustomerHeader';
import { ViewQuoteLineItem } from './ViewQuoteLineItem';

export const QuoteView = () => {
  const { id: quoteId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  if (!quoteId) {
    console.error('No quoteId provided in QuoteView');
    navigate(RoutesConfig.quoteQueue.path);
    return null;
  }
  const {
    isPending: isQuotePending,
    data: quote,
    error: quoteError,
  } = useQuery({
    queryKey: ['quote', 'view', 'quote', quoteId],
    queryFn: async () => {
      const quote = await quoteService.getQuote(quoteId);
      // Apply customer association fields as defaults
      quote.customerHeader = {
        ...quote.customer,
        name: quote.customer?.name, // name needs to be set explicitly in case of changes since we are using JSON
        ...quote.customerHeader,
      };

      return quote;
    },
  });

  const {
    isPending: isPdfPending,
    data: quotePdf,
    error: pdfError,
  } = useQuery({
    queryKey: ['quote', 'view', 'pdf', quoteId],
    queryFn: async () => await quoteService.getQuotePDF(quoteId),
  });

  const {
    isOpen: isPDFPreviewOpen,
    onOpen: onPDFPreviewOpen,
    onClose: onPDFPreviewClose,
  } = useDisclosure();

  const { genericErrorToast } = useValidationToast();

  const handlePDFPreview = async () => {
    onPDFPreviewOpen();
  };

  const pdfUrl =
    quotePdf && `data:application/pdf;base64,${quotePdf}#toolbar=0&navpanes=0`;
  const pdfButtonLabel = isPdfPending
    ? 'Loading PDF...'
    : quotePdf
      ? 'View PDF'
      : 'No PDF available';

  if (quoteError) {
    console.error('Error loading quote in QuoteView', quoteError);
    genericErrorToast('Error loading quote', quoteError.message, false, true);
    navigate(RoutesConfig.quoteQueue.path);
  }

  if (
    pdfError &&
    !(isAxiosError(pdfError) && pdfError?.response?.status === 404)
  ) {
    console.error('Error loading PDF in QuoteView', pdfError);
    genericErrorToast('Error loading PDF', pdfError.message, false);
  }

  return (
    <Skeleton isLoaded={!isQuotePending} width="100%">
      <Flex width="100%" height="100%" direction="column">
        <PageHeader
          title={`Viewing Quote ${quote?.quoteId}`}
          bgColor="mw.grey"
          textColor="mw.white"
          subtitle={`Issued ${monthDayYear(quote?.startDate)}`}
        />
        <Flex px={{ base: 0, md: '20px' }} width="100%" direction="column">
          <>
            <Spacer borderBottom="1px solid #eee" mb={4} />
            <ViewCustomerHeader customerInfo={quote?.customer} />
            <Spacer borderBottom="1px solid #eee" mb={4} mt={5} />
            {/* Parts */}
            <Box marginTop={5}>
              {quote?.lineItems.map((lineItem) => (
                <ViewQuoteLineItem
                  key={lineItem.id}
                  line={lineItem}
                  index={lineItem.lineNumber}
                />
              ))}
            </Box>
          </>
        </Flex>
        <Spacer borderBottom="1px solid #eee" mb={4} mt={5} />
        <PageFooter>
          <Box
            display={'flex'}
            width="100%"
            justifyContent="space-between"
            flexDirection={'row'}
          >
            <Flex
              alignContent={'end'}
              direction={'row'}
              gap={2}
              flexGrow={1}
              justifyContent={'end'}
            >
              <Button
                marginLeft={2}
                onClick={() => handlePDFPreview()}
                gap={2}
                bgColor="#0b7078"
                color="white"
                _hover={{ bg: '#046068' }}
                borderRadius={'0px'}
                title={pdfButtonLabel}
                isDisabled={!quotePdf}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  fontSize={12}
                  aria-label={pdfButtonLabel}
                />
                {pdfButtonLabel}
              </Button>
            </Flex>
          </Box>
        </PageFooter>
      </Flex>
      {quote && quotePdf && (
        <Modal
          onClose={onPDFPreviewClose}
          size={'full'}
          isOpen={isPDFPreviewOpen}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Quote {quote.quoteId}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <iframe
                src={pdfUrl}
                width="100%"
                height={window.innerHeight - 152}
                title={`Quote ${quote.quoteId}`}
              />
            </ModalBody>
            <ModalFooter justifyContent="start">
              <HStack width="100%" justifyContent="end">
                {pdfUrl && (
                  <Button
                    marginLeft={2}
                    gap={2}
                    bgColor="#0b7078"
                    color="white"
                    _hover={{ bg: '#046068', textDecoration: 'none' }}
                    as={Link}
                    href={pdfUrl}
                    download={`${quote.quoteId}-${Date.now()}.pdf`}
                  >
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      fontSize={12}
                      aria-label="Download PDF"
                    />
                    Download PDF
                  </Button>
                )}
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Skeleton>
  );
};
