import { Card, Center, Text } from '@chakra-ui/react';
import type { FC } from 'react';

export const NotFound: FC = () => {
  return (
    <Center height="100vh">
      <Card padding={10} justifyContent="center">
        <Text fontSize={36}>404</Text>
        <Text>Couldn't find what you're looking for</Text>
      </Card>
    </Center>
  );
};
