import { Box } from '@chakra-ui/react';
import type { PricingConfigResponse } from '@lib/responses';
import type {
  PricingConfigCreateFormData,
  PricingConfigUpdateFormData,
} from '@lib/validation';
import Loading from '@ui/components/Loading';
import {
  useAdminPricingConfigs,
  useCreateAdminPricingConfig,
  useUpdateAdminPricingConfig,
} from '@ui/data/admin/configs';
import { useGlobalState } from '@ui/hooks';
import { useValidationToast } from '@ui/pages/QuoteBuilder/hooks/useToast';
import { isAxiosError } from 'axios';
import { useLayoutEffect, useState } from 'react';
import { ConfigErrorAlert } from './ConfigErrorAlert';
import { ConfigSelector } from './ConfigSelector';
import { PricingConfigCreateForm } from './PricingConfigCreateForm';
import { PricingConfigForm } from './PricingConfigForm';

export const PricingConfigTab = ({ isActive }: { isActive: boolean }) => {
  const { setActiveModal } = useGlobalState();
  const { genericErrorToast } = useValidationToast();

  const [selectedConfig, setSelectedConfig] =
    useState<PricingConfigResponse | null>(null);

  const pricingConfigsQuery = useAdminPricingConfigs({
    enabled: isActive,
  });
  const createPricingConfigMutation = useCreateAdminPricingConfig();
  const updatePricingConfigMutation = useUpdateAdminPricingConfig(
    selectedConfig?.id,
  );

  /**
   * Using a layout effect prevents a flash of the empty form before the
   * setState call below.
   *
   * Syncs the `selectedConfig` state with query data. It's important to do
   * this after a refetch so that the form default values are updated and the
   * `isDirty` state is accurate after a config update.
   */
  useLayoutEffect(() => {
    if (pricingConfigsQuery.data?.length) {
      setSelectedConfig(
        (selectedConfig) =>
          pricingConfigsQuery.data.find(
            (config) => config.id === selectedConfig?.id,
          ) ?? pricingConfigsQuery.data[0],
      );
    }
  }, [pricingConfigsQuery.data]);

  async function createPricingConfig(data: PricingConfigCreateFormData) {
    try {
      const config = await createPricingConfigMutation.mutateAsync(data);

      setSelectedConfig(config);
      setActiveModal(null);
    } catch (err) {
      console.error(err);

      genericErrorToast(
        'Failed to create pricing config',
        isAxiosError(err)
          ? err.response?.data.message
          : 'An unexpected error occurred',
      );
    }
  }

  async function updatePricingConfig(data: PricingConfigUpdateFormData) {
    try {
      await updatePricingConfigMutation.mutateAsync(data);
    } catch (err) {
      // handled during render
      console.error(err);
    }
  }

  function handleCreateConfig() {
    setActiveModal({
      title: 'Create Pricing Config',
      children: <PricingConfigCreateForm onSubmit={createPricingConfig} />,
    });
  }

  if (pricingConfigsQuery.isLoading) {
    return <LoadingUI />;
  }

  if (pricingConfigsQuery.isSuccess) {
    if (pricingConfigsQuery.data.length === 0 || !selectedConfig) {
      return <EmptyUI />;
    }

    return (
      <SuccessUI
        data={pricingConfigsQuery.data}
        selectedConfig={selectedConfig}
        onCreate={handleCreateConfig}
        onUpdate={updatePricingConfig}
        onSelectConfig={setSelectedConfig}
      />
    );
  }

  if (pricingConfigsQuery.isError) {
    return (
      <ErrorUI
        message={
          isAxiosError(pricingConfigsQuery.error)
            ? pricingConfigsQuery.error.response?.data.message
            : pricingConfigsQuery.error.message
        }
        onRetry={pricingConfigsQuery.refetch}
        isLoading={pricingConfigsQuery.isFetching}
      />
    );
  }

  return null;
};

function LoadingUI() {
  return (
    <Box padding={2}>
      <Loading />
    </Box>
  );
}

function EmptyUI() {
  return (
    <Box>No pricing configs found. Please create a pricing config first.</Box>
  );
}

function SuccessUI({
  data,
  selectedConfig,
  onCreate,
  onUpdate,
  onSelectConfig,
}: {
  data: PricingConfigResponse[];
  selectedConfig: PricingConfigResponse;
  onCreate: () => void;
  onUpdate: (data: PricingConfigUpdateFormData) => void;
  onSelectConfig: (config: PricingConfigResponse | null) => void;
}) {
  return (
    <PricingConfigForm
      key={selectedConfig.id}
      defaultConfig={selectedConfig}
      onSubmit={onUpdate}
    >
      {({ isDirty, isSubmitting }) => (
        <ConfigSelector
          selectedConfigId={selectedConfig?.id}
          configOptions={data.map((config) => ({
            label: config.name,
            value: config.id,
          }))}
          onCreateConfig={onCreate}
          onSelectConfig={(id) =>
            onSelectConfig(data.find((config) => config.id === id) ?? null)
          }
          isDisabled={!isDirty}
          isLoading={isSubmitting}
        />
      )}
    </PricingConfigForm>
  );
}

function ErrorUI({
  message,
  onRetry,
  isLoading = false,
}: { message?: string; onRetry: () => void; isLoading?: boolean }) {
  return (
    <ConfigErrorAlert
      title="Failed to load pricing configs"
      message={message}
      onRetry={onRetry}
      isLoading={isLoading}
    />
  );
}
