import type { PartOptionsResponse } from '@lib/responses';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import { partService } from '@ui/services';
import { useAuth } from '@ui/state/auth';

export const partOptionsQuery = (partId: string, siteId = 'unknown') => {
  return {
    queryKey: [siteId, 'part', partId, 'partOptions'],
    queryFn: () => partService.getPartOptions(siteId, partId),
  };
};

export function useGetPartOptions(
  partId: string,
  options?: Omit<UseQueryOptions<PartOptionsResponse>, 'queryKey' | 'queryFn'>,
) {
  const { currentSite } = useAuth();

  const isEnabled =
    !!currentSite?.id &&
    currentSite.code === 'RAF' &&
    (options?.enabled ?? true);

  // Trying to avoid RAF specific code, but right now we're only loading
  // part options for RAF. To centralize the fetching logic a little bit,
  // we pass back the isEnabled flag so the caller can decide when to
  // render without hardcoding the site code.
  return {
    ...useQuery({
      ...partOptionsQuery(partId, currentSite?.id),
      ...options,
      enabled: isEnabled,
    }),
    isEnabled,
  };
}
