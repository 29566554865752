import { useAuth } from '@ui/state/auth';
import { useMemo } from 'react';

export const useActiveSiteConfig = () => {
  const { currentSite } = useAuth();
  if (!currentSite) {
    throw new Error('Users must have a current site to use this feature'); // if this throws, there's a whoops higher up
  }
  const { siteConfig } = currentSite;

  const configs = useMemo(
    () => ({
      siteConfig,
      quotingConfig: siteConfig.quotingConfig,
      pricingConfig: siteConfig.quotingConfig.pricingConfig,
      costingConfig: siteConfig.quotingConfig.costingConfig,
    }),
    [siteConfig],
  );

  return configs;
};
