import { IconButton, List, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { EllipsisVerticalIcon } from '@ui/components/Icons';
import { Show } from '../Show';

interface QuoteLineActionsProps {
  children: React.ReactNode;
}

/**
 * Wraps quote line actions
 *
 * Renders a menu or button group based on screen size
 */
export const QuoteLineActions: React.FC<QuoteLineActionsProps> = ({
  children,
}) => {
  return (
    <>
      <Show above="md">
        <List
          display="flex"
          borderRadius="6"
          borderWidth="1px"
          borderColor="mw.lightGrey"
          borderStyle="solid"
        >
          {children}
        </List>
      </Show>

      <Show below="md">
        <Menu>
          <MenuButton as={IconButton}>
            <EllipsisVerticalIcon />
          </MenuButton>
          <MenuList>{children}</MenuList>
        </Menu>
      </Show>
    </>
  );
};
