import type { PartResponse } from '@lib';
import { RESULTS_PER_PAGE } from '@lib/consts';
import { isAxiosError } from 'axios';
import { type FC, useEffect, useRef, useState } from 'react';
import Search from '../../components/form/Search';
import { partService } from '../../services';
import { PartSearchResultItem } from './PartSearchResultItem';
import { useValidationToast } from './hooks/useToast';

interface PartPickerProps {
  onSelection: (part: PartResponse) => Promise<void>;
  startWithSearchString?: string;
  customerId: string | null;
}

type ResultsCache = {
  [key: string]: PartResponse[][];
};

export const PartPicker: FC<PartPickerProps> = ({
  onSelection,
  startWithSearchString,
  customerId,
}) => {
  const { genericErrorToast } = useValidationToast();
  const [searchInput, setSearchInput] = useState('');
  const [results, setResults] = useState<PartResponse[]>([]);
  const [totalResultCount, setTotalResultCount] = useState(0);
  const searchInputRef = useRef<HTMLInputElement>(null);
  // use object instead of int to trigger setPage on initial search where page==1
  const [page, setPage] = useState({ currentPage: 1 });
  const [loading, setLoading] = useState(false);

  // cache results
  const resultsCache = useRef<ResultsCache>({});

  const fetchResources = async () => {
    if (searchInput.length > 0) {
      // first check the results cache for the current search term and page
      if (resultsCache.current?.[searchInput]?.[page.currentPage - 1]) {
        setResults(resultsCache.current[searchInput][page.currentPage - 1]);
        return;
      }

      // if not present in the cache, do the API call
      // we're asking for a set of items 1 greater than what we show, so that
      // if our shown number or fewer is returned, we know we're on the last page
      // (see Search component)
      setLoading(true);

      try {
        const data = await partService.searchParts({
          customerId,
          search: searchInput,
          limit: RESULTS_PER_PAGE + 1,
          offset: (page.currentPage - 1) * RESULTS_PER_PAGE,
        });

        setResults(data.parts);
        // cache the API call so we don't need to do it again on this page
        if (!resultsCache.current[searchInput]) {
          resultsCache.current[searchInput] = [];
        }
        resultsCache.current[searchInput][page.currentPage - 1] = data.parts;
      } catch (err: unknown) {
        console.error(err);

        genericErrorToast(
          'Error loading parts',
          isAxiosError(err) ? err.response?.data.message : 'Please try again.',
        );
      } finally {
        setLoading(false);
      }
    } else {
      // reset the results when search box is empty
      setResults([]);
    }
  };

  useEffect(() => {
    // on search change, set page to 1 to trigger search
    setPage({ currentPage: 1 });
  }, [searchInput]);

  useEffect(() => {
    // always run on page change
    (async () => {
      await fetchResources();
    })();
  }, [page]);

  const handleSearchSelection = async (part: PartResponse) => {
    // set loading animation on part selection
    setLoading(true);
    // wait for the part selection to run
    await onSelection(part);
    // reset input value so user can select multiple parts
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
      searchInputRef.current.focus();
    }
    setSearchInput('');
    setLoading(false);
  };

  return (
    <Search
      {...{
        results,
        ResultItem: PartSearchResultItem,
        searchInputLabel: 'Search parts...',
        setSearchInput,
        onSelection: handleSearchSelection,
        startWithSearchString,
        searchInputRef,
        loading,
        setPage,
        page,
      }}
    />
  );
};
