import { Badge, Flex, HStack, Text } from '@chakra-ui/react';
import type { CustomerResponse } from '@lib';
import type { FC } from 'react';

export const isDefined = (str: string | null) => {
  // some fields of the database have string "undefined"
  // some non-US and non-Canada locations have "00" for state
  return !!str && str !== 'undefined' && str !== '00';
};

export const locationString = ({
  city,
  state,
  countryCode,
}: {
  city: string | null;
  state: string | null;
  countryCode: string | null;
}) => {
  const addressParts = [];
  if (isDefined(city)) addressParts.push(city);
  if (isDefined(state)) addressParts.push(state);
  if (isDefined(countryCode)) addressParts.push(countryCode);
  return addressParts.join(', ');
};

export const CustomerSearchResultItem: FC<{ result: CustomerResponse }> = ({
  result: customer,
}) => {
  return (
    <Flex direction="column">
      <Text isTruncated>
        {customer.name} ({customer.customerId})
      </Text>
      <Text fontSize="xs">{locationString(customer)}</Text>
      <HStack>
        {customer.sites &&
          customer.sites.map((site) => {
            return <Badge key={site.code}>{site.code}</Badge>;
          })}
      </HStack>
    </Flex>
  );
};
