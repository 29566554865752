import { Box } from '@chakra-ui/react';
import type { PartOptionsResponse } from '@lib/responses';
import type { LinePathType } from '../types';
import { PartOptionGroupSelect } from './PartOptionGroupSelect';

type PartOptions = {
  options?: PartOptionsResponse;
  linePath: LinePathType;
};

export const PartOptions: React.FC<PartOptions> = ({ options, linePath }) => {
  if (options?.optionGroups.length) {
    return (
      <Box
        display="grid"
        gap={2}
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        paddingX={4}
        width="full"
      >
        {options.optionGroups.map((group, index) => (
          <PartOptionGroupSelect
            key={group.name}
            groupName={group.name}
            options={group.options}
            optionPath={`${linePath}.partOptions.${index}`}
            weight={options.weight}
          />
        ))}
      </Box>
    );
  }

  return null;
};
