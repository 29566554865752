import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
} from '@chakra-ui/react';

export const ConfigErrorAlert = ({
  title,
  message,
  onRetry,
  isLoading = false,
}: {
  title: string;
  message?: string;
  onRetry: () => void;
  isLoading?: boolean;
}) => {
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>{title}</AlertTitle>
      {message && <AlertDescription>{message}</AlertDescription>}

      <Box ml="auto">
        <Button
          ml={2}
          variant="link"
          colorScheme="black"
          onClick={onRetry}
          isLoading={isLoading}
        >
          Try again
        </Button>
      </Box>
    </Alert>
  );
};
