import {
  type IconDefinition,
  faCircleInfo,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import type { ValidationError } from '@lib';
import { P, match } from 'ts-pattern';
import theme from '../config/theme';

/**
 * @param V Valid props appended
 * @param I Invalid props appended
 * @param E Error props
 *
 * @returns discriminated union on props valid and hasFailed
 * valid - indicates errors
 * hasFailed - indicates any failed validation including errors, warnings, and info
 *
 */
export type UIManagerBase<
  V = {},
  I = {},
  E extends ErrorPropsBase = ErrorPropsBase,
> =
  | ({
      valid: true;
    } & (
      | { errorProps: E[]; mostSevere: E | undefined; hasFailed: true }
      | { errorProps: E[]; hasFailed: false }
    ) &
      V)
  | ({
      valid: false;
    } & { errorProps: E[]; mostSevere: E | undefined; hasFailed: true } & I);

export const getErrorPropsBase = <E extends ValidationError>(
  error: E,
  formatTitle: (status: 'error' | 'warning' | 'info', error: E) => string = (
    _,
    error,
  ) => error.message,
) => {
  const severityProps = match(error.severity)
    .with(P.number.gt(8000), (severity) => ({
      color: theme.colors.validation.error,
      status: 'error' as const,
      severity,
      icon: faTriangleExclamation,
    }))
    .with(P.number.between(5000, 8000), (severity) => ({
      color: theme.colors.validation.warning,
      status: 'warning' as const,
      severity,
      icon: faTriangleExclamation,
    }))
    .otherwise((severity) => {
      return {
        color: theme.colors.validation.info,
        status: 'info' as const,
        severity,
        icon: faCircleInfo,
      };
    });

  const { status } = severityProps;

  return {
    ...severityProps,
    title: formatTitle(status, error),
    userMessage: error.message,
  } satisfies UIManagerErrorBase;
};

export type UIManagerErrorBase = {
  title: string;
  color: string;
  status: 'error' | 'warning' | 'info';
  severity: number;
  userMessage: string;
  icon: IconDefinition;
};

export type ErrorPropsBase = ReturnType<typeof getErrorPropsBase>;
